@charset "UTF-8";

#loading-manipulate {
  position: absolute;
  z-index: 9999;
  top: 30%;
  left: 30%;
  width: 250px;
  border-radius: 4px;
  padding: 10px 40px 10px 40px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  transition-property: opacity;
  transition-duration: 2s;
  z-index: 9999;
}
#loading-manipulate[data-show="true"] {
  opacity: 0.7;
  display: block;
}
#loading-manipulate[data-show="false"] {
  opacity: 0;
  display: none;
}
#loading-manipulate:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}
@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

/*FULLSCREEN*/
div#fsbtn {
  z-index: 999;
  margin-bottom: -12.8px;
  display: inline-block !important;
  position: relative;
  margin-left: 0.7rem;
  background: url("assets/fsbtn.png") -1px -35px;
  width: 37px;
  height: 37px;
  background-repeat: no-repeat;
}

div#fsbtn:not(.disabled):hover {
  margin-bottom: -12.8px;
  display: inline-block !important;
  position: relative;
  margin-left: 0.7rem;
  background: url("assets/fsbtn.png") -1px 1px;
  width: 37px;
  height: 37px;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  div#fsbtn {
    margin-bottom: -12.8px;
    display: inline-block !important;
    position: relative;
    margin-left: 0.7rem;
    background: url("assets/fsbtn@2x.png") -1px -35px;
    background-size: 37px 72px;
    width: 37px;
    height: 37px;
    background-repeat: no-repeat;
  }

  div#fsbtn:not(.disabled):hover {
    margin-bottom: -12.8px;
    display: inline-block !important;
    position: relative;
    margin-left: 0.7rem;
    background: url("assets/fsbtn@2x.png") -1px 1px;
    background-size: 37px 72px;
    width: 37px;
    height: 37px;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}
div#fsExitBtn {
  width: 20px;
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("assets/x.png");
  height: 20px;
  right: 70px;
  top: 20px;
  cursor: pointer;
}

/*#fsbtn{
    width: 100px;
    font-size: 15px;
    position:absolute;
    top:0;
    left:0;
    height:40px;
    visibility: hidden;
}

.resizable:hover #fsbtn{
    visibility: visible;
    transition: 1s;
    }*/
.fullscreen {
  /*transform: scale(1.4, 1.4) translateX(0%) translateY(0%);*/
  transform: scale(1, 1) translateX(0%) translateY(0%);
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

div#fsExitBtn {
  margin: 0px;
  height: 0px;
  visibility: hidden;
}

div#fsHeader {
  font-size: 36px;
  font-weight: 400;
  color: #da1616;
  margin: 0px;
  height: 0px;
  visibility: hidden;
}

img#fsBranding {
  margin: 0px;
  height: 0px;
  visibility: hidden;
}

/*.resizable:-webkit-full-screen iframe {
    height: var(--fs-iframe-height);

}

.resizable:-moz-full-screen iframe {
    height: var(--fs-iframe-height);
}

.resizable:-ms-fullscreen iframe {
    height: var(--fs-iframe-height);
}*/
.resizable:-webkit-full-screen div#fsHeader {
  visibility: visible;
  margin-bottom: 0.6rem;
  margin-top: var(--fs-Offset-Y);
  height: auto;
}

.resizable:-moz-full-screen div#fsHeader {
  visibility: visible;
  margin-bottom: 0.6rem;
  margin-top: var(--fs-Offset-Y);
  height: auto;
}

.resizable:-ms-fullscreen div#fsHeader {
  visibility: visible;
  margin-bottom: 0.6rem;
  margin-top: var(--fs-Offset-Y);
  height: auto;
}

.resizable:-webkit-full-screen div#fsExitBtn {
  visibility: visible;
  width: 20px;
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("assets/x.png");
  height: 20px;
  right: 13px;
  top: 13px;
  cursor: pointer;
}

.resizable:-moz-full-screen div#fsExitBtn {
  visibility: visible;
  width: 20px;
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("assets/x.png");
  height: 20px;
  right: 70px;
  top: 20px;
  cursor: pointer;
}

.resizable:-ms-fullscreen div#fsExitBtn {
  visibility: visible;
  width: 20px;
  position: absolute;
  background-repeat: no-repeat;
  background-image: url("assets/x.png");
  height: 20px;
  right: 70px;
  top: 20px;
  cursor: pointer;
}

.resizable:-webkit-full-screen img#fsBranding {
  visibility: visible;
  height: auto;
}

.resizable:-moz-full-screen img#fsBranding {
  visibility: visible;
  height: auto;
}

.resizable:-ms-fullscreen img#fsBranding {
  visibility: visible;
  height: auto;
}

iframe#demo-embed {
  width: 700px;
  visibility: hidden;
}

.resizable:-webkit-full-screen #demo-embed {
  height: var(--fs-iframe-height) !important;
  width: 90vw !important;
  margin-left: var(--fs-marginX) !important;
  margin-right: var(--fs-marginX) !important;
}

.resizable:-moz-full-screen #demo-embed {
  height: var(--fs-iframe-height) !important;
  width: 90vw !important;
  margin-left: var(--fs-marginX) !important;
  margin-right: var(--fs-marginX) !important;
}

.resizable:-ms-fullscreen #demo-embed {
  height: var(--fs-iframe-height) !important;
  width: 90vw !important;
  margin-left: var(--fs-marginX) !important;
  margin-right: var(--fs-marginX) !important;
}

.demonstration .object .resizable:-moz-full-screen {
  width: -moz-available;
  height: -moz-available;
}

.demonstration .object .resizable:-webkit-full-screen {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

.demonstration .object .resizable:-ms-fullscreen {
  width: -ms-fill-available;
  height: -ms-fill-available;
  height: 100%;
  width: 100%;
}

/*SPINNER*/
.grayOutline {
  display: block;
  position: relative;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
  background-color: none;
  top: 35%;
  left: 8%;
  height: 326px;
  width: 326px;
}

.extraContainer {
  position: relative;
  top: 136px;
  left: 136px;
}

#spinner svg {
  width: 100px;
  height: 100px;
}

#spinner svg.arc {
  position: absolute;
  -webkit-animation: arcRotation 1.02s ease infinite;
  -moz-animation: arcRotation 1.02s ease infinite;
  -o-animation: arcRotation 1.02s ease infinite;
  animation: arcRotation 1.02s ease infinite;
}

@keyframes arcRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes arcRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes arcRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes arcRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#spinner svg#arc2 {
  animation-delay: 0.025s;
}

#spinner svg#arc3 {
  animation-delay: 0.05s;
}

#spinner svg#arc4 {
  animation-delay: 0.075s;
}

#spinner svg#arc5 {
  animation-delay: 0.1s;
}

#spinner svg#arc6 {
  animation-delay: 0.12s;
}

#spinner svg#arc7 {
  animation-delay: 0.13s;
}

#spinner svg#arc8 {
  animation-delay: 0.14s;
}

#spinner svg#arc9 {
  animation-delay: 0.15s;
}

#spinner svg#arc10 {
  animation-delay: 0.16s;
}

.arc {
  stroke: #bfbfbf;
  stroke-linecap: square;
  fill: none;
  stroke-width: 30;
}

#bottomCircle {
  stroke: #e5e5e5;
  fill: none;
  stroke-width: 30;
}

#spinner {
  position: absolute;
  top: 25%;
  left: 25%;
  transform: translate(50%, 50%);
}

/*LOADING OVERLAY*/
div#loading-background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-image: var(--loading-background-url);
  height: var(--loading-background-height);
  width: var(--loading-background-width);
}

/*FALLBACK SLIDES*/
#slideshow-fallback {
  z-index: 999;
  opacity: 100;
  transition-property: opacity;
  transition-duration: 2s;
  text-align: left;
}
#slideshow-fallback[data-show="false"] {
  z-index: 0;
  opacity: 0;
}

#slides {
  position: relative;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

.slide {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
}

.showing {
  opacity: 1;
  z-index: 2;
}

div.slide-overlay {
  display: none;
  position: relative;
  filter: brightness(0.6);
  z-index: 3;
  background-color: white;
  opacity: 0.3;
}

div.embed:hover div.slide-overlay {
  display: block;
}

/*BETA TAG*/
div#beta-tag-outer {
  box-sizing: content-box;
  top: 5px;
  display: inline-block !important;
  position: relative;
  width: 75px;
  height: 34px;
  padding-bottom: 10px;
}

div#beta-tag {
  box-sizing: content-box;
  padding-bottom: 5px;
  top: 5px;
  display: inline-block !important;
  position: relative;
  margin-left: 0.7rem;
  background: url("assets/beta-sprite.png") 0px -34px;
  width: 67px;
  height: 34px;
  background-repeat: no-repeat;
}

div#beta-tag:not(.disabled):hover {
  box-sizing: content-box;
  padding-bottom: 5px;
  top: 5px;
  display: inline-block !important;
  position: relative;
  margin-left: 0.7rem;
  background: url("assets/beta-sprite.png") 0px 0px;
  width: 67px;
  height: 34px;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  div#beta-tag {
    box-sizing: content-box;
    padding-bottom: 5px;
    top: 5px;
    display: inline-block !important;
    position: relative;
    margin-left: 0.7rem;
    background: url("assets/beta-sprite@2x.png") 0px 0px;
    background-size: 67px 72px;
    width: 67px;
    height: 34px;
    background-repeat: no-repeat;
  }

  div#beta-tag:not(.disabled):hover {
    box-sizing: content-box;
    padding-bottom: 5px;
    top: 5px;
    display: inline-block !important;
    position: relative;
    margin-left: 0.7rem;
    background: url("assets/beta-sprite@2x.png") 0px -36px;
    background-size: 67px 72px;
    width: 67px;
    height: 34px;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}
div#beta-tag.show ul.beta-dropdown {
  background: #dd1100;
  color: #fff !important;
}

ul.beta-dropdown {
  display: none;
  background: white;
  width: 160px;
  position: absolute;
  top: 45px;
  text-align: left;
  z-index: 999;
  right: -50px;
}

ul.beta-dropdown:hover {
  cursor: auto;
}

ul.beta-dropdown:before {
  content: "";
  position: absolute;
  right: 79px;
  top: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 9px 5px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 999;
}

ul.beta-dropdown:after {
  content: "";
  position: absolute;
  right: 78px;
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 10px 6px;
  border-color: transparent transparent #dd1201 transparent;
  z-index: 998;
}

.beta-dropdown li {
  height: 35px;
  overflow: visible;
  right: -43px;
  position: absolute;
}

.beta-dropdown li p {
  line-height: 16px;
  border: 1px solid #dd1100;
  padding-top: 15px;
  height: 82px;
  overflow-wrap: break-word;
  display: inline-block;
  word-wrap: break-word;
  width: 253px;
  font-size: 12px;
  background: white;
  margin-bottom: 0px;
  padding-bottom: 15px;
  padding-left: 13px;
  padding-right: 13px;
  color: #000000;
}

.beta-dropdown li a {
  text-decoration: none;
  color: #dd1100;
}

div.source-menu {
  display: inline-block !important;
  margin: 0 0 2.2rem 0.7rem;
  position: relative;
}

ul.source-dropdown {
  display: none;
  width: 160px;
  border-collapse: collapse;
  position: absolute;
  top: 45px;
  text-align: left;
  white-space: nowrap;
  z-index: 10;
  right: -49px;
}

ul.source-dropdown:before {
  content: "";
  position: absolute;
  right: 79px;
  top: -9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 9px 5px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9999;
}

ul.source-dropdown:after {
  content: "";
  position: absolute;
  right: 78px;
  top: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 10px 6px;
  border-color: transparent transparent #dd1201 transparent;
  z-index: 9998;
}

.source-menu[data-open="true"] a.arrow {
  width: 152px;
  border-radius: 3px 3px 0 0;
  color: #fff !important;
  background: #dd1100;
}

.source-menu[data-open="true"] a.arrow:after {
  border-top: 9px solid #fff;
}

.source-menu[data-open="true"] .source-dropdown {
  display: block;
}

.source-dropdown li {
  background: #fff;
  display: block;
  height: 35px;
  overflow: visible;
}

.source-dropdown a,
.source-dropdown div {
  color: #dd1100;
  display: block;
  border: 1px solid #dd1100;
  padding: 0.45rem 0.7rem;
  background: #fff;
  top: -1px;
  height: 37px;
  position: relative;
}

.source-dropdown li a:not(.disabled):hover {
  color: #fff;
  background: #f77700;
  border: 1px solid #f77700;
  z-index: 9999;
  top: -2px;
  height: 36px;
}

.source-menu[data-open="true"] a#srcBtnDropdown {
  background: #dd1100;
  color: #fff !important;
}

#loading-notif {
  display: none;
  position: absolute;
  border-radius: 4px;
  padding: 10px 40px 10px 40px;
  background-color: #000;
  opacity: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: 245px;
  transition-property: opacity;
  transition-duration: 2s;
}
#loading-notif[data-show="true"] {
  opacity: 0.6;
  display: block;
  z-index: 9999;
}

#loading-notif:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "…";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
:root {
  --loading-background-url: "";
  --loading-background-width: 0px;
  --loading-background-height: 0px;
  --fs-Offset-Y: 2rem;
  --fs-iframe-height: 600px;
  --fs-marginX: "";
  --resizeable-height: 600px;
}

#feedbackError {
  display: none;
}

/*  sidebar
    --------------------------------*/
/*fix for dissarpering buttons */
#st-1 .st-btn[data-network="facebook"] {
  display: inline-block !important;
}

#st-1 .st-btn[data-network="twitter"] {
  display: inline-block !important;
}

#st-1 .st-btn[data-network="linkedin"] {
  display: inline-block !important;
}

/*tab fix*/
.sidebar a.tab:not(selected) {
  top: 1px;
  border-bottom: 0.5px;
}

.sidebar .tabbed-item {
  min-width: 260px;
}

/*---------------------*/

.main.demonstration {
  margin: 0;
}
@media all and (min-width: 1000px) {
  .main.demonstration {
    margin: 0 0 4.3rem;
  }
}

section.mobile-sidebar {
  --sidebar-bg-color: hsl(0, 0%, 96%);
  background: var(--sidebar-bg-color);
  margin: 0 -2%;
  padding: 2rem 2%;
  box-shadow: -1em 0 0 var(--sidebar-bg-color), 1em 0 0 var(--sidebar-bg-color);
  margin-top: 5rem;
}

.sidebar {
  max-width: 720px;
  margin: 0 auto;
}
@media all and (min-width: 1000px) {
  .sidebar {
    display: table-cell;
    width: 277px;
    min-width: 277px;
    vertical-align: top;
    padding: 0 0 0 20px;
    margin: unset;
  }
}

.sidebar li img {
  width: 50px;
  display: inline-block;
  vertical-align: top;
  margin: 0 0.6rem 0.9rem 0;
}

.sidebar .details {
  width: 150px;
  display: inline-block;
  vertical-align: top;
  font-size: 0.875rem;
  margin: 0 0 0.9rem;
  line-height: 1.4;
}

.sidebar a.author {
  color: #494949;
}

.sidebar .tabbed-item {
  display: none;
  border: 1px solid #e9e9e9;
  padding: 0.3rem 0.8rem 0.8rem 0.8rem;
  border-radius: 0 0 5px 5px;
  height: 350px;
  overflow: auto;
}

.sidebar .sidebar-heading {
  color: #444;
  margin: 1.5rem 0 0.5rem;
}

.sidebar .sidebar-heading.first {
  margin: 0 0 0.6rem;
}

@media all and (max-width: 999.9px) {
  .sidebar .sidebar-heading {
    color: #555;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0.5rem 0 0.6rem;
    text-transform: uppercase;
    margin-top: 3rem;
  }
}

.sidebar a.tab {
  font-size: 13px;
  color: #494949;
  padding: 5px 9px;
  border: 1px solid #e9e9e9;
  bottom: -2px;
  position: relative;
  border-radius: 5px 5px 0 0;
  background: #fff;
  display: inline-block;
  margin: 1.3rem 0 0 0;
}

.sidebar .tab.selected {
  border-bottom: 1.5px solid #fff;
}

.sidebar .sharethis-inline-share-buttons {
  display: inline-block !important;
  vertical-align: top;
}

.sidebar .embed-link {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 4px;
  background: url("assets/embed2x.png");
  background-size: cover;
  vertical-align: top;
  top: 0;
  transition: opacity 0.2s ease-in, top 0.2s ease-in;
}

a.embed-link {
  margin-left: 10px;
}

.sidebar .embed-link:hover {
  top: -4px;
  opacity: 0.8;
  position: relative;
}

a.embed-link.disabled {
  opacity: 0.5;
  position: relative;
  pointer-events: none;
}

.disabled {
  opacity: 0.5;
  position: relative;
}

.sidebar .related-topics .topics-list.long {
  columns: 14em auto;
  column-gap: 4%;
}
.sidebar .related-topics a:after {
  content: " »";
  display: inline-block;
}

.sidebar .related-topics a {
  font-size: 0.875rem;
  line-height: 1.4;
}

.sidebar .embed-container {
  font-size: 0;
  position: relative;
}

.sidebar .embed-container textarea {
  vertical-align: top;
  height: 35px;
  width: 191px;
  border: 1px solid #e5e5e5;
  resize: none;
  font-size: 12px !important;
  color: #777;
  padding: 5px;
  overflow: hidden;
}

textarea#embedCopy {
  resize: vertical;
}

.st-total {
  display: none !important;
}

/*  content
    --------------------------------*/

/* .main a:not(.magnific):hover {
  color: #dd1100;
} */

.demonstration ul.relatedLinkList {
  line-height: 1.4;
}

.demonstration li#relatedLinkListElem a:first-of-type {
  color: #f77700;
}

.demonstration li#relatedLinkListElem a:first-of-type:hover {
  color: #dd1100;
}

.demonstration span.citation-author a {
  color: #f77700;
}

.demonstration section h1 {
  font-size: 2.25rem;
  font-weight: 500;
  color: #444;
  max-width: 720px;
  margin: 3.75rem auto 2.25rem;
  letter-spacing: -0.02rem;
}
@media all and (min-width: 1000px) {
  .demonstration section h1 {
    max-width: unset;
    margin: 3.75rem 0 2.25rem;
  }
}

.demonstration section h2 {
  font-size: 1.125rem;
  font-weight: 400;
  color: #555;
  margin: 0.5rem 0 0.6rem;
  text-transform: uppercase;
}

.demonstration .details + .reference h2 {
  font-size: 0.875rem;
  color: #494949;
  line-height: 1.4;
  text-transform: none;
}

.demonstration section .left {
  max-width: 720px;
  margin: 0 auto;
}
@media all and (min-width: 1000px) {
  .demonstration section .left {
    width: 720px;
    display: table-cell;
  }
}

.demonstration section p {
  font-size: 0.875rem;
  color: #444;
  line-height: 1.3;
  margin: 0 0 1.35rem;
}

.demonstration section img {
  max-width: 100%;
}

.demonstration section .embed {
  text-align: left;
  max-width: 440px;
  margin: 0 auto 2rem;
  display: block;
  position: relative;
}

.demonstration section .embed:not(.playing):hover .popup {
  display: block;
}

.demonstration section .embed:not(.playing):hover .overlay {
  display: block;
}

.embed:not(.playing):hover video {
  filter: brightness(0.6);
}

#fallback-overlay {
  opacity: 0;
}

.embed:hover #fallback-overlay {
  opacity: 0.3;
}

.popup {
  border-radius: 3px;
  display: none;
  position: absolute;
  top: var(--resizeable-height);
  /* bottom: 0;*/
  left: 0;
  right: 0;
  margin: auto;
  width: 250px;
  max-height: 240px;
  background: #fff;
  z-index: 10;
  line-height: 1;
  overflow: visible;
  text-align: left;
}

.demonstration section .popup div.popupInner {
  margin: 30px;
}

.demonstration section .popup img.overlayThumb {
  width: 20px;
  height: 20px;
  vertical-align: -25%;
  margin-right: 12px;
}

.demonstration section .popup p.overlayBtn:hover {
  cursor: pointer;
}

.demonstration section .popup p.overlayBtn:hover a {
  color: #f77700;
}

.demonstration section .popup p.title {
  color: #494949;
  font-size: 20px;
}

.demonstration section .popup a {
  color: #dd1100;
  font-size: 14px;
  line-height: 1.3;
}

.demonstration section .popup a:hover {
  color: #f77700;
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  /*background: rgba(0,0,0,.6);*/
}

.demonstration section hr {
  border: 0;
  height: 1px;
  background: #e5e5e5;
  margin: 2rem 0;
}

.demonstration section .left hr:last-of-type {
  height: 4px;
  background: #f77700;
}

.demonstration .description + hr {
  margin: 3.1rem 0 0;
}

.demonstration section ul li {
  line-height: 1.4;
  font-size: 0.875rem;
}

.demonstration section .pipe {
  margin: 0 10px;
}

.demonstration .object {
  position: relative;
}

.demonstration .object .resizable {
  height: var(--resizeable-height);
  overflow: hidden !important;
  /*text-align: left;*/
  /*overflow: auto;*/
  background: #fff;
  z-index: 100;
  margin-bottom: 30px;
  font-size: 0;
  position: relative;
  /* visibility: hidden;*/
}

.demonstration.a-hybrid-benz-grignani-logo .resizable {
  height: 450px;
}

.demonstration.fermat-point-for-many-points .resizable {
  height: 645px;
}

.demonstration .object .cloudiframe {
  width: 99%;
  height: 99%;
  overflow: hidden;
}

#video-wrapper {
  position: absolute;
  z-index: 999;
  opacity: 100;
  height: var(--resizeable-height);
  transition-property: opacity;
  transition-duration: 2s;
}
#video-wrapper[data-show="false"] {
  z-index: 0;
  opacity: 0;
}

#demo-embed {
  visibility: hidden;
  opacity: 0;
  z-index: 0;
  transition-property: opacity;
  transition-duration: 2s;
}
#demo-embed[data-show="true"] {
  visibility: unset;
  z-index: 999;
  opacity: 100;
}

.demonstration .citation a,
.demonstration .related-links a {
  color: #494949;
}

#external-links {
  columns: 20em;
  column-gap: 4%;
}
#external-links li {
  line-height: 18px;
}
#external-links a {
  color: #444;
  font-size: 1rem;
  line-height: 18px;
  margin: 9px 0;
  display: inline-grid;
  grid-template-columns: 25px 1fr;
}
#external-links a:hover {
  color: #d10;
}
#external-links svg.externalLinkIcon {
  width: 18px;
  height: 18px;
  fill: #888;
}

/*.demonstration .citation a:hover,
.demonstration .related-links a:hover {
    color: #f77700;
}*/
.demonstration .citations {
  display: block;
}

.demonstration .citation-author {
  display: block;
}

#cite-box {
  margin: 1rem 0 2.875rem;
}
#cite-box p {
  display: table-cell;
  border: 1px solid #ddd;
  padding: 0.75rem 1.25rem;
  vertical-align: middle;
}
#cite-box p:first-child {
  border-right: none;
  white-space: nowrap;
  font-size: 1rem;
  padding: 0.75rem;
}
#cite-box p a {
  color: inherit;
}
#cite-box p a:hover {
  color: #d10;
}
button.citationButton {
  background: none;
  border: none;
  text-align: left;
}

.demonstration .InlineFormula,
.demonstration .absmiddle {
  vertical-align: middle;
  font-size: 0;
  bottom: 3px;
  position: relative;
}

.demonstration code {
  font-size: 0.925rem;
}

/*fix for sidebar bold font*/
div#related-demonstrations-section ul li span.details a.title > b {
  font-weight: 400;
}

/*  snapshots
    --------------------------------*/
.snapshots {
  margin: 0.6rem auto 1.35rem;
  font-size: 0;
  width: 600px;
  text-align: center;
}

.snapshots a {
  width: 200px;
  display: inline-block;
}

.snapshots a.selected img {
  border-bottom: 5px solid #dd1100;
}

.snapshots .thumbs img {
  width: 189px;
  border-bottom: 5px solid transparent;
  padding-bottom: 3px;
}

.snapshots .thumbs a:hover img {
  opacity: 0.75;
}

.snapshots .current img {
  display: none;
}

.snapshots .current .show {
  display: block !important;
  width: 484px;
  margin: 1rem auto 0;
  padding: 0 0 0.5rem;
}

.demonstration .snapshots + hr {
  margin: 3.35rem 0 0;
}

/*  embed object
    --------------------------------*/
.object {
  text-align: center;
}

div.buttons {
  display: flex;
  justify-content: center;
}

#clipboard {
  width: 1px;
  height: 1px;
  border: 0;
  overflow: hidden;
  position: absolute;
  font-size: 0px;
}

.demonstrations-tooltip {
  background: #f5f5f5;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 8px;
  position: absolute;
  top: -45px;
  left: 0;
  right: 0;
  margin: auto;
  color: #898989;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  width: 150px;
  z-index: 200;
}

.demonstrations-tooltip:after {
  content: "";
  border-top: 10px solid #f5f5f5;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  /* border-bottom: 10px solid transparent; */
  display: block;
  position: absolute;
  top: 31px;
  width: 10px;
  right: 0;
  left: 0;
  margin: auto;
}

.demonstrations-tooltip:before {
  content: "";
  border-top: 10px solid #e5e5e5;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  /* border-bottom: 10px solid transparent; */
  display: block;
  position: absolute;
  top: 32px;
  width: 10px;
  right: 0;
  left: 0;
  margin: auto;
}

.demonstrations-tooltip#clipboard-tooltip-overlay {
  top: 150px !important;
}

.demonstrations-tooltip#clipboard-tooltip-overlay:before {
  top: 29px;
}

.demonstrations-tooltip#clipboard-tooltip-overlay:after {
  top: 28px;
}

.embed-container .demonstrations-tooltip {
  top: -20px;
}

a.button.red.magnific.inline:hover {
  background: #f77700;
  border: 1px solid #f77700;
}

button:not(.disabled).inline.clipboardManipulate:hover {
  background: #f77700;
  border: 1px solid #f77700;
}

.main .button-frame {
  text-align: left;
  margin: 0 -0.7em;
  font-size: 0.875rem;
}
@media all and (min-width: 1000px) {
  .main .button-frame {
    font-size: 1rem;
  }
}
.main .buttonUnderExample {
  font-size: 1em;
  margin: 1.5rem 0.4rem 1.5rem 0.7rem;
  padding: 0 0.8em;
  border-radius: 0.25em;
  border: 0.0625em solid #f77700;
  display: inline-block;
  position: relative;
  height: 2.1875em;
  line-height: 2.0625em;
  color: #fff;
  background: #f77700;
  vertical-align: middle;
}
main a.buttonUnderExample:hover {
  color: #fff;
}
.buttonUnderExample:hover {
  border-color: #d10;
  background: #d10;
}
.buttonUnderExample.Source {
  background: #fff;
  color: #f77700;
}
.buttonUnderExample.Source:hover {
  border-color: #d10;
  color: #d10;
}
.buttonUnderExample.FullScreen {
  background: #aaa;
  border-color: #aaa;
  padding: 0 0.51428571em;
}
.buttonUnderExample.FullScreen:hover {
  background: #d10;
  border-color: #d10;
}
.buttonUnderExample.FullScreen svg {
  display: block;
}

.PopoverContent,
.TooltipContent {
  max-height: var(--radix-popover-content-available-height);
  padding: 0 0.8em;
  border-radius: 3px;
  border: 1px solid #f77700;
  font-size: 0.875em;
  line-height: 1.25;
  background: #fff;
}
.TooltipContent {
  padding: 0.65625em 0.8em;
  border-color: #ddd;
  background: #f0f0f0;
  color: #666;
  font-size: 13px;
  padding: 0.5em;
}
.PopoverContent p {
  margin: 0.75em 0;
}
.PopoverContent a,
.PopoverContent button {
  text-decoration: none;
  color: #f77700;
  border: none;
  background: none;
}
.PopoverContent a:hover {
  color: #d10;
}
.PopoverArrow,
.TooltipArrow {
  fill: #f77700;
}
.TooltipArrow {
  fill: #ddd;
}
.PopoverContent.Download {
  max-width: 22em;
}
.PopoverContent.Source {
  padding: 0;
}
.PopoverContent.Source p {
  margin: 0;
}
.PopoverContent.Source a,
.PopoverContent.Source button {
  text-align: left;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0.75em 0.8em;
  color: #f77700;
  border-bottom: 1px solid #f77700;
}
.PopoverContent.Source a:hover,
.PopoverContent.Source button:hover {
  background: #f77700;
  color: #fff;
}
.DialogOverlaySourceNotebook {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;
  animation: fadeIn 150ms ease;
}
.DialogContentSourceNotebook {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.DialogContentSourceNotebook h2 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 0.5em;
}
.DialogContentSourceNotebook p {
  line-height: 1.5;
  margin: 0.5em 0;
}

.DialogContentSourceNotebook p a {
  color: #f77700;
}
.DialogContentSourceNotebook p a:hover {
  color: #d10;
}

.DialogContentSourceNotebook .IconButton {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
  background: none;
  border: 0;
  font-size: 1.5em;
  height: 1em;
  width: 1em;
  opacity: 0.25;
}
.DialogContentSourceNotebook .IconButton:hover {
  opacity: 0.5;
}

.demonstration section .SnapshotsTabsList {
  margin: 0 -1rem;
}
.demonstration section .SnapshotsTabsTrigger {
  all: unset;
  margin: 1rem;
  cursor: pointer;
  border: 1px solid hsl(0, 0%, 90%);
}
.demonstration section .SnapshotsTabsTrigger[data-state="active"] {
  border-color: #f77700;
}
.demonstration section .SnapshotsTabsTrigger img {
  display: block;
  max-width: 8rem;
}
.demonstration section .SnapshotsTabsTrigger img:hover {
  opacity: 0.8;
}
.demonstration section .SnapshotsTabsContent img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

#fullScreenModal {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: 1000;
  background: #fff;
}
#fullScreenModal .close-button {
  position: fixed;
  top: 0;
  right: 0;
  background: none;
  border: 0;
  padding: 1em;
  z-index: 1100;
}
#fullScreenModal .close-button svg {
  stroke: gray;
  display: block;
  width: 1.5em;
  height: 1.5em;
  stroke-linecap: round;
  stroke-width: 3;
}
#fullScreenModal .close-button:hover svg {
  stroke: #d10;
}

.cloudembed {
  position: relative;
}
.cloudembed .ddoverlay-frame {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 75%, 75%);
  display: none;
  justify-content: center;
  align-items: center;
}
.cloudembed:hover .ddoverlay-frame {
  display: flex;
}
.cloudembed .ddoverlay-frame .ddoverlay-tile {
  background: #fff;
  padding: 3em 2em;
  border-radius: 0.25em;
  text-align: left;
  max-width: 16em;
}
.cloudembed .ddoverlay-frame .ddoverlay-tile p {
  margin: 0;
}
.cloudembed .ddoverlay-frame .ddoverlay-tile p.title {
  font-size: 1.25rem;
  line-height: 1.25;
}
.cloudembed .ddoverlay-frame .ddoverlay-tile p.link-frame {
  margin-top: 0.78125rem;
  line-height: 1.5625rem;
}
.cloudembed .ddoverlay-frame .ddoverlay-tile p.link-frame svg,
.cloudembed .ddoverlay-frame .ddoverlay-tile p.link-frame span {
  display: inline-block;
  vertical-align: middle;
}
.cloudembed .ddoverlay-frame .ddoverlay-tile p.link-frame svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
}
