.SidebarTabsContent .loading-tab {
  padding: 3rem;
}
.SidebarTabsContent .loading-tab .demonstrations-logo {
  animation: 5s ease-in loading-fadeIn, 10s infinite 4s ease-in-out loading-spin;
}
@keyframes loading-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.SidebarTabsContent {
  columns: 14em auto;
  column-gap: 4%;
}
.SidebarTabsContent .resource-tile {
  font-size: 14px;
  margin: 1em 0;
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}
.SidebarTabsContent .resource-tile img {
  display: block;
  height: auto;
  filter: brightness(0.81) contrast(1.6);
  outline: 1px #e7e7e7 solid;
}
.SidebarTabsContent .resource-tile p {
  margin: 0;
}
.SidebarTabsContent .resource-tile .author a {
  color: #6f6f6f;
}
.SidebarTabsContent .resource-tile .author a:hover {
  color: #d10;
}
