main.all-page .visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
main.all-page .inner {
  position: relative;
}
main.all-page h1 {
  color: #444;
  font-size: 2.25rem;
  font-weight: 500;
  margin: 3.75rem 0 2.25rem;
  letter-spacing: -0.02rem;
}
main.all-page p.link-to-top {
  bottom: 2em;
  padding: 0;
  position: fixed;
  right: 3em;
  text-align: center;
}
main.all-page svg.back-to-top-icon {
  display: inline-block;
  vertical-align: top;
  margin-right: 0.5em;
  width: 20px;
  height: 20px;
}
main.all-page p.link-to-top svg.back-to-top-icon {
  display: block;
  margin: 0 auto;
  margin-right: 0;
  width: 25px;
  height: 25px;
}
main.all-page svg.back-to-top-icon rect {
  fill: #ddd;
}
main.all-page a:hover svg.back-to-top-icon rect {
  fill: #d10;
}
main.all-page ul.header-links {
  font-size: 1.25em;
  font-weight: bold;
  margin: 1.75em -0.4em;
}
main.all-page ul.header-links li,
main.all-page ul.header-links li a {
  display: inline-block;
  padding: 0.2em 0.25em;
}
main.all-page section.resources h2 {
  color: #444;
  font-weight: 600;
  border-top: 1px solid #ddd;
  margin-top: 1.5em;
  padding: 1em 0 0.25em;
}
main.all-page section.resources ul {
  font-size: 1em;
  line-height: 1.125em;
}
main.all-page.authors section.resources ul {
  column-width: 12em;
}
main.all-page section.resources li {
  padding: 0.5625em 0;
}
main.all-page section.resources li a {
  color: #444;
}
main.all-page section.resources li a:hover {
  color: #d10;
}
