.home .banner {
  margin: 3.125rem 0 4.37rem;
}
.home .banner em {
  color: #a6a6a6;
  font-size: 0.875rem;
  margin: 5px 0 0;
  display: block;
}
.home .banner h1 {
  color: #444;
  margin: 0 0 0.3rem;
  font-weight: 300;
  font-size: 2rem;
  text-transform: none;
}
.home .banner img {
  max-width: 100%;
}
.home .banner p {
  color: #494949;
  font-size: 1.125rem;
  line-height: 1.22;
}
.home .banner .inner {
  border-top: 3px solid #dd1100;
  box-shadow: inset 0 0 1px #777;
  padding: 1.8rem;
  position: relative;
}
.home .banner .flyover {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #a6a6a6;
  font-size: 0.75rem;
}
.home .banner .flyover:hover {
  color: #f77700;
}
.home .banner .heading-text {
  display: table-cell;
  max-width: 370px;
  padding: 0 1.5rem 0 0;
  vertical-align: middle;
}
.home .banner .img {
  display: table-cell;
  vertical-align: middle;
}

.home .section {
  margin: 0 0 4.37rem;
}
.home .section h1 {
  margin: 0.6rem 0 -0.5rem;
  font-weight: 500;
  color: #565656;
  font-size: 1.125rem;
  text-transform: uppercase;
}
.home .section img {
  max-width: 100%;
  height: auto;
  filter: hue-rotate(-25deg) saturate(1);
}
.home .section .inner {
  padding: 0;
  border-top: 3px solid #f57900;
}
.home section:first-child .inner {
  border: none;
}
.home .section:last-of-type {
  margin-bottom: 0;
}

.topics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.topic {
  margin: 2.2rem 0 0;
}
.topic h2,
.topic h2 a {
  font-size: 1.125rem;
  color: #565656;
  font-weight: 600;
  margin: -2px 0 3px 0;
}
.topic img {
  width: 30px;
  display: inline-block;
  vertical-align: top;
  margin: 0 18px 0 0;
}
.topic p,
.topic p a {
  font-size: 0.875rem;
  color: #888888;
}
.topic a:hover h2 {
  color: #dd1100;
}

.topic-text {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 70px);
}

.featured .col {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.featured .col featured-demo:not(:last-of-type) {
  margin: 0 0 14px 0;
}

.contributors {
  margin: 2.5rem 0 0;
}

.contributor-text {
  display: inline-block;
  vertical-align: top;
  margin: -3px 0 0 18px;
}
.contributor-text h2 {
  font-size: 1rem;
  color: #f77700;
}
.contributor-text p {
  font-size: 0.875rem;
  color: #494949;
  line-height: 1.43;
}
.contributor-text:hover h2 {
  color: #dd1100;
}

.join {
  margin: 1.8rem 0 0;
}
.join a {
  font-size: 0.938rem;
  color: #f77700;
}
.join a:hover {
  color: #dd1100;
}

.share {
  font-size: 0.875rem;
  line-height: 1.25;
  color: #494949;
}

span.pod img {
  border: #b3b3b3 1px solid;
  width: 238px;
}

span.topic-text a:hover {
  color: #f77700;
}

.home .section .inner .latest {
  text-align: right;
  margin-top: -26px;
  font-size: 14px;
}

#videoBanner {
  font-size: min(3vw, 16px);
  position: relative;
  overflow: hidden;
  height: 21.75em;

  --full-color: oklch(71.44% 0.169 47.13);
  --fade-color: oklch(67.67% 0.192 43.58 / 0.85);
  --shadow-color: oklch(from var(--full-color) calc(l - 0.25) c h);
  background-color: var(--full-color);
}
#videoBanner video,
#videoBanner > div {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -40em;
  height: 21.75em;
}
#videoBanner > div {
  width: 80em;
}
#videoBanner .fade {
  background: linear-gradient(
    to right,
    var(--full-color),
    var(--fade-color) 10%,
    var(--fade-color) 90%,
    var(--full-color)
  );
}
#videoBanner .text-frame {
  display: flex;
  justify-content: center;
  align-items: center;
}
#videoBanner .text {
  color: #fff;
  font-size: 1.5625em;
  font-weight: 600;
  line-height: 1.12;
  max-width: 20em;
  text-align: center;
  text-shadow: 0 0 0.12em var(--shadow-color);
}
#videoBanner h1 {
  display: block;
  font-size: 1.96em;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 0.2448979592em;
}
#videoBanner .button-frame {
  margin-top: 0.72em;
}
#videoBanner .DialogButton {
  font-size: 0.68em;
  background: none;
  border: 0.1176470588em solid #fff;
  border-radius: 0.2941176471em;
  color: #fff;
  height: auto;
  line-height: 2.1764705882;
  padding: 0 0.75em;
}
#videoBanner .DialogButton:hover,
#videoBanner .DialogButton:focus {
  background-color: #fff;
  color: var(--full-color);
}

.DialogOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;
  animation: fadeIn 150ms ease;
}
.DialogContent {
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0.25em 2em rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadeIn 200ms ease;
  width: min(min(90%, 140vh), 800px);
  aspect-ratio: 800 / 450;
}
.DialogContent iframe {
  display: block;
  width: 100%;
  aspect-ratio: 800 / 450;
}
.DialogContent:focus {
  outline: none;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
