#error-page {
  position: absolute;
  width: 80vw;
  height: 80vh;
  margin: 10vh 10vw;
  font-size: clamp(1rem, 2vw, 1.5rem);
  line-height: 1.5;
  text-align: center;
}

#error-page .demonstrations-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#error-page #page-contents {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#error-page #page-contents h1,
#error-page #page-contents p {
  margin: 0.375em 0;
}
#error-page #page-contents h1 {
  font-size: 2em;
}
#error-page #page-contents a {
  display: block;
  background-color: #d10;
  color: #fff;
  padding: 0.375em 0.75em;
  border-radius: 0.25em;
}
#error-page #page-contents a:hover,
#error-page #page-contents a:focus {
  background-color: #f77700;
}
