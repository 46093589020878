.upload-page .inner {
  margin: 0 auto;
  width: 800px;
}

.upload-page section h1 {
  color: #444;
  font-size: 2.25rem;
  font-weight: 400;
  margin: 3.75rem 0 2.25rem;
  letter-spacing: -0.02rem;
}

.upload-page section p {
  color: #555;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 23px;
}
