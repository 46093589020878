.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 16em;
  height: 16em;
  margin-top: -8em;
  margin-left: -8em;
  display: block;
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}