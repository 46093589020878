.resource-list {
  margin: 2rem 0;
}
.resource-list .resource-tile {
  position: relative;
  break-inside: avoid;
}
.resource-list .resource-tile a {
  border: 2px solid hsl(0, 0%, 87.5%);
  display: block;
  overflow: hidden;
  position: relative;
  box-shadow: 0 2px 5px hsla(0, 0%, 87.5%, 0.8);
}
.resource-list .resource-tile a:hover {
  border-color: hsl(26, 100%, 60%);
}
.resource-list .resource-tile .img-frame {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2096%20100%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m48%200%2010.5%2017.75L77.75%209.5%2075.5%2030.25%2096%2034.5%2082.25%2050%2096%2065.5l-20.5%204.25%202.25%2020.75-19.25-8.25L48%20100%2037.5%2082.25%2018.25%2090.5l2.25-20.75L0%2065.5%2013.75%2050%200%2034.5l20.5-4.25L18.25%209.5l19.25%208.25L48%200Zm2%2056.5c1.77-.55%206-4.15%206-6%200-5.35-8.5-5.5-8.5-5.5s4.25%201.86%204.25%206.5c0%203.09-1.75%205-1.75%205Zm-4-13c-1.77.55-6%204.15-6%206%200%205.35%208.5%205.5%208.5%205.5s-4.25-1.86-4.25-6.5c0-3.09%201.75-5%201.75-5ZM58%2027s-7.01-4-14-4-21%205.9-21%2023.5C23%2065.52%2042%2065%2042%2065s-9.17-3.98-9-16c.3-20.97%2025-22%2025-22ZM34%2052c-.6%203.39%204.53%2013%2011%2013%207.47%200%2011-13%2011-13s-3.63%206-10.5%206C38.62%2058%2034%2052%2034%2052Zm28-4c.6-3.39-4.53-13-11-13-7.47%200-11%2013-11%2013s3.63-6%2010.5-6c6.88%200%2011.5%206%2011.5%206ZM38%2073s7.01%204%2014%204%2021-5.9%2021-23.5C73%2034.48%2054%2035%2054%2035s9.17%203.98%209%2016c-.3%2020.97-25%2022-25%2022Z%22%20fill%3D%22%23DDD%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 25%;
  min-height: 80px;
  display: flex;
  align-items: center;
}
.resource-list .resource-tile img {
  display: block;
  width: 100%;
  height: auto;
}
.resource-list .resource-tile p {
  backdrop-filter: blur(3px);
  background: hsla(0, 0%, 90%, 0.8);
  bottom: 0;
  color: #444;
  font-size: 0.875rem;
  font-weight: 600;
  opacity: 0;
  padding: 5px 10px;
  position: absolute;
  width: 100%;
}
.resource-list.show-titles .resource-tile p {
  background: hsl(0deg 0% 95.69%);
  position: static;
}
.resource-list .resource-tile:hover p,
.resource-list.show-titles .resource-tile p {
  opacity: 1;
}
