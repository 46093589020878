main.topic-tree > section {
  margin-top: 3.125rem;
}
main.topic-tree h1 {
  color: #444;
  font-size: 2.25rem;
  font-weight: 500;
  margin: 3.75rem 0 2.25rem;
  letter-spacing: -0.02rem;
}
main.topic-tree section.main-topic {
  max-width: 37.5em;
  padding: 1em 0;
  background-repeat: no-repeat;
  background-size: 2em;
  background-position: 0 2em;
  border-bottom: 1px solid #ccc;
}

main.topic-tree section.main-topic .section-header {
  padding: 0 3em;
  background-repeat: no-repeat;
  background-size: 2em;
  cursor: pointer;
}
main.topic-tree section.main-topic .section-header h2 {
  font-size: 17px;
  line-height: 20px;
  font-weight: 600;
  color: #555;
}
main.topic-tree section.main-topic .section-header p {
  font-size: 14px;
  line-height: 20px;
  color: #aaa;
}
main.topic-tree section.main-topic .section-header:hover h2,
main.topic-tree section.main-topic .section-header:focus h2 {
  color: #f77700;
}

main.topic-tree section.main-topic ul {
  margin-left: 1em;
}
main.topic-tree section.main-topic > ul {
  margin-left: 3em;
  margin-top: 15px;
}
main.topic-tree section.main-topic ul button,
main.topic-tree section.main-topic ul a {
  display: block;
  border: 0;
  background: none;
  padding: 0 1em;
  margin: 10px 0;
  color: #494949;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
main.topic-tree section.main-topic ul ul button,
main.topic-tree section.main-topic ul ul a {
  font-size: 14px;
  font-weight: 400;
}
main.topic-tree section.main-topic ul button:hover,
main.topic-tree section.main-topic ul a:hover,
main.topic-tree section.main-topic ul button:focus,
main.topic-tree section.main-topic ul a:focus {
  color: #f77700;
}
main.topic-tree section.main-topic ul button {
  background-image: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cg%20fill%3D%22%23f77700%22%3E%0A%20%20%20%20%3Crect%20x%3D%224%22%20y%3D%2210%22%20width%3D%228%22%20height%3D%222%22%20%2F%3E%0A%20%20%20%20%3Crect%20x%3D%227%22%20y%3D%227%22%20width%3D%222%22%20height%3D%228%22%20%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E);
  background-size: contain;
  background-repeat: no-repeat;
}
main.topic-tree section.main-topic ul button[aria-expanded="true"] {
  background-image: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cg%20fill%3D%22%23f77700%22%3E%0A%20%20%20%20%3Crect%20x%3D%224%22%20y%3D%2210%22%20width%3D%228%22%20height%3D%222%22%20%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E);
}
