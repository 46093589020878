@charset "utf-8";

/* Global front-end framework.

   "An escalator can never break, it can only become stairs." - Mitch Hedberg

   developer:   marionm
   requires:    nothing
   ========================================================================== */

/* ==========================================================================
   setup the environment
   ========================================================================== */

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

html {
  -moz-text-size-adjust: 100%; /* preserve font size on orientation change */
  -ms-text-size-adjust: 100%; /* ^ */
  -webkit-text-size-adjust: 100%; /* ^ */
  background: #fff;
  color: #000;
  font-family: Arial, sans-serif;
  font-size: 16px; /* set default size for rems; this changes at various breakpoints and is used to scale page elements */
  font-weight: 400;
  height: 100%; /* set viewport height as the minimum page height */
  line-height: 1.3; /* scale to any font size */
  min-width: 320px; /* minimum supported device width */
  width: 100%; /* set viewport width as the minimum page width */
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; /* define html5 block elements for inept browsers */
}

audio,
canvas,
progress,
video {
  display: inline-block; /* define html5 inline-block elements for inept browsers */
}

audio:not([controls]) {
  display: none; /* correct display in iOS 4-7 */
  height: 0; /* ^ */
}

progress {
  vertical-align: baseline;
}

dd,
dl,
dt,
li,
ol,
ul {
  display: block; /* no default list styles */
  list-style: none; /* ^ */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: inherit; /* no default heading styles */
  line-height: 1.2; /* tighter for headings */
}

caption,
col,
colgroup,
table,
tbody,
td,
tfoot,
th,
thead,
tr {
  border-collapse: collapse; /* remove excess spacing around table elements */
  border-spacing: 0; /* ^ */
}

a,
summary {
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); /* remove highlight triggered on tap on some mobile devices */
  -webkit-tap-highlight-color: transparent; /* ^ */
  background: transparent; /* remove gray background from active links in IE 10 */
  cursor: pointer;
  text-decoration: none;
}

img {
  border: none; /* remove border on linked images in IE */
}

b,
optgroup,
strong {
  font-weight: 600;
}

dfn,
em,
i {
  font-style: italic;
}

del,
s {
  text-decoration: line-through;
}

code,
kbd,
pre,
samp,
tt {
  font-family: monospace, monospace; /* disable automatic monospace resizing */
  font-family: Consolas, Monaco, monospace; /* experimental */
  font-size: inherit;
}

sub,
sup {
  font-size: 0.75em;
  line-height: 0; /* prevent from affecting the line-height of the parent element */
  position: relative; /* ^ */
  vertical-align: baseline; /* ^ */
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.325em;
}

small {
  font-size: 0.875rem;
}

pre {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

hr {
  height: 0; /* Firefox */
  overflow: visible; /* IE */
}

legend {
  color: inherit; /* inherit from fieldset */
  display: table; /* fix IE text wrapping */
  max-width: 100%; /* ^ */
  white-space: normal; /* ^ */
}

textarea {
  overflow: auto; /* remove default vertical scrollbars in IE */
}

abbr[title] {
  border-bottom: none; /* Firefox */
  text-decoration: underline; /* Chrome, Edge, IE, Opera, and Safari */
  text-decoration: underline dotted; /* ^ */
}

button,
fieldset,
form,
input,
label,
legend,
optgroup,
option,
select,
textarea {
  font: inherit; /* improve font style inheritance */
}

button,
input,
select {
  overflow: visible; /* IE */
  text-transform: none; /* Firefox */
}

button,
html [type="button"],
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* enable button styles in iOS */
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0; /* remove unwelcome Firefox styles */
  padding: 0; /* ^ */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; /* prevent cursor change on increment/decrement buttons in Chrome */
}

input[type="search"] {
  -webkit-appearance: textfield; /* use same appearance as text input */
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; /* remove cancel button in Safari and Chrome on OSX */
}

svg:not(:root) {
  overflow: hidden; /* IE */
}

template,
[hidden] {
  display: none; /* IE */
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
keygen,
output,
select,
textarea {
  font-size: 16px !important; /* disable zoom on text input elements in iOS */
}

/* ==========================================================================
   utility styles
   ========================================================================== */

/* clearfix
   ========================================================================== */

.cf:after,
.heirs-cf > *:after,
.cf:before,
.heirs-cf > *:before,
.break-after:after,
.heirs-break-after > *:after,
.break-before:before,
.heirs-break-before > *:before {
  clear: both;
  content: "";
  display: table;
}

/* responsive images
   ========================================================================== */

.fill,
.heirs-fill > * {
  height: auto;
  max-width: 100%;
}

.fill-and-center,
.heirs-fill-and-center > * {
  display: block;
  float: none;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

/* ==========================================================================
   grid systems
   ========================================================================== */

/* block and inline-block grids
   ========================================================================== */

.grid,
.heirs-grid > *,
.row,
.heirs-row > * {
  display: block;
}

.inline-grid,
.heirs-inline-grid > * {
  display: inline-block;
}

.grid:after,
.heirs-grid > *:after,
.grid:before,
.heirs-grid > *:before,
.inline-grid:after,
.heirs-inline-grid > *:after,
.inline-grid:before,
.heirs-inline-grid > *:before,
.collapse > *:after,
.heirs-collapse > * > *:after,
.collapse > *:before,
.heirs-collapse > * > *:before {
  clear: both;
  content: "";
  display: table;
}

.grid > *,
.heirs-grid > * > *,
.row > *,
.heirs-row > * > *,
.col,
.heirs-col > *,
.inline-grid > *,
.heirs-inline-grid > * > * {
  float: left;
  display: block;
}

/* columns
   ================================== */

.cols-1 > *:nth-child(1n + 2),
.heirs-cols-1 > * > *:nth-child(1n + 2),
.cols-2 > *:nth-child(2n + 3),
.heirs-cols-2 > * > *:nth-child(2n + 3),
.cols-3 > *:nth-child(3n + 4),
.heirs-cols-3 > * > *:nth-child(3n + 4),
.cols-4 > *:nth-child(4n + 5),
.heirs-cols-4 > * > *:nth-child(4n + 5),
.cols-5 > *:nth-child(5n + 6),
.heirs-cols-5 > * > *:nth-child(5n + 6),
.cols-6 > *:nth-child(6n + 7),
.heirs-cols-6 > * > *:nth-child(6n + 7),
.cols-7 > *:nth-child(7n + 8),
.heirs-cols-7 > * > *:nth-child(7n + 8),
.cols-8 > *:nth-child(8n + 9),
.heirs-cols-8 > * > *:nth-child(8n + 9),
.cols-9 > *:nth-child(9n + 10),
.heirs-cols-9 > * > *:nth-child(9n + 10),
.cols-10 > *:nth-child(10n + 11),
.heirs-cols-10 > * > *:nth-child(10n + 11),
.cols-11 > *:nth-child(11n + 12),
.heirs-cols-11 > * > *:nth-child(11n + 12),
.cols-12 > *:nth-child(12n + 13),
.heirs-cols-12 > * > *:nth-child(12n + 13) {
  clear: both;
}

/* mini grids
   ========================================================================== */

.mini,
.heirs-mini > *,
.mini-b,
.heirs-mini-b > *,
.mini-m,
.heirs-mini-m > *,
.mini-t,
.heirs-mini-t > * {
  display: block;
  page-break-inside: avoid;
}

.mini > *,
.heirs-mini > * > *,
.mini-b > *,
.heirs-mini-b > * > *,
.mini-m > *,
.heirs-mini-m > * > *,
.mini-t > *,
.heirs-mini-t > * > * {
  display: table-cell;
  vertical-align: top;
}

.mini-b > *,
.heirs-mini-b > * > * {
  vertical-align: bottom;
}
.mini-m > *,
.heirs-mini-m > * > * {
  vertical-align: middle;
}
.mini-t > *,
.heirs-mini-t > * > * {
  vertical-align: top;
}

.mini-collapse > *,
.heirs-mini-collapse > * > * {
  display: block;
}

/* table grids
   ========================================================================== */

.table,
.heirs-table > *,
.table > *,
.heirs-table > * > *,
.table > * > *,
.heirs-table > * > * > * {
  border-collapse: collapse; /* remove excess spacing around table elements */
  border-spacing: 0; /* ^ */
}

.table,
.heirs-table > * {
  display: table;
}

.table > *,
.heirs-table > * > *,
.table-row > *,
.heirs-table-row > * > * {
  display: table-row;
}

.table > * > *,
.heirs-table > * > * > *,
.table-col > * > *,
.heirs-table-col > * > * > * {
  display: table-cell;
  vertical-align: top;
}

.table-collapse,
.heirs-table-collapse > *,
.table-collapse > *,
.heirs-table-collapse > * > *,
.table-collapse > * > *,
.heirs-table-collapse > * > * > * {
  display: block;
}

/* flexbox grids
   ========================================================================== */

.flex,
.heirs-flex > * {
  display: flex;
}

.inline-flex,
.heirs-inline-flex > * {
  display: inline-flex;
}

/* direction
   ================================== */

.flex-direction-c,
.heirs-flex-direction-c > * {
  flex-direction: column;
}
.flex-direction-cr,
.heirs-flex-direction-cr > * {
  flex-direction: column-reverse;
}
.flex-direction-r,
.heirs-flex-direction-r > * {
  flex-direction: row;
}
.flex-direction-rr,
.heirs-flex-direction-rr > * {
  flex-direction: row-reverse;
}

/* wrap
   ================================== */

.flex-wrap-n,
.heirs-flex-wrap-n > * {
  flex-wrap: nowrap;
}
.flex-wrap-w,
.heirs-flex-wrap-w > * {
  flex-wrap: wrap;
}
.flex-wrap-wr,
.heirs-flex-wrap-wr > * {
  flex-wrap: wrap-reverse;
}

/* justify content
   ================================== */

.justify-content-c,
.heirs-justify-content-c > * {
  justify-content: center;
}
.justify-content-fe,
.heirs-justify-content-fe > * {
  justify-content: flex-end;
}
.justify-content-fs,
.heirs-justify-content-fs > * {
  justify-content: flex-start;
}
.justify-content-sa,
.heirs-justify-content-sa > * {
  justify-content: space-around;
}
.justify-content-sb,
.heirs-justify-content-sb > * {
  justify-content: space-between;
}
.justify-content-se,
.heirs-justify-content-se > * {
  justify-content: space-evenly;
}

/* align items
   ================================== */

.align-items-b,
.heirs-align-items-b > * {
  align-items: baseline;
}
.align-items-c,
.heirs-align-items-c > * {
  align-items: center;
}
.align-items-fe,
.heirs-align-items-fe > * {
  align-items: flex-end;
}
.align-items-fs,
.heirs-align-items-fs > * {
  align-items: flex-start;
}
.align-items-s,
.heirs-align-items-s > * {
  align-items: stretch;
}

/* align content
   ================================== */

.align-content-c,
.heirs-align-content-c > * {
  align-content: center;
}
.align-content-fe,
.heirs-align-content-fe > * {
  align-content: flex-end;
}
.align-content-fs,
.heirs-align-content-fs > * {
  align-content: flex-start;
}
.align-content-s,
.heirs-align-content-se > * {
  align-content: stretch;
}
.align-content-sa,
.heirs-align-content-sa > * {
  align-content: space-around;
}
.align-content-sb,
.heirs-align-content-sb > * {
  align-content: space-between;
}

/* flexbox items
   ========================================================================== */

/* align self
   ================================== */

.align-self-a,
.heirs-align-self-a > * {
  align-self: auto;
}
.align-self-b,
.heirs-align-self-b > * {
  align-self: baseline;
}
.align-self-c,
.heirs-align-self-c > * {
  align-self: center;
}
.align-self-fe,
.heirs-align-self-fe > * {
  align-self: flex-end;
}
.align-self-fs,
.heirs-align-self-fs > * {
  align-self: flex-start;
}
.align-self-s,
.heirs-align-self-se > * {
  align-self: stretch;
}

/* basis
   ================================== */

.flex-basis-a,
.heirs-flex-basis-a > * {
  flex-basis: auto;
}

/* grow
   ================================== */

.flex-grow-0,
.heirs-flex-grow-0 > * {
  flex-grow: 0;
}
.flex-grow-1,
.heirs-flex-grow-1 > * {
  flex-grow: 1;
}
.flex-grow-2,
.heirs-flex-grow-2 > * {
  flex-grow: 2;
}
.flex-grow-3,
.heirs-flex-grow-3 > * {
  flex-grow: 3;
}
.flex-grow-4,
.heirs-flex-grow-4 > * {
  flex-grow: 4;
}
.flex-grow-5,
.heirs-flex-grow-5 > * {
  flex-grow: 5;
}
.flex-grow-6,
.heirs-flex-grow-6 > * {
  flex-grow: 6;
}
.flex-grow-7,
.heirs-flex-grow-7 > * {
  flex-grow: 7;
}
.flex-grow-8,
.heirs-flex-grow-8 > * {
  flex-grow: 8;
}
.flex-grow-9,
.heirs-flex-grow-9 > * {
  flex-grow: 9;
}
.flex-grow-10,
.heirs-flex-grow-10 > * {
  flex-grow: 10;
}
.flex-grow-11,
.heirs-flex-grow-11 > * {
  flex-grow: 11;
}
.flex-grow-12,
.heirs-flex-grow-12 > * {
  flex-grow: 12;
}

/* shrink
   ================================== */

.flex-shrink-1,
.heirs-flex-shrink-1 > * {
  flex-shrink: 1;
}
.flex-shrink-2,
.heirs-flex-shrink-2 > * {
  flex-shrink: 2;
}
.flex-shrink-3,
.heirs-flex-shrink-3 > * {
  flex-shrink: 3;
}
.flex-shrink-4,
.heirs-flex-shrink-4 > * {
  flex-shrink: 4;
}
.flex-shrink-5,
.heirs-flex-shrink-5 > * {
  flex-shrink: 5;
}
.flex-shrink-6,
.heirs-flex-shrink-6 > * {
  flex-shrink: 6;
}
.flex-shrink-7,
.heirs-flex-shrink-7 > * {
  flex-shrink: 7;
}
.flex-shrink-8,
.heirs-flex-shrink-8 > * {
  flex-shrink: 8;
}
.flex-shrink-9,
.heirs-flex-shrink-9 > * {
  flex-shrink: 9;
}
.flex-shrink-10,
.heirs-flex-shrink-10 > * {
  flex-shrink: 10;
}
.flex-shrink-11,
.heirs-flex-shrink-11 > * {
  flex-shrink: 11;
}
.flex-shrink-12,
.heirs-flex-shrink-12 > * {
  flex-shrink: 12;
}

/* order
   ================================== */

.order-0,
.heirs-order-0 > * {
  order: 0;
}
.order-1,
.heirs-order-1 > * {
  order: 1;
}
.order-2,
.heirs-order-2 > * {
  order: 2;
}
.order-3,
.heirs-order-3 > * {
  order: 3;
}
.order-4,
.heirs-order-4 > * {
  order: 4;
}
.order-5,
.heirs-order-5 > * {
  order: 5;
}
.order-6,
.heirs-order-6 > * {
  order: 6;
}
.order-7,
.heirs-order-7 > * {
  order: 7;
}
.order-8,
.heirs-order-8 > * {
  order: 8;
}
.order-9,
.heirs-order-9 > * {
  order: 9;
}
.order-10,
.heirs-order-10 > * {
  order: 10;
}
.order-11,
.heirs-order-11 > * {
  order: 11;
}
.order-12,
.heirs-order-12 > * {
  order: 12;
}

/* column grids
   ========================================================================== */

/* columns
   ================================== */

.columns-a,
.heirs-columns-a > * {
  columns: auto;
}
.columns-i,
.heirs-columns-i > * {
  columns: inherit;
}

.columns-0,
.heirs-columns-0 > * {
  columns: 0;
}
.columns-1,
.heirs-columns-1 > * {
  columns: 1;
}
.columns-2,
.heirs-columns-2 > * {
  columns: 2;
}
.columns-3,
.heirs-columns-3 > * {
  columns: 3;
}
.columns-4,
.heirs-columns-4 > * {
  columns: 4;
}
.columns-5,
.heirs-columns-5 > * {
  columns: 5;
}
.columns-6,
.heirs-columns-6 > * {
  columns: 6;
}
.columns-7,
.heirs-columns-7 > * {
  columns: 7;
}
.columns-8,
.heirs-columns-8 > * {
  columns: 8;
}
.columns-9,
.heirs-columns-9 > * {
  columns: 9;
}
.columns-10,
.heirs-columns-10 > * {
  columns: 10;
}
.columns-11,
.heirs-columns-11 > * {
  columns: 11;
}
.columns-12,
.heirs-columns-12 > * {
  columns: 12;
}

/* column count
   ================================== */

.column-count-a,
.heirs-column-count-a > * {
  column-count: auto;
}
.column-count-i,
.heirs-column-count-i > * {
  column-count: inherit;
}

.column-count-0,
.heirs-column-count-0 > * {
  column-count: 0;
}
.column-count-1,
.heirs-column-count-1 > * {
  column-count: 1;
}
.column-count-2,
.heirs-column-count-2 > * {
  column-count: 2;
}
.column-count-3,
.heirs-column-count-3 > * {
  column-count: 3;
}
.column-count-4,
.heirs-column-count-4 > * {
  column-count: 4;
}
.column-count-5,
.heirs-column-count-5 > * {
  column-count: 5;
}
.column-count-6,
.heirs-column-count-6 > * {
  column-count: 6;
}
.column-count-7,
.heirs-column-count-7 > * {
  column-count: 7;
}
.column-count-8,
.heirs-column-count-8 > * {
  column-count: 8;
}
.column-count-9,
.heirs-column-count-9 > * {
  column-count: 9;
}
.column-count-10,
.heirs-column-count-10 > * {
  column-count: 10;
}
.column-count-11,
.heirs-column-count-11 > * {
  column-count: 11;
}
.column-count-12,
.heirs-column-count-12 > * {
  column-count: 12;
}

/* column width
   ================================== */

.column-width-a,
.heirs-column-width-a > * {
  column-width: auto;
}
.column-width-full,
.heirs-column-width-full > * {
  column-width: 100%;
}
.column-width-i,
.heirs-column-width-i > * {
  column-width: inherit;
}
.column-width-0,
.heirs-column-width-0 > * {
  column-width: 0;
}

.column-width-1-2,
.heirs-column-width-1-2 > * {
  column-width: 50%;
}
.column-width-1-3,
.heirs-column-width-1-3 > * {
  column-width: 33.33333333333333%;
}
.column-width-1-4,
.heirs-column-width-1-4 > * {
  column-width: 25%;
}
.column-width-1-5,
.heirs-column-width-1-5 > * {
  column-width: 20%;
}
.column-width-1-6,
.heirs-column-width-1-6 > * {
  column-width: 16.66666666666667%;
}
.column-width-1-7,
.heirs-column-width-1-7 > * {
  column-width: 14.28571428571429%;
}
.column-width-1-8,
.heirs-column-width-1-8 > * {
  column-width: 12.5%;
}
.column-width-1-9,
.heirs-column-width-1-9 > * {
  column-width: 11.11111111111111%;
}
.column-width-1-10,
.heirs-column-width-1-10 > * {
  column-width: 10%;
}
.column-width-1-11,
.heirs-column-width-1-11 > * {
  column-width: 9.090909090909091%;
}
.column-width-1-12,
.heirs-column-width-1-12 > * {
  column-width: 8.333333333333333%;
}
.column-width-1-20,
.heirs-column-width-1-20 > * {
  column-width: 5%;
}
.column-width-2-3,
.heirs-column-width-2-3 > * {
  column-width: 66.66666666666667%;
}
.column-width-2-5,
.heirs-column-width-2-5 > * {
  column-width: 40%;
}
.column-width-2-7,
.heirs-column-width-2-7 > * {
  column-width: 28.57142857142858%;
}
.column-width-2-9,
.heirs-column-width-2-9 > * {
  column-width: 22.22222222222222%;
}
.column-width-2-11,
.heirs-column-width-2-11 > * {
  column-width: 18.18181818181818%;
}
.column-width-3-4,
.heirs-column-width-3-4 > * {
  column-width: 75%;
}
.column-width-3-5,
.heirs-column-width-3-5 > * {
  column-width: 60%;
}
.column-width-3-7,
.heirs-column-width-3-7 > * {
  column-width: 42.85714285714287%;
}
.column-width-3-8,
.heirs-column-width-3-8 > * {
  column-width: 37.5%;
}
.column-width-3-10,
.heirs-column-width-3-10 > * {
  column-width: 30%;
}
.column-width-3-11,
.heirs-column-width-3-11 > * {
  column-width: 27.27272727272727%;
}
.column-width-4-5,
.heirs-column-width-4-5 > * {
  column-width: 80%;
}
.column-width-4-7,
.heirs-column-width-4-7 > * {
  column-width: 57.14285714285716%;
}
.column-width-4-9,
.heirs-column-width-4-9 > * {
  column-width: 44.44444444444444%;
}
.column-width-4-11,
.heirs-column-width-4-11 > * {
  column-width: 36.36363636363636%;
}
.column-width-5-6,
.heirs-column-width-5-6 > * {
  column-width: 83.33333333333333%;
}
.column-width-5-7,
.heirs-column-width-5-7 > * {
  column-width: 71.42857142857145%;
}
.column-width-5-8,
.heirs-column-width-5-8 > * {
  column-width: 62.5%;
}
.column-width-5-9,
.heirs-column-width-5-9 > * {
  column-width: 55.55555555555555%;
}
.column-width-5-11,
.heirs-column-width-5-11 > * {
  column-width: 45.45454545454546%;
}
.column-width-5-12,
.heirs-column-width-5-12 > * {
  column-width: 41.66666666666667%;
}
.column-width-6-7,
.heirs-column-width-6-7 > * {
  column-width: 85.71428571428574%;
}
.column-width-6-11,
.heirs-column-width-6-11 > * {
  column-width: 54.54545454545455%;
}
.column-width-7-8,
.heirs-column-width-7-8 > * {
  column-width: 87.5%;
}
.column-width-7-9,
.heirs-column-width-7-9 > * {
  column-width: 77.77777777777777%;
}
.column-width-7-10,
.heirs-column-width-7-10 > * {
  column-width: 70%;
}
.column-width-7-11,
.heirs-column-width-7-11 > * {
  column-width: 63.63636363636364%;
}
.column-width-7-12,
.heirs-column-width-7-12 > * {
  column-width: 58.33333333333333%;
}
.column-width-8-9,
.heirs-column-width-8-9 > * {
  column-width: 88.88888888888888%;
}
.column-width-8-11,
.heirs-column-width-8-11 > * {
  column-width: 72.72727272727273%;
}
.column-width-9-10,
.heirs-column-width-9-10 > * {
  column-width: 90%;
}
.column-width-9-11,
.heirs-column-width-9-11 > * {
  column-width: 81.81818181818182%;
}
.column-width-10-11,
.heirs-column-width-10-11 > * {
  column-width: 90.90909090909091%;
}
.column-width-11-12,
.heirs-column-width-11-12 > * {
  column-width: 91.66666666666666%;
}

/* ==========================================================================
   cues & pointers
   ========================================================================== */

.arrow-after:after,
.heirs-arrow-after > *:after,
.heirs-arrow-between > *:not(:first-child):before,
.arrow-l-after:after,
.heirs-arrow-l-after > *:after,
.heirs-arrow-l-between > *:not(:first-child):before,
.bar-after:after,
.heirs-bar-after > *:after,
.heirs-bar-between > *:not(:first-child):before,
.bullet-after:after,
.heirs-bullet-after > *:after,
.heirs-bullet-between > *:not(:first-child):before,
.chevron-after:after,
.heirs-chevron-after > *:after,
.heirs-chevron-between > *:not(:first-child):before,
.chevron-l-after:after,
.heirs-chevron-l-after > *:after,
.heirs-chevron-l-between > *:not(:first-child):before,
.square-after:after,
.heirs-square-after > *:after,
.heirs-square-between > *:not(:first-child):before {
  display: inline;
  margin-left: 0.25rem;
}

.arrow-before:before,
.heirs-arrow-before > *:before,
.heirs-arrow-between > *:not(:first-child):before,
.arrow-l-before:before,
.heirs-arrow-l-before > *:before,
.heirs-arrow-l-between > *:not(:first-child):before,
.bar-before:before,
.heirs-bar-before > *:before,
.heirs-bar-between > *:not(:first-child):before,
.bullet-before:before,
.heirs-bullet-before > *:before,
.heirs-bullet-between > *:not(:first-child):before,
.chevron-before:before,
.heirs-chevron-before > *:before,
.heirs-chevron-between > *:not(:first-child):before,
.chevron-l-before:before,
.heirs-chevron-l-before > *:before,
.heirs-chevron-l-between > *:not(:first-child):before,
.square-before:before,
.heirs-square-before > *:before,
.heirs-square-between > *:not(:first-child):before {
  display: inline;
  margin-right: 0.25rem;
}

.arrow-after:after,
.heirs-arrow-after > *:after,
.arrow-before:before,
.heirs-arrow-before > *:before,
.heirs-arrow-between > *:not(:first-child):before {
  content: "›";
}

.arrow-l-after:after,
.heirs-arrow-l-after > *:after,
.arrow-l-before:before,
.heirs-arrow-l-before > *:before,
.heirs-arrow-l-between > *:not(:first-child):before {
  content: "‹";
}

.bar-after:after,
.heirs-bar-after > *:after,
.bar-before:before,
.heirs-bar-before > *:before,
.heirs-bar-between > *:not(:first-child):before {
  content: "|";
}

.bullet-after:after,
.heirs-bullet-after > *:after,
.bullet-before:before,
.heirs-bullet-before > *:before,
.heirs-bullet-between > *:not(:first-child):before {
  content: "•";
  font-family: Arial;
}

.chevron-after:after,
.heirs-chevron-after > *:after,
.chevron-before:before,
.heirs-chevron-before > *:before,
.heirs-chevron-between > *:not(:first-child):before {
  content: "»";
}

.chevron-l-after:after,
.heirs-chevron-l-after > *:after,
.chevron-l-before:before,
.heirs-chevron-l-before > *:before,
.heirs-chevron-l-between > *:not(:first-child):before {
  content: "«";
}

.square-after:after,
.heirs-square-after > *:after,
.square-before:before,
.heirs-square-before > *:before,
.heirs-square-between > *:not(:first-child):before {
  content: "■";
  font-family: Arial;
}

.nothing-after:after,
.heirs-nothing-after > *:after,
.nothing-before:before,
.heirs-nothing-before > *:before,
.heirs-nothing-between > *:not(:first-child):before {
  content: "";
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   snowflake management
   ========================================================================== */

/* text
   ========================================================================== */

.font-size-i,
.heirs-font-size-i > * {
  font-size: inherit !important;
}

.font-size-0,
.heirs-font-size-0 > * {
  font-size: 0 !important;
}
.font-size-1-8,
.heirs-font-size-1-8 > * {
  font-size: 0.125rem !important;
}
.font-size-1-4,
.heirs-font-size-1-4 > * {
  font-size: 0.25rem !important;
}
.font-size-3-8,
.heirs-font-size-3-8 > * {
  font-size: 0.375rem !important;
}
.font-size-1-2,
.heirs-font-size-1-2 > * {
  font-size: 0.5rem !important;
}
.font-size-5-8,
.heirs-font-size-5-8 > * {
  font-size: 0.625rem !important;
}
.font-size-3-4,
.heirs-font-size-3-4 > * {
  font-size: 0.75rem !important;
}
.font-size-7-8,
.heirs-font-size-7-8 > * {
  font-size: 0.875rem !important;
}

.font-size-1,
.heirs-font-size-1 > * {
  font-size: 1rem !important;
}
.font-size-1-1-8,
.heirs-font-size-1-1-8 > * {
  font-size: 1.125rem !important;
}
.font-size-1-1-4,
.heirs-font-size-1-1-4 > * {
  font-size: 1.25rem !important;
}
.font-size-1-3-8,
.heirs-font-size-1-3-8 > * {
  font-size: 1.375rem !important;
}
.font-size-1-1-2,
.heirs-font-size-1-1-2 > * {
  font-size: 1.5rem !important;
}
.font-size-1-5-8,
.heirs-font-size-1-5-8 > * {
  font-size: 1.625rem !important;
}
.font-size-1-3-4,
.heirs-font-size-1-3-4 > * {
  font-size: 1.75rem !important;
}
.font-size-1-7-8,
.heirs-font-size-1-7-8 > * {
  font-size: 1.875rem !important;
}

.font-size-2,
.heirs-font-size-2 > * {
  font-size: 2rem !important;
}
.font-size-2-1-8,
.heirs-font-size-2-1-8 > * {
  font-size: 2.125rem !important;
}
.font-size-2-1-4,
.heirs-font-size-2-1-4 > * {
  font-size: 2.25rem !important;
}
.font-size-2-3-8,
.heirs-font-size-2-3-8 > * {
  font-size: 2.375rem !important;
}
.font-size-2-1-2,
.heirs-font-size-2-1-2 > * {
  font-size: 2.5rem !important;
}
.font-size-2-5-8,
.heirs-font-size-2-5-8 > * {
  font-size: 2.625rem !important;
}
.font-size-2-3-4,
.heirs-font-size-2-3-4 > * {
  font-size: 2.75rem !important;
}
.font-size-2-7-8,
.heirs-font-size-2-7-8 > * {
  font-size: 2.875rem !important;
}

.font-size-3,
.heirs-font-size-3 > * {
  font-size: 3rem !important;
}
.font-size-3-1-8,
.heirs-font-size-3-1-8 > * {
  font-size: 3.125rem !important;
}
.font-size-3-1-4,
.heirs-font-size-3-1-4 > * {
  font-size: 3.25rem !important;
}
.font-size-3-3-8,
.heirs-font-size-3-3-8 > * {
  font-size: 3.375rem !important;
}
.font-size-3-1-2,
.heirs-font-size-3-1-2 > * {
  font-size: 3.5rem !important;
}
.font-size-3-5-8,
.heirs-font-size-3-5-8 > * {
  font-size: 3.625rem !important;
}
.font-size-3-3-4,
.heirs-font-size-3-3-4 > * {
  font-size: 3.75rem !important;
}
.font-size-3-7-8,
.heirs-font-size-3-7-8 > * {
  font-size: 3.875rem !important;
}

.font-size-4,
.heirs-font-size-4 > * {
  font-size: 4rem !important;
}
.font-size-4-1-8,
.heirs-font-size-4-1-8 > * {
  font-size: 4.125rem !important;
}
.font-size-4-1-4,
.heirs-font-size-4-1-4 > * {
  font-size: 4.25rem !important;
}
.font-size-4-3-8,
.heirs-font-size-4-3-8 > * {
  font-size: 4.375rem !important;
}
.font-size-4-1-2,
.heirs-font-size-4-1-2 > * {
  font-size: 4.5rem !important;
}
.font-size-4-5-8,
.heirs-font-size-4-5-8 > * {
  font-size: 4.625rem !important;
}
.font-size-4-3-4,
.heirs-font-size-4-3-4 > * {
  font-size: 4.75rem !important;
}
.font-size-4-7-8,
.heirs-font-size-4-7-8 > * {
  font-size: 4.875rem !important;
}

.font-style-i,
.heirs-font-style-i > * {
  font-style: italic !important;
}
.font-style-n,
.heirs-font-style-n > * {
  font-style: normal !important;
}

.font-weight-200,
.heirs-font-weight-200 > * {
  font-weight: 200 !important;
}
.font-weight-300,
.heirs-font-weight-300 > * {
  font-weight: 300 !important;
}
.font-weight-400,
.heirs-font-weight-400 > * {
  font-weight: 400 !important;
}
.font-weight-500,
.heirs-font-weight-500 > * {
  font-weight: 500 !important;
}
.font-weight-600,
.heirs-font-weight-600 > * {
  font-weight: 600 !important;
}

.font-family-monospace,
.heirs-font-family-monospace > * {
  font-family: Consolas, Monaco, monospace !important;
}
.font-family-sans-serif,
.heirs-font-family-sans-serif > * {
  font-family: Arial, sans-serif !important;
}
.font-family-serif,
.heirs-font-family-serif > * {
  font-family: Times, serif !important;
}

.hyphens-a,
.heirs-hyphens-a > * {
  -ms-hyphens: auto !important;
  -webkit-hyphens: auto !important;
  hyphens: auto !important;
}
.hyphens-m,
.heirs-hyphens-m > * {
  -ms-hyphens: manual !important;
  -webkit-hyphens: manual !important;
  hyphens: manual !important;
}
.hyphens-n,
.heirs-hyphens-n > * {
  -ms-hyphens: none !important;
  -webkit-hyphens: none !important;
  hyphens: none !important;
}

.line-height-i,
.heirs-line-height-i {
  line-height: inherit !important;
}
.line-height-1w,
.heirs-line-height-1w > * {
  line-height: 1 !important;
}

.line-height-0,
.heirs-line-height-0 > * {
  line-height: 0 !important;
}
.line-height-1-8,
.heirs-line-height-1-8 > * {
  line-height: 0.125rem !important;
}
.line-height-1-4,
.heirs-line-height-1-4 > * {
  line-height: 0.25rem !important;
}
.line-height-3-8,
.heirs-line-height-3-8 > * {
  line-height: 0.375rem !important;
}
.line-height-1-2,
.heirs-line-height-1-2 > * {
  line-height: 0.5rem !important;
}
.line-height-5-8,
.heirs-line-height-5-8 > * {
  line-height: 0.625rem !important;
}
.line-height-3-4,
.heirs-line-height-3-4 > * {
  line-height: 0.75rem !important;
}
.line-height-7-8,
.heirs-line-height-7-8 > * {
  line-height: 0.875rem !important;
}

.line-height-1,
.heirs-line-height-1 > * {
  line-height: 1rem !important;
}
.line-height-1-1-8,
.heirs-line-height-1-1-8 > * {
  line-height: 1.125rem !important;
}
.line-height-1-1-4,
.heirs-line-height-1-1-4 > * {
  line-height: 1.25rem !important;
}
.line-height-1-3-8,
.heirs-line-height-1-3-8 > * {
  line-height: 1.375rem !important;
}
.line-height-1-1-2,
.heirs-line-height-1-1-2 > * {
  line-height: 1.5rem !important;
}
.line-height-1-5-8,
.heirs-line-height-1-5-8 > * {
  line-height: 1.625rem !important;
}
.line-height-1-3-4,
.heirs-line-height-1-3-4 > * {
  line-height: 1.75rem !important;
}
.line-height-1-7-8,
.heirs-line-height-1-7-8 > * {
  line-height: 1.875rem !important;
}

.line-height-2,
.heirs-line-height-2 > * {
  line-height: 2rem !important;
}
.line-height-2-1-8,
.heirs-line-height-2-1-8 > * {
  line-height: 2.125rem !important;
}
.line-height-2-1-4,
.heirs-line-height-2-1-4 > * {
  line-height: 2.25rem !important;
}
.line-height-2-3-8,
.heirs-line-height-2-3-8 > * {
  line-height: 2.375rem !important;
}
.line-height-2-1-2,
.heirs-line-height-2-1-2 > * {
  line-height: 2.5rem !important;
}
.line-height-2-5-8,
.heirs-line-height-2-5-8 > * {
  line-height: 2.625rem !important;
}
.line-height-2-3-4,
.heirs-line-height-2-3-4 > * {
  line-height: 2.75rem !important;
}
.line-height-2-7-8,
.heirs-line-height-2-7-8 > * {
  line-height: 2.875rem !important;
}

.line-height-3,
.heirs-line-height-3 > * {
  line-height: 3rem !important;
}
.line-height-3-1-8,
.heirs-line-height-3-1-8 > * {
  line-height: 3.125rem !important;
}
.line-height-3-1-4,
.heirs-line-height-3-1-4 > * {
  line-height: 3.25rem !important;
}
.line-height-3-3-8,
.heirs-line-height-3-3-8 > * {
  line-height: 3.375rem !important;
}
.line-height-3-1-2,
.heirs-line-height-3-1-2 > * {
  line-height: 3.5rem !important;
}
.line-height-3-5-8,
.heirs-line-height-3-5-8 > * {
  line-height: 3.625rem !important;
}
.line-height-3-3-4,
.heirs-line-height-3-3-4 > * {
  line-height: 3.75rem !important;
}
.line-height-3-7-8,
.heirs-line-height-3-7-8 > * {
  line-height: 3.875rem !important;
}

.line-height-4,
.heirs-line-height-4 > * {
  line-height: 4rem !important;
}
.line-height-4-1-8,
.heirs-line-height-4-1-8 > * {
  line-height: 4.125rem !important;
}
.line-height-4-1-4,
.heirs-line-height-4-1-4 > * {
  line-height: 4.25rem !important;
}
.line-height-4-3-8,
.heirs-line-height-4-3-8 > * {
  line-height: 4.375rem !important;
}
.line-height-4-1-2,
.heirs-line-height-4-1-2 > * {
  line-height: 4.5rem !important;
}
.line-height-4-5-8,
.heirs-line-height-4-5-8 > * {
  line-height: 4.625rem !important;
}
.line-height-4-3-4,
.heirs-line-height-4-3-4 > * {
  line-height: 4.75rem !important;
}
.line-height-4-7-8,
.heirs-line-height-4-7-8 > * {
  line-height: 4.875rem !important;
}

.text-align-c,
.heirs-text-align-c > * {
  text-align: center !important;
}
.text-align-j,
.heirs-text-align-j > * {
  text-align: justify !important;
}
.text-align-l,
.heirs-text-align-l > * {
  text-align: left !important;
}
.text-align-r,
.heirs-text-align-r > * {
  text-align: right !important;
}

.text-decoration-lt,
.heirs-text-decoration-lt > * {
  text-decoration: line-through !important;
}
.text-decoration-o,
.heirs-text-decoration-o > * {
  text-decoration: overline !important;
}
.text-decoration-u,
.heirs-text-decoration-u > * {
  text-decoration: underline !important;
}
.text-decoration-uo,
.heirs-text-decoration-uo > * {
  text-decoration: underline overline !important;
}
.text-decoration-n,
.heirs-text-decoration-n > * {
  text-decoration: none !important;
}

.text-transform-l,
.heirs-text-transform-l > * {
  text-transform: lowercase !important;
}
.text-transform-u,
.heirs-text-transform-u > * {
  text-transform: uppercase !important;
}
.text-transform-n,
.heirs-text-transform-n > * {
  text-transform: none !important;
}

.white-space-n,
.heirs-white-space-n > * {
  white-space: normal !important;
}
.white-space-nw,
.heirs-white-space-nw > * {
  white-space: nowrap !important;
}
.white-space-p,
.heirs-white-space-p > * {
  white-space: pre !important;
}
.white-space-pl,
.heirs-white-space-pl > * {
  white-space: pre-line !important;
}
.white-space-pw,
.heirs-white-space-pw > * {
  white-space: pre-wrap !important;
}

.word-break-ba,
.heirs-word-break-ba > * {
  word-break: break-all !important;
}
.word-break-ka,
.heirs-word-break-ka > * {
  word-break: keep-all !important;
}
.word-break-n,
.heirs-word-break-n > * {
  word-break: normal !important;
}

.word-wrap-bw,
.heirs-word-wrap-bw > * {
  word-wrap: break-word !important;
}
.word-wrap-n,
.heirs-word-wrap-n > * {
  word-wrap: normal !important;
}

/* display
   ========================================================================== */

.display-b,
.heirs-display-b > *,
.show,
.heirs-show > * {
  display: block !important;
}
.display-i,
.heirs-display-i > * {
  display: inline !important;
}
.display-ib,
.heirs-display-ib > * {
  display: inline-block !important;
}
.display-it,
.heirs-display-it > * {
  display: inline-table !important;
}
.display-li,
.heirs-display-li > * {
  display: list-item !important;
}
.display-tcap,
.heirs-display-tcap > * {
  display: table-caption !important;
}
.display-td,
.heirs-display-td > * {
  display: table-cell !important;
}
.display-tcg,
.heirs-display-tcg > * {
  display: table-column-group !important;
}
.display-tc,
.heirs-display-tc > * {
  display: table-column !important;
}
.display-tfg,
.heirs-display-tfg > * {
  display: table-footer-group !important;
}
.display-thg,
.heirs-display-thg > * {
  display: table-header-group !important;
}
.display-trg,
.heirs-display-trg > * {
  display: table-row-group !important;
}
.display-tr,
.heirs-display-tr > * {
  display: table-row !important;
}
.display-t,
.heirs-display-t > * {
  display: table !important;
}
.display-n,
.heirs-display-n > *,
.hide,
.heirs-hide > * {
  display: none !important;
}

.opacity-0,
.heirs-opacity-0 > * {
  opacity: 0 !important;
}
.opacity-1,
.heirs-opacity-1 > * {
  opacity: 1 !important;
}

.visibility-h,
.heirs-visibility-h > *,
.hidden,
.heirs-hidden > * {
  visibility: hidden !important;
}
.visibility-v,
.heirs-visibility-v > *,
.visible,
.heirs-visible > * {
  visibility: visible !important;
}

/* positioning
   ========================================================================== */

.clear-l,
.heirs-clear-l > * {
  clear: left !important;
}
.clear-r,
.heirs-clear-r > * {
  clear: right !important;
}
.clear-b,
.heirs-clear-b > * {
  clear: both !important;
}
.clear-n,
.heirs-clear-n > * {
  clear: none !important;
}

.float-l,
.heirs-float-l > * {
  float: left !important;
}
.float-r,
.heirs-float-r > * {
  float: right !important;
}
.float-n,
.heirs-float-n > * {
  float: none !important;
}

.position-a,
.heirs-position-a > * {
  position: absolute !important;
}
.position-f,
.heirs-position-f > * {
  position: fixed !important;
}
.position-r,
.heirs-position-r > * {
  position: relative !important;
}
.position-s,
.heirs-position-s > * {
  position: static !important;
}

.vertical-align-bl,
.heirs-vertical-align-bl > * {
  vertical-align: baseline !important;
}
.vertical-align-b,
.heirs-vertical-align-b > * {
  vertical-align: bottom !important;
}
.vertical-align-m,
.heirs-vertical-align-m > * {
  vertical-align: middle !important;
}
.vertical-align-sub,
.heirs-vertical-align-sub > * {
  vertical-align: sub !important;
}
.vertical-align-sup,
.heirs-vertical-align-sup > * {
  vertical-align: super !important;
}
.vertical-align-tb,
.heirs-vertical-align-tb > * {
  vertical-align: text-bottom !important;
}
.vertical-align-tt,
.heirs-vertical-align-tt > * {
  vertical-align: text-top !important;
}
.vertical-align-t,
.heirs-vertical-align-t > * {
  vertical-align: top !important;
}

/* dimensions
   ========================================================================== */

/* heights
   ================================== */

.height-a,
.heirs-height-a > * {
  height: auto !important;
}
.height-full,
.heirs-height-full > * {
  height: 100% !important;
}
.height-i,
.heirs-height-i > * {
  height: inherit !important;
}
.height-0,
.heirs-height-0 > * {
  height: 0 !important;
}
.height-1,
.heirs-height-1 > * {
  height: 1px !important;
}

.max-height-full,
.heirs-max-height-full > * {
  max-height: 100% !important;
}
.max-height-i,
.heirs-max-height-i > * {
  max-height: inherit !important;
}
.max-height-0,
.heirs-max-height-0 > * {
  max-height: 0 !important;
}
.max-height-1,
.heirs-max-height-1 > * {
  max-height: 1px !important;
}

.min-height-a,
.heirs-min-height-a > * {
  min-height: auto !important;
}
.min-height-full,
.heirs-min-height-full > * {
  min-height: 100% !important;
}
.min-height-i,
.heirs-min-height-i > * {
  min-height: inherit !important;
}
.min-height-0,
.heirs-min-height-0 > * {
  min-height: 0 !important;
}
.min-height-1,
.heirs-min-height-1 > * {
  min-height: 1px !important;
}

/* widths
   ================================== */

.width-a,
.heirs-width-a > * {
  width: auto !important;
}
.width-full,
.heirs-width-full > * {
  width: 100% !important;
}
.width-i,
.heirs-width-i > * {
  width: inherit !important;
}
.width-0,
.heirs-width-0 > * {
  width: 0 !important;
}

.width-1200,
.heirs-width-1200 > * {
  width: 1200px !important;
}
.width-900,
.heirs-width-900 > * {
  width: 900px !important;
}
.width-600,
.heirs-width-600 > * {
  width: 600px !important;
}
.width-320,
.heirs-width-320 > * {
  width: 320px !important;
}

.width-1-2,
.heirs-width-1-2 > * {
  width: 50% !important;
}
.width-1-3,
.heirs-width-1-3 > * {
  width: 33.33333333333333% !important;
}
.width-1-4,
.heirs-width-1-4 > * {
  width: 25% !important;
}
.width-1-5,
.heirs-width-1-5 > * {
  width: 20% !important;
}
.width-1-6,
.heirs-width-1-6 > * {
  width: 16.66666666666667% !important;
}
.width-1-7,
.heirs-width-1-7 > * {
  width: 14.28571428571429% !important;
}
.width-1-8,
.heirs-width-1-8 > * {
  width: 12.5% !important;
}
.width-1-9,
.heirs-width-1-9 > * {
  width: 11.11111111111111% !important;
}
.width-1-10,
.heirs-width-1-10 > * {
  width: 10% !important;
}
.width-1-11,
.heirs-width-1-11 > * {
  width: 9.090909090909091% !important;
}
.width-1-12,
.heirs-width-1-12 > * {
  width: 8.333333333333333% !important;
}
.width-1-20,
.heirs-width-1-20 > * {
  width: 5% !important;
}
.width-2-3,
.heirs-width-2-3 > * {
  width: 66.66666666666667% !important;
}
.width-2-5,
.heirs-width-2-5 > * {
  width: 40% !important;
}
.width-2-7,
.heirs-width-2-7 > * {
  width: 28.57142857142858% !important;
}
.width-2-9,
.heirs-width-2-9 > * {
  width: 22.22222222222222% !important;
}
.width-2-11,
.heirs-width-2-11 > * {
  width: 18.18181818181818% !important;
}
.width-3-4,
.heirs-width-3-4 > * {
  width: 75% !important;
}
.width-3-5,
.heirs-width-3-5 > * {
  width: 60% !important;
}
.width-3-7,
.heirs-width-3-7 > * {
  width: 42.85714285714287% !important;
}
.width-3-8,
.heirs-width-3-8 > * {
  width: 37.5% !important;
}
.width-3-10,
.heirs-width-3-10 > * {
  width: 30% !important;
}
.width-3-11,
.heirs-width-3-11 > * {
  width: 27.27272727272727% !important;
}
.width-4-5,
.heirs-width-4-5 > * {
  width: 80% !important;
}
.width-4-7,
.heirs-width-4-7 > * {
  width: 57.14285714285716% !important;
}
.width-4-9,
.heirs-width-4-9 > * {
  width: 44.44444444444444% !important;
}
.width-4-11,
.heirs-width-4-11 > * {
  width: 36.36363636363636% !important;
}
.width-5-6,
.heirs-width-5-6 > * {
  width: 83.33333333333333% !important;
}
.width-5-7,
.heirs-width-5-7 > * {
  width: 71.42857142857145% !important;
}
.width-5-8,
.heirs-width-5-8 > * {
  width: 62.5% !important;
}
.width-5-9,
.heirs-width-5-9 > * {
  width: 55.55555555555555% !important;
}
.width-5-11,
.heirs-width-5-11 > * {
  width: 45.45454545454546% !important;
}
.width-5-12,
.heirs-width-5-12 > * {
  width: 41.66666666666667% !important;
}
.width-6-7,
.heirs-width-6-7 > * {
  width: 85.71428571428574% !important;
}
.width-6-11,
.heirs-width-6-11 > * {
  width: 54.54545454545455% !important;
}
.width-7-8,
.heirs-width-7-8 > * {
  width: 87.5% !important;
}
.width-7-9,
.heirs-width-7-9 > * {
  width: 77.77777777777777% !important;
}
.width-7-10,
.heirs-width-7-10 > * {
  width: 70% !important;
}
.width-7-11,
.heirs-width-7-11 > * {
  width: 63.63636363636364% !important;
}
.width-7-12,
.heirs-width-7-12 > * {
  width: 58.33333333333333% !important;
}
.width-8-9,
.heirs-width-8-9 > * {
  width: 88.88888888888888% !important;
}
.width-8-11,
.heirs-width-8-11 > * {
  width: 72.72727272727273% !important;
}
.width-9-10,
.heirs-width-9-10 > * {
  width: 90% !important;
}
.width-9-11,
.heirs-width-9-11 > * {
  width: 81.81818181818182% !important;
}
.width-10-11,
.heirs-width-10-11 > * {
  width: 90.90909090909091% !important;
}
.width-11-12,
.heirs-width-11-12 > * {
  width: 91.66666666666666% !important;
}

.max-width-full,
.heirs-max-width-full > * {
  max-width: 100% !important;
}
.max-width-i,
.heirs-max-width-i > * {
  max-width: inherit !important;
}
.max-width-n,
.heirs-max-width-n > * {
  max-width: none !important;
}
.max-width-0,
.heirs-max-width-0 > * {
  max-width: 0 !important;
}

.max-width-1200,
.heirs-max-width-1200 > * {
  max-width: 1200px !important;
}
.max-width-900,
.heirs-max-width-900 > * {
  max-width: 900px !important;
}
.max-width-600,
.heirs-max-width-600 > * {
  max-width: 600px !important;
}
.max-width-320,
.heirs-max-width-320 > * {
  max-width: 320px !important;
}

.max-width-1-2,
.heirs-max-width-1-2 > * {
  max-width: 50% !important;
}
.max-width-1-3,
.heirs-max-width-1-3 > * {
  max-width: 33.33333333333333% !important;
}
.max-width-1-4,
.heirs-max-width-1-4 > * {
  max-width: 25% !important;
}
.max-width-1-5,
.heirs-max-width-1-5 > * {
  max-width: 20% !important;
}
.max-width-1-6,
.heirs-max-width-1-6 > * {
  max-width: 16.66666666666667% !important;
}
.max-width-1-7,
.heirs-max-width-1-7 > * {
  max-width: 14.28571428571429% !important;
}
.max-width-1-8,
.heirs-max-width-1-8 > * {
  max-width: 12.5% !important;
}
.max-width-1-9,
.heirs-max-width-1-9 > * {
  max-width: 11.11111111111111% !important;
}
.max-width-1-10,
.heirs-max-width-1-10 > * {
  max-width: 10% !important;
}
.max-width-1-11,
.heirs-max-width-1-11 > * {
  max-width: 9.090909090909091% !important;
}
.max-width-1-12,
.heirs-max-width-1-12 > * {
  max-width: 8.333333333333333% !important;
}
.max-width-1-20,
.heirs-max-width-1-20 > * {
  max-width: 5% !important;
}
.max-width-2-3,
.heirs-max-width-2-3 > * {
  max-width: 66.66666666666667% !important;
}
.max-width-2-5,
.heirs-max-width-2-5 > * {
  max-width: 40% !important;
}
.max-width-2-7,
.heirs-max-width-2-7 > * {
  max-width: 28.57142857142858% !important;
}
.max-width-2-9,
.heirs-max-width-2-9 > * {
  max-width: 22.22222222222222% !important;
}
.max-width-2-11,
.heirs-max-width-2-11 > * {
  max-width: 18.18181818181818% !important;
}
.max-width-3-4,
.heirs-max-width-3-4 > * {
  max-width: 75% !important;
}
.max-width-3-5,
.heirs-max-width-3-5 > * {
  max-width: 60% !important;
}
.max-width-3-7,
.heirs-max-width-3-7 > * {
  max-width: 42.85714285714287% !important;
}
.max-width-3-8,
.heirs-max-width-3-8 > * {
  max-width: 37.5% !important;
}
.max-width-3-10,
.heirs-max-width-3-10 > * {
  max-width: 30% !important;
}
.max-width-3-11,
.heirs-max-width-3-11 > * {
  max-width: 27.27272727272727% !important;
}
.max-width-4-5,
.heirs-max-width-4-5 > * {
  max-width: 80% !important;
}
.max-width-4-7,
.heirs-max-width-4-7 > * {
  max-width: 57.14285714285716% !important;
}
.max-width-4-9,
.heirs-max-width-4-9 > * {
  max-width: 44.44444444444444% !important;
}
.max-width-4-11,
.heirs-max-width-4-11 > * {
  max-width: 36.36363636363636% !important;
}
.max-width-5-6,
.heirs-max-width-5-6 > * {
  max-width: 83.33333333333333% !important;
}
.max-width-5-7,
.heirs-max-width-5-7 > * {
  max-width: 71.42857142857145% !important;
}
.max-width-5-8,
.heirs-max-width-5-8 > * {
  max-width: 62.5% !important;
}
.max-width-5-9,
.heirs-max-width-5-9 > * {
  max-width: 55.55555555555555% !important;
}
.max-width-5-11,
.heirs-max-width-5-11 > * {
  max-width: 45.45454545454546% !important;
}
.max-width-5-12,
.heirs-max-width-5-12 > * {
  max-width: 41.66666666666667% !important;
}
.max-width-6-7,
.heirs-max-width-6-7 > * {
  max-width: 85.71428571428574% !important;
}
.max-width-6-11,
.heirs-max-width-6-11 > * {
  max-width: 54.54545454545455% !important;
}
.max-width-7-8,
.heirs-max-width-7-8 > * {
  max-width: 87.5% !important;
}
.max-width-7-9,
.heirs-max-width-7-9 > * {
  max-width: 77.77777777777777% !important;
}
.max-width-7-10,
.heirs-max-width-7-10 > * {
  max-width: 70% !important;
}
.max-width-7-11,
.heirs-max-width-7-11 > * {
  max-width: 63.63636363636364% !important;
}
.max-width-7-12,
.heirs-max-width-7-12 > * {
  max-width: 58.33333333333333% !important;
}
.max-width-8-9,
.heirs-max-width-8-9 > * {
  max-width: 88.88888888888888% !important;
}
.max-width-8-11,
.heirs-max-width-8-11 > * {
  max-width: 72.72727272727273% !important;
}
.max-width-9-10,
.heirs-max-width-9-10 > * {
  max-width: 90% !important;
}
.max-width-9-11,
.heirs-max-width-9-11 > * {
  max-width: 81.81818181818182% !important;
}
.max-width-10-11,
.heirs-max-width-10-11 > * {
  max-width: 90.90909090909091% !important;
}
.max-width-11-12,
.heirs-max-width-11-12 > * {
  max-width: 91.66666666666666% !important;
}

.min-width-a,
.heirs-min-width-a > * {
  min-width: auto !important;
}
.min-width-full,
.heirs-min-width-full > * {
  min-width: 100% !important;
}
.min-width-i,
.heirs-min-width-i > * {
  min-width: inherit !important;
}
.min-width-0,
.heirs-min-width-0 > * {
  min-width: 0 !important;
}

.min-width-1200,
.heirs-min-width-1200 > * {
  min-width: 1200px !important;
}
.min-width-900,
.heirs-min-width-900 > * {
  min-width: 900px !important;
}
.min-width-600,
.heirs-min-width-600 > * {
  min-width: 600px !important;
}
.min-width-320,
.heirs-min-width-320 > * {
  min-width: 320px !important;
}

.min-width-1-2,
.heirs-min-width-1-2 > * {
  min-width: 50% !important;
}
.min-width-1-3,
.heirs-min-width-1-3 > * {
  min-width: 33.33333333333333% !important;
}
.min-width-1-4,
.heirs-min-width-1-4 > * {
  min-width: 25% !important;
}
.min-width-1-5,
.heirs-min-width-1-5 > * {
  min-width: 20% !important;
}
.min-width-1-6,
.heirs-min-width-1-6 > * {
  min-width: 16.66666666666667% !important;
}
.min-width-1-7,
.heirs-min-width-1-7 > * {
  min-width: 14.28571428571429% !important;
}
.min-width-1-8,
.heirs-min-width-1-8 > * {
  min-width: 12.5% !important;
}
.min-width-1-9,
.heirs-min-width-1-9 > * {
  min-width: 11.11111111111111% !important;
}
.min-width-1-10,
.heirs-min-width-1-10 > * {
  min-width: 10% !important;
}
.min-width-1-11,
.heirs-min-width-1-11 > * {
  min-width: 9.090909090909091% !important;
}
.min-width-1-12,
.heirs-min-width-1-12 > * {
  min-width: 8.333333333333333% !important;
}
.min-width-1-20,
.heirs-min-width-1-20 > * {
  min-width: 5% !important;
}
.min-width-2-3,
.heirs-min-width-2-3 > * {
  min-width: 66.66666666666667% !important;
}
.min-width-2-5,
.heirs-min-width-2-5 > * {
  min-width: 40% !important;
}
.min-width-2-7,
.heirs-min-width-2-7 > * {
  min-width: 28.57142857142858% !important;
}
.min-width-2-9,
.heirs-min-width-2-9 > * {
  min-width: 22.22222222222222% !important;
}
.min-width-2-11,
.heirs-min-width-2-11 > * {
  min-width: 18.18181818181818% !important;
}
.min-width-3-4,
.heirs-min-width-3-4 > * {
  min-width: 75% !important;
}
.min-width-3-5,
.heirs-min-width-3-5 > * {
  min-width: 60% !important;
}
.min-width-3-7,
.heirs-min-width-3-7 > * {
  min-width: 42.85714285714287% !important;
}
.min-width-3-8,
.heirs-min-width-3-8 > * {
  min-width: 37.5% !important;
}
.min-width-3-10,
.heirs-min-width-3-10 > * {
  min-width: 30% !important;
}
.min-width-3-11,
.heirs-min-width-3-11 > * {
  min-width: 27.27272727272727% !important;
}
.min-width-4-5,
.heirs-min-width-4-5 > * {
  min-width: 80% !important;
}
.min-width-4-7,
.heirs-min-width-4-7 > * {
  min-width: 57.14285714285716% !important;
}
.min-width-4-9,
.heirs-min-width-4-9 > * {
  min-width: 44.44444444444444% !important;
}
.min-width-4-11,
.heirs-min-width-4-11 > * {
  min-width: 36.36363636363636% !important;
}
.min-width-5-6,
.heirs-min-width-5-6 > * {
  min-width: 83.33333333333333% !important;
}
.min-width-5-7,
.heirs-min-width-5-7 > * {
  min-width: 71.42857142857145% !important;
}
.min-width-5-8,
.heirs-min-width-5-8 > * {
  min-width: 62.5% !important;
}
.min-width-5-9,
.heirs-min-width-5-9 > * {
  min-width: 55.55555555555555% !important;
}
.min-width-5-11,
.heirs-min-width-5-11 > * {
  min-width: 45.45454545454546% !important;
}
.min-width-5-12,
.heirs-min-width-5-12 > * {
  min-width: 41.66666666666667% !important;
}
.min-width-6-7,
.heirs-min-width-6-7 > * {
  min-width: 85.71428571428574% !important;
}
.min-width-6-11,
.heirs-min-width-6-11 > * {
  min-width: 54.54545454545455% !important;
}
.min-width-7-8,
.heirs-min-width-7-8 > * {
  min-width: 87.5% !important;
}
.min-width-7-9,
.heirs-min-width-7-9 > * {
  min-width: 77.77777777777777% !important;
}
.min-width-7-10,
.heirs-min-width-7-10 > * {
  min-width: 70% !important;
}
.min-width-7-11,
.heirs-min-width-7-11 > * {
  min-width: 63.63636363636364% !important;
}
.min-width-7-12,
.heirs-min-width-7-12 > * {
  min-width: 58.33333333333333% !important;
}
.min-width-8-9,
.heirs-min-width-8-9 > * {
  min-width: 88.88888888888888% !important;
}
.min-width-8-11,
.heirs-min-width-8-11 > * {
  min-width: 72.72727272727273% !important;
}
.min-width-9-10,
.heirs-min-width-9-10 > * {
  min-width: 90% !important;
}
.min-width-9-11,
.heirs-min-width-9-11 > * {
  min-width: 81.81818181818182% !important;
}
.min-width-10-11,
.heirs-min-width-10-11 > * {
  min-width: 90.90909090909091% !important;
}
.min-width-11-12,
.heirs-min-width-11-12 > * {
  min-width: 91.66666666666666% !important;
}

/* box styles
   ========================================================================== */

.background-n,
.heirs-background-n > * {
  background: none !important;
}

.border-n,
.heirs-border-n > * {
  border: none !important;
}
.border-b-n,
.heirs-border-b-n > *,
.border-bt-n,
.heirs-border-bt-n > * {
  border-bottom: none !important;
}
.border-l-n,
.heirs-border-l-n > *,
.border-lr-n,
.heirs-border-lr-n > * {
  border-left: none !important;
}
.border-r-n,
.heirs-border-r-n > *,
.border-lr-n,
.heirs-border-lr-n > * {
  border-right: none !important;
}
.border-t-n,
.heirs-border-t-n > *,
.border-bt-n,
.heirs-border-bt-n > * {
  border-top: none !important;
}

.box-sizing-bb,
.heirs-box-sizing-bb > * {
  box-sizing: border-box !important;
}
.box-sizing-cb,
.heirs-box-sizing-cb > * {
  box-sizing: content-box !important;
}

/* margins
   ========================================================================== */

.center,
.heirs-center > * {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.margin-0,
.heirs-margin-0 > * {
  margin: 0 !important;
}
.margin-b-0,
.heirs-margin-b-0 > *,
.margin-bt-0,
.heirs-margin-bt-0 > * {
  margin-bottom: 0 !important;
}
.margin-l-0,
.heirs-margin-l-0 > *,
.margin-lr-0,
.heirs-margin-lr-0 > * {
  margin-left: 0 !important;
}
.margin-r-0,
.heirs-margin-r-0 > *,
.margin-lr-0,
.heirs-margin-lr-0 > * {
  margin-right: 0 !important;
}
.margin-t-0,
.heirs-margin-t-0 > *,
.margin-bt-0,
.heirs-margin-bt-0 > * {
  margin-top: 0 !important;
}

.margin-1-8,
.heirs-margin-1-8 > * {
  margin: 0.125rem !important;
}
.margin-b-1-8,
.heirs-margin-b-1-8 > *,
.margin-bt-1-8,
.heirs-margin-bt-1-8 > * {
  margin-bottom: 0.125rem !important;
}
.margin-l-1-8,
.heirs-margin-l-1-8 > *,
.margin-lr-1-8,
.heirs-margin-lr-1-8 > * {
  margin-left: 0.125rem !important;
}
.margin-r-1-8,
.heirs-margin-r-1-8 > *,
.margin-lr-1-8,
.heirs-margin-lr-1-8 > * {
  margin-right: 0.125rem !important;
}
.margin-t-1-8,
.heirs-margin-t-1-8 > *,
.margin-bt-1-8,
.heirs-margin-bt-1-8 > * {
  margin-top: 0.125rem !important;
}

.margin-1-4,
.heirs-margin-1-4 > * {
  margin: 0.25rem !important;
}
.margin-b-1-4,
.heirs-margin-b-1-4 > *,
.margin-bt-1-4,
.heirs-margin-bt-1-4 > * {
  margin-bottom: 0.25rem !important;
}
.margin-l-1-4,
.heirs-margin-l-1-4 > *,
.margin-lr-1-4,
.heirs-margin-lr-1-4 > * {
  margin-left: 0.25rem !important;
}
.margin-r-1-4,
.heirs-margin-r-1-4 > *,
.margin-lr-1-4,
.heirs-margin-lr-1-4 > * {
  margin-right: 0.25rem !important;
}
.margin-t-1-4,
.heirs-margin-t-1-4 > *,
.margin-bt-1-4,
.heirs-margin-bt-1-4 > * {
  margin-top: 0.25rem !important;
}

.margin-1-2,
.heirs-margin-1-2 > * {
  margin: 0.5rem !important;
}
.margin-b-1-2,
.heirs-margin-b-1-2 > *,
.margin-bt-1-2,
.heirs-margin-bt-1-2 > * {
  margin-bottom: 0.5rem !important;
}
.margin-l-1-2,
.heirs-margin-l-1-2 > *,
.margin-lr-1-2,
.heirs-margin-lr-1-2 > * {
  margin-left: 0.5rem !important;
}
.margin-r-1-2,
.heirs-margin-r-1-2 > *,
.margin-lr-1-2,
.heirs-margin-lr-1-2 > * {
  margin-right: 0.5rem !important;
}
.margin-t-1-2,
.heirs-margin-t-1-2 > *,
.margin-bt-1-2,
.heirs-margin-bt-1-2 > * {
  margin-top: 0.5rem !important;
}

.margin-3-4,
.heirs-margin-3-4 > * {
  margin: 0.75rem !important;
}
.margin-b-3-4,
.heirs-margin-b-3-4 > *,
.margin-bt-3-4,
.heirs-margin-bt-3-4 > * {
  margin-bottom: 0.75rem !important;
}
.margin-l-3-4,
.heirs-margin-l-3-4 > *,
.margin-lr-3-4,
.heirs-margin-lr-3-4 > * {
  margin-left: 0.75rem !important;
}
.margin-r-3-4,
.heirs-margin-r-3-4 > *,
.margin-lr-3-4,
.heirs-margin-lr-3-4 > * {
  margin-right: 0.75rem !important;
}
.margin-t-3-4,
.heirs-margin-t-3-4 > *,
.margin-bt-3-4,
.heirs-margin-bt-3-4 > * {
  margin-top: 0.75rem !important;
}

.margin-1,
.heirs-margin-1 > * {
  margin: 1rem !important;
}
.margin-b-1,
.heirs-margin-b-1 > *,
.margin-bt-1,
.heirs-margin-bt-1 > * {
  margin-bottom: 1rem !important;
}
.margin-l-1,
.heirs-margin-l-1 > *,
.margin-lr-1,
.heirs-margin-lr-1 > * {
  margin-left: 1rem !important;
}
.margin-r-1,
.heirs-margin-r-1 > *,
.margin-lr-1,
.heirs-margin-lr-1 > * {
  margin-right: 1rem !important;
}
.margin-t-1,
.heirs-margin-t-1 > *,
.margin-bt-1,
.heirs-margin-bt-1 > * {
  margin-top: 1rem !important;
}

.margin-2,
.heirs-margin-2 > * {
  margin: 2rem !important;
}
.margin-b-2,
.heirs-margin-b-2 > *,
.margin-bt-2,
.heirs-margin-bt-2 > * {
  margin-bottom: 2rem !important;
}
.margin-l-2,
.heirs-margin-l-2 > *,
.margin-lr-2,
.heirs-margin-lr-2 > * {
  margin-left: 2rem !important;
}
.margin-r-2,
.heirs-margin-r-2 > *,
.margin-lr-2,
.heirs-margin-lr-2 > * {
  margin-right: 2rem !important;
}
.margin-t-2,
.heirs-margin-t-2 > *,
.margin-bt-2,
.heirs-margin-bt-2 > * {
  margin-top: 2rem !important;
}

.margin-3,
.heirs-margin-3 > * {
  margin: 3rem !important;
}
.margin-b-3,
.heirs-margin-b-3 > *,
.margin-bt-3,
.heirs-margin-bt-3 > * {
  margin-bottom: 3rem !important;
}
.margin-l-3,
.heirs-margin-l-3 > *,
.margin-lr-3,
.heirs-margin-lr-3 > * {
  margin-left: 3rem !important;
}
.margin-r-3,
.heirs-margin-r-3 > *,
.margin-lr-3,
.heirs-margin-lr-3 > * {
  margin-right: 3rem !important;
}
.margin-t-3,
.heirs-margin-t-3 > *,
.margin-bt-3,
.heirs-margin-bt-3 > * {
  margin-top: 3rem !important;
}

.margin-4,
.heirs-margin-4 > * {
  margin: 4rem !important;
}
.margin-b-4,
.heirs-margin-b-4 > *,
.margin-bt-4,
.heirs-margin-bt-4 > * {
  margin-bottom: 4rem !important;
}
.margin-l-4,
.heirs-margin-l-4 > *,
.margin-lr-4,
.heirs-margin-lr-4 > * {
  margin-left: 4rem !important;
}
.margin-r-4,
.heirs-margin-r-4 > *,
.margin-lr-4,
.heirs-margin-lr-4 > * {
  margin-right: 4rem !important;
}
.margin-t-4,
.heirs-margin-t-4 > *,
.margin-bt-4,
.heirs-margin-bt-4 > * {
  margin-top: 4rem !important;
}

/* padding
   ========================================================================== */

.padding-0,
.heirs-padding-0 > * {
  padding: 0 !important;
}
.padding-b-0,
.heirs-padding-b-0 > *,
.padding-bt-0,
.heirs-padding-bt-0 > * {
  padding-bottom: 0 !important;
}
.padding-l-0,
.heirs-padding-l-0 > *,
.padding-lr-0,
.heirs-padding-lr-0 > * {
  padding-left: 0 !important;
}
.padding-r-0,
.heirs-padding-r-0 > *,
.padding-lr-0,
.heirs-padding-lr-0 > * {
  padding-right: 0 !important;
}
.padding-t-0,
.heirs-padding-t-0 > *,
.padding-bt-0,
.heirs-padding-bt-0 > * {
  padding-top: 0 !important;
}

.padding-1-8,
.heirs-padding-1-8 > * {
  padding: 0.125rem !important;
}
.padding-b-1-8,
.heirs-padding-b-1-8 > *,
.padding-bt-1-8,
.heirs-padding-bt-1-8 > * {
  padding-bottom: 0.125rem !important;
}
.padding-l-1-8,
.heirs-padding-l-1-8 > *,
.padding-lr-1-8,
.heirs-padding-lr-1-8 > * {
  padding-left: 0.125rem !important;
}
.padding-r-1-8,
.heirs-padding-r-1-8 > *,
.padding-lr-1-8,
.heirs-padding-lr-1-8 > * {
  padding-right: 0.125rem !important;
}
.padding-t-1-8,
.heirs-padding-t-1-8 > *,
.padding-bt-1-8,
.heirs-padding-bt-1-8 > * {
  padding-top: 0.125rem !important;
}

.padding-1-4,
.heirs-padding-1-4 > * {
  padding: 0.25rem !important;
}
.padding-b-1-4,
.heirs-padding-b-1-4 > *,
.padding-bt-1-4,
.heirs-padding-bt-1-4 > * {
  padding-bottom: 0.25rem !important;
}
.padding-l-1-4,
.heirs-padding-l-1-4 > *,
.padding-lr-1-4,
.heirs-padding-lr-1-4 > * {
  padding-left: 0.25rem !important;
}
.padding-r-1-4,
.heirs-padding-r-1-4 > *,
.padding-lr-1-4,
.heirs-padding-lr-1-4 > * {
  padding-right: 0.25rem !important;
}
.padding-t-1-4,
.heirs-padding-t-1-4 > *,
.padding-bt-1-4,
.heirs-padding-bt-1-4 > * {
  padding-top: 0.25rem !important;
}

.padding-1-2,
.heirs-padding-1-2 > * {
  padding: 0.5rem !important;
}
.padding-b-1-2,
.heirs-padding-b-1-2 > *,
.padding-bt-1-2,
.heirs-padding-bt-1-2 > * {
  padding-bottom: 0.5rem !important;
}
.padding-l-1-2,
.heirs-padding-l-1-2 > *,
.padding-lr-1-2,
.heirs-padding-lr-1-2 > * {
  padding-left: 0.5rem !important;
}
.padding-r-1-2,
.heirs-padding-r-1-2 > *,
.padding-lr-1-2,
.heirs-padding-lr-1-2 > * {
  padding-right: 0.5rem !important;
}
.padding-t-1-2,
.heirs-padding-t-1-2 > *,
.padding-bt-1-2,
.heirs-padding-bt-1-2 > * {
  padding-top: 0.5rem !important;
}

.padding-3-4,
.heirs-padding-3-4 > * {
  padding: 0.75rem !important;
}
.padding-b-3-4,
.heirs-padding-b-3-4 > *,
.padding-bt-3-4,
.heirs-padding-bt-3-4 > * {
  padding-bottom: 0.75rem !important;
}
.padding-l-3-4,
.heirs-padding-l-3-4 > *,
.padding-lr-3-4,
.heirs-padding-lr-3-4 > * {
  padding-left: 0.75rem !important;
}
.padding-r-3-4,
.heirs-padding-r-3-4 > *,
.padding-lr-3-4,
.heirs-padding-lr-3-4 > * {
  padding-right: 0.75rem !important;
}
.padding-t-3-4,
.heirs-padding-t-3-4 > *,
.padding-bt-3-4,
.heirs-padding-bt-3-4 > * {
  padding-top: 0.75rem !important;
}

.padding-1,
.heirs-padding-1 > * {
  padding: 1rem !important;
}
.padding-b-1,
.heirs-padding-b-1 > *,
.padding-bt-1,
.heirs-padding-bt-1 > * {
  padding-bottom: 1rem !important;
}
.padding-l-1,
.heirs-padding-l-1 > *,
.padding-lr-1,
.heirs-padding-lr-1 > * {
  padding-left: 1rem !important;
}
.padding-r-1,
.heirs-padding-r-1 > *,
.padding-lr-1,
.heirs-padding-lr-1 > * {
  padding-right: 1rem !important;
}
.padding-t-1,
.heirs-padding-t-1 > *,
.padding-bt-1,
.heirs-padding-bt-1 > * {
  padding-top: 1rem !important;
}

.padding-2,
.heirs-padding-2 > * {
  padding: 2rem !important;
}
.padding-b-2,
.heirs-padding-b-2 > *,
.padding-bt-2,
.heirs-padding-bt-2 > * {
  padding-bottom: 2rem !important;
}
.padding-l-2,
.heirs-padding-l-2 > *,
.padding-lr-2,
.heirs-padding-lr-2 > * {
  padding-left: 2rem !important;
}
.padding-r-2,
.heirs-padding-r-2 > *,
.padding-lr-2,
.heirs-padding-lr-2 > * {
  padding-right: 2rem !important;
}
.padding-t-2,
.heirs-padding-t-2 > *,
.padding-bt-2,
.heirs-padding-bt-2 > * {
  padding-top: 2rem !important;
}

.padding-3,
.heirs-padding-3 > * {
  padding: 3rem !important;
}
.padding-b-3,
.heirs-padding-b-3 > *,
.padding-bt-3,
.heirs-padding-bt-3 > * {
  padding-bottom: 3rem !important;
}
.padding-l-3,
.heirs-padding-l-3 > *,
.padding-lr-3,
.heirs-padding-lr-3 > * {
  padding-left: 3rem !important;
}
.padding-r-3,
.heirs-padding-r-3 > *,
.padding-lr-3,
.heirs-padding-lr-3 > * {
  padding-right: 3rem !important;
}
.padding-t-3,
.heirs-padding-t-3 > *,
.padding-bt-3,
.heirs-padding-bt-3 > * {
  padding-top: 3rem !important;
}

.padding-4,
.heirs-padding-4 > * {
  padding: 4rem !important;
}
.padding-b-4,
.heirs-padding-b-4 > *,
.padding-bt-4,
.heirs-padding-bt-4 > * {
  padding-bottom: 4rem !important;
}
.padding-l-4,
.heirs-padding-l-4 > *,
.padding-lr-4,
.heirs-padding-lr-4 > * {
  padding-left: 4rem !important;
}
.padding-r-4,
.heirs-padding-r-4 > *,
.padding-lr-4,
.heirs-padding-lr-4 > * {
  padding-right: 4rem !important;
}
.padding-t-4,
.heirs-padding-t-4 > *,
.padding-bt-4,
.heirs-padding-bt-4 > * {
  padding-top: 4rem !important;
}

/* colors
   ========================================================================== */

.color-i,
.heirs-color-i > *,
.color-i:active,
.heirs-color-i > *:active,
.color-i:focus,
.heirs-color-i > *:focus,
.color-i:hover,
.heirs-color-i > *:hover,
:active .color-i,
:active .heirs-color-i > *,
:focus .color-i,
:focus .heirs-color-i > *,
:hover .color-i,
:hover .heirs-color-i > * {
  color: inherit;
}

/* text and links
   ================================== */

.black,
.heirs-black > *,
.black-active:active,
.heirs-black-active > *:active,
.black-after:after,
.heirs-black-after > *:after,
.black-before:before,
.heirs-black-before > *:before,
.heirs-black-between > *:not(:first-child):before,
.black-hover:hover,
.heirs-black-hover > *:hover {
  color: #000 !important;
}

.white,
.heirs-white > *,
.white-active:active,
.heirs-white-active > *:active,
.white-after:after,
.heirs-white-after > *:after,
.white-before:before,
.heirs-white-before > *:before,
.heirs-white-between > *:not(:first-child):before,
.white-hover:hover,
.heirs-white-hover > *:hover {
  color: #fff !important;
}

.gray,
.heirs-gray > *,
.gray-active:active,
.heirs-gray-active > *:active,
.gray-after:after,
.heirs-gray-after > *:after,
.gray-before:before,
.heirs-gray-before > *:before,
.heirs-gray-between > *:not(:first-child):before,
.gray-hover:hover,
.heirs-gray-hover > *:hover {
  color: #b3b3b3 !important;
}

.dark-gray,
.heirs-dark-gray > *,
.dark-gray-active:active,
.heirs-dark-gray-active > *:active,
.dark-gray-after:after,
.heirs-dark-gray-after > *:after,
.dark-gray-before:before,
.heirs-dark-gray-before > *:before,
.heirs-dark-gray-between > *:not(:first-child):before,
.dark-gray-hover:hover,
.heirs-dark-gray-hover > *:hover {
  color: #535353 !important;
}
.darker-gray,
.heirs-darker-gray > *,
.darker-gray-active:active,
.heirs-darker-gray-active > *:active,
.darker-gray-after:after,
.heirs-darker-gray-after > *:after,
.darker-gray-before:before,
.heirs-darker-gray-before > *:before,
.heirs-darker-gray-between > *:not(:first-child):before,
.darker-gray-hover:hover,
.heirs-darker-gray-hover > *:hover {
  color: #424242 !important;
}
.darkest-gray,
.heirs-darkest-gray > *,
.darkest-gray-active:active,
.heirs-darkest-gray-active > *:active,
.darkest-gray-after:after,
.heirs-darkest-gray-after > *:after,
.darkest-gray-before:before,
.heirs-darkest-gray-before > *:before,
.heirs-darkest-gray-between > *:not(:first-child):before,
.darkest-gray-hover:hover,
.heirs-darkest-gray-hover > *:hover {
  color: #393939 !important;
}

.light-gray,
.heirs-light-gray > *,
.light-gray-active:active,
.heirs-light-gray-active > *:active,
.light-gray-after:after,
.heirs-light-gray-after > *:after,
.light-gray-before:before,
.heirs-light-gray-before > *:before,
.heirs-light-gray-between > *:not(:first-child):before,
.light-gray-hover:hover .heirs-light-gray-hover > *:hover {
  color: #ccc !important;
}
.lighter-gray,
.heirs-lighter-gray > *,
.lighter-gray-active:active,
.heirs-lighter-gray-active > *:active,
.lighter-gray-after:after,
.heirs-lighter-gray-after > *:after,
.lighter-gray-before:before,
.heirs-lighter-gray-before > *:before,
.heirs-lighter-gray-between > *:not(:first-child):before,
.lighter-gray-hover:hover .heirs-lighter-gray-hover > *:hover {
  color: #e5e5e5 !important;
}
.lightest-gray,
.heirs-lightest-gray > *,
.lightest-gray-active:active,
.heirs-lightest-gray-active > *:active,
.lightest-gray-after:after,
.heirs-lightest-gray-after > *:after,
.lightest-gray-before:before,
.heirs-lightest-gray-before > *:before,
.heirs-lightest-gray-between > *:not(:first-child):before,
.lightest-gray-hover:hover,
.heirs-lightest-gray-hover > *:hover {
  color: #f6f6f6 !important;
}

.blue,
.heirs-blue > *,
.blue-active:active,
.heirs-blue-active > *:active,
.blue-after:after,
.heirs-blue-after > *:after,
.blue-before:before,
.heirs-blue-before > *:before,
.heirs-blue-between > *:not(:first-child):before,
.blue-hover:hover,
.heirs-blue-hover > *:hover {
  color: #33aadd !important;
}
.dark-blue,
.heirs-dark-blue > *,
.dark-blue-active:active,
.heirs-dark-blue-active > *:active,
.dark-blue-after:after,
.heirs-dark-blue-after > *:after,
.dark-blue-before:before,
.heirs-dark-blue-before > *:before,
.heirs-dark-blue-between > *:not(:first-child):before,
.dark-blue-hover:hover,
.heirs-dark-blue-hover > *:hover {
  color: #0088bb !important;
}
.bright-blue,
.heirs-bright-blue > *,
.bright-blue-active:active,
.heirs-bright-blue-active > *:active,
.bright-blue-after:after,
.heirs-bright-blue-after > *:after,
.bright-blue-before:before,
.heirs-bright-blue-before > *:before,
.heirs-bright-blue-between > *:not(:first-child):before,
.bright-blue-hover:hover,
.heirs-bright-blue-hover > *:hover {
  color: #33c0ee !important;
}

.orange,
.heirs-orange > *,
.orange-active:active,
.heirs-orange-active > *:active,
.orange-after:after,
.heirs-orange-after > *:after,
.orange-before:before,
.heirs-orange-before > *:before,
.heirs-orange-between > *:not(:first-child):before,
.orange-hover:hover,
.heirs-orange-hover > *:hover {
  color: #f77700 !important;
}
.dark-orange,
.heirs-dark-orange > *,
.dark-orange-active:active,
.heirs-dark-orange-active > *:active,
.dark-orange-after:after,
.heirs-dark-orange-after > *:after,
.dark-orange-before:before,
.heirs-dark-orange-before > *:before,
.heirs-dark-orange-between > *:not(:first-child):before,
.dark-orange-hover:hover,
.heirs-dark-orange-hover > *:hover {
  color: #ef7000 !important;
}
.bright-orange,
.heirs-bright-orange > *,
.bright-orange-active:active,
.heirs-bright-orange-active > *:active,
.bright-orange-after:after,
.heirs-bright-orange-after > *:after,
.bright-orange-before:before,
.heirs-bright-orange-before > *:before,
.heirs-bright-orange-between > *:not(:first-child):before,
.bright-orange-hover:hover,
.heirs-bright-orange-hover > *:hover {
  color: #ff8800 !important;
}

.red,
.heirs-red > *,
.red-active:active,
.heirs-red-active > *:active,
.red-after:after,
.heirs-red-after > *:after,
.red-before:before,
.heirs-red-before > *:before,
.heirs-red-between > *:not(:first-child):before,
.red-hover:hover,
.heirs-red-hover > *:hover {
  color: #dd1100 !important;
}
.dark-red,
.heirs-dark-red > *,
.dark-red-active:active,
.heirs-dark-red-active > *:active,
.dark-red-after:after,
.heirs-dark-red-after > *:after,
.dark-red-before:before,
.heirs-dark-red-before > *:before,
.heirs-dark-red-between > *:not(:first-child):before,
.dark-red-hover:hover,
.heirs-dark-red-hover > *:hover {
  color: #b00f00 !important;
}
.bright-red,
.heirs-bright-red > *,
.bright-red-active:active,
.heirs-bright-red-active > *:active,
.bright-red-after:after,
.heirs-bright-red-after > *:after,
.bright-red-before:before,
.heirs-bright-red-before > *:before,
.heirs-bright-red-between > *:not(:first-child):before,
.bright-red-hover:hover,
.heirs-bright-red-hover > *:hover {
  color: #fe0000 !important;
}

/* backgrounds
   ================================== */

.black-bg,
.heirs-black-bg > * {
  background-color: #000 !important;
}

.white-bg,
.heirs-white-bg > * {
  background-color: #fff !important;
}

.gray-bg,
.heirs-gray-bg > * {
  background-color: #b3b3b3 !important;
}

.dark-gray-bg,
.heirs-dark-gray-bg > * {
  background-color: #535353 !important;
}
.darker-gray-bg,
.heirs-darker-gray-bg > * {
  background-color: #424242 !important;
}
.darkest-gray-bg,
.heirs-darkest-gray-bg > * {
  background-color: #393939 !important;
}

.light-gray-bg,
.heirs-light-gray-bg > * {
  background-color: #ccc !important;
}
.lighter-gray-bg,
.heirs-lighter-gray-bg > * {
  background-color: #e5e5e5 !important;
}
.lightest-gray-bg,
.heirs-lightest-gray-bg > * {
  background-color: #f6f6f6 !important;
}

.blue-bg,
.heirs-blue-bg > * {
  background-color: #33aadd !important;
}
.dark-blue-bg,
.heirs-dark-blue-bg > * {
  background-color: #0088bb !important;
}
.bright-blue-bg,
.heirs-bright-blue-bg > * {
  background-color: #33c0ee !important;
}

.orange-bg,
.heirs-orange-bg > * {
  background-color: #f77700 !important;
}
.dark-orange-bg,
.heirs-dark-orange-bg > * {
  background-color: #ef7000 !important;
}
.bright-orange-bg,
.heirs-bright-orange-bg > * {
  background-color: #ff8800 !important;
}

.red-bg,
.heirs-red-bg > * {
  background-color: #dd1100 !important;
}
.dark-red-bg,
.heirs-dark-red-bg > * {
  background-color: #b00f00 !important;
}
.bright-red-bg,
.heirs-bright-red-bg > * {
  background-color: #fe0000 !important;
}

/* borders
   ================================== */

.black-border,
.heirs-black-border > * {
  border: 1px solid #000 !important;
}

.white-border,
.heirs-white-border > * {
  border: 1px solid #fff !important;
}

.gray-border,
.heirs-gray-border > * {
  border: 1px solid #b3b3b3 !important;
}

.dark-gray-border,
.heirs-dark-gray-border > * {
  border: 1px solid #535353 !important;
}
.darker-gray-border,
.heirs-darker-gray-border > * {
  border: 1px solid #424242 !important;
}
.darkest-gray-border,
.heirs-darkest-gray-border > * {
  border: 1px solid #393939 !important;
}

.light-gray-border,
.heirs-light-gray-border > * {
  border: 1px solid #ccc !important;
}
.lighter-gray-border,
.heirs-lighter-gray-border > * {
  border: 1px solid #e5e5e5 !important;
}
.lightest-gray-border,
.heirs-lightest-gray-border > * {
  border: 1px solid #f6f6f6 !important;
}

.blue-border,
.heirs-blue-border > * {
  border: 1px solid #33aadd !important;
}
.dark-blue-border,
.heirs-dark-blue-border > * {
  border: 1px solid #0088bb !important;
}
.bright-blue-border,
.heirs-bright-blue-border > * {
  border: 1px solid #33c0ee !important;
}

.orange-border,
.heirs-orange-border > * {
  border: 1px solid #f77700 !important;
}
.dark-orange-border,
.heirs-dark-orange-border > * {
  border: 1px solid #ef7000 !important;
}
.bright-orange-border,
.heirs-bright-orange-border > * {
  border: 1px solid #ff8800 !important;
}

.red-border,
.heirs-red-border > * {
  border: 1px solid #dd1100 !important;
}
.dark-red-border,
.heirs-dark-red-border > * {
  border: 1px solid #b00f00 !important;
}
.bright-red-border,
.heirs-bright-red-border > * {
  border: 1px solid #fe0000 !important;
}

/* border-bottoms
   ================================== */

.black-border-b,
.heirs-black-border-b > * {
  border-bottom: 1px solid #000 !important;
}

.white-border-b,
.heirs-white-border-b > * {
  border-bottom: 1px solid #fff !important;
}

.gray-border-b,
.heirs-gray-border-b > * {
  border-bottom: 1px solid #b3b3b3 !important;
}

.dark-gray-border-b,
.heirs-dark-gray-border-b > * {
  border-bottom: 1px solid #535353 !important;
}
.darker-gray-border-b,
.heirs-darker-gray-border-b > * {
  border-bottom: 1px solid #424242 !important;
}
.darkest-gray-border-b,
.heirs-darkest-gray-border-b > * {
  border-bottom: 1px solid #393939 !important;
}

.light-gray-border-b,
.heirs-light-gray-border-b > * {
  border-bottom: 1px solid #ccc !important;
}
.lighter-gray-border-b,
.heirs-lighter-gray-border-b > * {
  border-bottom: 1px solid #e5e5e5 !important;
}
.lightest-gray-border-b,
.heirs-lightest-gray-border-b > * {
  border-bottom: 1px solid #f6f6f6 !important;
}

.blue-border-b,
.heirs-blue-border-b > * {
  border-bottom: 1px solid #33aadd !important;
}
.dark-blue-border-b,
.heirs-dark-blue-border-b > * {
  border-bottom: 1px solid #0088bb !important;
}
.bright-blue-border-b,
.heirs-bright-blue-border-b > * {
  border-bottom: 1px solid #33c0ee !important;
}

.orange-border-b,
.heirs-orange-border-b > * {
  border-bottom: 1px solid #f77700 !important;
}
.dark-orange-border-b,
.heirs-dark-orange-border-b > * {
  border-bottom: 1px solid #ef7000 !important;
}
.bright-orange-border-b,
.heirs-bright-orange-border-b > * {
  border-bottom: 1px solid #ff8800 !important;
}

.red-border-b,
.heirs-red-border-b > * {
  border-bottom: 1px solid #dd1100 !important;
}
.dark-red-border-b,
.heirs-dark-red-border-b > * {
  border-bottom: 1px solid #b00f00 !important;
}
.bright-red-border-b,
.heirs-bright-red-border-b > * {
  border-bottom: 1px solid #fe0000 !important;
}

/* border-lefts
   ================================== */

.black-border-l,
.heirs-black-border-l > * {
  border-left: 1px solid #000 !important;
}

.white-border-l,
.heirs-white-border-l > * {
  border-left: 1px solid #fff !important;
}

.gray-border-l,
.heirs-gray-border-l > * {
  border-left: 1px solid #b3b3b3 !important;
}

.dark-gray-border-l,
.heirs-dark-gray-border-l > * {
  border-left: 1px solid #535353 !important;
}
.darker-gray-border-l,
.heirs-darker-gray-border-l > * {
  border-left: 1px solid #424242 !important;
}
.darkest-gray-border-l,
.heirs-darkest-gray-border-l > * {
  border-left: 1px solid #393939 !important;
}

.light-gray-border-l,
.heirs-light-gray-border-l > * {
  border-left: 1px solid #ccc !important;
}
.lighter-gray-border-l,
.heirs-lighter-gray-border-l > * {
  border-left: 1px solid #e5e5e5 !important;
}
.lightest-gray-border-l,
.heirs-lightest-gray-border-l > * {
  border-left: 1px solid #f6f6f6 !important;
}

.blue-border-l,
.heirs-blue-border-l > * {
  border-left: 1px solid #33aadd !important;
}
.dark-blue-border-l,
.heirs-dark-blue-border-l > * {
  border-left: 1px solid #0088bb !important;
}
.bright-blue-border-l,
.heirs-bright-blue-border-l > * {
  border-left: 1px solid #33c0ee !important;
}

.orange-border-l,
.heirs-orange-border-l > * {
  border-left: 1px solid #f77700 !important;
}
.dark-orange-border-l,
.heirs-dark-orange-border-l > * {
  border-left: 1px solid #ef7000 !important;
}
.bright-orange-border-l,
.heirs-bright-orange-border-l > * {
  border-left: 1px solid #ff8800 !important;
}

.red-border-l,
.heirs-red-border-l > * {
  border-left: 1px solid #dd1100 !important;
}
.dark-red-border-l,
.heirs-dark-red-border-l > * {
  border-left: 1px solid #b00f00 !important;
}
.bright-red-border-l,
.heirs-bright-red-border-l > * {
  border-left: 1px solid #fe0000 !important;
}

/* border-rights
   ================================== */

.black-border-r,
.heirs-black-border-r > * {
  border-right: 1px solid #000 !important;
}

.white-border-r,
.heirs-white-border-r > * {
  border-right: 1px solid #fff !important;
}

.gray-border-r,
.heirs-gray-border-r > * {
  border-right: 1px solid #b3b3b3 !important;
}

.dark-gray-border-r,
.heirs-dark-gray-border-r > * {
  border-right: 1px solid #535353 !important;
}
.darker-gray-border-r,
.heirs-darker-gray-border-r > * {
  border-right: 1px solid #424242 !important;
}
.darkest-gray-border-r,
.heirs-darkest-gray-border-r > * {
  border-right: 1px solid #393939 !important;
}

.light-gray-border-r,
.heirs-light-gray-border-r > * {
  border-right: 1px solid #ccc !important;
}
.lighter-gray-border-r,
.heirs-lighter-gray-border-r > * {
  border-right: 1px solid #e5e5e5 !important;
}
.lightest-gray-border-r,
.heirs-lightest-gray-border-r > * {
  border-right: 1px solid #f6f6f6 !important;
}

.blue-border-r,
.heirs-blue-border-r > * {
  border-right: 1px solid #33aadd !important;
}
.dark-blue-border-r,
.heirs-dark-blue-border-r > * {
  border-right: 1px solid #0088bb !important;
}
.bright-blue-border-r,
.heirs-bright-blue-border-r > * {
  border-right: 1px solid #33c0ee !important;
}

.orange-border-r,
.heirs-orange-border-r > * {
  border-right: 1px solid #f77700 !important;
}
.dark-orange-border-r,
.heirs-dark-orange-border-r > * {
  border-right: 1px solid #ef7000 !important;
}
.bright-orange-border-r,
.heirs-bright-orange-border-r > * {
  border-right: 1px solid #ff8800 !important;
}

.red-border-r,
.heirs-red-border-r > * {
  border-right: 1px solid #dd1100 !important;
}
.dark-red-border-r,
.heirs-dark-red-border-r > * {
  border-right: 1px solid #b00f00 !important;
}
.bright-red-border-r,
.heirs-bright-red-border-r > * {
  border-right: 1px solid #fe0000 !important;
}

/* border-tops
   ================================== */

.black-border-t,
.heirs-black-border-t > * {
  border-top: 1px solid #000 !important;
}

.white-border-t,
.heirs-white-border-t > * {
  border-top: 1px solid #fff !important;
}

.gray-border-t,
.heirs-gray-border-t > * {
  border-top: 1px solid #b3b3b3 !important;
}

.dark-gray-border-t,
.heirs-dark-gray-border-t > * {
  border-top: 1px solid #535353 !important;
}
.darker-gray-border-t,
.heirs-darker-gray-border-t > * {
  border-top: 1px solid #424242 !important;
}
.darkest-gray-border-t,
.heirs-darkest-gray-border-t > * {
  border-top: 1px solid #393939 !important;
}

.light-gray-border-t,
.heirs-light-gray-border-t > * {
  border-top: 1px solid #ccc !important;
}
.lighter-gray-border-t,
.heirs-lighter-gray-border-t > * {
  border-top: 1px solid #e5e5e5 !important;
}
.lightest-gray-border-t,
.heirs-lightest-gray-border-t > * {
  border-top: 1px solid #f6f6f6 !important;
}

.blue-border-t,
.heirs-blue-border-t > * {
  border-top: 1px solid #33aadd !important;
}
.dark-blue-border-t,
.heirs-dark-blue-border-t > * {
  border-top: 1px solid #0088bb !important;
}
.bright-blue-border-t,
.heirs-bright-blue-border-t > * {
  border-top: 1px solid #33c0ee !important;
}

.orange-border-t,
.heirs-orange-border-t > * {
  border-top: 1px solid #f77700 !important;
}
.dark-orange-border-t,
.heirs-dark-orange-border-t > * {
  border-top: 1px solid #ef7000 !important;
}
.bright-orange-border-t,
.heirs-bright-orange-border-t > * {
  border-top: 1px solid #ff8800 !important;
}

.red-border-t,
.heirs-red-border-t > * {
  border-top: 1px solid #dd1100 !important;
}
.dark-red-border-t,
.heirs-dark-red-border-t > * {
  border-top: 1px solid #b00f00 !important;
}
.bright-red-border-t,
.heirs-bright-red-border-t > * {
  border-top: 1px solid #fe0000 !important;
}

/* ==========================================================================
   responsive breakpoint styles
   ========================================================================== */

/* styles for large screens (1200px)
   ========================================================================== */

@media all and (max-width: 1200px) {
  html {
    font-size: 15px;
  }

  .cf__1200:after,
  .heirs-cf__1200 > *:after,
  .cf__1200:before,
  .heirs-cf__1200 > *:before,
  .break-after__1200:after,
  .heirs-break-after__1200 > *:after,
  .break-before__1200:before,
  .heirs-break-before__1200 > *:before {
    clear: both;
    content: "";
    display: table;
  }

  .fill__1200,
  .heirs-fill__1200 > * {
    height: auto;
    max-width: 100%;
  }

  .fill-and-center__1200,
  .heirs-fill-and-center__1200 > * {
    display: block;
    float: none;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .grid__1200,
  .heirs-grid__1200 > *,
  .row__1200,
  .heirs-row__1200 > * {
    display: block;
  }

  .inline-grid__1200,
  .heirs-inline-grid__1200 > * {
    display: inline-block;
  }

  .grid__1200:after,
  .heirs-grid__1200 > *:after,
  .grid__1200:before,
  .heirs-grid__1200 > *:before,
  .inline-grid__1200:after,
  .heirs-inline-grid__1200 > *:after,
  .inline-grid__1200:before,
  .heirs-inline-grid__1200 > *:before,
  .collapse__1200 > *:after,
  .heirs-collapse__1200 > * > *:after,
  .collapse__1200 > *:before,
  .heirs-collapse__1200 > * > *:before {
    clear: both;
    content: "";
    display: table;
  }

  .grid__1200 > *,
  .heirs-grid__1200 > * > *,
  .row__1200 > *,
  .heirs-row__1200 > * > *,
  .col__1200,
  .heirs-col__1200 > *,
  .inline-grid__1200 > *,
  .heirs-inline-grid__1200 > * > * {
    float: left;
    display: block;
  }

  .cols-1__1200 > *:nth-child(1n + 2),
  .heirs-cols-1__1200 > * > *:nth-child(1n + 2),
  .cols-2__1200 > *:nth-child(1n + 2),
  .heirs-cols-2__1200 > * > *:nth-child(1n + 2),
  .cols-3__1200 > *:nth-child(1n + 2),
  .heirs-cols-3__1200 > * > *:nth-child(1n + 2),
  .cols-4__1200 > *:nth-child(1n + 2),
  .heirs-cols-4__1200 > * > *:nth-child(1n + 2),
  .cols-5__1200 > *:nth-child(1n + 2),
  .heirs-cols-5__1200 > * > *:nth-child(1n + 2),
  .cols-6__1200 > *:nth-child(1n + 2),
  .heirs-cols-6__1200 > * > *:nth-child(1n + 2),
  .cols-7__1200 > *:nth-child(1n + 2),
  .heirs-cols-7__1200 > * > *:nth-child(1n + 2),
  .cols-8__1200 > *:nth-child(1n + 2),
  .heirs-cols-8__1200 > * > *:nth-child(1n + 2),
  .cols-9__1200 > *:nth-child(1n + 2),
  .heirs-cols-9__1200 > * > *:nth-child(1n + 2),
  .cols-10__1200 > *:nth-child(1n + 2),
  .heirs-cols-10__1200 > * > *:nth-child(1n + 2),
  .cols-11__1200 > *:nth-child(1n + 2),
  .heirs-cols-11__1200 > * > *:nth-child(1n + 2),
  .cols-12__1200 > *:nth-child(1n + 2),
  .heirs-cols-12__1200 > * > *:nth-child(1n + 2) {
    clear: none !important;
  }

  .cols-1__1200 > *:nth-child(1n + 2),
  .heirs-cols-1__1200 > * > *:nth-child(1n + 2),
  .cols-2__1200 > *:nth-child(2n + 3),
  .heirs-cols-2__1200 > * > *:nth-child(2n + 3),
  .cols-3__1200 > *:nth-child(3n + 4),
  .heirs-cols-3__1200 > * > *:nth-child(3n + 4),
  .cols-4__1200 > *:nth-child(4n + 5),
  .heirs-cols-4__1200 > * > *:nth-child(4n + 5),
  .cols-5__1200 > *:nth-child(5n + 6),
  .heirs-cols-5__1200 > * > *:nth-child(5n + 6),
  .cols-6__1200 > *:nth-child(6n + 7),
  .heirs-cols-6__1200 > * > *:nth-child(6n + 7),
  .cols-7__1200 > *:nth-child(7n + 8),
  .heirs-cols-7__1200 > * > *:nth-child(7n + 8),
  .cols-8__1200 > *:nth-child(8n + 9),
  .heirs-cols-8__1200 > * > *:nth-child(8n + 9),
  .cols-9__1200 > *:nth-child(9n + 10),
  .heirs-cols-9__1200 > * > *:nth-child(9n + 10),
  .cols-10__1200 > *:nth-child(10n + 11),
  .heirs-cols-10__1200 > * > *:nth-child(10n + 11),
  .cols-11__1200 > *:nth-child(11n + 12),
  .heirs-cols-11__1200 > * > *:nth-child(11n + 12),
  .cols-12__1200 > *:nth-child(12n + 13),
  .heirs-cols-12__1200 > * > *:nth-child(12n + 13) {
    clear: both !important;
  }

  .mini__1200,
  .heirs-mini__1200 > *,
  .mini-b__1200,
  .heirs-mini-b__1200 > *,
  .mini-m__1200,
  .heirs-mini-m__1200 > *,
  .mini-t__1200,
  .heirs-mini-t__1200 > * {
    display: block;
    page-break-inside: avoid;
  }

  .mini__1200 > *,
  .heirs-mini__1200 > * > *,
  .mini-b__1200 > *,
  .heirs-mini-b__1200 > * > *,
  .mini-m__1200 > *,
  .heirs-mini-m__1200 > * > *,
  .mini-t__1200 > *,
  .heirs-mini-t__1200 > * > * {
    display: table-cell;
    vertical-align: top;
  }

  .mini-b__1200 > *,
  .heirs-mini-b__1200 > * > * {
    vertical-align: bottom;
  }
  .mini-m__1200 > *,
  .heirs-mini-m__1200 > * > * {
    vertical-align: middle;
  }
  .mini-t__1200 > *,
  .heirs-mini-t__1200 > * > * {
    vertical-align: top;
  }

  .mini-collapse__1200 > *,
  .heirs-mini-collapse__1200 > * > * {
    display: block;
  }

  .table__1200,
  .heirs-table__1200 > *,
  .table__1200 > *,
  .heirs-table__1200 > * > *,
  .table__1200 > * > *,
  .heirs-table__1200 > * > * > * {
    border-collapse: collapse; /* remove excess spacing around table elements */
    border-spacing: 0; /* ^ */
  }

  .table__1200,
  .heirs-table__1200 > * {
    display: table;
  }

  .table__1200 > *,
  .heirs-table__1200 > * > *,
  .table-row__1200 > *,
  .heirs-table-row__1200 > * > * {
    display: table-row;
  }

  .table__1200 > * > *,
  .heirs-table__1200 > * > * > *,
  .table-col__1200 > * > *,
  .heirs-table-col__1200 > * > * > * {
    display: table-cell;
    vertical-align: top;
  }

  .table-collapse__1200,
  .heirs-table-collapse__1200 > *,
  .table-collapse__1200 > *,
  .heirs-table-collapse__1200 > * > *,
  .table-collapse__1200 > * > *,
  .heirs-table-collapse__1200 > * > * > * {
    display: block;
  }

  .flex__1200,
  .heirs-flex__1200 > * {
    display: flex;
  }

  .inline-flex__1200,
  .heirs-inline-flex__1200 > * {
    display: inline-flex;
  }

  .flex-direction-c__1200,
  .heirs-flex-direction-c__1200 > * {
    flex-direction: column;
  }
  .flex-direction-cr__1200,
  .heirs-flex-direction-cr__1200 > * {
    flex-direction: column-reverse;
  }
  .flex-direction-r__1200,
  .heirs-flex-direction-r__1200 > * {
    flex-direction: row;
  }
  .flex-direction-rr__1200,
  .heirs-flex-direction-rr__1200 > * {
    flex-direction: row-reverse;
  }

  .flex-wrap-n__1200,
  .heirs-flex-wrap-n__1200 > * {
    flex-wrap: nowrap;
  }
  .flex-wrap-w__1200,
  .heirs-flex-wrap-w__1200 > * {
    flex-wrap: wrap;
  }
  .flex-wrap-wr__1200,
  .heirs-flex-wrap-wr__1200 > * {
    flex-wrap: wrap-reverse;
  }

  .justify-content-c__1200,
  .heirs-justify-content-c__1200 > * {
    justify-content: center;
  }
  .justify-content-fe__1200,
  .heirs-justify-content-fe__1200 > * {
    justify-content: flex-end;
  }
  .justify-content-fs__1200,
  .heirs-justify-content-fs__1200 > * {
    justify-content: flex-start;
  }
  .justify-content-sa__1200,
  .heirs-justify-content-sa__1200 > * {
    justify-content: space-around;
  }
  .justify-content-sb__1200,
  .heirs-justify-content-sb__1200 > * {
    justify-content: space-between;
  }
  .justify-content-se__1200,
  .heirs-justify-content-se__1200 > * {
    justify-content: space-evenly;
  }

  .align-items-b__1200,
  .heirs-align-items-b__1200 > * {
    align-items: baseline;
  }
  .align-items-c__1200,
  .heirs-align-items-c__1200 > * {
    align-items: center;
  }
  .align-items-fe__1200,
  .heirs-align-items-fe__1200 > * {
    align-items: flex-end;
  }
  .align-items-fs__1200,
  .heirs-align-items-fs__1200 > * {
    align-items: flex-start;
  }
  .align-items-s__1200,
  .heirs-align-items-s__1200 > * {
    align-items: stretch;
  }

  .align-content-c__1200,
  .heirs-align-content-c__1200 > * {
    align-content: center;
  }
  .align-content-fe__1200,
  .heirs-align-content-fe__1200 > * {
    align-content: flex-end;
  }
  .align-content-fs__1200,
  .heirs-align-content-fs__1200 > * {
    align-content: flex-start;
  }
  .align-content-s__1200,
  .heirs-align-content-se__1200 > * {
    align-content: stretch;
  }
  .align-content-sa__1200,
  .heirs-align-content-sa__1200 > * {
    align-content: space-around;
  }
  .align-content-sb__1200,
  .heirs-align-content-sb__1200 > * {
    align-content: space-between;
  }

  .align-self-a__1200,
  .heirs-align-self-a__1200 > * {
    align-self: auto;
  }
  .align-self-b__1200,
  .heirs-align-self-b__1200 > * {
    align-self: baseline;
  }
  .align-self-c__1200,
  .heirs-align-self-c__1200 > * {
    align-self: center;
  }
  .align-self-fe__1200,
  .heirs-align-self-fe__1200 > * {
    align-self: flex-end;
  }
  .align-self-fs__1200,
  .heirs-align-self-fs__1200 > * {
    align-self: flex-start;
  }
  .align-self-s__1200,
  .heirs-align-self-se__1200 > * {
    align-self: stretch;
  }

  .flex-basis-a__1200,
  .heirs-flex-basis-a__1200 > * {
    flex-basis: auto;
  }

  .flex-grow-0__1200,
  .heirs-flex-grow-0__1200 > * {
    flex-grow: 0;
  }
  .flex-grow-1__1200,
  .heirs-flex-grow-1__1200 > * {
    flex-grow: 1;
  }
  .flex-grow-2__1200,
  .heirs-flex-grow-2__1200 > * {
    flex-grow: 2;
  }
  .flex-grow-3__1200,
  .heirs-flex-grow-3__1200 > * {
    flex-grow: 3;
  }
  .flex-grow-4__1200,
  .heirs-flex-grow-4__1200 > * {
    flex-grow: 4;
  }
  .flex-grow-5__1200,
  .heirs-flex-grow-5__1200 > * {
    flex-grow: 5;
  }
  .flex-grow-6__1200,
  .heirs-flex-grow-6__1200 > * {
    flex-grow: 6;
  }
  .flex-grow-7__1200,
  .heirs-flex-grow-7__1200 > * {
    flex-grow: 7;
  }
  .flex-grow-8__1200,
  .heirs-flex-grow-8__1200 > * {
    flex-grow: 8;
  }
  .flex-grow-9__1200,
  .heirs-flex-grow-9__1200 > * {
    flex-grow: 9;
  }
  .flex-grow-10__1200,
  .heirs-flex-grow-10__1200 > * {
    flex-grow: 10;
  }
  .flex-grow-11__1200,
  .heirs-flex-grow-11__1200 > * {
    flex-grow: 11;
  }
  .flex-grow-12__1200,
  .heirs-flex-grow-12__1200 > * {
    flex-grow: 12;
  }

  .flex-shrink-1__1200,
  .heirs-flex-shrink-1__1200 > * {
    flex-shrink: 1;
  }
  .flex-shrink-2__1200,
  .heirs-flex-shrink-2__1200 > * {
    flex-shrink: 2;
  }
  .flex-shrink-3__1200,
  .heirs-flex-shrink-3__1200 > * {
    flex-shrink: 3;
  }
  .flex-shrink-4__1200,
  .heirs-flex-shrink-4__1200 > * {
    flex-shrink: 4;
  }
  .flex-shrink-5__1200,
  .heirs-flex-shrink-5__1200 > * {
    flex-shrink: 5;
  }
  .flex-shrink-6__1200,
  .heirs-flex-shrink-6__1200 > * {
    flex-shrink: 6;
  }
  .flex-shrink-7__1200,
  .heirs-flex-shrink-7__1200 > * {
    flex-shrink: 7;
  }
  .flex-shrink-8__1200,
  .heirs-flex-shrink-8__1200 > * {
    flex-shrink: 8;
  }
  .flex-shrink-9__1200,
  .heirs-flex-shrink-9__1200 > * {
    flex-shrink: 9;
  }
  .flex-shrink-10__1200,
  .heirs-flex-shrink-10__1200 > * {
    flex-shrink: 10;
  }
  .flex-shrink-11__1200,
  .heirs-flex-shrink-11__1200 > * {
    flex-shrink: 11;
  }
  .flex-shrink-12__1200,
  .heirs-flex-shrink-12__1200 > * {
    flex-shrink: 12;
  }

  .order-0__1200,
  .heirs-order-0__1200 > * {
    order: 0;
  }
  .order-1__1200,
  .heirs-order-1__1200 > * {
    order: 1;
  }
  .order-2__1200,
  .heirs-order-2__1200 > * {
    order: 2;
  }
  .order-3__1200,
  .heirs-order-3__1200 > * {
    order: 3;
  }
  .order-4__1200,
  .heirs-order-4__1200 > * {
    order: 4;
  }
  .order-5__1200,
  .heirs-order-5__1200 > * {
    order: 5;
  }
  .order-6__1200,
  .heirs-order-6__1200 > * {
    order: 6;
  }
  .order-7__1200,
  .heirs-order-7__1200 > * {
    order: 7;
  }
  .order-8__1200,
  .heirs-order-8__1200 > * {
    order: 8;
  }
  .order-9__1200,
  .heirs-order-9__1200 > * {
    order: 9;
  }
  .order-10__1200,
  .heirs-order-10__1200 > * {
    order: 10;
  }
  .order-11__1200,
  .heirs-order-11__1200 > * {
    order: 11;
  }
  .order-12__1200,
  .heirs-order-12__1200 > * {
    order: 12;
  }

  .columns-a__1200,
  .heirs-columns-a__1200 > * {
    columns: auto;
  }
  .columns-i__1200,
  .heirs-columns-i__1200 > * {
    columns: inherit;
  }

  .columns-0__1200,
  .heirs-columns-0__1200 > * {
    columns: 0;
  }
  .columns-1__1200,
  .heirs-columns-1__1200 > * {
    columns: 1;
  }
  .columns-2__1200,
  .heirs-columns-2__1200 > * {
    columns: 2;
  }
  .columns-3__1200,
  .heirs-columns-3__1200 > * {
    columns: 3;
  }
  .columns-4__1200,
  .heirs-columns-4__1200 > * {
    columns: 4;
  }
  .columns-5__1200,
  .heirs-columns-5__1200 > * {
    columns: 5;
  }
  .columns-6__1200,
  .heirs-columns-6__1200 > * {
    columns: 6;
  }
  .columns-7__1200,
  .heirs-columns-7__1200 > * {
    columns: 7;
  }
  .columns-8__1200,
  .heirs-columns-8__1200 > * {
    columns: 8;
  }
  .columns-9__1200,
  .heirs-columns-9__1200 > * {
    columns: 9;
  }
  .columns-10__1200,
  .heirs-columns-10__1200 > * {
    columns: 10;
  }
  .columns-11__1200,
  .heirs-columns-11__1200 > * {
    columns: 11;
  }
  .columns-12__1200,
  .heirs-columns-12__1200 > * {
    columns: 12;
  }

  .column-count-a__1200,
  .heirs-column-count-a__1200 > * {
    column-count: auto;
  }
  .column-count-i__1200,
  .heirs-column-count-i__1200 > * {
    column-count: inherit;
  }

  .column-count-0__1200,
  .heirs-column-count-0__1200 > * {
    column-count: 0;
  }
  .column-count-1__1200,
  .heirs-column-count-1__1200 > * {
    column-count: 1;
  }
  .column-count-2__1200,
  .heirs-column-count-2__1200 > * {
    column-count: 2;
  }
  .column-count-3__1200,
  .heirs-column-count-3__1200 > * {
    column-count: 3;
  }
  .column-count-4__1200,
  .heirs-column-count-4__1200 > * {
    column-count: 4;
  }
  .column-count-5__1200,
  .heirs-column-count-5__1200 > * {
    column-count: 5;
  }
  .column-count-6__1200,
  .heirs-column-count-6__1200 > * {
    column-count: 6;
  }
  .column-count-7__1200,
  .heirs-column-count-7__1200 > * {
    column-count: 7;
  }
  .column-count-8__1200,
  .heirs-column-count-8__1200 > * {
    column-count: 8;
  }
  .column-count-9__1200,
  .heirs-column-count-9__1200 > * {
    column-count: 9;
  }
  .column-count-10__1200,
  .heirs-column-count-10__1200 > * {
    column-count: 10;
  }
  .column-count-11__1200,
  .heirs-column-count-11__1200 > * {
    column-count: 11;
  }
  .column-count-12__1200,
  .heirs-column-count-12__1200 > * {
    column-count: 12;
  }

  .column-width-a__1200,
  .heirs-column-width-a__1200 > * {
    column-width: auto;
  }
  .column-width-full__1200,
  .heirs-column-width-full__1200 > * {
    column-width: 100%;
  }
  .column-width-i__1200,
  .heirs-column-width-i__1200 > * {
    column-width: inherit;
  }
  .column-width-0__1200,
  .heirs-column-width-0__1200 > * {
    column-width: 0;
  }

  .column-width-1-2__1200,
  .heirs-column-width-1-2__1200 > * {
    column-width: 50%;
  }
  .column-width-1-3__1200,
  .heirs-column-width-1-3__1200 > * {
    column-width: 33.33333333333333%;
  }
  .column-width-1-4__1200,
  .heirs-column-width-1-4__1200 > * {
    column-width: 25%;
  }
  .column-width-1-5__1200,
  .heirs-column-width-1-5__1200 > * {
    column-width: 20%;
  }
  .column-width-1-6__1200,
  .heirs-column-width-1-6__1200 > * {
    column-width: 16.66666666666667%;
  }
  .column-width-1-7__1200,
  .heirs-column-width-1-7__1200 > * {
    column-width: 14.28571428571429%;
  }
  .column-width-1-8__1200,
  .heirs-column-width-1-8__1200 > * {
    column-width: 12.5%;
  }
  .column-width-1-9__1200,
  .heirs-column-width-1-9__1200 > * {
    column-width: 11.11111111111111%;
  }
  .column-width-1-10__1200,
  .heirs-column-width-1-10__1200 > * {
    column-width: 10%;
  }
  .column-width-1-11__1200,
  .heirs-column-width-1-11__1200 > * {
    column-width: 9.090909090909091%;
  }
  .column-width-1-12__1200,
  .heirs-column-width-1-12__1200 > * {
    column-width: 8.333333333333333%;
  }
  .column-width-1-20__1200,
  .heirs-column-width-1-20__1200 > * {
    column-width: 5%;
  }
  .column-width-2-3__1200,
  .heirs-column-width-2-3__1200 > * {
    column-width: 66.66666666666667%;
  }
  .column-width-2-5__1200,
  .heirs-column-width-2-5__1200 > * {
    column-width: 40%;
  }
  .column-width-2-7__1200,
  .heirs-column-width-2-7__1200 > * {
    column-width: 28.57142857142858%;
  }
  .column-width-2-9__1200,
  .heirs-column-width-2-9__1200 > * {
    column-width: 22.22222222222222%;
  }
  .column-width-2-11__1200,
  .heirs-column-width-2-11__1200 > * {
    column-width: 18.18181818181818%;
  }
  .column-width-3-4__1200,
  .heirs-column-width-3-4__1200 > * {
    column-width: 75%;
  }
  .column-width-3-5__1200,
  .heirs-column-width-3-5__1200 > * {
    column-width: 60%;
  }
  .column-width-3-7__1200,
  .heirs-column-width-3-7__1200 > * {
    column-width: 42.85714285714287%;
  }
  .column-width-3-8__1200,
  .heirs-column-width-3-8__1200 > * {
    column-width: 37.5%;
  }
  .column-width-3-10__1200,
  .heirs-column-width-3-10__1200 > * {
    column-width: 30%;
  }
  .column-width-3-11__1200,
  .heirs-column-width-3-11__1200 > * {
    column-width: 27.27272727272727%;
  }
  .column-width-4-5__1200,
  .heirs-column-width-4-5__1200 > * {
    column-width: 80%;
  }
  .column-width-4-7__1200,
  .heirs-column-width-4-7__1200 > * {
    column-width: 57.14285714285716%;
  }
  .column-width-4-9__1200,
  .heirs-column-width-4-9__1200 > * {
    column-width: 44.44444444444444%;
  }
  .column-width-4-11__1200,
  .heirs-column-width-4-11__1200 > * {
    column-width: 36.36363636363636%;
  }
  .column-width-5-6__1200,
  .heirs-column-width-5-6__1200 > * {
    column-width: 83.33333333333333%;
  }
  .column-width-5-7__1200,
  .heirs-column-width-5-7__1200 > * {
    column-width: 71.42857142857145%;
  }
  .column-width-5-8__1200,
  .heirs-column-width-5-8__1200 > * {
    column-width: 62.5%;
  }
  .column-width-5-9__1200,
  .heirs-column-width-5-9__1200 > * {
    column-width: 55.55555555555555%;
  }
  .column-width-5-11__1200,
  .heirs-column-width-5-11__1200 > * {
    column-width: 45.45454545454546%;
  }
  .column-width-5-12__1200,
  .heirs-column-width-5-12__1200 > * {
    column-width: 41.66666666666667%;
  }
  .column-width-6-7__1200,
  .heirs-column-width-6-7__1200 > * {
    column-width: 85.71428571428574%;
  }
  .column-width-6-11__1200,
  .heirs-column-width-6-11__1200 > * {
    column-width: 54.54545454545455%;
  }
  .column-width-7-8__1200,
  .heirs-column-width-7-8__1200 > * {
    column-width: 87.5%;
  }
  .column-width-7-9__1200,
  .heirs-column-width-7-9__1200 > * {
    column-width: 77.77777777777777%;
  }
  .column-width-7-10__1200,
  .heirs-column-width-7-10__1200 > * {
    column-width: 70%;
  }
  .column-width-7-11__1200,
  .heirs-column-width-7-11__1200 > * {
    column-width: 63.63636363636364%;
  }
  .column-width-7-12__1200,
  .heirs-column-width-7-12__1200 > * {
    column-width: 58.33333333333333%;
  }
  .column-width-8-9__1200,
  .heirs-column-width-8-9__1200 > * {
    column-width: 88.88888888888888%;
  }
  .column-width-8-11__1200,
  .heirs-column-width-8-11__1200 > * {
    column-width: 72.72727272727273%;
  }
  .column-width-9-10__1200,
  .heirs-column-width-9-10__1200 > * {
    column-width: 90%;
  }
  .column-width-9-11__1200,
  .heirs-column-width-9-11__1200 > * {
    column-width: 81.81818181818182%;
  }
  .column-width-10-11__1200,
  .heirs-column-width-10-11__1200 > * {
    column-width: 90.90909090909091%;
  }
  .column-width-11-12__1200,
  .heirs-column-width-11-12__1200 > * {
    column-width: 91.66666666666666%;
  }

  .arrow-after__1200:after,
  .heirs-arrow-after__1200 > *:after,
  .heirs-arrow-between__1200 > *:not(:first-child):before,
  .arrow-l-after__1200:after,
  .heirs-arrow-l-after__1200 > *:after,
  .heirs-arrow-l-between__1200 > *:not(:first-child):before,
  .bar-after__1200:after,
  .heirs-bar-after__1200 > *:after,
  .heirs-bar-between__1200 > *:not(:first-child):before,
  .bullet-after__1200:after,
  .heirs-bullet-after__1200 > *:after,
  .heirs-bullet-between__1200 > *:not(:first-child):before,
  .chevron-after__1200:after,
  .heirs-chevron-after__1200 > *:after,
  .heirs-chevron-between__1200 > *:not(:first-child):before,
  .chevron-l-after__1200:after,
  .heirs-chevron-l-after__1200 > *:after,
  .heirs-chevron-l-between__1200 > *:not(:first-child):before,
  .square-after__1200:after,
  .heirs-square-after__1200 > *:after,
  .heirs-square-between__1200 > *:not(:first-child):before {
    display: inline;
    margin-left: 0.25rem;
  }

  .arrow-before__1200:before,
  .heirs-arrow-before__1200 > *:before,
  .heirs-arrow-between__1200 > *:not(:first-child):before,
  .arrow-l-before__1200:before,
  .heirs-arrow-l-before__1200 > *:before,
  .heirs-arrow-l-between__1200 > *:not(:first-child):before,
  .bar-before__1200:before,
  .heirs-bar-before__1200 > *:before,
  .heirs-bar-between__1200 > *:not(:first-child):before,
  .bullet-before__1200:before,
  .heirs-bullet-before__1200 > *:before,
  .heirs-bullet-between__1200 > *:not(:first-child):before,
  .chevron-before__1200:before,
  .heirs-chevron-before__1200 > *:before,
  .heirs-chevron-between__1200 > *:not(:first-child):before,
  .chevron-l-before__1200:before,
  .heirs-chevron-l-before__1200 > *:before,
  .heirs-chevron-l-between__1200 > *:not(:first-child):before,
  .square-before__1200:before,
  .heirs-square-before__1200 > *:before,
  .heirs-square-between__1200 > *:not(:first-child):before {
    display: inline;
    margin-right: 0.25rem;
  }

  .arrow-after__1200:after,
  .heirs-arrow-after__1200 > *:after,
  .arrow-before__1200:before,
  .heirs-arrow-before__1200 > *:before,
  .heirs-arrow-between__1200 > *:not(:first-child):before {
    content: "›";
  }

  .arrow-l-after__1200:after,
  .heirs-arrow-l-after__1200 > *:after,
  .arrow-l-before__1200:before,
  .heirs-arrow-l-before__1200 > *:before,
  .heirs-arrow-l-between__1200 > *:not(:first-child):before {
    content: "‹";
  }

  .bar-after__1200:after,
  .heirs-bar-after__1200 > *:after,
  .bar-before__1200:before,
  .heirs-bar-before__1200 > *:before,
  .heirs-bar-between__1200 > *:not(:first-child):before {
    content: "|";
  }

  .bullet-after__1200:after,
  .heirs-bullet-after__1200 > *:after,
  .bullet-before__1200:before,
  .heirs-bullet-before__1200 > *:before,
  .heirs-bullet-between__1200 > *:not(:first-child):before {
    content: "•";
    font-family: Arial;
  }

  .chevron-after__1200:after,
  .heirs-chevron-after__1200 > *:after,
  .chevron-before__1200:before,
  .heirs-chevron-before__1200 > *:before,
  .heirs-chevron-between__1200 > *:not(:first-child):before {
    content: "»";
  }

  .chevron-l-after__1200:after,
  .heirs-chevron-l-after__1200 > *:after,
  .chevron-l-before__1200:before,
  .heirs-chevron-l-before__1200 > *:before,
  .heirs-chevron-l-between__1200 > *:not(:first-child):before {
    content: "«";
  }

  .square-after__1200:after,
  .heirs-square-after__1200 > *:after,
  .square-before__1200:before,
  .heirs-square-before__1200 > *:before,
  .heirs-square-between__1200 > *:not(:first-child):before {
    content: "■";
    font-family: Arial;
  }

  .nothing-after__1200:after,
  .heirs-nothing-after__1200 > *:after,
  .nothing-before__1200:before,
  .heirs-nothing-before__1200 > *:before,
  .heirs-nothing-between__1200 > *:not(:first-child):before {
    content: "";
    margin-left: 0;
    margin-right: 0;
  }

  .font-size-i__1200,
  .heirs-font-size-i__1200 > * {
    font-size: inherit !important;
  }

  .font-size-0__1200,
  .heirs-font-size-0__1200 > * {
    font-size: 0 !important;
  }
  .font-size-1-8__1200,
  .heirs-font-size-1-8__1200 > * {
    font-size: 0.125rem !important;
  }
  .font-size-1-4__1200,
  .heirs-font-size-1-4__1200 > * {
    font-size: 0.25rem !important;
  }
  .font-size-3-8__1200,
  .heirs-font-size-3-8__1200 > * {
    font-size: 0.375rem !important;
  }
  .font-size-1-2__1200,
  .heirs-font-size-1-2__1200 > * {
    font-size: 0.5rem !important;
  }
  .font-size-5-8__1200,
  .heirs-font-size-5-8__1200 > * {
    font-size: 0.625rem !important;
  }
  .font-size-3-4__1200,
  .heirs-font-size-3-4__1200 > * {
    font-size: 0.75rem !important;
  }
  .font-size-7-8__1200,
  .heirs-font-size-7-8__1200 > * {
    font-size: 0.875rem !important;
  }

  .font-size-1__1200,
  .heirs-font-size-1__1200 > * {
    font-size: 1rem !important;
  }
  .font-size-1-1-8__1200,
  .heirs-font-size-1-1-8__1200 > * {
    font-size: 1.125rem !important;
  }
  .font-size-1-1-4__1200,
  .heirs-font-size-1-1-4__1200 > * {
    font-size: 1.25rem !important;
  }
  .font-size-1-3-8__1200,
  .heirs-font-size-1-3-8__1200 > * {
    font-size: 1.375rem !important;
  }
  .font-size-1-1-2__1200,
  .heirs-font-size-1-1-2__1200 > * {
    font-size: 1.5rem !important;
  }
  .font-size-1-5-8__1200,
  .heirs-font-size-1-5-8__1200 > * {
    font-size: 1.625rem !important;
  }
  .font-size-1-3-4__1200,
  .heirs-font-size-1-3-4__1200 > * {
    font-size: 1.75rem !important;
  }
  .font-size-1-7-8__1200,
  .heirs-font-size-1-7-8__1200 > * {
    font-size: 1.875rem !important;
  }

  .font-size-2__1200,
  .heirs-font-size-2__1200 > * {
    font-size: 2rem !important;
  }
  .font-size-2-1-8__1200,
  .heirs-font-size-2-1-8__1200 > * {
    font-size: 2.125rem !important;
  }
  .font-size-2-1-4__1200,
  .heirs-font-size-2-1-4__1200 > * {
    font-size: 2.25rem !important;
  }
  .font-size-2-3-8__1200,
  .heirs-font-size-2-3-8__1200 > * {
    font-size: 2.375rem !important;
  }
  .font-size-2-1-2__1200,
  .heirs-font-size-2-1-2__1200 > * {
    font-size: 2.5rem !important;
  }
  .font-size-2-5-8__1200,
  .heirs-font-size-2-5-8__1200 > * {
    font-size: 2.625rem !important;
  }
  .font-size-2-3-4__1200,
  .heirs-font-size-2-3-4__1200 > * {
    font-size: 2.75rem !important;
  }
  .font-size-2-7-8__1200,
  .heirs-font-size-2-7-8__1200 > * {
    font-size: 2.875rem !important;
  }

  .font-size-3__1200,
  .heirs-font-size-3__1200 > * {
    font-size: 3rem !important;
  }
  .font-size-3-1-8__1200,
  .heirs-font-size-3-1-8__1200 > * {
    font-size: 3.125rem !important;
  }
  .font-size-3-1-4__1200,
  .heirs-font-size-3-1-4__1200 > * {
    font-size: 3.25rem !important;
  }
  .font-size-3-3-8__1200,
  .heirs-font-size-3-3-8__1200 > * {
    font-size: 3.375rem !important;
  }
  .font-size-3-1-2__1200,
  .heirs-font-size-3-1-2__1200 > * {
    font-size: 3.5rem !important;
  }
  .font-size-3-5-8__1200,
  .heirs-font-size-3-5-8__1200 > * {
    font-size: 3.625rem !important;
  }
  .font-size-3-3-4__1200,
  .heirs-font-size-3-3-4__1200 > * {
    font-size: 3.75rem !important;
  }
  .font-size-3-7-8__1200,
  .heirs-font-size-3-7-8__1200 > * {
    font-size: 3.875rem !important;
  }

  .font-size-4__1200,
  .heirs-font-size-4__1200 > * {
    font-size: 4rem !important;
  }
  .font-size-4-1-8__1200,
  .heirs-font-size-4-1-8__1200 > * {
    font-size: 4.125rem !important;
  }
  .font-size-4-1-4__1200,
  .heirs-font-size-4-1-4__1200 > * {
    font-size: 4.25rem !important;
  }
  .font-size-4-3-8__1200,
  .heirs-font-size-4-3-8__1200 > * {
    font-size: 4.375rem !important;
  }
  .font-size-4-1-2__1200,
  .heirs-font-size-4-1-2__1200 > * {
    font-size: 4.5rem !important;
  }
  .font-size-4-5-8__1200,
  .heirs-font-size-4-5-8__1200 > * {
    font-size: 4.625rem !important;
  }
  .font-size-4-3-4__1200,
  .heirs-font-size-4-3-4__1200 > * {
    font-size: 4.75rem !important;
  }
  .font-size-4-7-8__1200,
  .heirs-font-size-4-7-8__1200 > * {
    font-size: 4.875rem !important;
  }

  .font-style-i__1200,
  .heirs-font-style-i__1200 > * {
    font-style: italic !important;
  }
  .font-style-n__1200,
  .heirs-font-style-n__1200 > * {
    font-style: normal !important;
  }

  .font-weight-200__1200,
  .heirs-font-weight-200__1200 > * {
    font-weight: 200 !important;
  }
  .font-weight-300__1200,
  .heirs-font-weight-300__1200 > * {
    font-weight: 300 !important;
  }
  .font-weight-400__1200,
  .heirs-font-weight-400__1200 > * {
    font-weight: 400 !important;
  }
  .font-weight-500__1200,
  .heirs-font-weight-500__1200 > * {
    font-weight: 500 !important;
  }
  .font-weight-600__1200,
  .heirs-font-weight-600__1200 > * {
    font-weight: 600 !important;
  }

  .font-family-monospace__1200,
  .heirs-font-family-monospace__1200 > * {
    font-family: Consolas, Monaco, monospace !important;
  }
  .font-family-sans-serif__1200,
  .heirs-font-family-sans-serif__1200 > * {
    font-family: Arial, sans-serif !important;
  }
  .font-family-serif__1200,
  .heirs-font-family-serif__1200 > * {
    font-family: Times, serif !important;
  }

  .hyphens-a__1200,
  .heirs-hyphens-a__1200 > * {
    -ms-hyphens: auto !important;
    -webkit-hyphens: auto !important;
    hyphens: auto !important;
  }
  .hyphens-m__1200,
  .heirs-hyphens-m__1200 > * {
    -ms-hyphens: manual !important;
    -webkit-hyphens: manual !important;
    hyphens: manual !important;
  }
  .hyphens-n__1200,
  .heirs-hyphens-n__1200 > * {
    -ms-hyphens: none !important;
    -webkit-hyphens: none !important;
    hyphens: none !important;
  }

  .line-height-i__1200,
  .heirs-line-height-i__1200 > * {
    line-height: inherit !important;
  }
  .line-height-1w__1200,
  .heirs-line-height-1w__1200 > * {
    line-height: 1 !important;
  }

  .line-height-0__1200,
  .heirs-line-height-0__1200 > * {
    line-height: 0 !important;
  }
  .line-height-1-8__1200,
  .heirs-line-height-1-8__1200 > * {
    line-height: 0.125rem !important;
  }
  .line-height-1-4__1200,
  .heirs-line-height-1-4__1200 > * {
    line-height: 0.25rem !important;
  }
  .line-height-3-8__1200,
  .heirs-line-height-3-8__1200 > * {
    line-height: 0.375rem !important;
  }
  .line-height-1-2__1200,
  .heirs-line-height-1-2__1200 > * {
    line-height: 0.5rem !important;
  }
  .line-height-5-8__1200,
  .heirs-line-height-5-8__1200 > * {
    line-height: 0.625rem !important;
  }
  .line-height-3-4__1200,
  .heirs-line-height-3-4__1200 > * {
    line-height: 0.75rem !important;
  }
  .line-height-7-8__1200,
  .heirs-line-height-7-8__1200 > * {
    line-height: 0.875rem !important;
  }

  .line-height-1__1200,
  .heirs-line-height-1__1200 > * {
    line-height: 1rem !important;
  }
  .line-height-1-1-8__1200,
  .heirs-line-height-1-1-8__1200 > * {
    line-height: 1.125rem !important;
  }
  .line-height-1-1-4__1200,
  .heirs-line-height-1-1-4__1200 > * {
    line-height: 1.25rem !important;
  }
  .line-height-1-3-8__1200,
  .heirs-line-height-1-3-8__1200 > * {
    line-height: 1.375rem !important;
  }
  .line-height-1-1-2__1200,
  .heirs-line-height-1-1-2__1200 > * {
    line-height: 1.5rem !important;
  }
  .line-height-1-5-8__1200,
  .heirs-line-height-1-5-8__1200 > * {
    line-height: 1.625rem !important;
  }
  .line-height-1-3-4__1200,
  .heirs-line-height-1-3-4__1200 > * {
    line-height: 1.75rem !important;
  }
  .line-height-1-7-8__1200,
  .heirs-line-height-1-7-8__1200 > * {
    line-height: 1.875rem !important;
  }

  .line-height-2__1200,
  .heirs-line-height-2__1200 > * {
    line-height: 2rem !important;
  }
  .line-height-2-1-8__1200,
  .heirs-line-height-2-1-8__1200 > * {
    line-height: 2.125rem !important;
  }
  .line-height-2-1-4__1200,
  .heirs-line-height-2-1-4__1200 > * {
    line-height: 2.25rem !important;
  }
  .line-height-2-3-8__1200,
  .heirs-line-height-2-3-8__1200 > * {
    line-height: 2.375rem !important;
  }
  .line-height-2-1-2__1200,
  .heirs-line-height-2-1-2__1200 > * {
    line-height: 2.5rem !important;
  }
  .line-height-2-5-8__1200,
  .heirs-line-height-2-5-8__1200 > * {
    line-height: 2.625rem !important;
  }
  .line-height-2-3-4__1200,
  .heirs-line-height-2-3-4__1200 > * {
    line-height: 2.75rem !important;
  }
  .line-height-2-7-8__1200,
  .heirs-line-height-2-7-8__1200 > * {
    line-height: 2.875rem !important;
  }

  .line-height-3__1200,
  .heirs-line-height-3__1200 > * {
    line-height: 3rem !important;
  }
  .line-height-3-1-8__1200,
  .heirs-line-height-3-1-8__1200 > * {
    line-height: 3.125rem !important;
  }
  .line-height-3-1-4__1200,
  .heirs-line-height-3-1-4__1200 > * {
    line-height: 3.25rem !important;
  }
  .line-height-3-3-8__1200,
  .heirs-line-height-3-3-8__1200 > * {
    line-height: 3.375rem !important;
  }
  .line-height-3-1-2__1200,
  .heirs-line-height-3-1-2__1200 > * {
    line-height: 3.5rem !important;
  }
  .line-height-3-5-8__1200,
  .heirs-line-height-3-5-8__1200 > * {
    line-height: 3.625rem !important;
  }
  .line-height-3-3-4__1200,
  .heirs-line-height-3-3-4__1200 > * {
    line-height: 3.75rem !important;
  }
  .line-height-3-7-8__1200,
  .heirs-line-height-3-7-8__1200 > * {
    line-height: 3.875rem !important;
  }

  .line-height-4__1200,
  .heirs-line-height-4__1200 > * {
    line-height: 4rem !important;
  }
  .line-height-4-1-8__1200,
  .heirs-line-height-4-1-8__1200 > * {
    line-height: 4.125rem !important;
  }
  .line-height-4-1-4__1200,
  .heirs-line-height-4-1-4__1200 > * {
    line-height: 4.25rem !important;
  }
  .line-height-4-3-8__1200,
  .heirs-line-height-4-3-8__1200 > * {
    line-height: 4.375rem !important;
  }
  .line-height-4-1-2__1200,
  .heirs-line-height-4-1-2__1200 > * {
    line-height: 4.5rem !important;
  }
  .line-height-4-5-8__1200,
  .heirs-line-height-4-5-8__1200 > * {
    line-height: 4.625rem !important;
  }
  .line-height-4-3-4__1200,
  .heirs-line-height-4-3-4__1200 > * {
    line-height: 4.75rem !important;
  }
  .line-height-4-7-8__1200,
  .heirs-line-height-4-7-8__1200 > * {
    line-height: 4.875rem !important;
  }

  .text-align-c__1200,
  .heirs-text-align-c__1200 > * {
    text-align: center !important;
  }
  .text-align-j__1200,
  .heirs-text-align-j__1200 > * {
    text-align: justify !important;
  }
  .text-align-l__1200,
  .heirs-text-align-l__1200 > * {
    text-align: left !important;
  }
  .text-align-r__1200,
  .heirs-text-align-r__1200 > * {
    text-align: right !important;
  }

  .text-decoration-lt__1200,
  .heirs-text-decoration-lt__1200 > * {
    text-decoration: line-through !important;
  }
  .text-decoration-o__1200,
  .heirs-text-decoration-o__1200 > * {
    text-decoration: overline !important;
  }
  .text-decoration-u__1200,
  .heirs-text-decoration-u__1200 > * {
    text-decoration: underline !important;
  }
  .text-decoration-uo__1200,
  .heirs-text-decoration-uo__1200 > * {
    text-decoration: underline overline !important;
  }
  .text-decoration-n__1200,
  .heirs-text-decoration-n__1200 > * {
    text-decoration: none !important;
  }

  .text-transform-l__1200,
  .heirs-text-transform-l__1200 > * {
    text-transform: lowercase !important;
  }
  .text-transform-u__1200,
  .heirs-text-transform-u__1200 > * {
    text-transform: uppercase !important;
  }
  .text-transform-n__1200,
  .heirs-text-transform-n__1200 > * {
    text-transform: none !important;
  }

  .white-space-n__1200,
  .heirs-white-space-n__1200 > * {
    white-space: normal !important;
  }
  .white-space-nw__1200,
  .heirs-white-space-nw__1200 > * {
    white-space: nowrap !important;
  }
  .white-space-p__1200,
  .heirs-white-space-p__1200 > * {
    white-space: pre !important;
  }
  .white-space-pl__1200,
  .heirs-white-space-pl__1200 > * {
    white-space: pre-line !important;
  }
  .white-space-pw__1200,
  .heirs-white-space-pw__1200 > * {
    white-space: pre-wrap !important;
  }

  .word-break-ba__1200,
  .heirs-word-break-ba__1200 > * {
    word-break: break-all !important;
  }
  .word-break-ka__1200,
  .heirs-word-break-ka__1200 > * {
    word-break: keep-all !important;
  }
  .word-break-n__1200,
  .heirs-word-break-n__1200 > * {
    word-break: normal !important;
  }

  .word-wrap-bw__1200,
  .heirs-word-wrap-bw__1200 > * {
    word-wrap: break-word !important;
  }
  .word-wrap-n__1200,
  .heirs-word-wrap-n__1200 > * {
    word-wrap: normal !important;
  }

  .display-b__1200,
  .heirs-display-b__1200 > *,
  .show__1200,
  .heirs-show__1200 > * {
    display: block !important;
  }
  .display-i__1200,
  .heirs-display-i__1200 > * {
    display: inline !important;
  }
  .display-ib__1200,
  .heirs-display-ib__1200 > * {
    display: inline-block !important;
  }
  .display-it__1200,
  .heirs-display-it__1200 > * {
    display: inline-table !important;
  }
  .display-li__1200,
  .heirs-display-li__1200 > * {
    display: list-item !important;
  }
  .display-tcap__1200,
  .heirs-display-tcap__1200 > * {
    display: table-caption !important;
  }
  .display-td__1200,
  .heirs-display-td__1200 > * {
    display: table-cell !important;
  }
  .display-tcg__1200,
  .heirs-display-tcg__1200 > * {
    display: table-column-group !important;
  }
  .display-tc__1200,
  .heirs-display-tc__1200 > * {
    display: table-column !important;
  }
  .display-tfg__1200,
  .heirs-display-tfg__1200 > * {
    display: table-footer-group !important;
  }
  .display-thg__1200,
  .heirs-display-thg__1200 > * {
    display: table-header-group !important;
  }
  .display-trg__1200,
  .heirs-display-trg__1200 > * {
    display: table-row-group !important;
  }
  .display-tr__1200,
  .heirs-display-tr__1200 > * {
    display: table-row !important;
  }
  .display-t__1200,
  .heirs-display-t__1200 > * {
    display: table !important;
  }
  .display-n__1200,
  .heirs-display-n__1200 > *,
  .hide__1200,
  .heirs-hide__1200 > * {
    display: none !important;
  }

  .opacity-0__1200,
  .heirs-opacity-0__1200 > * {
    opacity: 0 !important;
  }
  .opacity-1__1200,
  .heirs-opacity-1__1200 > * {
    opacity: 1 !important;
  }

  .visibility-h__1200,
  .heirs-visibility-h__1200 > *,
  .hidden__1200,
  .heirs-hidden__1200 > * {
    visibility: hidden !important;
  }
  .visibility-v__1200,
  .heirs-visibility-v__1200 > *,
  .visible__1200,
  .heirs-visible__1200 > * {
    visibility: visible !important;
  }

  .clear-l__1200,
  .heirs-clear-l__1200 > * {
    clear: left !important;
  }
  .clear-r__1200,
  .heirs-clear-r__1200 > * {
    clear: right !important;
  }
  .clear-b__1200,
  .heirs-clear-b__1200 > * {
    clear: both !important;
  }
  .clear-n__1200,
  .heirs-clear-n__1200 > * {
    clear: none !important;
  }

  .float-l__1200,
  .heirs-float-l__1200 > * {
    float: left !important;
  }
  .float-r__1200,
  .heirs-float-r__1200 > * {
    float: right !important;
  }
  .float-n__1200,
  .heirs-float-n__1200 > * {
    float: none !important;
  }

  .position-a__1200,
  .heirs-position-a__1200 > * {
    position: absolute !important;
  }
  .position-f__1200,
  .heirs-position-f__1200 > * {
    position: fixed !important;
  }
  .position-r__1200,
  .heirs-position-r__1200 > * {
    position: relative !important;
  }
  .position-s__1200,
  .heirs-position-s__1200 > * {
    position: static !important;
  }

  .vertical-align-bl__1200,
  .heirs-vertical-align-bl__1200 > * {
    vertical-align: baseline !important;
  }
  .vertical-align-b__1200,
  .heirs-vertical-align-b__1200 > * {
    vertical-align: bottom !important;
  }
  .vertical-align-m__1200,
  .heirs-vertical-align-m__1200 > * {
    vertical-align: middle !important;
  }
  .vertical-align-sub__1200,
  .heirs-vertical-align-sub__1200 > * {
    vertical-align: sub !important;
  }
  .vertical-align-sup__1200,
  .heirs-vertical-align-sup__1200 > * {
    vertical-align: super !important;
  }
  .vertical-align-tb__1200,
  .heirs-vertical-align-tb__1200 > * {
    vertical-align: text-bottom !important;
  }
  .vertical-align-tt__1200,
  .heirs-vertical-align-tt__1200 > * {
    vertical-align: text-top !important;
  }
  .vertical-align-t__1200,
  .heirs-vertical-align-t__1200 > * {
    vertical-align: top !important;
  }

  .height-a__1200,
  .heirs-height-a__1200 > * {
    height: auto !important;
  }
  .height-full__1200,
  .heirs-height-full__1200 > * {
    height: 100% !important;
  }
  .height-i__1200,
  .heirs-height-i__1200 > * {
    height: inherit !important;
  }
  .height-0__1200,
  .heirs-height-0__1200 > * {
    height: 0 !important;
  }
  .height-1__1200,
  .heirs-height-1__1200 > * {
    height: 1px !important;
  }

  .max-height-full__1200,
  .heirs-max-height-full__1200 > * {
    max-height: 100% !important;
  }
  .max-height-i__1200,
  .heirs-max-height-i__1200 > * {
    max-height: inherit !important;
  }
  .max-height-0__1200,
  .heirs-max-height-0__1200 > * {
    max-height: 0 !important;
  }
  .max-height-1__1200,
  .heirs-max-height-1__1200 > * {
    max-height: 1px !important;
  }

  .min-height-a__1200,
  .heirs-min-height-a__1200 > * {
    min-height: auto !important;
  }
  .min-height-full__1200,
  .heirs-min-height-full__1200 > * {
    min-height: 100% !important;
  }
  .min-height-i__1200,
  .heirs-min-height-i__1200 > * {
    min-height: inherit !important;
  }
  .min-height-0__1200,
  .heirs-min-height-0__1200 > * {
    min-height: 0 !important;
  }
  .min-height-1__1200,
  .heirs-min-height-1__1200 > * {
    min-height: 1px !important;
  }

  .width-a__1200,
  .heirs-width-a__1200 > * {
    width: auto !important;
  }
  .width-full__1200,
  .heirs-width-full__1200 > * {
    width: 100% !important;
  }
  .width-i__1200,
  .heirs-width-i__1200 > * {
    width: inherit !important;
  }
  .width-0__1200,
  .heirs-width-0__1200 > * {
    width: 0 !important;
  }

  .width-1200__1200,
  .heirs-width-1200__1200 > * {
    width: 1200px !important;
  }
  .width-900__1200,
  .heirs-width-900__1200 > * {
    width: 900px !important;
  }
  .width-600__1200,
  .heirs-width-600__1200 > * {
    width: 600px !important;
  }
  .width-320__1200,
  .heirs-width-320__1200 > * {
    width: 320px !important;
  }

  .width-1-2__1200,
  .heirs-width-1-2__1200 > * {
    width: 50% !important;
  }
  .width-1-3__1200,
  .heirs-width-1-3__1200 > * {
    width: 33.33333333333333% !important;
  }
  .width-1-4__1200,
  .heirs-width-1-4__1200 > * {
    width: 25% !important;
  }
  .width-1-5__1200,
  .heirs-width-1-5__1200 > * {
    width: 20% !important;
  }
  .width-1-6__1200,
  .heirs-width-1-6__1200 > * {
    width: 16.66666666666667% !important;
  }
  .width-1-7__1200,
  .heirs-width-1-7__1200 > * {
    width: 14.28571428571429% !important;
  }
  .width-1-8__1200,
  .heirs-width-1-8__1200 > * {
    width: 12.5% !important;
  }
  .width-1-9__1200,
  .heirs-width-1-9__1200 > * {
    width: 11.11111111111111% !important;
  }
  .width-1-10__1200,
  .heirs-width-1-10__1200 > * {
    width: 10% !important;
  }
  .width-1-11__1200,
  .heirs-width-1-11__1200 > * {
    width: 9.090909090909091% !important;
  }
  .width-1-12__1200,
  .heirs-width-1-12__1200 > * {
    width: 8.333333333333333% !important;
  }
  .width-1-20__1200,
  .heirs-width-1-20__1200 > * {
    width: 5% !important;
  }
  .width-2-3__1200,
  .heirs-width-2-3__1200 > * {
    width: 66.66666666666667% !important;
  }
  .width-2-5__1200,
  .heirs-width-2-5__1200 > * {
    width: 40% !important;
  }
  .width-2-7__1200,
  .heirs-width-2-7__1200 > * {
    width: 28.57142857142858% !important;
  }
  .width-2-9__1200,
  .heirs-width-2-9__1200 > * {
    width: 22.22222222222222% !important;
  }
  .width-2-11__1200,
  .heirs-width-2-11__1200 > * {
    width: 18.18181818181818% !important;
  }
  .width-3-4__1200,
  .heirs-width-3-4__1200 > * {
    width: 75% !important;
  }
  .width-3-5__1200,
  .heirs-width-3-5__1200 > * {
    width: 60% !important;
  }
  .width-3-7__1200,
  .heirs-width-3-7__1200 > * {
    width: 42.85714285714287% !important;
  }
  .width-3-8__1200,
  .heirs-width-3-8__1200 > * {
    width: 37.5% !important;
  }
  .width-3-10__1200,
  .heirs-width-3-10__1200 > * {
    width: 30% !important;
  }
  .width-3-11__1200,
  .heirs-width-3-11__1200 > * {
    width: 27.27272727272727% !important;
  }
  .width-4-5__1200,
  .heirs-width-4-5__1200 > * {
    width: 80% !important;
  }
  .width-4-7__1200,
  .heirs-width-4-7__1200 > * {
    width: 57.14285714285716% !important;
  }
  .width-4-9__1200,
  .heirs-width-4-9__1200 > * {
    width: 44.44444444444444% !important;
  }
  .width-4-11__1200,
  .heirs-width-4-11__1200 > * {
    width: 36.36363636363636% !important;
  }
  .width-5-6__1200,
  .heirs-width-5-6__1200 > * {
    width: 83.33333333333333% !important;
  }
  .width-5-7__1200,
  .heirs-width-5-7__1200 > * {
    width: 71.42857142857145% !important;
  }
  .width-5-8__1200,
  .heirs-width-5-8__1200 > * {
    width: 62.5% !important;
  }
  .width-5-9__1200,
  .heirs-width-5-9__1200 > * {
    width: 55.55555555555555% !important;
  }
  .width-5-11__1200,
  .heirs-width-5-11__1200 > * {
    width: 45.45454545454546% !important;
  }
  .width-5-12__1200,
  .heirs-width-5-12__1200 > * {
    width: 41.66666666666667% !important;
  }
  .width-6-7__1200,
  .heirs-width-6-7__1200 > * {
    width: 85.71428571428574% !important;
  }
  .width-6-11__1200,
  .heirs-width-6-11__1200 > * {
    width: 54.54545454545455% !important;
  }
  .width-7-8__1200,
  .heirs-width-7-8__1200 > * {
    width: 87.5% !important;
  }
  .width-7-9__1200,
  .heirs-width-7-9__1200 > * {
    width: 77.77777777777777% !important;
  }
  .width-7-10__1200,
  .heirs-width-7-10__1200 > * {
    width: 70% !important;
  }
  .width-7-11__1200,
  .heirs-width-7-11__1200 > * {
    width: 63.63636363636364% !important;
  }
  .width-7-12__1200,
  .heirs-width-7-12__1200 > * {
    width: 58.33333333333333% !important;
  }
  .width-8-9__1200,
  .heirs-width-8-9__1200 > * {
    width: 88.88888888888888% !important;
  }
  .width-8-11__1200,
  .heirs-width-8-11__1200 > * {
    width: 72.72727272727273% !important;
  }
  .width-9-10__1200,
  .heirs-width-9-10__1200 > * {
    width: 90% !important;
  }
  .width-9-11__1200,
  .heirs-width-9-11__1200 > * {
    width: 81.81818181818182% !important;
  }
  .width-10-11__1200,
  .heirs-width-10-11__1200 > * {
    width: 90.90909090909091% !important;
  }
  .width-11-12__1200,
  .heirs-width-11-12__1200 > * {
    width: 91.66666666666666% !important;
  }

  .max-width-full__1200,
  .heirs-max-width-full__1200 > * {
    max-width: 100% !important;
  }
  .max-width-i__1200,
  .heirs-max-width-i__1200 > * {
    max-width: inherit !important;
  }
  .max-width-n__1200,
  .heirs-max-width-n__1200 > * {
    max-width: none !important;
  }
  .max-width-0__1200,
  .heirs-max-width-0__1200 > * {
    max-width: 0 !important;
  }

  .max-width-1200__1200,
  .heirs-max-width-1200__1200 > * {
    max-width: 1200px !important;
  }
  .max-width-900__1200,
  .heirs-max-width-900__1200 > * {
    max-width: 900px !important;
  }
  .max-width-600__1200,
  .heirs-max-width-600__1200 > * {
    max-width: 600px !important;
  }
  .max-width-320__1200,
  .heirs-max-width-320__1200 > * {
    max-width: 320px !important;
  }

  .max-width-1-2__1200,
  .heirs-max-width-1-2__1200 > * {
    max-width: 50% !important;
  }
  .max-width-1-3__1200,
  .heirs-max-width-1-3__1200 > * {
    max-width: 33.33333333333333% !important;
  }
  .max-width-1-4__1200,
  .heirs-max-width-1-4__1200 > * {
    max-width: 25% !important;
  }
  .max-width-1-5__1200,
  .heirs-max-width-1-5__1200 > * {
    max-width: 20% !important;
  }
  .max-width-1-6__1200,
  .heirs-max-width-1-6__1200 > * {
    max-width: 16.66666666666667% !important;
  }
  .max-width-1-7__1200,
  .heirs-max-width-1-7__1200 > * {
    max-width: 14.28571428571429% !important;
  }
  .max-width-1-8__1200,
  .heirs-max-width-1-8__1200 > * {
    max-width: 12.5% !important;
  }
  .max-width-1-9__1200,
  .heirs-max-width-1-9__1200 > * {
    max-width: 11.11111111111111% !important;
  }
  .max-width-1-10__1200,
  .heirs-max-width-1-10__1200 > * {
    max-width: 10% !important;
  }
  .max-width-1-11__1200,
  .heirs-max-width-1-11__1200 > * {
    max-width: 9.090909090909091% !important;
  }
  .max-width-1-12__1200,
  .heirs-max-width-1-12__1200 > * {
    max-width: 8.333333333333333% !important;
  }
  .max-width-1-20__1200,
  .heirs-max-width-1-20__1200 > * {
    max-width: 5% !important;
  }
  .max-width-2-3__1200,
  .heirs-max-width-2-3__1200 > * {
    max-width: 66.66666666666667% !important;
  }
  .max-width-2-5__1200,
  .heirs-max-width-2-5__1200 > * {
    max-width: 40% !important;
  }
  .max-width-2-7__1200,
  .heirs-max-width-2-7__1200 > * {
    max-width: 28.57142857142858% !important;
  }
  .max-width-2-9__1200,
  .heirs-max-width-2-9__1200 > * {
    max-width: 22.22222222222222% !important;
  }
  .max-width-2-11__1200,
  .heirs-max-width-2-11__1200 > * {
    max-width: 18.18181818181818% !important;
  }
  .max-width-3-4__1200,
  .heirs-max-width-3-4__1200 > * {
    max-width: 75% !important;
  }
  .max-width-3-5__1200,
  .heirs-max-width-3-5__1200 > * {
    max-width: 60% !important;
  }
  .max-width-3-7__1200,
  .heirs-max-width-3-7__1200 > * {
    max-width: 42.85714285714287% !important;
  }
  .max-width-3-8__1200,
  .heirs-max-width-3-8__1200 > * {
    max-width: 37.5% !important;
  }
  .max-width-3-10__1200,
  .heirs-max-width-3-10__1200 > * {
    max-width: 30% !important;
  }
  .max-width-3-11__1200,
  .heirs-max-width-3-11__1200 > * {
    max-width: 27.27272727272727% !important;
  }
  .max-width-4-5__1200,
  .heirs-max-width-4-5__1200 > * {
    max-width: 80% !important;
  }
  .max-width-4-7__1200,
  .heirs-max-width-4-7__1200 > * {
    max-width: 57.14285714285716% !important;
  }
  .max-width-4-9__1200,
  .heirs-max-width-4-9__1200 > * {
    max-width: 44.44444444444444% !important;
  }
  .max-width-4-11__1200,
  .heirs-max-width-4-11__1200 > * {
    max-width: 36.36363636363636% !important;
  }
  .max-width-5-6__1200,
  .heirs-max-width-5-6__1200 > * {
    max-width: 83.33333333333333% !important;
  }
  .max-width-5-7__1200,
  .heirs-max-width-5-7__1200 > * {
    max-width: 71.42857142857145% !important;
  }
  .max-width-5-8__1200,
  .heirs-max-width-5-8__1200 > * {
    max-width: 62.5% !important;
  }
  .max-width-5-9__1200,
  .heirs-max-width-5-9__1200 > * {
    max-width: 55.55555555555555% !important;
  }
  .max-width-5-11__1200,
  .heirs-max-width-5-11__1200 > * {
    max-width: 45.45454545454546% !important;
  }
  .max-width-5-12__1200,
  .heirs-max-width-5-12__1200 > * {
    max-width: 41.66666666666667% !important;
  }
  .max-width-6-7__1200,
  .heirs-max-width-6-7__1200 > * {
    max-width: 85.71428571428574% !important;
  }
  .max-width-6-11__1200,
  .heirs-max-width-6-11__1200 > * {
    max-width: 54.54545454545455% !important;
  }
  .max-width-7-8__1200,
  .heirs-max-width-7-8__1200 > * {
    max-width: 87.5% !important;
  }
  .max-width-7-9__1200,
  .heirs-max-width-7-9__1200 > * {
    max-width: 77.77777777777777% !important;
  }
  .max-width-7-10__1200,
  .heirs-max-width-7-10__1200 > * {
    max-width: 70% !important;
  }
  .max-width-7-11__1200,
  .heirs-max-width-7-11__1200 > * {
    max-width: 63.63636363636364% !important;
  }
  .max-width-7-12__1200,
  .heirs-max-width-7-12__1200 > * {
    max-width: 58.33333333333333% !important;
  }
  .max-width-8-9__1200,
  .heirs-max-width-8-9__1200 > * {
    max-width: 88.88888888888888% !important;
  }
  .max-width-8-11__1200,
  .heirs-max-width-8-11__1200 > * {
    max-width: 72.72727272727273% !important;
  }
  .max-width-9-10__1200,
  .heirs-max-width-9-10__1200 > * {
    max-width: 90% !important;
  }
  .max-width-9-11__1200,
  .heirs-max-width-9-11__1200 > * {
    max-width: 81.81818181818182% !important;
  }
  .max-width-10-11__1200,
  .heirs-max-width-10-11__1200 > * {
    max-width: 90.90909090909091% !important;
  }
  .max-width-11-12__1200,
  .heirs-max-width-11-12__1200 > * {
    max-width: 91.66666666666666% !important;
  }

  .min-width-a__1200,
  .heirs-min-width-a__1200 > * {
    min-width: auto !important;
  }
  .min-width-full__1200,
  .heirs-min-width-full__1200 > * {
    min-width: 100% !important;
  }
  .min-width-i__1200,
  .heirs-min-width-i__1200 > * {
    min-width: inherit !important;
  }
  .min-width-0__1200,
  .heirs-min-width-0__1200 > * {
    min-width: 0 !important;
  }

  .min-width-1200__1200,
  .heirs-min-width-1200__1200 > * {
    min-width: 1200px !important;
  }
  .min-width-900__1200,
  .heirs-min-width-900__1200 > * {
    min-width: 900px !important;
  }
  .min-width-600__1200,
  .heirs-min-width-600__1200 > * {
    min-width: 600px !important;
  }
  .min-width-320__1200,
  .heirs-min-width-320__1200 > * {
    min-width: 320px !important;
  }

  .min-width-1-2__1200,
  .heirs-min-width-1-2__1200 > * {
    min-width: 50% !important;
  }
  .min-width-1-3__1200,
  .heirs-min-width-1-3__1200 > * {
    min-width: 33.33333333333333% !important;
  }
  .min-width-1-4__1200,
  .heirs-min-width-1-4__1200 > * {
    min-width: 25% !important;
  }
  .min-width-1-5__1200,
  .heirs-min-width-1-5__1200 > * {
    min-width: 20% !important;
  }
  .min-width-1-6__1200,
  .heirs-min-width-1-6__1200 > * {
    min-width: 16.66666666666667% !important;
  }
  .min-width-1-7__1200,
  .heirs-min-width-1-7__1200 > * {
    min-width: 14.28571428571429% !important;
  }
  .min-width-1-8__1200,
  .heirs-min-width-1-8__1200 > * {
    min-width: 12.5% !important;
  }
  .min-width-1-9__1200,
  .heirs-min-width-1-9__1200 > * {
    min-width: 11.11111111111111% !important;
  }
  .min-width-1-10__1200,
  .heirs-min-width-1-10__1200 > * {
    min-width: 10% !important;
  }
  .min-width-1-11__1200,
  .heirs-min-width-1-11__1200 > * {
    min-width: 9.090909090909091% !important;
  }
  .min-width-1-12__1200,
  .heirs-min-width-1-12__1200 > * {
    min-width: 8.333333333333333% !important;
  }
  .min-width-1-20__1200,
  .heirs-min-width-1-20__1200 > * {
    min-width: 5% !important;
  }
  .min-width-2-3__1200,
  .heirs-min-width-2-3__1200 > * {
    min-width: 66.66666666666667% !important;
  }
  .min-width-2-5__1200,
  .heirs-min-width-2-5__1200 > * {
    min-width: 40% !important;
  }
  .min-width-2-7__1200,
  .heirs-min-width-2-7__1200 > * {
    min-width: 28.57142857142858% !important;
  }
  .min-width-2-9__1200,
  .heirs-min-width-2-9__1200 > * {
    min-width: 22.22222222222222% !important;
  }
  .min-width-2-11__1200,
  .heirs-min-width-2-11__1200 > * {
    min-width: 18.18181818181818% !important;
  }
  .min-width-3-4__1200,
  .heirs-min-width-3-4__1200 > * {
    min-width: 75% !important;
  }
  .min-width-3-5__1200,
  .heirs-min-width-3-5__1200 > * {
    min-width: 60% !important;
  }
  .min-width-3-7__1200,
  .heirs-min-width-3-7__1200 > * {
    min-width: 42.85714285714287% !important;
  }
  .min-width-3-8__1200,
  .heirs-min-width-3-8__1200 > * {
    min-width: 37.5% !important;
  }
  .min-width-3-10__1200,
  .heirs-min-width-3-10__1200 > * {
    min-width: 30% !important;
  }
  .min-width-3-11__1200,
  .heirs-min-width-3-11__1200 > * {
    min-width: 27.27272727272727% !important;
  }
  .min-width-4-5__1200,
  .heirs-min-width-4-5__1200 > * {
    min-width: 80% !important;
  }
  .min-width-4-7__1200,
  .heirs-min-width-4-7__1200 > * {
    min-width: 57.14285714285716% !important;
  }
  .min-width-4-9__1200,
  .heirs-min-width-4-9__1200 > * {
    min-width: 44.44444444444444% !important;
  }
  .min-width-4-11__1200,
  .heirs-min-width-4-11__1200 > * {
    min-width: 36.36363636363636% !important;
  }
  .min-width-5-6__1200,
  .heirs-min-width-5-6__1200 > * {
    min-width: 83.33333333333333% !important;
  }
  .min-width-5-7__1200,
  .heirs-min-width-5-7__1200 > * {
    min-width: 71.42857142857145% !important;
  }
  .min-width-5-8__1200,
  .heirs-min-width-5-8__1200 > * {
    min-width: 62.5% !important;
  }
  .min-width-5-9__1200,
  .heirs-min-width-5-9__1200 > * {
    min-width: 55.55555555555555% !important;
  }
  .min-width-5-11__1200,
  .heirs-min-width-5-11__1200 > * {
    min-width: 45.45454545454546% !important;
  }
  .min-width-5-12__1200,
  .heirs-min-width-5-12__1200 > * {
    min-width: 41.66666666666667% !important;
  }
  .min-width-6-7__1200,
  .heirs-min-width-6-7__1200 > * {
    min-width: 85.71428571428574% !important;
  }
  .min-width-6-11__1200,
  .heirs-min-width-6-11__1200 > * {
    min-width: 54.54545454545455% !important;
  }
  .min-width-7-8__1200,
  .heirs-min-width-7-8__1200 > * {
    min-width: 87.5% !important;
  }
  .min-width-7-9__1200,
  .heirs-min-width-7-9__1200 > * {
    min-width: 77.77777777777777% !important;
  }
  .min-width-7-10__1200,
  .heirs-min-width-7-10__1200 > * {
    min-width: 70% !important;
  }
  .min-width-7-11__1200,
  .heirs-min-width-7-11__1200 > * {
    min-width: 63.63636363636364% !important;
  }
  .min-width-7-12__1200,
  .heirs-min-width-7-12__1200 > * {
    min-width: 58.33333333333333% !important;
  }
  .min-width-8-9__1200,
  .heirs-min-width-8-9__1200 > * {
    min-width: 88.88888888888888% !important;
  }
  .min-width-8-11__1200,
  .heirs-min-width-8-11__1200 > * {
    min-width: 72.72727272727273% !important;
  }
  .min-width-9-10__1200,
  .heirs-min-width-9-10__1200 > * {
    min-width: 90% !important;
  }
  .min-width-9-11__1200,
  .heirs-min-width-9-11__1200 > * {
    min-width: 81.81818181818182% !important;
  }
  .min-width-10-11__1200,
  .heirs-min-width-10-11__1200 > * {
    min-width: 90.90909090909091% !important;
  }
  .min-width-11-12__1200,
  .heirs-min-width-11-12__1200 > * {
    min-width: 91.66666666666666% !important;
  }

  .background-n__1200,
  .heirs-background-n__1200 > * {
    background: none !important;
  }

  .border-n__1200,
  .heirs-border-n__1200 > * {
    border: none !important;
  }
  .border-b-n__1200,
  .heirs-border-b-n__1200 > *,
  .border-bt-n__1200,
  .heirs-border-bt-n__1200 > * {
    border-bottom: none !important;
  }
  .border-l-n__1200,
  .heirs-border-l-n__1200 > *,
  .border-lr-n__1200,
  .heirs-border-lr-n__1200 > * {
    border-left: none !important;
  }
  .border-r-n__1200,
  .heirs-border-r-n__1200 > *,
  .border-lr-n__1200,
  .heirs-border-lr-n__1200 > * {
    border-right: none !important;
  }
  .border-t-n__1200,
  .heirs-border-t-n__1200 > *,
  .border-bt-n__1200,
  .heirs-border-bt-n__1200 > * {
    border-top: none !important;
  }

  .box-sizing-bb__1200,
  .heirs-box-sizing-bb__1200 > * {
    box-sizing: border-box !important;
  }
  .box-sizing-cb__1200,
  .heirs-box-sizing-cb__1200 > * {
    box-sizing: content-box !important;
  }

  .center__1200,
  .heirs-center__1200 > * {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .margin-0__1200,
  .heirs-margin-0__1200 > * {
    margin: 0 !important;
  }
  .margin-b-0__1200,
  .heirs-margin-b-0__1200 > *,
  .margin-bt-0__1200,
  .heirs-margin-bt-0__1200 > * {
    margin-bottom: 0 !important;
  }
  .margin-l-0__1200,
  .heirs-margin-l-0__1200 > *,
  .margin-lr-0__1200,
  .heirs-margin-lr-0__1200 > * {
    margin-left: 0 !important;
  }
  .margin-r-0__1200,
  .heirs-margin-r-0__1200 > *,
  .margin-lr-0__1200,
  .heirs-margin-lr-0__1200 > * {
    margin-right: 0 !important;
  }
  .margin-t-0__1200,
  .heirs-margin-t-0__1200 > *,
  .margin-bt-0__1200,
  .heirs-margin-bt-0__1200 > * {
    margin-top: 0 !important;
  }

  .margin-1-8__1200,
  .heirs-margin-1-8__1200 > * {
    margin: 0.125rem !important;
  }
  .margin-b-1-8__1200,
  .heirs-margin-b-1-8__1200 > *,
  .margin-bt-1-8__1200,
  .heirs-margin-bt-1-8__1200 > * {
    margin-bottom: 0.125rem !important;
  }
  .margin-l-1-8__1200,
  .heirs-margin-l-1-8__1200 > *,
  .margin-lr-1-8__1200,
  .heirs-margin-lr-1-8__1200 > * {
    margin-left: 0.125rem !important;
  }
  .margin-r-1-8__1200,
  .heirs-margin-r-1-8__1200 > *,
  .margin-lr-1-8__1200,
  .heirs-margin-lr-1-8__1200 > * {
    margin-right: 0.125rem !important;
  }
  .margin-t-1-8__1200,
  .heirs-margin-t-1-8__1200 > *,
  .margin-bt-1-8__1200,
  .heirs-margin-bt-1-8__1200 > * {
    margin-top: 0.125rem !important;
  }

  .margin-1-4__1200,
  .heirs-margin-1-4__1200 > * {
    margin: 0.25rem !important;
  }
  .margin-b-1-4__1200,
  .heirs-margin-b-1-4__1200 > *,
  .margin-bt-1-4__1200,
  .heirs-margin-bt-1-4__1200 > * {
    margin-bottom: 0.25rem !important;
  }
  .margin-l-1-4__1200,
  .heirs-margin-l-1-4__1200 > *,
  .margin-lr-1-4__1200,
  .heirs-margin-lr-1-4__1200 > * {
    margin-left: 0.25rem !important;
  }
  .margin-r-1-4__1200,
  .heirs-margin-r-1-4__1200 > *,
  .margin-lr-1-4__1200,
  .heirs-margin-lr-1-4__1200 > * {
    margin-right: 0.25rem !important;
  }
  .margin-t-1-4__1200,
  .heirs-margin-t-1-4__1200 > *,
  .margin-bt-1-4__1200,
  .heirs-margin-bt-1-4__1200 > * {
    margin-top: 0.25rem !important;
  }

  .margin-1-2__1200,
  .heirs-margin-1-2__1200 > * {
    margin: 0.5rem !important;
  }
  .margin-b-1-2__1200,
  .heirs-margin-b-1-2__1200 > *,
  .margin-bt-1-2__1200,
  .heirs-margin-bt-1-2__1200 > * {
    margin-bottom: 0.5rem !important;
  }
  .margin-l-1-2__1200,
  .heirs-margin-l-1-2__1200 > *,
  .margin-lr-1-2__1200,
  .heirs-margin-lr-1-2__1200 > * {
    margin-left: 0.5rem !important;
  }
  .margin-r-1-2__1200,
  .heirs-margin-r-1-2__1200 > *,
  .margin-lr-1-2__1200,
  .heirs-margin-lr-1-2__1200 > * {
    margin-right: 0.5rem !important;
  }
  .margin-t-1-2__1200,
  .heirs-margin-t-1-2__1200 > *,
  .margin-bt-1-2__1200,
  .heirs-margin-bt-1-2__1200 > * {
    margin-top: 0.5rem !important;
  }

  .margin-3-4__1200,
  .heirs-margin-3-4__1200 > * {
    margin: 0.75rem !important;
  }
  .margin-b-3-4__1200,
  .heirs-margin-b-3-4__1200 > *,
  .margin-bt-3-4__1200,
  .heirs-margin-bt-3-4__1200 > * {
    margin-bottom: 0.75rem !important;
  }
  .margin-l-3-4__1200,
  .heirs-margin-l-3-4__1200 > *,
  .margin-lr-3-4__1200,
  .heirs-margin-lr-3-4__1200 > * {
    margin-left: 0.75rem !important;
  }
  .margin-r-3-4__1200,
  .heirs-margin-r-3-4__1200 > *,
  .margin-lr-3-4__1200,
  .heirs-margin-lr-3-4__1200 > * {
    margin-right: 0.75rem !important;
  }
  .margin-t-3-4__1200,
  .heirs-margin-t-3-4__1200 > *,
  .margin-bt-3-4__1200,
  .heirs-margin-bt-3-4__1200 > * {
    margin-top: 0.75rem !important;
  }

  .margin-1__1200,
  .heirs-margin-1__1200 > * {
    margin: 1rem !important;
  }
  .margin-b-1__1200,
  .heirs-margin-b-1__1200 > *,
  .margin-bt-1__1200,
  .heirs-margin-bt-1__1200 > * {
    margin-bottom: 1rem !important;
  }
  .margin-l-1__1200,
  .heirs-margin-l-1__1200 > *,
  .margin-lr-1__1200,
  .heirs-margin-lr-1__1200 > * {
    margin-left: 1rem !important;
  }
  .margin-r-1__1200,
  .heirs-margin-r-1__1200 > *,
  .margin-lr-1__1200,
  .heirs-margin-lr-1__1200 > * {
    margin-right: 1rem !important;
  }
  .margin-t-1__1200,
  .heirs-margin-t-1__1200 > *,
  .margin-bt-1__1200,
  .heirs-margin-bt-1__1200 > * {
    margin-top: 1rem !important;
  }

  .margin-2__1200,
  .heirs-margin-2__1200 > * {
    margin: 2rem !important;
  }
  .margin-b-2__1200,
  .heirs-margin-b-2__1200 > *,
  .margin-bt-2__1200,
  .heirs-margin-bt-2__1200 > * {
    margin-bottom: 2rem !important;
  }
  .margin-l-2__1200,
  .heirs-margin-l-2__1200 > *,
  .margin-lr-2__1200,
  .heirs-margin-lr-2__1200 > * {
    margin-left: 2rem !important;
  }
  .margin-r-2__1200,
  .heirs-margin-r-2__1200 > *,
  .margin-lr-2__1200,
  .heirs-margin-lr-2__1200 > * {
    margin-right: 2rem !important;
  }
  .margin-t-2__1200,
  .heirs-margin-t-2__1200 > *,
  .margin-bt-2__1200,
  .heirs-margin-bt-2__1200 > * {
    margin-top: 2rem !important;
  }

  .margin-3__1200,
  .heirs-margin-3__1200 > * {
    margin: 3rem !important;
  }
  .margin-b-3__1200,
  .heirs-margin-b-3__1200 > *,
  .margin-bt-3__1200,
  .heirs-margin-bt-3__1200 > * {
    margin-bottom: 3rem !important;
  }
  .margin-l-3__1200,
  .heirs-margin-l-3__1200 > *,
  .margin-lr-3__1200,
  .heirs-margin-lr-3__1200 > * {
    margin-left: 3rem !important;
  }
  .margin-r-3__1200,
  .heirs-margin-r-3__1200 > *,
  .margin-lr-3__1200,
  .heirs-margin-lr-3__1200 > * {
    margin-right: 3rem !important;
  }
  .margin-t-3__1200,
  .heirs-margin-t-3__1200 > *,
  .margin-bt-3__1200,
  .heirs-margin-bt-3__1200 > * {
    margin-top: 3rem !important;
  }

  .margin-4__1200,
  .heirs-margin-4__1200 > * {
    margin: 4rem !important;
  }
  .margin-b-4__1200,
  .heirs-margin-b-4__1200 > *,
  .margin-bt-4__1200,
  .heirs-margin-bt-4__1200 > * {
    margin-bottom: 4rem !important;
  }
  .margin-l-4__1200,
  .heirs-margin-l-4__1200 > *,
  .margin-lr-4__1200,
  .heirs-margin-lr-4__1200 > * {
    margin-left: 4rem !important;
  }
  .margin-r-4__1200,
  .heirs-margin-r-4__1200 > *,
  .margin-lr-4__1200,
  .heirs-margin-lr-4__1200 > * {
    margin-right: 4rem !important;
  }
  .margin-t-4__1200,
  .heirs-margin-t-4__1200 > *,
  .margin-bt-4__1200,
  .heirs-margin-bt-4__1200 > * {
    margin-top: 4rem !important;
  }

  .padding-0__1200,
  .heirs-padding-0__1200 > * {
    padding: 0 !important;
  }
  .padding-b-0__1200,
  .heirs-padding-b-0__1200 > *,
  .padding-bt-0__1200,
  .heirs-padding-bt-0__1200 > * {
    padding-bottom: 0 !important;
  }
  .padding-l-0__1200,
  .heirs-padding-l-0__1200 > *,
  .padding-lr-0__1200,
  .heirs-padding-lr-0__1200 > * {
    padding-left: 0 !important;
  }
  .padding-r-0__1200,
  .heirs-padding-r-0__1200 > *,
  .padding-lr-0__1200,
  .heirs-padding-lr-0__1200 > * {
    padding-right: 0 !important;
  }
  .padding-t-0__1200,
  .heirs-padding-t-0__1200 > *,
  .padding-bt-0__1200,
  .heirs-padding-bt-0__1200 > * {
    padding-top: 0 !important;
  }

  .padding-1-8__1200,
  .heirs-padding-1-8__1200 > * {
    padding: 0.125rem !important;
  }
  .padding-b-1-8__1200,
  .heirs-padding-b-1-8__1200 > *,
  .padding-bt-1-8__1200,
  .heirs-padding-bt-1-8__1200 > * {
    padding-bottom: 0.125rem !important;
  }
  .padding-l-1-8__1200,
  .heirs-padding-l-1-8__1200 > *,
  .padding-lr-1-8__1200,
  .heirs-padding-lr-1-8__1200 > * {
    padding-left: 0.125rem !important;
  }
  .padding-r-1-8__1200,
  .heirs-padding-r-1-8__1200 > *,
  .padding-lr-1-8__1200,
  .heirs-padding-lr-1-8__1200 > * {
    padding-right: 0.125rem !important;
  }
  .padding-t-1-8__1200,
  .heirs-padding-t-1-8__1200 > *,
  .padding-bt-1-8__1200,
  .heirs-padding-bt-1-8__1200 > * {
    padding-top: 0.125rem !important;
  }

  .padding-1-4__1200,
  .heirs-padding-1-4__1200 > * {
    padding: 0.25rem !important;
  }
  .padding-b-1-4__1200,
  .heirs-padding-b-1-4__1200 > *,
  .padding-bt-1-4__1200,
  .heirs-padding-bt-1-4__1200 > * {
    padding-bottom: 0.25rem !important;
  }
  .padding-l-1-4__1200,
  .heirs-padding-l-1-4__1200 > *,
  .padding-lr-1-4__1200,
  .heirs-padding-lr-1-4__1200 > * {
    padding-left: 0.25rem !important;
  }
  .padding-r-1-4__1200,
  .heirs-padding-r-1-4__1200 > *,
  .padding-lr-1-4__1200,
  .heirs-padding-lr-1-4__1200 > * {
    padding-right: 0.25rem !important;
  }
  .padding-t-1-4__1200,
  .heirs-padding-t-1-4__1200 > *,
  .padding-bt-1-4__1200,
  .heirs-padding-bt-1-4__1200 > * {
    padding-top: 0.25rem !important;
  }

  .padding-1-2__1200,
  .heirs-padding-1-2__1200 > * {
    padding: 0.5rem !important;
  }
  .padding-b-1-2__1200,
  .heirs-padding-b-1-2__1200 > *,
  .padding-bt-1-2__1200,
  .heirs-padding-bt-1-2__1200 > * {
    padding-bottom: 0.5rem !important;
  }
  .padding-l-1-2__1200,
  .heirs-padding-l-1-2__1200 > *,
  .padding-lr-1-2__1200,
  .heirs-padding-lr-1-2__1200 > * {
    padding-left: 0.5rem !important;
  }
  .padding-r-1-2__1200,
  .heirs-padding-r-1-2__1200 > *,
  .padding-lr-1-2__1200,
  .heirs-padding-lr-1-2__1200 > * {
    padding-right: 0.5rem !important;
  }
  .padding-t-1-2__1200,
  .heirs-padding-t-1-2__1200 > *,
  .padding-bt-1-2__1200,
  .heirs-padding-bt-1-2__1200 > * {
    padding-top: 0.5rem !important;
  }

  .padding-3-4__1200,
  .heirs-padding-3-4__1200 > * {
    padding: 0.75rem !important;
  }
  .padding-b-3-4__1200,
  .heirs-padding-b-3-4__1200 > *,
  .padding-bt-3-4__1200,
  .heirs-padding-bt-3-4__1200 > * {
    padding-bottom: 0.75rem !important;
  }
  .padding-l-3-4__1200,
  .heirs-padding-l-3-4__1200 > *,
  .padding-lr-3-4__1200,
  .heirs-padding-lr-3-4__1200 > * {
    padding-left: 0.75rem !important;
  }
  .padding-r-3-4__1200,
  .heirs-padding-r-3-4__1200 > *,
  .padding-lr-3-4__1200,
  .heirs-padding-lr-3-4__1200 > * {
    padding-right: 0.75rem !important;
  }
  .padding-t-3-4__1200,
  .heirs-padding-t-3-4__1200 > *,
  .padding-bt-3-4__1200,
  .heirs-padding-bt-3-4__1200 > * {
    padding-top: 0.75rem !important;
  }

  .padding-1__1200,
  .heirs-padding-1__1200 > * {
    padding: 1rem !important;
  }
  .padding-b-1__1200,
  .heirs-padding-b-1__1200 > *,
  .padding-bt-1__1200,
  .heirs-padding-bt-1__1200 > * {
    padding-bottom: 1rem !important;
  }
  .padding-l-1__1200,
  .heirs-padding-l-1__1200 > *,
  .padding-lr-1__1200,
  .heirs-padding-lr-1__1200 > * {
    padding-left: 1rem !important;
  }
  .padding-r-1__1200,
  .heirs-padding-r-1__1200 > *,
  .padding-lr-1__1200,
  .heirs-padding-lr-1__1200 > * {
    padding-right: 1rem !important;
  }
  .padding-t-1__1200,
  .heirs-padding-t-1__1200 > *,
  .padding-bt-1__1200,
  .heirs-padding-bt-1__1200 > * {
    padding-top: 1rem !important;
  }

  .padding-2__1200,
  .heirs-padding-2__1200 > * {
    padding: 2rem !important;
  }
  .padding-b-2__1200,
  .heirs-padding-b-2__1200 > *,
  .padding-bt-2__1200,
  .heirs-padding-bt-2__1200 > * {
    padding-bottom: 2rem !important;
  }
  .padding-l-2__1200,
  .heirs-padding-l-2__1200 > *,
  .padding-lr-2__1200,
  .heirs-padding-lr-2__1200 > * {
    padding-left: 2rem !important;
  }
  .padding-r-2__1200,
  .heirs-padding-r-2__1200 > *,
  .padding-lr-2__1200,
  .heirs-padding-lr-2__1200 > * {
    padding-right: 2rem !important;
  }
  .padding-t-2__1200,
  .heirs-padding-t-2__1200 > *,
  .padding-bt-2__1200,
  .heirs-padding-bt-2__1200 > * {
    padding-top: 2rem !important;
  }

  .padding-3__1200,
  .heirs-padding-3__1200 > * {
    padding: 3rem !important;
  }
  .padding-b-3__1200,
  .heirs-padding-b-3__1200 > *,
  .padding-bt-3__1200,
  .heirs-padding-bt-3__1200 > * {
    padding-bottom: 3rem !important;
  }
  .padding-l-3__1200,
  .heirs-padding-l-3__1200 > *,
  .padding-lr-3__1200,
  .heirs-padding-lr-3__1200 > * {
    padding-left: 3rem !important;
  }
  .padding-r-3__1200,
  .heirs-padding-r-3__1200 > *,
  .padding-lr-3__1200,
  .heirs-padding-lr-3__1200 > * {
    padding-right: 3rem !important;
  }
  .padding-t-3__1200,
  .heirs-padding-t-3__1200 > *,
  .padding-bt-3__1200,
  .heirs-padding-bt-3__1200 > * {
    padding-top: 3rem !important;
  }

  .padding-4__1200,
  .heirs-padding-4__1200 > * {
    padding: 4rem !important;
  }
  .padding-b-4__1200,
  .heirs-padding-b-4__1200 > *,
  .padding-bt-4__1200,
  .heirs-padding-bt-4__1200 > * {
    padding-bottom: 4rem !important;
  }
  .padding-l-4__1200,
  .heirs-padding-l-4__1200 > *,
  .padding-lr-4__1200,
  .heirs-padding-lr-4__1200 > * {
    padding-left: 4rem !important;
  }
  .padding-r-4__1200,
  .heirs-padding-r-4__1200 > *,
  .padding-lr-4__1200,
  .heirs-padding-lr-4__1200 > * {
    padding-right: 4rem !important;
  }
  .padding-t-4__1200,
  .heirs-padding-t-4__1200 > *,
  .padding-bt-4__1200,
  .heirs-padding-bt-4__1200 > * {
    padding-top: 4rem !important;
  }
}

/* styles for medium screens (900px)
   ========================================================================== */

@media all and (max-width: 900px) {
  html {
    font-size: 14px;
  }

  .cf__900:after,
  .heirs-cf__900 > *:after,
  .cf__900:before,
  .heirs-cf__900 > *:before,
  .break-after__900:after,
  .heirs-break-after__900 > *:after,
  .break-before__900:before,
  .heirs-break-before__900 > *:before {
    clear: both;
    content: "";
    display: table;
  }

  .fill__900,
  .heirs-fill__900 > * {
    height: auto;
    max-width: 100%;
  }

  .fill-and-center__900,
  .heirs-fill-and-center__900 > * {
    display: block;
    float: none;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .grid__900,
  .heirs-grid__900 > *,
  .row__900,
  .heirs-row__900 > * {
    display: block;
  }

  .inline-grid__900,
  .heirs-inline-grid__900 > * {
    display: inline-block;
  }

  .grid__900:after,
  .heirs-grid__900 > *:after,
  .grid__900:before,
  .heirs-grid__900 > *:before,
  .inline-grid__900:after,
  .heirs-inline-grid__900 > *:after,
  .inline-grid__900:before,
  .heirs-inline-grid__900 > *:before,
  .collapse__900 > *:after,
  .heirs-collapse__900 > * > *:after,
  .collapse__900 > *:before,
  .heirs-collapse__900 > * > *:before {
    clear: both;
    content: "";
    display: table;
  }

  .grid__900 > *,
  .heirs-grid__900 > * > *,
  .row__900 > *,
  .heirs-row__900 > * > *,
  .col__900,
  .heirs-col__900 > *,
  .inline-grid__900 > *,
  .heirs-inline-grid__900 > * > * {
    float: left;
    display: block;
  }

  .cols-1__900 > *:nth-child(1n + 2),
  .heirs-cols-1__900 > * > *:nth-child(1n + 2),
  .cols-2__900 > *:nth-child(1n + 2),
  .heirs-cols-2__900 > * > *:nth-child(1n + 2),
  .cols-3__900 > *:nth-child(1n + 2),
  .heirs-cols-3__900 > * > *:nth-child(1n + 2),
  .cols-4__900 > *:nth-child(1n + 2),
  .heirs-cols-4__900 > * > *:nth-child(1n + 2),
  .cols-5__900 > *:nth-child(1n + 2),
  .heirs-cols-5__900 > * > *:nth-child(1n + 2),
  .cols-6__900 > *:nth-child(1n + 2),
  .heirs-cols-6__900 > * > *:nth-child(1n + 2),
  .cols-7__900 > *:nth-child(1n + 2),
  .heirs-cols-7__900 > * > *:nth-child(1n + 2),
  .cols-8__900 > *:nth-child(1n + 2),
  .heirs-cols-8__900 > * > *:nth-child(1n + 2),
  .cols-9__900 > *:nth-child(1n + 2),
  .heirs-cols-9__900 > * > *:nth-child(1n + 2),
  .cols-10__900 > *:nth-child(1n + 2),
  .heirs-cols-10__900 > * > *:nth-child(1n + 2),
  .cols-11__900 > *:nth-child(1n + 2),
  .heirs-cols-11__900 > * > *:nth-child(1n + 2),
  .cols-12__900 > *:nth-child(1n + 2),
  .heirs-cols-12__900 > * > *:nth-child(1n + 2) {
    clear: none !important;
  }

  .cols-1__900 > *:nth-child(1n + 2),
  .heirs-cols-1__900 > * > *:nth-child(1n + 2),
  .cols-2__900 > *:nth-child(2n + 3),
  .heirs-cols-2__900 > * > *:nth-child(2n + 3),
  .cols-3__900 > *:nth-child(3n + 4),
  .heirs-cols-3__900 > * > *:nth-child(3n + 4),
  .cols-4__900 > *:nth-child(4n + 5),
  .heirs-cols-4__900 > * > *:nth-child(4n + 5),
  .cols-5__900 > *:nth-child(5n + 6),
  .heirs-cols-5__900 > * > *:nth-child(5n + 6),
  .cols-6__900 > *:nth-child(6n + 7),
  .heirs-cols-6__900 > * > *:nth-child(6n + 7),
  .cols-7__900 > *:nth-child(7n + 8),
  .heirs-cols-7__900 > * > *:nth-child(7n + 8),
  .cols-8__900 > *:nth-child(8n + 9),
  .heirs-cols-8__900 > * > *:nth-child(8n + 9),
  .cols-9__900 > *:nth-child(9n + 10),
  .heirs-cols-9__900 > * > *:nth-child(9n + 10),
  .cols-10__900 > *:nth-child(10n + 11),
  .heirs-cols-10__900 > * > *:nth-child(10n + 11),
  .cols-11__900 > *:nth-child(11n + 12),
  .heirs-cols-11__900 > * > *:nth-child(11n + 12),
  .cols-12__900 > *:nth-child(12n + 13),
  .heirs-cols-12__900 > * > *:nth-child(12n + 13) {
    clear: both !important;
  }

  .mini__900,
  .heirs-mini__900 > *,
  .mini-b__900,
  .heirs-mini-b__900 > *,
  .mini-m__900,
  .heirs-mini-m__900 > *,
  .mini-t__900,
  .heirs-mini-t__900 > * {
    display: block;
    page-break-inside: avoid;
  }

  .mini__900 > *,
  .heirs-mini__900 > * > *,
  .mini-b__900 > *,
  .heirs-mini-b__900 > * > *,
  .mini-m__900 > *,
  .heirs-mini-m__900 > * > *,
  .mini-t__900 > *,
  .heirs-mini-t__900 > * > * {
    display: table-cell;
    vertical-align: top;
  }

  .mini-b__900 > *,
  .heirs-mini-b__900 > * > * {
    vertical-align: bottom;
  }
  .mini-m__900 > *,
  .heirs-mini-m__900 > * > * {
    vertical-align: middle;
  }
  .mini-t__900 > *,
  .heirs-mini-t__900 > * > * {
    vertical-align: top;
  }

  .mini-collapse__900 > *,
  .heirs-mini-collapse__900 > * > * {
    display: block;
  }

  .table__900,
  .heirs-table__900 > *,
  .table__900 > *,
  .heirs-table__900 > * > *,
  .table__900 > * > *,
  .heirs-table__900 > * > * > * {
    border-collapse: collapse; /* remove excess spacing around table elements */
    border-spacing: 0; /* ^ */
  }

  .table__900,
  .heirs-table__900 > * {
    display: table;
  }

  .table__900 > *,
  .heirs-table__900 > * > *,
  .table-row__900 > *,
  .heirs-table-row__900 > * > * {
    display: table-row;
  }

  .table__900 > * > *,
  .heirs-table__900 > * > * > *,
  .table-col__900 > * > *,
  .heirs-table-col__900 > * > * > * {
    display: table-cell;
    vertical-align: top;
  }

  .table-collapse__900,
  .heirs-table-collapse__900 > *,
  .table-collapse__900 > *,
  .heirs-table-collapse__900 > * > *,
  .table-collapse__900 > * > *,
  .heirs-table-collapse__900 > * > * > * {
    display: block;
  }

  .flex__900,
  .heirs-flex__900 > * {
    display: flex;
  }

  .inline-flex__900,
  .heirs-inline-flex__900 > * {
    display: inline-flex;
  }

  .flex-direction-c__900,
  .heirs-flex-direction-c__900 > * {
    flex-direction: column;
  }
  .flex-direction-cr__900,
  .heirs-flex-direction-cr__900 > * {
    flex-direction: column-reverse;
  }
  .flex-direction-r__900,
  .heirs-flex-direction-r__900 > * {
    flex-direction: row;
  }
  .flex-direction-rr__900,
  .heirs-flex-direction-rr__900 > * {
    flex-direction: row-reverse;
  }

  .flex-wrap-n__900,
  .heirs-flex-wrap-n__900 > * {
    flex-wrap: nowrap;
  }
  .flex-wrap-w__900,
  .heirs-flex-wrap-w__900 > * {
    flex-wrap: wrap;
  }
  .flex-wrap-wr__900,
  .heirs-flex-wrap-wr__900 > * {
    flex-wrap: wrap-reverse;
  }

  .justify-content-c__900,
  .heirs-justify-content-c__900 > * {
    justify-content: center;
  }
  .justify-content-fe__900,
  .heirs-justify-content-fe__900 > * {
    justify-content: flex-end;
  }
  .justify-content-fs__900,
  .heirs-justify-content-fs__900 > * {
    justify-content: flex-start;
  }
  .justify-content-sa__900,
  .heirs-justify-content-sa__900 > * {
    justify-content: space-around;
  }
  .justify-content-sb__900,
  .heirs-justify-content-sb__900 > * {
    justify-content: space-between;
  }
  .justify-content-se__900,
  .heirs-justify-content-se__900 > * {
    justify-content: space-evenly;
  }

  .align-items-b__900,
  .heirs-align-items-b__900 > * {
    align-items: baseline;
  }
  .align-items-c__900,
  .heirs-align-items-c__900 > * {
    align-items: center;
  }
  .align-items-fe__900,
  .heirs-align-items-fe__900 > * {
    align-items: flex-end;
  }
  .align-items-fs__900,
  .heirs-align-items-fs__900 > * {
    align-items: flex-start;
  }
  .align-items-s__900,
  .heirs-align-items-s__900 > * {
    align-items: stretch;
  }

  .align-content-c__900,
  .heirs-align-content-c__900 > * {
    align-content: center;
  }
  .align-content-fe__900,
  .heirs-align-content-fe__900 > * {
    align-content: flex-end;
  }
  .align-content-fs__900,
  .heirs-align-content-fs__900 > * {
    align-content: flex-start;
  }
  .align-content-s__900,
  .heirs-align-content-se__900 > * {
    align-content: stretch;
  }
  .align-content-sa__900,
  .heirs-align-content-sa__900 > * {
    align-content: space-around;
  }
  .align-content-sb__900,
  .heirs-align-content-sb__900 > * {
    align-content: space-between;
  }

  .align-self-a__900,
  .heirs-align-self-a__900 > * {
    align-self: auto;
  }
  .align-self-b__900,
  .heirs-align-self-b__900 > * {
    align-self: baseline;
  }
  .align-self-c__900,
  .heirs-align-self-c__900 > * {
    align-self: center;
  }
  .align-self-fe__900,
  .heirs-align-self-fe__900 > * {
    align-self: flex-end;
  }
  .align-self-fs__900,
  .heirs-align-self-fs__900 > * {
    align-self: flex-start;
  }
  .align-self-s__900,
  .heirs-align-self-se__900 > * {
    align-self: stretch;
  }

  .flex-basis-a__900,
  .heirs-flex-basis-a__900 > * {
    flex-basis: auto;
  }

  .flex-grow-0__900,
  .heirs-flex-grow-0__900 > * {
    flex-grow: 0;
  }
  .flex-grow-1__900,
  .heirs-flex-grow-1__900 > * {
    flex-grow: 1;
  }
  .flex-grow-2__900,
  .heirs-flex-grow-2__900 > * {
    flex-grow: 2;
  }
  .flex-grow-3__900,
  .heirs-flex-grow-3__900 > * {
    flex-grow: 3;
  }
  .flex-grow-4__900,
  .heirs-flex-grow-4__900 > * {
    flex-grow: 4;
  }
  .flex-grow-5__900,
  .heirs-flex-grow-5__900 > * {
    flex-grow: 5;
  }
  .flex-grow-6__900,
  .heirs-flex-grow-6__900 > * {
    flex-grow: 6;
  }
  .flex-grow-7__900,
  .heirs-flex-grow-7__900 > * {
    flex-grow: 7;
  }
  .flex-grow-8__900,
  .heirs-flex-grow-8__900 > * {
    flex-grow: 8;
  }
  .flex-grow-9__900,
  .heirs-flex-grow-9__900 > * {
    flex-grow: 9;
  }
  .flex-grow-10__900,
  .heirs-flex-grow-10__900 > * {
    flex-grow: 10;
  }
  .flex-grow-11__900,
  .heirs-flex-grow-11__900 > * {
    flex-grow: 11;
  }
  .flex-grow-12__900,
  .heirs-flex-grow-12__900 > * {
    flex-grow: 12;
  }

  .flex-shrink-1__900,
  .heirs-flex-shrink-1__900 > * {
    flex-shrink: 1;
  }
  .flex-shrink-2__900,
  .heirs-flex-shrink-2__900 > * {
    flex-shrink: 2;
  }
  .flex-shrink-3__900,
  .heirs-flex-shrink-3__900 > * {
    flex-shrink: 3;
  }
  .flex-shrink-4__900,
  .heirs-flex-shrink-4__900 > * {
    flex-shrink: 4;
  }
  .flex-shrink-5__900,
  .heirs-flex-shrink-5__900 > * {
    flex-shrink: 5;
  }
  .flex-shrink-6__900,
  .heirs-flex-shrink-6__900 > * {
    flex-shrink: 6;
  }
  .flex-shrink-7__900,
  .heirs-flex-shrink-7__900 > * {
    flex-shrink: 7;
  }
  .flex-shrink-8__900,
  .heirs-flex-shrink-8__900 > * {
    flex-shrink: 8;
  }
  .flex-shrink-9__900,
  .heirs-flex-shrink-9__900 > * {
    flex-shrink: 9;
  }
  .flex-shrink-10__900,
  .heirs-flex-shrink-10__900 > * {
    flex-shrink: 10;
  }
  .flex-shrink-11__900,
  .heirs-flex-shrink-11__900 > * {
    flex-shrink: 11;
  }
  .flex-shrink-12__900,
  .heirs-flex-shrink-12__900 > * {
    flex-shrink: 12;
  }

  .order-0__900,
  .heirs-order-0__900 > * {
    order: 0;
  }
  .order-1__900,
  .heirs-order-1__900 > * {
    order: 1;
  }
  .order-2__900,
  .heirs-order-2__900 > * {
    order: 2;
  }
  .order-3__900,
  .heirs-order-3__900 > * {
    order: 3;
  }
  .order-4__900,
  .heirs-order-4__900 > * {
    order: 4;
  }
  .order-5__900,
  .heirs-order-5__900 > * {
    order: 5;
  }
  .order-6__900,
  .heirs-order-6__900 > * {
    order: 6;
  }
  .order-7__900,
  .heirs-order-7__900 > * {
    order: 7;
  }
  .order-8__900,
  .heirs-order-8__900 > * {
    order: 8;
  }
  .order-9__900,
  .heirs-order-9__900 > * {
    order: 9;
  }
  .order-10__900,
  .heirs-order-10__900 > * {
    order: 10;
  }
  .order-11__900,
  .heirs-order-11__900 > * {
    order: 11;
  }
  .order-12__900,
  .heirs-order-12__900 > * {
    order: 12;
  }

  .columns-a__900,
  .heirs-columns-a__900 > * {
    columns: auto;
  }
  .columns-i__900,
  .heirs-columns-i__900 > * {
    columns: inherit;
  }

  .columns-0__900,
  .heirs-columns-0__900 > * {
    columns: 0;
  }
  .columns-1__900,
  .heirs-columns-1__900 > * {
    columns: 1;
  }
  .columns-2__900,
  .heirs-columns-2__900 > * {
    columns: 2;
  }
  .columns-3__900,
  .heirs-columns-3__900 > * {
    columns: 3;
  }
  .columns-4__900,
  .heirs-columns-4__900 > * {
    columns: 4;
  }
  .columns-5__900,
  .heirs-columns-5__900 > * {
    columns: 5;
  }
  .columns-6__900,
  .heirs-columns-6__900 > * {
    columns: 6;
  }
  .columns-7__900,
  .heirs-columns-7__900 > * {
    columns: 7;
  }
  .columns-8__900,
  .heirs-columns-8__900 > * {
    columns: 8;
  }
  .columns-9__900,
  .heirs-columns-9__900 > * {
    columns: 9;
  }
  .columns-10__900,
  .heirs-columns-10__900 > * {
    columns: 10;
  }
  .columns-11__900,
  .heirs-columns-11__900 > * {
    columns: 11;
  }
  .columns-12__900,
  .heirs-columns-12__900 > * {
    columns: 12;
  }

  .column-count-a__900,
  .heirs-column-count-a__900 > * {
    column-count: auto;
  }
  .column-count-i__900,
  .heirs-column-count-i__900 > * {
    column-count: inherit;
  }

  .column-count-0__900,
  .heirs-column-count-0__900 > * {
    column-count: 0;
  }
  .column-count-1__900,
  .heirs-column-count-1__900 > * {
    column-count: 1;
  }
  .column-count-2__900,
  .heirs-column-count-2__900 > * {
    column-count: 2;
  }
  .column-count-3__900,
  .heirs-column-count-3__900 > * {
    column-count: 3;
  }
  .column-count-4__900,
  .heirs-column-count-4__900 > * {
    column-count: 4;
  }
  .column-count-5__900,
  .heirs-column-count-5__900 > * {
    column-count: 5;
  }
  .column-count-6__900,
  .heirs-column-count-6__900 > * {
    column-count: 6;
  }
  .column-count-7__900,
  .heirs-column-count-7__900 > * {
    column-count: 7;
  }
  .column-count-8__900,
  .heirs-column-count-8__900 > * {
    column-count: 8;
  }
  .column-count-9__900,
  .heirs-column-count-9__900 > * {
    column-count: 9;
  }
  .column-count-10__900,
  .heirs-column-count-10__900 > * {
    column-count: 10;
  }
  .column-count-11__900,
  .heirs-column-count-11__900 > * {
    column-count: 11;
  }
  .column-count-12__900,
  .heirs-column-count-12__900 > * {
    column-count: 12;
  }

  .column-width-a__900,
  .heirs-column-width-a__900 > * {
    column-width: auto;
  }
  .column-width-full__900,
  .heirs-column-width-full__900 > * {
    column-width: 100%;
  }
  .column-width-i__900,
  .heirs-column-width-i__900 > * {
    column-width: inherit;
  }
  .column-width-0__900,
  .heirs-column-width-0__900 > * {
    column-width: 0;
  }

  .column-width-1-2__900,
  .heirs-column-width-1-2__900 > * {
    column-width: 50%;
  }
  .column-width-1-3__900,
  .heirs-column-width-1-3__900 > * {
    column-width: 33.33333333333333%;
  }
  .column-width-1-4__900,
  .heirs-column-width-1-4__900 > * {
    column-width: 25%;
  }
  .column-width-1-5__900,
  .heirs-column-width-1-5__900 > * {
    column-width: 20%;
  }
  .column-width-1-6__900,
  .heirs-column-width-1-6__900 > * {
    column-width: 16.66666666666667%;
  }
  .column-width-1-7__900,
  .heirs-column-width-1-7__900 > * {
    column-width: 14.28571428571429%;
  }
  .column-width-1-8__900,
  .heirs-column-width-1-8__900 > * {
    column-width: 12.5%;
  }
  .column-width-1-9__900,
  .heirs-column-width-1-9__900 > * {
    column-width: 11.11111111111111%;
  }
  .column-width-1-10__900,
  .heirs-column-width-1-10__900 > * {
    column-width: 10%;
  }
  .column-width-1-11__900,
  .heirs-column-width-1-11__900 > * {
    column-width: 9.090909090909091%;
  }
  .column-width-1-12__900,
  .heirs-column-width-1-12__900 > * {
    column-width: 8.333333333333333%;
  }
  .column-width-1-20__900,
  .heirs-column-width-1-20__900 > * {
    column-width: 5%;
  }
  .column-width-2-3__900,
  .heirs-column-width-2-3__900 > * {
    column-width: 66.66666666666667%;
  }
  .column-width-2-5__900,
  .heirs-column-width-2-5__900 > * {
    column-width: 40%;
  }
  .column-width-2-7__900,
  .heirs-column-width-2-7__900 > * {
    column-width: 28.57142857142858%;
  }
  .column-width-2-9__900,
  .heirs-column-width-2-9__900 > * {
    column-width: 22.22222222222222%;
  }
  .column-width-2-11__900,
  .heirs-column-width-2-11__900 > * {
    column-width: 18.18181818181818%;
  }
  .column-width-3-4__900,
  .heirs-column-width-3-4__900 > * {
    column-width: 75%;
  }
  .column-width-3-5__900,
  .heirs-column-width-3-5__900 > * {
    column-width: 60%;
  }
  .column-width-3-7__900,
  .heirs-column-width-3-7__900 > * {
    column-width: 42.85714285714287%;
  }
  .column-width-3-8__900,
  .heirs-column-width-3-8__900 > * {
    column-width: 37.5%;
  }
  .column-width-3-10__900,
  .heirs-column-width-3-10__900 > * {
    column-width: 30%;
  }
  .column-width-3-11__900,
  .heirs-column-width-3-11__900 > * {
    column-width: 27.27272727272727%;
  }
  .column-width-4-5__900,
  .heirs-column-width-4-5__900 > * {
    column-width: 80%;
  }
  .column-width-4-7__900,
  .heirs-column-width-4-7__900 > * {
    column-width: 57.14285714285716%;
  }
  .column-width-4-9__900,
  .heirs-column-width-4-9__900 > * {
    column-width: 44.44444444444444%;
  }
  .column-width-4-11__900,
  .heirs-column-width-4-11__900 > * {
    column-width: 36.36363636363636%;
  }
  .column-width-5-6__900,
  .heirs-column-width-5-6__900 > * {
    column-width: 83.33333333333333%;
  }
  .column-width-5-7__900,
  .heirs-column-width-5-7__900 > * {
    column-width: 71.42857142857145%;
  }
  .column-width-5-8__900,
  .heirs-column-width-5-8__900 > * {
    column-width: 62.5%;
  }
  .column-width-5-9__900,
  .heirs-column-width-5-9__900 > * {
    column-width: 55.55555555555555%;
  }
  .column-width-5-11__900,
  .heirs-column-width-5-11__900 > * {
    column-width: 45.45454545454546%;
  }
  .column-width-5-12__900,
  .heirs-column-width-5-12__900 > * {
    column-width: 41.66666666666667%;
  }
  .column-width-6-7__900,
  .heirs-column-width-6-7__900 > * {
    column-width: 85.71428571428574%;
  }
  .column-width-6-11__900,
  .heirs-column-width-6-11__900 > * {
    column-width: 54.54545454545455%;
  }
  .column-width-7-8__900,
  .heirs-column-width-7-8__900 > * {
    column-width: 87.5%;
  }
  .column-width-7-9__900,
  .heirs-column-width-7-9__900 > * {
    column-width: 77.77777777777777%;
  }
  .column-width-7-10__900,
  .heirs-column-width-7-10__900 > * {
    column-width: 70%;
  }
  .column-width-7-11__900,
  .heirs-column-width-7-11__900 > * {
    column-width: 63.63636363636364%;
  }
  .column-width-7-12__900,
  .heirs-column-width-7-12__900 > * {
    column-width: 58.33333333333333%;
  }
  .column-width-8-9__900,
  .heirs-column-width-8-9__900 > * {
    column-width: 88.88888888888888%;
  }
  .column-width-8-11__900,
  .heirs-column-width-8-11__900 > * {
    column-width: 72.72727272727273%;
  }
  .column-width-9-10__900,
  .heirs-column-width-9-10__900 > * {
    column-width: 90%;
  }
  .column-width-9-11__900,
  .heirs-column-width-9-11__900 > * {
    column-width: 81.81818181818182%;
  }
  .column-width-10-11__900,
  .heirs-column-width-10-11__900 > * {
    column-width: 90.90909090909091%;
  }
  .column-width-11-12__900,
  .heirs-column-width-11-12__900 > * {
    column-width: 91.66666666666666%;
  }

  .arrow-after__900:after,
  .heirs-arrow-after__900 > *:after,
  .heirs-arrow-between__900 > *:not(:first-child):before,
  .arrow-l-after__900:after,
  .heirs-arrow-l-after__900 > *:after,
  .heirs-arrow-l-between__900 > *:not(:first-child):before,
  .bar-after__900:after,
  .heirs-bar-after__900 > *:after,
  .heirs-bar-between__900 > *:not(:first-child):before,
  .bullet-after__900:after,
  .heirs-bullet-after__900 > *:after,
  .heirs-bullet-between__900 > *:not(:first-child):before,
  .chevron-after__900:after,
  .heirs-chevron-after__900 > *:after,
  .heirs-chevron-between__900 > *:not(:first-child):before,
  .chevron-l-after__900:after,
  .heirs-chevron-l-after__900 > *:after,
  .heirs-chevron-l-between__900 > *:not(:first-child):before,
  .square-after__900:after,
  .heirs-square-after__900 > *:after,
  .heirs-square-between__900 > *:not(:first-child):before {
    display: inline;
    margin-left: 0.25rem;
  }

  .arrow-before__900:before,
  .heirs-arrow-before__900 > *:before,
  .heirs-arrow-between__900 > *:not(:first-child):before,
  .arrow-l-before__900:before,
  .heirs-arrow-l-before__900 > *:before,
  .heirs-arrow-l-between__900 > *:not(:first-child):before,
  .bar-before__900:before,
  .heirs-bar-before__900 > *:before,
  .heirs-bar-between__900 > *:not(:first-child):before,
  .bullet-before__900:before,
  .heirs-bullet-before__900 > *:before,
  .heirs-bullet-between__900 > *:not(:first-child):before,
  .chevron-before__900:before,
  .heirs-chevron-before__900 > *:before,
  .heirs-chevron-between__900 > *:not(:first-child):before,
  .chevron-l-before__900:before,
  .heirs-chevron-l-before__900 > *:before,
  .heirs-chevron-l-between__900 > *:not(:first-child):before,
  .square-before__900:before,
  .heirs-square-before__900 > *:before,
  .heirs-square-between__900 > *:not(:first-child):before {
    display: inline;
    margin-right: 0.25rem;
  }

  .arrow-after__900:after,
  .heirs-arrow-after__900 > *:after,
  .arrow-before__900:before,
  .heirs-arrow-before__900 > *:before,
  .heirs-arrow-between__900 > *:not(:first-child):before {
    content: "›";
  }

  .arrow-l-after__900:after,
  .heirs-arrow-l-after__900 > *:after,
  .arrow-l-before__900:before,
  .heirs-arrow-l-before__900 > *:before,
  .heirs-arrow-l-between__900 > *:not(:first-child):before {
    content: "‹";
  }

  .bar-after__900:after,
  .heirs-bar-after__900 > *:after,
  .bar-before__900:before,
  .heirs-bar-before__900 > *:before,
  .heirs-bar-between__900 > *:not(:first-child):before {
    content: "|";
  }

  .bullet-after__900:after,
  .heirs-bullet-after__900 > *:after,
  .bullet-before__900:before,
  .heirs-bullet-before__900 > *:before,
  .heirs-bullet-between__900 > *:not(:first-child):before {
    content: "•";
    font-family: Arial;
  }

  .chevron-after__900:after,
  .heirs-chevron-after__900 > *:after,
  .chevron-before__900:before,
  .heirs-chevron-before__900 > *:before,
  .heirs-chevron-between__900 > *:not(:first-child):before {
    content: "»";
  }

  .chevron-l-after__900:after,
  .heirs-chevron-l-after__900 > *:after,
  .chevron-l-before__900:before,
  .heirs-chevron-l-before__900 > *:before,
  .heirs-chevron-l-between__900 > *:not(:first-child):before {
    content: "«";
  }

  .square-after__900:after,
  .heirs-square-after__900 > *:after,
  .square-before__900:before,
  .heirs-square-before__900 > *:before,
  .heirs-square-between__900 > *:not(:first-child):before {
    content: "■";
    font-family: Arial;
  }

  .nothing-after__900:after,
  .heirs-nothing-after__900 > *:after,
  .nothing-before__900:before,
  .heirs-nothing-before__900 > *:before,
  .heirs-nothing-between__900 > *:not(:first-child):before {
    content: "";
    margin-left: 0;
    margin-right: 0;
  }

  .font-size-i__900,
  .heirs-font-size-i__900 > * {
    font-size: inherit !important;
  }

  .font-size-0__900,
  .heirs-font-size-0__900 > * {
    font-size: 0 !important;
  }
  .font-size-1-8__900,
  .heirs-font-size-1-8__900 > * {
    font-size: 0.125rem !important;
  }
  .font-size-1-4__900,
  .heirs-font-size-1-4__900 > * {
    font-size: 0.25rem !important;
  }
  .font-size-3-8__900,
  .heirs-font-size-3-8__900 > * {
    font-size: 0.375rem !important;
  }
  .font-size-1-2__900,
  .heirs-font-size-1-2__900 > * {
    font-size: 0.5rem !important;
  }
  .font-size-5-8__900,
  .heirs-font-size-5-8__900 > * {
    font-size: 0.625rem !important;
  }
  .font-size-3-4__900,
  .heirs-font-size-3-4__900 > * {
    font-size: 0.75rem !important;
  }
  .font-size-7-8__900,
  .heirs-font-size-7-8__900 > * {
    font-size: 0.875rem !important;
  }

  .font-size-1__900,
  .heirs-font-size-1__900 > * {
    font-size: 1rem !important;
  }
  .font-size-1-1-8__900,
  .heirs-font-size-1-1-8__900 > * {
    font-size: 1.125rem !important;
  }
  .font-size-1-1-4__900,
  .heirs-font-size-1-1-4__900 > * {
    font-size: 1.25rem !important;
  }
  .font-size-1-3-8__900,
  .heirs-font-size-1-3-8__900 > * {
    font-size: 1.375rem !important;
  }
  .font-size-1-1-2__900,
  .heirs-font-size-1-1-2__900 > * {
    font-size: 1.5rem !important;
  }
  .font-size-1-5-8__900,
  .heirs-font-size-1-5-8__900 > * {
    font-size: 1.625rem !important;
  }
  .font-size-1-3-4__900,
  .heirs-font-size-1-3-4__900 > * {
    font-size: 1.75rem !important;
  }
  .font-size-1-7-8__900,
  .heirs-font-size-1-7-8__900 > * {
    font-size: 1.875rem !important;
  }

  .font-size-2__900,
  .heirs-font-size-2__900 > * {
    font-size: 2rem !important;
  }
  .font-size-2-1-8__900,
  .heirs-font-size-2-1-8__900 > * {
    font-size: 2.125rem !important;
  }
  .font-size-2-1-4__900,
  .heirs-font-size-2-1-4__900 > * {
    font-size: 2.25rem !important;
  }
  .font-size-2-3-8__900,
  .heirs-font-size-2-3-8__900 > * {
    font-size: 2.375rem !important;
  }
  .font-size-2-1-2__900,
  .heirs-font-size-2-1-2__900 > * {
    font-size: 2.5rem !important;
  }
  .font-size-2-5-8__900,
  .heirs-font-size-2-5-8__900 > * {
    font-size: 2.625rem !important;
  }
  .font-size-2-3-4__900,
  .heirs-font-size-2-3-4__900 > * {
    font-size: 2.75rem !important;
  }
  .font-size-2-7-8__900,
  .heirs-font-size-2-7-8__900 > * {
    font-size: 2.875rem !important;
  }

  .font-size-3__900,
  .heirs-font-size-3__900 > * {
    font-size: 3rem !important;
  }
  .font-size-3-1-8__900,
  .heirs-font-size-3-1-8__900 > * {
    font-size: 3.125rem !important;
  }
  .font-size-3-1-4__900,
  .heirs-font-size-3-1-4__900 > * {
    font-size: 3.25rem !important;
  }
  .font-size-3-3-8__900,
  .heirs-font-size-3-3-8__900 > * {
    font-size: 3.375rem !important;
  }
  .font-size-3-1-2__900,
  .heirs-font-size-3-1-2__900 > * {
    font-size: 3.5rem !important;
  }
  .font-size-3-5-8__900,
  .heirs-font-size-3-5-8__900 > * {
    font-size: 3.625rem !important;
  }
  .font-size-3-3-4__900,
  .heirs-font-size-3-3-4__900 > * {
    font-size: 3.75rem !important;
  }
  .font-size-3-7-8__900,
  .heirs-font-size-3-7-8__900 > * {
    font-size: 3.875rem !important;
  }

  .font-size-4__900,
  .heirs-font-size-4__900 > * {
    font-size: 4rem !important;
  }
  .font-size-4-1-8__900,
  .heirs-font-size-4-1-8__900 > * {
    font-size: 4.125rem !important;
  }
  .font-size-4-1-4__900,
  .heirs-font-size-4-1-4__900 > * {
    font-size: 4.25rem !important;
  }
  .font-size-4-3-8__900,
  .heirs-font-size-4-3-8__900 > * {
    font-size: 4.375rem !important;
  }
  .font-size-4-1-2__900,
  .heirs-font-size-4-1-2__900 > * {
    font-size: 4.5rem !important;
  }
  .font-size-4-5-8__900,
  .heirs-font-size-4-5-8__900 > * {
    font-size: 4.625rem !important;
  }
  .font-size-4-3-4__900,
  .heirs-font-size-4-3-4__900 > * {
    font-size: 4.75rem !important;
  }
  .font-size-4-7-8__900,
  .heirs-font-size-4-7-8__900 > * {
    font-size: 4.875rem !important;
  }

  .font-style-i__900,
  .heirs-font-style-i__900 > * {
    font-style: italic !important;
  }
  .font-style-n__900,
  .heirs-font-style-n__900 > * {
    font-style: normal !important;
  }

  .font-weight-200__900,
  .heirs-font-weight-200__900 > * {
    font-weight: 200 !important;
  }
  .font-weight-300__900,
  .heirs-font-weight-300__900 > * {
    font-weight: 300 !important;
  }
  .font-weight-400__900,
  .heirs-font-weight-400__900 > * {
    font-weight: 400 !important;
  }
  .font-weight-500__900,
  .heirs-font-weight-500__900 > * {
    font-weight: 500 !important;
  }
  .font-weight-600__900,
  .heirs-font-weight-600__900 > * {
    font-weight: 600 !important;
  }

  .font-family-monospace__900,
  .heirs-font-family-monospace__900 > * {
    font-family: Consolas, Monaco, monospace !important;
  }
  .font-family-sans-serif__900,
  .heirs-font-family-sans-serif__900 > * {
    font-family: Arial, sans-serif !important;
  }
  .font-family-serif__900,
  .heirs-font-family-serif__900 > * {
    font-family: Times, serif !important;
  }

  .hyphens-a__900,
  .heirs-hyphens-a__900 > * {
    -ms-hyphens: auto !important;
    -webkit-hyphens: auto !important;
    hyphens: auto !important;
  }
  .hyphens-m__900,
  .heirs-hyphens-m__900 > * {
    -ms-hyphens: manual !important;
    -webkit-hyphens: manual !important;
    hyphens: manual !important;
  }
  .hyphens-n__900,
  .heirs-hyphens-n__900 > * {
    -ms-hyphens: none !important;
    -webkit-hyphens: none !important;
    hyphens: none !important;
  }

  .line-height-i__900,
  .heirs-line-height-i__900 > * {
    line-height: inherit !important;
  }
  .line-height-1w__900,
  .heirs-line-height-1w__900 > * {
    line-height: 1 !important;
  }

  .line-height-0__900,
  .heirs-line-height-0__900 > * {
    line-height: 0 !important;
  }
  .line-height-1-8__900,
  .heirs-line-height-1-8__900 > * {
    line-height: 0.125rem !important;
  }
  .line-height-1-4__900,
  .heirs-line-height-1-4__900 > * {
    line-height: 0.25rem !important;
  }
  .line-height-3-8__900,
  .heirs-line-height-3-8__900 > * {
    line-height: 0.375rem !important;
  }
  .line-height-1-2__900,
  .heirs-line-height-1-2__900 > * {
    line-height: 0.5rem !important;
  }
  .line-height-5-8__900,
  .heirs-line-height-5-8__900 > * {
    line-height: 0.625rem !important;
  }
  .line-height-3-4__900,
  .heirs-line-height-3-4__900 > * {
    line-height: 0.75rem !important;
  }
  .line-height-7-8__900,
  .heirs-line-height-7-8__900 > * {
    line-height: 0.875rem !important;
  }

  .line-height-1__900,
  .heirs-line-height-1__900 > * {
    line-height: 1rem !important;
  }
  .line-height-1-1-8__900,
  .heirs-line-height-1-1-8__900 > * {
    line-height: 1.125rem !important;
  }
  .line-height-1-1-4__900,
  .heirs-line-height-1-1-4__900 > * {
    line-height: 1.25rem !important;
  }
  .line-height-1-3-8__900,
  .heirs-line-height-1-3-8__900 > * {
    line-height: 1.375rem !important;
  }
  .line-height-1-1-2__900,
  .heirs-line-height-1-1-2__900 > * {
    line-height: 1.5rem !important;
  }
  .line-height-1-5-8__900,
  .heirs-line-height-1-5-8__900 > * {
    line-height: 1.625rem !important;
  }
  .line-height-1-3-4__900,
  .heirs-line-height-1-3-4__900 > * {
    line-height: 1.75rem !important;
  }
  .line-height-1-7-8__900,
  .heirs-line-height-1-7-8__900 > * {
    line-height: 1.875rem !important;
  }

  .line-height-2__900,
  .heirs-line-height-2__900 > * {
    line-height: 2rem !important;
  }
  .line-height-2-1-8__900,
  .heirs-line-height-2-1-8__900 > * {
    line-height: 2.125rem !important;
  }
  .line-height-2-1-4__900,
  .heirs-line-height-2-1-4__900 > * {
    line-height: 2.25rem !important;
  }
  .line-height-2-3-8__900,
  .heirs-line-height-2-3-8__900 > * {
    line-height: 2.375rem !important;
  }
  .line-height-2-1-2__900,
  .heirs-line-height-2-1-2__900 > * {
    line-height: 2.5rem !important;
  }
  .line-height-2-5-8__900,
  .heirs-line-height-2-5-8__900 > * {
    line-height: 2.625rem !important;
  }
  .line-height-2-3-4__900,
  .heirs-line-height-2-3-4__900 > * {
    line-height: 2.75rem !important;
  }
  .line-height-2-7-8__900,
  .heirs-line-height-2-7-8__900 > * {
    line-height: 2.875rem !important;
  }

  .line-height-3__900,
  .heirs-line-height-3__900 > * {
    line-height: 3rem !important;
  }
  .line-height-3-1-8__900,
  .heirs-line-height-3-1-8__900 > * {
    line-height: 3.125rem !important;
  }
  .line-height-3-1-4__900,
  .heirs-line-height-3-1-4__900 > * {
    line-height: 3.25rem !important;
  }
  .line-height-3-3-8__900,
  .heirs-line-height-3-3-8__900 > * {
    line-height: 3.375rem !important;
  }
  .line-height-3-1-2__900,
  .heirs-line-height-3-1-2__900 > * {
    line-height: 3.5rem !important;
  }
  .line-height-3-5-8__900,
  .heirs-line-height-3-5-8__900 > * {
    line-height: 3.625rem !important;
  }
  .line-height-3-3-4__900,
  .heirs-line-height-3-3-4__900 > * {
    line-height: 3.75rem !important;
  }
  .line-height-3-7-8__900,
  .heirs-line-height-3-7-8__900 > * {
    line-height: 3.875rem !important;
  }

  .line-height-4__900,
  .heirs-line-height-4__900 > * {
    line-height: 4rem !important;
  }
  .line-height-4-1-8__900,
  .heirs-line-height-4-1-8__900 > * {
    line-height: 4.125rem !important;
  }
  .line-height-4-1-4__900,
  .heirs-line-height-4-1-4__900 > * {
    line-height: 4.25rem !important;
  }
  .line-height-4-3-8__900,
  .heirs-line-height-4-3-8__900 > * {
    line-height: 4.375rem !important;
  }
  .line-height-4-1-2__900,
  .heirs-line-height-4-1-2__900 > * {
    line-height: 4.5rem !important;
  }
  .line-height-4-5-8__900,
  .heirs-line-height-4-5-8__900 > * {
    line-height: 4.625rem !important;
  }
  .line-height-4-3-4__900,
  .heirs-line-height-4-3-4__900 > * {
    line-height: 4.75rem !important;
  }
  .line-height-4-7-8__900,
  .heirs-line-height-4-7-8__900 > * {
    line-height: 4.875rem !important;
  }

  .text-align-c__900,
  .heirs-text-align-c__900 > * {
    text-align: center !important;
  }
  .text-align-j__900,
  .heirs-text-align-j__900 > * {
    text-align: justify !important;
  }
  .text-align-l__900,
  .heirs-text-align-l__900 > * {
    text-align: left !important;
  }
  .text-align-r__900,
  .heirs-text-align-r__900 > * {
    text-align: right !important;
  }

  .text-decoration-lt__900,
  .heirs-text-decoration-lt__900 > * {
    text-decoration: line-through !important;
  }
  .text-decoration-o__900,
  .heirs-text-decoration-o__900 > * {
    text-decoration: overline !important;
  }
  .text-decoration-u__900,
  .heirs-text-decoration-u__900 > * {
    text-decoration: underline !important;
  }
  .text-decoration-uo__900,
  .heirs-text-decoration-uo__900 > * {
    text-decoration: underline overline !important;
  }
  .text-decoration-n__900,
  .heirs-text-decoration-n__900 > * {
    text-decoration: none !important;
  }

  .text-transform-l__900,
  .heirs-text-transform-l__900 > * {
    text-transform: lowercase !important;
  }
  .text-transform-u__900,
  .heirs-text-transform-u__900 > * {
    text-transform: uppercase !important;
  }
  .text-transform-n__900,
  .heirs-text-transform-n__900 > * {
    text-transform: none !important;
  }

  .white-space-n__900,
  .heirs-white-space-n__900 > * {
    white-space: normal !important;
  }
  .white-space-nw__900,
  .heirs-white-space-nw__900 > * {
    white-space: nowrap !important;
  }
  .white-space-p__900,
  .heirs-white-space-p__900 > * {
    white-space: pre !important;
  }
  .white-space-pl__900,
  .heirs-white-space-pl__900 > * {
    white-space: pre-line !important;
  }
  .white-space-pw__900,
  .heirs-white-space-pw__900 > * {
    white-space: pre-wrap !important;
  }

  .word-break-ba__900,
  .heirs-word-break-ba__900 > * {
    word-break: break-all !important;
  }
  .word-break-ka__900,
  .heirs-word-break-ka__900 > * {
    word-break: keep-all !important;
  }
  .word-break-n__900,
  .heirs-word-break-n__900 > * {
    word-break: normal !important;
  }

  .word-wrap-bw__900,
  .heirs-word-wrap-bw__900 > * {
    word-wrap: break-word !important;
  }
  .word-wrap-n__900,
  .heirs-word-wrap-n__900 > * {
    word-wrap: normal !important;
  }

  .display-b__900,
  .heirs-display-b__900 > *,
  .show__900,
  .heirs-show__900 > * {
    display: block !important;
  }
  .display-i__900,
  .heirs-display-i__900 > * {
    display: inline !important;
  }
  .display-ib__900,
  .heirs-display-ib__900 > * {
    display: inline-block !important;
  }
  .display-it__900,
  .heirs-display-it__900 > * {
    display: inline-table !important;
  }
  .display-li__900,
  .heirs-display-li__900 > * {
    display: list-item !important;
  }
  .display-tcap__900,
  .heirs-display-tcap__900 > * {
    display: table-caption !important;
  }
  .display-td__900,
  .heirs-display-td__900 > * {
    display: table-cell !important;
  }
  .display-tcg__900,
  .heirs-display-tcg__900 > * {
    display: table-column-group !important;
  }
  .display-tc__900,
  .heirs-display-tc__900 > * {
    display: table-column !important;
  }
  .display-tfg__900,
  .heirs-display-tfg__900 > * {
    display: table-footer-group !important;
  }
  .display-thg__900,
  .heirs-display-thg__900 > * {
    display: table-header-group !important;
  }
  .display-trg__900,
  .heirs-display-trg__900 > * {
    display: table-row-group !important;
  }
  .display-tr__900,
  .heirs-display-tr__900 > * {
    display: table-row !important;
  }
  .display-t__900,
  .heirs-display-t__900 > * {
    display: table !important;
  }
  .display-n__900,
  .heirs-display-n__900 > *,
  .hide__900,
  .heirs-hide__900 > * {
    display: none !important;
  }

  .opacity-0__900,
  .heirs-opacity-0__900 > * {
    opacity: 0 !important;
  }
  .opacity-1__900,
  .heirs-opacity-1__900 > * {
    opacity: 1 !important;
  }

  .visibility-h__900,
  .heirs-visibility-h__900 > *,
  .hidden__900,
  .heirs-hidden__900 > * {
    visibility: hidden !important;
  }
  .visibility-v__900,
  .heirs-visibility-v__900 > *,
  .visible__900,
  .heirs-visible__900 > * {
    visibility: visible !important;
  }

  .clear-l__900,
  .heirs-clear-l__900 > * {
    clear: left !important;
  }
  .clear-r__900,
  .heirs-clear-r__900 > * {
    clear: right !important;
  }
  .clear-b__900,
  .heirs-clear-b__900 > * {
    clear: both !important;
  }
  .clear-n__900,
  .heirs-clear-n__900 > * {
    clear: none !important;
  }

  .float-l__900,
  .heirs-float-l__900 > * {
    float: left !important;
  }
  .float-r__900,
  .heirs-float-r__900 > * {
    float: right !important;
  }
  .float-n__900,
  .heirs-float-n__900 > * {
    float: none !important;
  }

  .position-a__900,
  .heirs-position-a__900 > * {
    position: absolute !important;
  }
  .position-f__900,
  .heirs-position-f__900 > * {
    position: fixed !important;
  }
  .position-r__900,
  .heirs-position-r__900 > * {
    position: relative !important;
  }
  .position-s__900,
  .heirs-position-s__900 > * {
    position: static !important;
  }

  .vertical-align-bl__900,
  .heirs-vertical-align-bl__900 > * {
    vertical-align: baseline !important;
  }
  .vertical-align-b__900,
  .heirs-vertical-align-b__900 > * {
    vertical-align: bottom !important;
  }
  .vertical-align-m__900,
  .heirs-vertical-align-m__900 > * {
    vertical-align: middle !important;
  }
  .vertical-align-sub__900,
  .heirs-vertical-align-sub__900 > * {
    vertical-align: sub !important;
  }
  .vertical-align-sup__900,
  .heirs-vertical-align-sup__900 > * {
    vertical-align: super !important;
  }
  .vertical-align-tb__900,
  .heirs-vertical-align-tb__900 > * {
    vertical-align: text-bottom !important;
  }
  .vertical-align-tt__900,
  .heirs-vertical-align-tt__900 > * {
    vertical-align: text-top !important;
  }
  .vertical-align-t__900,
  .heirs-vertical-align-t__900 > * {
    vertical-align: top !important;
  }

  .height-a__900,
  .heirs-height-a__900 > * {
    height: auto !important;
  }
  .height-full__900,
  .heirs-height-full__900 > * {
    height: 100% !important;
  }
  .height-i__900,
  .heirs-height-i__900 > * {
    height: inherit !important;
  }
  .height-0__900,
  .heirs-height-0__900 > * {
    height: 0 !important;
  }
  .height-1__900,
  .heirs-height-1__900 > * {
    height: 1px !important;
  }

  .max-height-full__900,
  .heirs-max-height-full__900 > * {
    max-height: 100% !important;
  }
  .max-height-i__900,
  .heirs-max-height-i__900 > * {
    max-height: inherit !important;
  }
  .max-height-0__900,
  .heirs-max-height-0__900 > * {
    max-height: 0 !important;
  }
  .max-height-1__900,
  .heirs-max-height-1__900 > * {
    max-height: 1px !important;
  }

  .min-height-a__900,
  .heirs-min-height-a__900 > * {
    min-height: auto !important;
  }
  .min-height-full__900,
  .heirs-min-height-full__900 > * {
    min-height: 100% !important;
  }
  .min-height-i__900,
  .heirs-min-height-i__900 > * {
    min-height: inherit !important;
  }
  .min-height-0__900,
  .heirs-min-height-0__900 > * {
    min-height: 0 !important;
  }
  .min-height-1__900,
  .heirs-min-height-1__900 > * {
    min-height: 1px !important;
  }

  .width-a__900,
  .heirs-width-a__900 > * {
    width: auto !important;
  }
  .width-full__900,
  .heirs-width-full__900 > * {
    width: 100% !important;
  }
  .width-i__900,
  .heirs-width-i__900 > * {
    width: inherit !important;
  }
  .width-0__900,
  .heirs-width-0__900 > * {
    width: 0 !important;
  }

  .width-1200__900,
  .heirs-width-1200__900 > * {
    width: 1200px !important;
  }
  .width-900__900,
  .heirs-width-900__900 > * {
    width: 900px !important;
  }
  .width-600__900,
  .heirs-width-600__900 > * {
    width: 600px !important;
  }
  .width-320__900,
  .heirs-width-320__900 > * {
    width: 320px !important;
  }

  .width-1-2__900,
  .heirs-width-1-2__900 > * {
    width: 50% !important;
  }
  .width-1-3__900,
  .heirs-width-1-3__900 > * {
    width: 33.33333333333333% !important;
  }
  .width-1-4__900,
  .heirs-width-1-4__900 > * {
    width: 25% !important;
  }
  .width-1-5__900,
  .heirs-width-1-5__900 > * {
    width: 20% !important;
  }
  .width-1-6__900,
  .heirs-width-1-6__900 > * {
    width: 16.66666666666667% !important;
  }
  .width-1-7__900,
  .heirs-width-1-7__900 > * {
    width: 14.28571428571429% !important;
  }
  .width-1-8__900,
  .heirs-width-1-8__900 > * {
    width: 12.5% !important;
  }
  .width-1-9__900,
  .heirs-width-1-9__900 > * {
    width: 11.11111111111111% !important;
  }
  .width-1-10__900,
  .heirs-width-1-10__900 > * {
    width: 10% !important;
  }
  .width-1-11__900,
  .heirs-width-1-11__900 > * {
    width: 9.090909090909091% !important;
  }
  .width-1-12__900,
  .heirs-width-1-12__900 > * {
    width: 8.333333333333333% !important;
  }
  .width-1-20__900,
  .heirs-width-1-20__900 > * {
    width: 5% !important;
  }
  .width-2-3__900,
  .heirs-width-2-3__900 > * {
    width: 66.66666666666667% !important;
  }
  .width-2-5__900,
  .heirs-width-2-5__900 > * {
    width: 40% !important;
  }
  .width-2-7__900,
  .heirs-width-2-7__900 > * {
    width: 28.57142857142858% !important;
  }
  .width-2-9__900,
  .heirs-width-2-9__900 > * {
    width: 22.22222222222222% !important;
  }
  .width-2-11__900,
  .heirs-width-2-11__900 > * {
    width: 18.18181818181818% !important;
  }
  .width-3-4__900,
  .heirs-width-3-4__900 > * {
    width: 75% !important;
  }
  .width-3-5__900,
  .heirs-width-3-5__900 > * {
    width: 60% !important;
  }
  .width-3-7__900,
  .heirs-width-3-7__900 > * {
    width: 42.85714285714287% !important;
  }
  .width-3-8__900,
  .heirs-width-3-8__900 > * {
    width: 37.5% !important;
  }
  .width-3-10__900,
  .heirs-width-3-10__900 > * {
    width: 30% !important;
  }
  .width-3-11__900,
  .heirs-width-3-11__900 > * {
    width: 27.27272727272727% !important;
  }
  .width-4-5__900,
  .heirs-width-4-5__900 > * {
    width: 80% !important;
  }
  .width-4-7__900,
  .heirs-width-4-7__900 > * {
    width: 57.14285714285716% !important;
  }
  .width-4-9__900,
  .heirs-width-4-9__900 > * {
    width: 44.44444444444444% !important;
  }
  .width-4-11__900,
  .heirs-width-4-11__900 > * {
    width: 36.36363636363636% !important;
  }
  .width-5-6__900,
  .heirs-width-5-6__900 > * {
    width: 83.33333333333333% !important;
  }
  .width-5-7__900,
  .heirs-width-5-7__900 > * {
    width: 71.42857142857145% !important;
  }
  .width-5-8__900,
  .heirs-width-5-8__900 > * {
    width: 62.5% !important;
  }
  .width-5-9__900,
  .heirs-width-5-9__900 > * {
    width: 55.55555555555555% !important;
  }
  .width-5-11__900,
  .heirs-width-5-11__900 > * {
    width: 45.45454545454546% !important;
  }
  .width-5-12__900,
  .heirs-width-5-12__900 > * {
    width: 41.66666666666667% !important;
  }
  .width-6-7__900,
  .heirs-width-6-7__900 > * {
    width: 85.71428571428574% !important;
  }
  .width-6-11__900,
  .heirs-width-6-11__900 > * {
    width: 54.54545454545455% !important;
  }
  .width-7-8__900,
  .heirs-width-7-8__900 > * {
    width: 87.5% !important;
  }
  .width-7-9__900,
  .heirs-width-7-9__900 > * {
    width: 77.77777777777777% !important;
  }
  .width-7-10__900,
  .heirs-width-7-10__900 > * {
    width: 70% !important;
  }
  .width-7-11__900,
  .heirs-width-7-11__900 > * {
    width: 63.63636363636364% !important;
  }
  .width-7-12__900,
  .heirs-width-7-12__900 > * {
    width: 58.33333333333333% !important;
  }
  .width-8-9__900,
  .heirs-width-8-9__900 > * {
    width: 88.88888888888888% !important;
  }
  .width-8-11__900,
  .heirs-width-8-11__900 > * {
    width: 72.72727272727273% !important;
  }
  .width-9-10__900,
  .heirs-width-9-10__900 > * {
    width: 90% !important;
  }
  .width-9-11__900,
  .heirs-width-9-11__900 > * {
    width: 81.81818181818182% !important;
  }
  .width-10-11__900,
  .heirs-width-10-11__900 > * {
    width: 90.90909090909091% !important;
  }
  .width-11-12__900,
  .heirs-width-11-12__900 > * {
    width: 91.66666666666666% !important;
  }

  .max-width-full__900,
  .heirs-max-width-full__900 > * {
    max-width: 100% !important;
  }
  .max-width-i__900,
  .heirs-max-width-i__900 > * {
    max-width: inherit !important;
  }
  .max-width-n__900,
  .heirs-max-width-n__900 > * {
    max-width: none !important;
  }
  .max-width-0__900,
  .heirs-max-width-0__900 > * {
    max-width: 0 !important;
  }

  .max-width-1200__900,
  .heirs-max-width-1200__900 > * {
    max-width: 1200px !important;
  }
  .max-width-900__900,
  .heirs-max-width-900__900 > * {
    max-width: 900px !important;
  }
  .max-width-600__900,
  .heirs-max-width-600__900 > * {
    max-width: 600px !important;
  }
  .max-width-320__900,
  .heirs-max-width-320__900 > * {
    max-width: 320px !important;
  }

  .max-width-1-2__900,
  .heirs-max-width-1-2__900 > * {
    max-width: 50% !important;
  }
  .max-width-1-3__900,
  .heirs-max-width-1-3__900 > * {
    max-width: 33.33333333333333% !important;
  }
  .max-width-1-4__900,
  .heirs-max-width-1-4__900 > * {
    max-width: 25% !important;
  }
  .max-width-1-5__900,
  .heirs-max-width-1-5__900 > * {
    max-width: 20% !important;
  }
  .max-width-1-6__900,
  .heirs-max-width-1-6__900 > * {
    max-width: 16.66666666666667% !important;
  }
  .max-width-1-7__900,
  .heirs-max-width-1-7__900 > * {
    max-width: 14.28571428571429% !important;
  }
  .max-width-1-8__900,
  .heirs-max-width-1-8__900 > * {
    max-width: 12.5% !important;
  }
  .max-width-1-9__900,
  .heirs-max-width-1-9__900 > * {
    max-width: 11.11111111111111% !important;
  }
  .max-width-1-10__900,
  .heirs-max-width-1-10__900 > * {
    max-width: 10% !important;
  }
  .max-width-1-11__900,
  .heirs-max-width-1-11__900 > * {
    max-width: 9.090909090909091% !important;
  }
  .max-width-1-12__900,
  .heirs-max-width-1-12__900 > * {
    max-width: 8.333333333333333% !important;
  }
  .max-width-1-20__900,
  .heirs-max-width-1-20__900 > * {
    max-width: 5% !important;
  }
  .max-width-2-3__900,
  .heirs-max-width-2-3__900 > * {
    max-width: 66.66666666666667% !important;
  }
  .max-width-2-5__900,
  .heirs-max-width-2-5__900 > * {
    max-width: 40% !important;
  }
  .max-width-2-7__900,
  .heirs-max-width-2-7__900 > * {
    max-width: 28.57142857142858% !important;
  }
  .max-width-2-9__900,
  .heirs-max-width-2-9__900 > * {
    max-width: 22.22222222222222% !important;
  }
  .max-width-2-11__900,
  .heirs-max-width-2-11__900 > * {
    max-width: 18.18181818181818% !important;
  }
  .max-width-3-4__900,
  .heirs-max-width-3-4__900 > * {
    max-width: 75% !important;
  }
  .max-width-3-5__900,
  .heirs-max-width-3-5__900 > * {
    max-width: 60% !important;
  }
  .max-width-3-7__900,
  .heirs-max-width-3-7__900 > * {
    max-width: 42.85714285714287% !important;
  }
  .max-width-3-8__900,
  .heirs-max-width-3-8__900 > * {
    max-width: 37.5% !important;
  }
  .max-width-3-10__900,
  .heirs-max-width-3-10__900 > * {
    max-width: 30% !important;
  }
  .max-width-3-11__900,
  .heirs-max-width-3-11__900 > * {
    max-width: 27.27272727272727% !important;
  }
  .max-width-4-5__900,
  .heirs-max-width-4-5__900 > * {
    max-width: 80% !important;
  }
  .max-width-4-7__900,
  .heirs-max-width-4-7__900 > * {
    max-width: 57.14285714285716% !important;
  }
  .max-width-4-9__900,
  .heirs-max-width-4-9__900 > * {
    max-width: 44.44444444444444% !important;
  }
  .max-width-4-11__900,
  .heirs-max-width-4-11__900 > * {
    max-width: 36.36363636363636% !important;
  }
  .max-width-5-6__900,
  .heirs-max-width-5-6__900 > * {
    max-width: 83.33333333333333% !important;
  }
  .max-width-5-7__900,
  .heirs-max-width-5-7__900 > * {
    max-width: 71.42857142857145% !important;
  }
  .max-width-5-8__900,
  .heirs-max-width-5-8__900 > * {
    max-width: 62.5% !important;
  }
  .max-width-5-9__900,
  .heirs-max-width-5-9__900 > * {
    max-width: 55.55555555555555% !important;
  }
  .max-width-5-11__900,
  .heirs-max-width-5-11__900 > * {
    max-width: 45.45454545454546% !important;
  }
  .max-width-5-12__900,
  .heirs-max-width-5-12__900 > * {
    max-width: 41.66666666666667% !important;
  }
  .max-width-6-7__900,
  .heirs-max-width-6-7__900 > * {
    max-width: 85.71428571428574% !important;
  }
  .max-width-6-11__900,
  .heirs-max-width-6-11__900 > * {
    max-width: 54.54545454545455% !important;
  }
  .max-width-7-8__900,
  .heirs-max-width-7-8__900 > * {
    max-width: 87.5% !important;
  }
  .max-width-7-9__900,
  .heirs-max-width-7-9__900 > * {
    max-width: 77.77777777777777% !important;
  }
  .max-width-7-10__900,
  .heirs-max-width-7-10__900 > * {
    max-width: 70% !important;
  }
  .max-width-7-11__900,
  .heirs-max-width-7-11__900 > * {
    max-width: 63.63636363636364% !important;
  }
  .max-width-7-12__900,
  .heirs-max-width-7-12__900 > * {
    max-width: 58.33333333333333% !important;
  }
  .max-width-8-9__900,
  .heirs-max-width-8-9__900 > * {
    max-width: 88.88888888888888% !important;
  }
  .max-width-8-11__900,
  .heirs-max-width-8-11__900 > * {
    max-width: 72.72727272727273% !important;
  }
  .max-width-9-10__900,
  .heirs-max-width-9-10__900 > * {
    max-width: 90% !important;
  }
  .max-width-9-11__900,
  .heirs-max-width-9-11__900 > * {
    max-width: 81.81818181818182% !important;
  }
  .max-width-10-11__900,
  .heirs-max-width-10-11__900 > * {
    max-width: 90.90909090909091% !important;
  }
  .max-width-11-12__900,
  .heirs-max-width-11-12__900 > * {
    max-width: 91.66666666666666% !important;
  }

  .min-width-a__900,
  .heirs-min-width-a__900 > * {
    min-width: auto !important;
  }
  .min-width-full__900,
  .heirs-min-width-full__900 > * {
    min-width: 100% !important;
  }
  .min-width-i__900,
  .heirs-min-width-i__900 > * {
    min-width: inherit !important;
  }
  .min-width-0__900,
  .heirs-min-width-0__900 > * {
    min-width: 0 !important;
  }

  .min-width-1200__900,
  .heirs-min-width-1200__900 > * {
    min-width: 1200px !important;
  }
  .min-width-900__900,
  .heirs-min-width-900__900 > * {
    min-width: 900px !important;
  }
  .min-width-600__900,
  .heirs-min-width-600__900 > * {
    min-width: 600px !important;
  }
  .min-width-320__900,
  .heirs-min-width-320__900 > * {
    min-width: 320px !important;
  }

  .min-width-1-2__900,
  .heirs-min-width-1-2__900 > * {
    min-width: 50% !important;
  }
  .min-width-1-3__900,
  .heirs-min-width-1-3__900 > * {
    min-width: 33.33333333333333% !important;
  }
  .min-width-1-4__900,
  .heirs-min-width-1-4__900 > * {
    min-width: 25% !important;
  }
  .min-width-1-5__900,
  .heirs-min-width-1-5__900 > * {
    min-width: 20% !important;
  }
  .min-width-1-6__900,
  .heirs-min-width-1-6__900 > * {
    min-width: 16.66666666666667% !important;
  }
  .min-width-1-7__900,
  .heirs-min-width-1-7__900 > * {
    min-width: 14.28571428571429% !important;
  }
  .min-width-1-8__900,
  .heirs-min-width-1-8__900 > * {
    min-width: 12.5% !important;
  }
  .min-width-1-9__900,
  .heirs-min-width-1-9__900 > * {
    min-width: 11.11111111111111% !important;
  }
  .min-width-1-10__900,
  .heirs-min-width-1-10__900 > * {
    min-width: 10% !important;
  }
  .min-width-1-11__900,
  .heirs-min-width-1-11__900 > * {
    min-width: 9.090909090909091% !important;
  }
  .min-width-1-12__900,
  .heirs-min-width-1-12__900 > * {
    min-width: 8.333333333333333% !important;
  }
  .min-width-1-20__900,
  .heirs-min-width-1-20__900 > * {
    min-width: 5% !important;
  }
  .min-width-2-3__900,
  .heirs-min-width-2-3__900 > * {
    min-width: 66.66666666666667% !important;
  }
  .min-width-2-5__900,
  .heirs-min-width-2-5__900 > * {
    min-width: 40% !important;
  }
  .min-width-2-7__900,
  .heirs-min-width-2-7__900 > * {
    min-width: 28.57142857142858% !important;
  }
  .min-width-2-9__900,
  .heirs-min-width-2-9__900 > * {
    min-width: 22.22222222222222% !important;
  }
  .min-width-2-11__900,
  .heirs-min-width-2-11__900 > * {
    min-width: 18.18181818181818% !important;
  }
  .min-width-3-4__900,
  .heirs-min-width-3-4__900 > * {
    min-width: 75% !important;
  }
  .min-width-3-5__900,
  .heirs-min-width-3-5__900 > * {
    min-width: 60% !important;
  }
  .min-width-3-7__900,
  .heirs-min-width-3-7__900 > * {
    min-width: 42.85714285714287% !important;
  }
  .min-width-3-8__900,
  .heirs-min-width-3-8__900 > * {
    min-width: 37.5% !important;
  }
  .min-width-3-10__900,
  .heirs-min-width-3-10__900 > * {
    min-width: 30% !important;
  }
  .min-width-3-11__900,
  .heirs-min-width-3-11__900 > * {
    min-width: 27.27272727272727% !important;
  }
  .min-width-4-5__900,
  .heirs-min-width-4-5__900 > * {
    min-width: 80% !important;
  }
  .min-width-4-7__900,
  .heirs-min-width-4-7__900 > * {
    min-width: 57.14285714285716% !important;
  }
  .min-width-4-9__900,
  .heirs-min-width-4-9__900 > * {
    min-width: 44.44444444444444% !important;
  }
  .min-width-4-11__900,
  .heirs-min-width-4-11__900 > * {
    min-width: 36.36363636363636% !important;
  }
  .min-width-5-6__900,
  .heirs-min-width-5-6__900 > * {
    min-width: 83.33333333333333% !important;
  }
  .min-width-5-7__900,
  .heirs-min-width-5-7__900 > * {
    min-width: 71.42857142857145% !important;
  }
  .min-width-5-8__900,
  .heirs-min-width-5-8__900 > * {
    min-width: 62.5% !important;
  }
  .min-width-5-9__900,
  .heirs-min-width-5-9__900 > * {
    min-width: 55.55555555555555% !important;
  }
  .min-width-5-11__900,
  .heirs-min-width-5-11__900 > * {
    min-width: 45.45454545454546% !important;
  }
  .min-width-5-12__900,
  .heirs-min-width-5-12__900 > * {
    min-width: 41.66666666666667% !important;
  }
  .min-width-6-7__900,
  .heirs-min-width-6-7__900 > * {
    min-width: 85.71428571428574% !important;
  }
  .min-width-6-11__900,
  .heirs-min-width-6-11__900 > * {
    min-width: 54.54545454545455% !important;
  }
  .min-width-7-8__900,
  .heirs-min-width-7-8__900 > * {
    min-width: 87.5% !important;
  }
  .min-width-7-9__900,
  .heirs-min-width-7-9__900 > * {
    min-width: 77.77777777777777% !important;
  }
  .min-width-7-10__900,
  .heirs-min-width-7-10__900 > * {
    min-width: 70% !important;
  }
  .min-width-7-11__900,
  .heirs-min-width-7-11__900 > * {
    min-width: 63.63636363636364% !important;
  }
  .min-width-7-12__900,
  .heirs-min-width-7-12__900 > * {
    min-width: 58.33333333333333% !important;
  }
  .min-width-8-9__900,
  .heirs-min-width-8-9__900 > * {
    min-width: 88.88888888888888% !important;
  }
  .min-width-8-11__900,
  .heirs-min-width-8-11__900 > * {
    min-width: 72.72727272727273% !important;
  }
  .min-width-9-10__900,
  .heirs-min-width-9-10__900 > * {
    min-width: 90% !important;
  }
  .min-width-9-11__900,
  .heirs-min-width-9-11__900 > * {
    min-width: 81.81818181818182% !important;
  }
  .min-width-10-11__900,
  .heirs-min-width-10-11__900 > * {
    min-width: 90.90909090909091% !important;
  }
  .min-width-11-12__900,
  .heirs-min-width-11-12__900 > * {
    min-width: 91.66666666666666% !important;
  }

  .background-n__900,
  .heirs-background-n__900 > * {
    background: none !important;
  }

  .border-n__900,
  .heirs-border-n__900 > * {
    border: none !important;
  }
  .border-b-n__900,
  .heirs-border-b-n__900 > *,
  .border-bt-n__900,
  .heirs-border-bt-n__900 > * {
    border-bottom: none !important;
  }
  .border-l-n__900,
  .heirs-border-l-n__900 > *,
  .border-lr-n__900,
  .heirs-border-lr-n__900 > * {
    border-left: none !important;
  }
  .border-r-n__900,
  .heirs-border-r-n__900 > *,
  .border-lr-n__900,
  .heirs-border-lr-n__900 > * {
    border-right: none !important;
  }
  .border-t-n__900,
  .heirs-border-t-n__900 > *,
  .border-bt-n__900,
  .heirs-border-bt-n__900 > * {
    border-top: none !important;
  }

  .box-sizing-bb__900,
  .heirs-box-sizing-bb__900 > * {
    box-sizing: border-box !important;
  }
  .box-sizing-cb__900,
  .heirs-box-sizing-cb__900 > * {
    box-sizing: content-box !important;
  }

  .center__900,
  .heirs-center__900 > * {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .margin-0__900,
  .heirs-margin-0__900 > * {
    margin: 0 !important;
  }
  .margin-b-0__900,
  .heirs-margin-b-0__900 > *,
  .margin-bt-0__900,
  .heirs-margin-bt-0__900 > * {
    margin-bottom: 0 !important;
  }
  .margin-l-0__900,
  .heirs-margin-l-0__900 > *,
  .margin-lr-0__900,
  .heirs-margin-lr-0__900 > * {
    margin-left: 0 !important;
  }
  .margin-r-0__900,
  .heirs-margin-r-0__900 > *,
  .margin-lr-0__900,
  .heirs-margin-lr-0__900 > * {
    margin-right: 0 !important;
  }
  .margin-t-0__900,
  .heirs-margin-t-0__900 > *,
  .margin-bt-0__900,
  .heirs-margin-bt-0__900 > * {
    margin-top: 0 !important;
  }

  .margin-1-8__900,
  .heirs-margin-1-8__900 > * {
    margin: 0.125rem !important;
  }
  .margin-b-1-8__900,
  .heirs-margin-b-1-8__900 > *,
  .margin-bt-1-8__900,
  .heirs-margin-bt-1-8__900 > * {
    margin-bottom: 0.125rem !important;
  }
  .margin-l-1-8__900,
  .heirs-margin-l-1-8__900 > *,
  .margin-lr-1-8__900,
  .heirs-margin-lr-1-8__900 > * {
    margin-left: 0.125rem !important;
  }
  .margin-r-1-8__900,
  .heirs-margin-r-1-8__900 > *,
  .margin-lr-1-8__900,
  .heirs-margin-lr-1-8__900 > * {
    margin-right: 0.125rem !important;
  }
  .margin-t-1-8__900,
  .heirs-margin-t-1-8__900 > *,
  .margin-bt-1-8__900,
  .heirs-margin-bt-1-8__900 > * {
    margin-top: 0.125rem !important;
  }

  .margin-1-4__900,
  .heirs-margin-1-4__900 > * {
    margin: 0.25rem !important;
  }
  .margin-b-1-4__900,
  .heirs-margin-b-1-4__900 > *,
  .margin-bt-1-4__900,
  .heirs-margin-bt-1-4__900 > * {
    margin-bottom: 0.25rem !important;
  }
  .margin-l-1-4__900,
  .heirs-margin-l-1-4__900 > *,
  .margin-lr-1-4__900,
  .heirs-margin-lr-1-4__900 > * {
    margin-left: 0.25rem !important;
  }
  .margin-r-1-4__900,
  .heirs-margin-r-1-4__900 > *,
  .margin-lr-1-4__900,
  .heirs-margin-lr-1-4__900 > * {
    margin-right: 0.25rem !important;
  }
  .margin-t-1-4__900,
  .heirs-margin-t-1-4__900 > *,
  .margin-bt-1-4__900,
  .heirs-margin-bt-1-4__900 > * {
    margin-top: 0.25rem !important;
  }

  .margin-1-2__900,
  .heirs-margin-1-2__900 > * {
    margin: 0.5rem !important;
  }
  .margin-b-1-2__900,
  .heirs-margin-b-1-2__900 > *,
  .margin-bt-1-2__900,
  .heirs-margin-bt-1-2__900 > * {
    margin-bottom: 0.5rem !important;
  }
  .margin-l-1-2__900,
  .heirs-margin-l-1-2__900 > *,
  .margin-lr-1-2__900,
  .heirs-margin-lr-1-2__900 > * {
    margin-left: 0.5rem !important;
  }
  .margin-r-1-2__900,
  .heirs-margin-r-1-2__900 > *,
  .margin-lr-1-2__900,
  .heirs-margin-lr-1-2__900 > * {
    margin-right: 0.5rem !important;
  }
  .margin-t-1-2__900,
  .heirs-margin-t-1-2__900 > *,
  .margin-bt-1-2__900,
  .heirs-margin-bt-1-2__900 > * {
    margin-top: 0.5rem !important;
  }

  .margin-3-4__900,
  .heirs-margin-3-4__900 > * {
    margin: 0.75rem !important;
  }
  .margin-b-3-4__900,
  .heirs-margin-b-3-4__900 > *,
  .margin-bt-3-4__900,
  .heirs-margin-bt-3-4__900 > * {
    margin-bottom: 0.75rem !important;
  }
  .margin-l-3-4__900,
  .heirs-margin-l-3-4__900 > *,
  .margin-lr-3-4__900,
  .heirs-margin-lr-3-4__900 > * {
    margin-left: 0.75rem !important;
  }
  .margin-r-3-4__900,
  .heirs-margin-r-3-4__900 > *,
  .margin-lr-3-4__900,
  .heirs-margin-lr-3-4__900 > * {
    margin-right: 0.75rem !important;
  }
  .margin-t-3-4__900,
  .heirs-margin-t-3-4__900 > *,
  .margin-bt-3-4__900,
  .heirs-margin-bt-3-4__900 > * {
    margin-top: 0.75rem !important;
  }

  .margin-1__900,
  .heirs-margin-1__900 > * {
    margin: 1rem !important;
  }
  .margin-b-1__900,
  .heirs-margin-b-1__900 > *,
  .margin-bt-1__900,
  .heirs-margin-bt-1__900 > * {
    margin-bottom: 1rem !important;
  }
  .margin-l-1__900,
  .heirs-margin-l-1__900 > *,
  .margin-lr-1__900,
  .heirs-margin-lr-1__900 > * {
    margin-left: 1rem !important;
  }
  .margin-r-1__900,
  .heirs-margin-r-1__900 > *,
  .margin-lr-1__900,
  .heirs-margin-lr-1__900 > * {
    margin-right: 1rem !important;
  }
  .margin-t-1__900,
  .heirs-margin-t-1__900 > *,
  .margin-bt-1__900,
  .heirs-margin-bt-1__900 > * {
    margin-top: 1rem !important;
  }

  .margin-2__900,
  .heirs-margin-2__900 > * {
    margin: 2rem !important;
  }
  .margin-b-2__900,
  .heirs-margin-b-2__900 > *,
  .margin-bt-2__900,
  .heirs-margin-bt-2__900 > * {
    margin-bottom: 2rem !important;
  }
  .margin-l-2__900,
  .heirs-margin-l-2__900 > *,
  .margin-lr-2__900,
  .heirs-margin-lr-2__900 > * {
    margin-left: 2rem !important;
  }
  .margin-r-2__900,
  .heirs-margin-r-2__900 > *,
  .margin-lr-2__900,
  .heirs-margin-lr-2__900 > * {
    margin-right: 2rem !important;
  }
  .margin-t-2__900,
  .heirs-margin-t-2__900 > *,
  .margin-bt-2__900,
  .heirs-margin-bt-2__900 > * {
    margin-top: 2rem !important;
  }

  .margin-3__900,
  .heirs-margin-3__900 > * {
    margin: 3rem !important;
  }
  .margin-b-3__900,
  .heirs-margin-b-3__900 > *,
  .margin-bt-3__900,
  .heirs-margin-bt-3__900 > * {
    margin-bottom: 3rem !important;
  }
  .margin-l-3__900,
  .heirs-margin-l-3__900 > *,
  .margin-lr-3__900,
  .heirs-margin-lr-3__900 > * {
    margin-left: 3rem !important;
  }
  .margin-r-3__900,
  .heirs-margin-r-3__900 > *,
  .margin-lr-3__900,
  .heirs-margin-lr-3__900 > * {
    margin-right: 3rem !important;
  }
  .margin-t-3__900,
  .heirs-margin-t-3__900 > *,
  .margin-bt-3__900,
  .heirs-margin-bt-3__900 > * {
    margin-top: 3rem !important;
  }

  .margin-4__900,
  .heirs-margin-4__900 > * {
    margin: 4rem !important;
  }
  .margin-b-4__900,
  .heirs-margin-b-4__900 > *,
  .margin-bt-4__900,
  .heirs-margin-bt-4__900 > * {
    margin-bottom: 4rem !important;
  }
  .margin-l-4__900,
  .heirs-margin-l-4__900 > *,
  .margin-lr-4__900,
  .heirs-margin-lr-4__900 > * {
    margin-left: 4rem !important;
  }
  .margin-r-4__900,
  .heirs-margin-r-4__900 > *,
  .margin-lr-4__900,
  .heirs-margin-lr-4__900 > * {
    margin-right: 4rem !important;
  }
  .margin-t-4__900,
  .heirs-margin-t-4__900 > *,
  .margin-bt-4__900,
  .heirs-margin-bt-4__900 > * {
    margin-top: 4rem !important;
  }

  .padding-0__900,
  .heirs-padding-0__900 > * {
    padding: 0 !important;
  }
  .padding-b-0__900,
  .heirs-padding-b-0__900 > *,
  .padding-bt-0__900,
  .heirs-padding-bt-0__900 > * {
    padding-bottom: 0 !important;
  }
  .padding-l-0__900,
  .heirs-padding-l-0__900 > *,
  .padding-lr-0__900,
  .heirs-padding-lr-0__900 > * {
    padding-left: 0 !important;
  }
  .padding-r-0__900,
  .heirs-padding-r-0__900 > *,
  .padding-lr-0__900,
  .heirs-padding-lr-0__900 > * {
    padding-right: 0 !important;
  }
  .padding-t-0__900,
  .heirs-padding-t-0__900 > *,
  .padding-bt-0__900,
  .heirs-padding-bt-0__900 > * {
    padding-top: 0 !important;
  }

  .padding-1-8__900,
  .heirs-padding-1-8__900 > * {
    padding: 0.125rem !important;
  }
  .padding-b-1-8__900,
  .heirs-padding-b-1-8__900 > *,
  .padding-bt-1-8__900,
  .heirs-padding-bt-1-8__900 > * {
    padding-bottom: 0.125rem !important;
  }
  .padding-l-1-8__900,
  .heirs-padding-l-1-8__900 > *,
  .padding-lr-1-8__900,
  .heirs-padding-lr-1-8__900 > * {
    padding-left: 0.125rem !important;
  }
  .padding-r-1-8__900,
  .heirs-padding-r-1-8__900 > *,
  .padding-lr-1-8__900,
  .heirs-padding-lr-1-8__900 > * {
    padding-right: 0.125rem !important;
  }
  .padding-t-1-8__900,
  .heirs-padding-t-1-8__900 > *,
  .padding-bt-1-8__900,
  .heirs-padding-bt-1-8__900 > * {
    padding-top: 0.125rem !important;
  }

  .padding-1-4__900,
  .heirs-padding-1-4__900 > * {
    padding: 0.25rem !important;
  }
  .padding-b-1-4__900,
  .heirs-padding-b-1-4__900 > *,
  .padding-bt-1-4__900,
  .heirs-padding-bt-1-4__900 > * {
    padding-bottom: 0.25rem !important;
  }
  .padding-l-1-4__900,
  .heirs-padding-l-1-4__900 > *,
  .padding-lr-1-4__900,
  .heirs-padding-lr-1-4__900 > * {
    padding-left: 0.25rem !important;
  }
  .padding-r-1-4__900,
  .heirs-padding-r-1-4__900 > *,
  .padding-lr-1-4__900,
  .heirs-padding-lr-1-4__900 > * {
    padding-right: 0.25rem !important;
  }
  .padding-t-1-4__900,
  .heirs-padding-t-1-4__900 > *,
  .padding-bt-1-4__900,
  .heirs-padding-bt-1-4__900 > * {
    padding-top: 0.25rem !important;
  }

  .padding-1-2__900,
  .heirs-padding-1-2__900 > * {
    padding: 0.5rem !important;
  }
  .padding-b-1-2__900,
  .heirs-padding-b-1-2__900 > *,
  .padding-bt-1-2__900,
  .heirs-padding-bt-1-2__900 > * {
    padding-bottom: 0.5rem !important;
  }
  .padding-l-1-2__900,
  .heirs-padding-l-1-2__900 > *,
  .padding-lr-1-2__900,
  .heirs-padding-lr-1-2__900 > * {
    padding-left: 0.5rem !important;
  }
  .padding-r-1-2__900,
  .heirs-padding-r-1-2__900 > *,
  .padding-lr-1-2__900,
  .heirs-padding-lr-1-2__900 > * {
    padding-right: 0.5rem !important;
  }
  .padding-t-1-2__900,
  .heirs-padding-t-1-2__900 > *,
  .padding-bt-1-2__900,
  .heirs-padding-bt-1-2__900 > * {
    padding-top: 0.5rem !important;
  }

  .padding-3-4__900,
  .heirs-padding-3-4__900 > * {
    padding: 0.75rem !important;
  }
  .padding-b-3-4__900,
  .heirs-padding-b-3-4__900 > *,
  .padding-bt-3-4__900,
  .heirs-padding-bt-3-4__900 > * {
    padding-bottom: 0.75rem !important;
  }
  .padding-l-3-4__900,
  .heirs-padding-l-3-4__900 > *,
  .padding-lr-3-4__900,
  .heirs-padding-lr-3-4__900 > * {
    padding-left: 0.75rem !important;
  }
  .padding-r-3-4__900,
  .heirs-padding-r-3-4__900 > *,
  .padding-lr-3-4__900,
  .heirs-padding-lr-3-4__900 > * {
    padding-right: 0.75rem !important;
  }
  .padding-t-3-4__900,
  .heirs-padding-t-3-4__900 > *,
  .padding-bt-3-4__900,
  .heirs-padding-bt-3-4__900 > * {
    padding-top: 0.75rem !important;
  }

  .padding-1__900,
  .heirs-padding-1__900 > * {
    padding: 1rem !important;
  }
  .padding-b-1__900,
  .heirs-padding-b-1__900 > *,
  .padding-bt-1__900,
  .heirs-padding-bt-1__900 > * {
    padding-bottom: 1rem !important;
  }
  .padding-l-1__900,
  .heirs-padding-l-1__900 > *,
  .padding-lr-1__900,
  .heirs-padding-lr-1__900 > * {
    padding-left: 1rem !important;
  }
  .padding-r-1__900,
  .heirs-padding-r-1__900 > *,
  .padding-lr-1__900,
  .heirs-padding-lr-1__900 > * {
    padding-right: 1rem !important;
  }
  .padding-t-1__900,
  .heirs-padding-t-1__900 > *,
  .padding-bt-1__900,
  .heirs-padding-bt-1__900 > * {
    padding-top: 1rem !important;
  }

  .padding-2__900,
  .heirs-padding-2__900 > * {
    padding: 2rem !important;
  }
  .padding-b-2__900,
  .heirs-padding-b-2__900 > *,
  .padding-bt-2__900,
  .heirs-padding-bt-2__900 > * {
    padding-bottom: 2rem !important;
  }
  .padding-l-2__900,
  .heirs-padding-l-2__900 > *,
  .padding-lr-2__900,
  .heirs-padding-lr-2__900 > * {
    padding-left: 2rem !important;
  }
  .padding-r-2__900,
  .heirs-padding-r-2__900 > *,
  .padding-lr-2__900,
  .heirs-padding-lr-2__900 > * {
    padding-right: 2rem !important;
  }
  .padding-t-2__900,
  .heirs-padding-t-2__900 > *,
  .padding-bt-2__900,
  .heirs-padding-bt-2__900 > * {
    padding-top: 2rem !important;
  }

  .padding-3__900,
  .heirs-padding-3__900 > * {
    padding: 3rem !important;
  }
  .padding-b-3__900,
  .heirs-padding-b-3__900 > *,
  .padding-bt-3__900,
  .heirs-padding-bt-3__900 > * {
    padding-bottom: 3rem !important;
  }
  .padding-l-3__900,
  .heirs-padding-l-3__900 > *,
  .padding-lr-3__900,
  .heirs-padding-lr-3__900 > * {
    padding-left: 3rem !important;
  }
  .padding-r-3__900,
  .heirs-padding-r-3__900 > *,
  .padding-lr-3__900,
  .heirs-padding-lr-3__900 > * {
    padding-right: 3rem !important;
  }
  .padding-t-3__900,
  .heirs-padding-t-3__900 > *,
  .padding-bt-3__900,
  .heirs-padding-bt-3__900 > * {
    padding-top: 3rem !important;
  }

  .padding-4__900,
  .heirs-padding-4__900 > * {
    padding: 4rem !important;
  }
  .padding-b-4__900,
  .heirs-padding-b-4__900 > *,
  .padding-bt-4__900,
  .heirs-padding-bt-4__900 > * {
    padding-bottom: 4rem !important;
  }
  .padding-l-4__900,
  .heirs-padding-l-4__900 > *,
  .padding-lr-4__900,
  .heirs-padding-lr-4__900 > * {
    padding-left: 4rem !important;
  }
  .padding-r-4__900,
  .heirs-padding-r-4__900 > *,
  .padding-lr-4__900,
  .heirs-padding-lr-4__900 > * {
    padding-right: 4rem !important;
  }
  .padding-t-4__900,
  .heirs-padding-t-4__900 > *,
  .padding-bt-4__900,
  .heirs-padding-bt-4__900 > * {
    padding-top: 4rem !important;
  }
}

/* styles for small screens (600px)
   ========================================================================== */

@media all and (max-width: 600px) {
  html {
    font-size: 13px;
  }

  .cf__600:after,
  .heirs-cf__600 > *:after,
  .cf__600:before,
  .heirs-cf__600 > *:before,
  .break-after__600:after,
  .heirs-break-after__600 > *:after,
  .break-before__600:before,
  .heirs-break-before__600 > *:before {
    clear: both;
    content: "";
    display: table;
  }

  .fill__600,
  .heirs-fill__600 > * {
    height: auto;
    max-width: 100%;
  }

  .fill-and-center__600,
  .heirs-fill-and-center__600 > * {
    display: block;
    float: none;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .grid__600,
  .heirs-grid__600 > *,
  .row__600,
  .heirs-row__600 > * {
    display: block;
  }

  .inline-grid__600,
  .heirs-inline-grid__600 > * {
    display: inline-block;
  }

  .grid__600:after,
  .heirs-grid__600 > *:after,
  .grid__600:before,
  .heirs-grid__600 > *:before,
  .inline-grid__600:after,
  .heirs-inline-grid__600 > *:after,
  .inline-grid__600:before,
  .heirs-inline-grid__600 > *:before,
  .collapse__600 > *:after,
  .heirs-collapse__600 > * > *:after,
  .collapse__600 > *:before,
  .heirs-collapse__600 > * > *:before {
    clear: both;
    content: "";
    display: table;
  }

  .grid__600 > *,
  .heirs-grid__600 > * > *,
  .row__600 > *,
  .heirs-row__600 > * > *,
  .col__600,
  .heirs-col__600 > *,
  .inline-grid__600 > *,
  .heirs-inline-grid__600 > * > * {
    float: left;
    display: block;
  }

  .cols-1__600 > *:nth-child(1n + 2),
  .heirs-cols-1__600 > * > *:nth-child(1n + 2),
  .cols-2__600 > *:nth-child(1n + 2),
  .heirs-cols-2__600 > * > *:nth-child(1n + 2),
  .cols-3__600 > *:nth-child(1n + 2),
  .heirs-cols-3__600 > * > *:nth-child(1n + 2),
  .cols-4__600 > *:nth-child(1n + 2),
  .heirs-cols-4__600 > * > *:nth-child(1n + 2),
  .cols-5__600 > *:nth-child(1n + 2),
  .heirs-cols-5__600 > * > *:nth-child(1n + 2),
  .cols-6__600 > *:nth-child(1n + 2),
  .heirs-cols-6__600 > * > *:nth-child(1n + 2),
  .cols-7__600 > *:nth-child(1n + 2),
  .heirs-cols-7__600 > * > *:nth-child(1n + 2),
  .cols-8__600 > *:nth-child(1n + 2),
  .heirs-cols-8__600 > * > *:nth-child(1n + 2),
  .cols-9__600 > *:nth-child(1n + 2),
  .heirs-cols-9__600 > * > *:nth-child(1n + 2),
  .cols-10__600 > *:nth-child(1n + 2),
  .heirs-cols-10__600 > * > *:nth-child(1n + 2),
  .cols-11__600 > *:nth-child(1n + 2),
  .heirs-cols-11__600 > * > *:nth-child(1n + 2),
  .cols-12__600 > *:nth-child(1n + 2),
  .heirs-cols-12__600 > * > *:nth-child(1n + 2) {
    clear: none !important;
  }

  .cols-1__600 > *:nth-child(1n + 2),
  .heirs-cols-1__600 > * > *:nth-child(1n + 2),
  .cols-2__600 > *:nth-child(2n + 3),
  .heirs-cols-2__600 > * > *:nth-child(2n + 3),
  .cols-3__600 > *:nth-child(3n + 4),
  .heirs-cols-3__600 > * > *:nth-child(3n + 4),
  .cols-4__600 > *:nth-child(4n + 5),
  .heirs-cols-4__600 > * > *:nth-child(4n + 5),
  .cols-5__600 > *:nth-child(5n + 6),
  .heirs-cols-5__600 > * > *:nth-child(5n + 6),
  .cols-6__600 > *:nth-child(6n + 7),
  .heirs-cols-6__600 > * > *:nth-child(6n + 7),
  .cols-7__600 > *:nth-child(7n + 8),
  .heirs-cols-7__600 > * > *:nth-child(7n + 8),
  .cols-8__600 > *:nth-child(8n + 9),
  .heirs-cols-8__600 > * > *:nth-child(8n + 9),
  .cols-9__600 > *:nth-child(9n + 10),
  .heirs-cols-9__600 > * > *:nth-child(9n + 10),
  .cols-10__600 > *:nth-child(10n + 11),
  .heirs-cols-10__600 > * > *:nth-child(10n + 11),
  .cols-11__600 > *:nth-child(11n + 12),
  .heirs-cols-11__600 > * > *:nth-child(11n + 12),
  .cols-12__600 > *:nth-child(12n + 13),
  .heirs-cols-12__600 > * > *:nth-child(12n + 13) {
    clear: both !important;
  }

  .mini__600,
  .heirs-mini__600 > *,
  .mini-b__600,
  .heirs-mini-b__600 > *,
  .mini-m__600,
  .heirs-mini-m__600 > *,
  .mini-t__600,
  .heirs-mini-t__600 > * {
    display: block;
    page-break-inside: avoid;
  }

  .mini__600 > *,
  .heirs-mini__600 > * > *,
  .mini-b__600 > *,
  .heirs-mini-b__600 > * > *,
  .mini-m__600 > *,
  .heirs-mini-m__600 > * > *,
  .mini-t__600 > *,
  .heirs-mini-t__600 > * > * {
    display: table-cell;
    vertical-align: top;
  }

  .mini-b__600 > *,
  .heirs-mini-b__600 > * > * {
    vertical-align: bottom;
  }
  .mini-m__600 > *,
  .heirs-mini-m__600 > * > * {
    vertical-align: middle;
  }
  .mini-t__600 > *,
  .heirs-mini-t__600 > * > * {
    vertical-align: top;
  }

  .mini-collapse__600 > *,
  .heirs-mini-collapse__600 > * > * {
    display: block;
  }

  .table__600,
  .heirs-table__600 > *,
  .table__600 > *,
  .heirs-table__600 > * > *,
  .table__600 > * > *,
  .heirs-table__600 > * > * > * {
    border-collapse: collapse; /* remove excess spacing around table elements */
    border-spacing: 0; /* ^ */
  }

  .table__600,
  .heirs-table__600 > * {
    display: table;
  }

  .table__600 > *,
  .heirs-table__600 > * > *,
  .table-row__600 > *,
  .heirs-table-row__600 > * > * {
    display: table-row;
  }

  .table__600 > * > *,
  .heirs-table__600 > * > * > *,
  .table-col__600 > * > *,
  .heirs-table-col__600 > * > * > * {
    display: table-cell;
    vertical-align: top;
  }

  .table-collapse__600,
  .heirs-table-collapse__600 > *,
  .table-collapse__600 > *,
  .heirs-table-collapse__600 > * > *,
  .table-collapse__600 > * > *,
  .heirs-table-collapse__600 > * > * > * {
    display: block;
  }

  .flex__600,
  .heirs-flex__600 > * {
    display: flex;
  }

  .inline-flex__600,
  .heirs-inline-flex__600 > * {
    display: inline-flex;
  }

  .flex-direction-c__600,
  .heirs-flex-direction-c__600 > * {
    flex-direction: column;
  }
  .flex-direction-cr__600,
  .heirs-flex-direction-cr__600 > * {
    flex-direction: column-reverse;
  }
  .flex-direction-r__600,
  .heirs-flex-direction-r__600 > * {
    flex-direction: row;
  }
  .flex-direction-rr__600,
  .heirs-flex-direction-rr__600 > * {
    flex-direction: row-reverse;
  }

  .flex-wrap-n__600,
  .heirs-flex-wrap-n__600 > * {
    flex-wrap: nowrap;
  }
  .flex-wrap-w__600,
  .heirs-flex-wrap-w__600 > * {
    flex-wrap: wrap;
  }
  .flex-wrap-wr__600,
  .heirs-flex-wrap-wr__600 > * {
    flex-wrap: wrap-reverse;
  }

  .justify-content-c__600,
  .heirs-justify-content-c__600 > * {
    justify-content: center;
  }
  .justify-content-fe__600,
  .heirs-justify-content-fe__600 > * {
    justify-content: flex-end;
  }
  .justify-content-fs__600,
  .heirs-justify-content-fs__600 > * {
    justify-content: flex-start;
  }
  .justify-content-sa__600,
  .heirs-justify-content-sa__600 > * {
    justify-content: space-around;
  }
  .justify-content-sb__600,
  .heirs-justify-content-sb__600 > * {
    justify-content: space-between;
  }
  .justify-content-se__600,
  .heirs-justify-content-se__600 > * {
    justify-content: space-evenly;
  }

  .align-items-b__600,
  .heirs-align-items-b__600 > * {
    align-items: baseline;
  }
  .align-items-c__600,
  .heirs-align-items-c__600 > * {
    align-items: center;
  }
  .align-items-fe__600,
  .heirs-align-items-fe__600 > * {
    align-items: flex-end;
  }
  .align-items-fs__600,
  .heirs-align-items-fs__600 > * {
    align-items: flex-start;
  }
  .align-items-s__600,
  .heirs-align-items-s__600 > * {
    align-items: stretch;
  }

  .align-content-c__600,
  .heirs-align-content-c__600 > * {
    align-content: center;
  }
  .align-content-fe__600,
  .heirs-align-content-fe__600 > * {
    align-content: flex-end;
  }
  .align-content-fs__600,
  .heirs-align-content-fs__600 > * {
    align-content: flex-start;
  }
  .align-content-s__600,
  .heirs-align-content-se__600 > * {
    align-content: stretch;
  }
  .align-content-sa__600,
  .heirs-align-content-sa__600 > * {
    align-content: space-around;
  }
  .align-content-sb__600,
  .heirs-align-content-sb__600 > * {
    align-content: space-between;
  }

  .align-self-a__600,
  .heirs-align-self-a__600 > * {
    align-self: auto;
  }
  .align-self-b__600,
  .heirs-align-self-b__600 > * {
    align-self: baseline;
  }
  .align-self-c__600,
  .heirs-align-self-c__600 > * {
    align-self: center;
  }
  .align-self-fe__600,
  .heirs-align-self-fe__600 > * {
    align-self: flex-end;
  }
  .align-self-fs__600,
  .heirs-align-self-fs__600 > * {
    align-self: flex-start;
  }
  .align-self-s__600,
  .heirs-align-self-se__600 > * {
    align-self: stretch;
  }

  .flex-basis-a__600,
  .heirs-flex-basis-a__600 > * {
    flex-basis: auto;
  }

  .flex-grow-0__600,
  .heirs-flex-grow-0__600 > * {
    flex-grow: 0;
  }
  .flex-grow-1__600,
  .heirs-flex-grow-1__600 > * {
    flex-grow: 1;
  }
  .flex-grow-2__600,
  .heirs-flex-grow-2__600 > * {
    flex-grow: 2;
  }
  .flex-grow-3__600,
  .heirs-flex-grow-3__600 > * {
    flex-grow: 3;
  }
  .flex-grow-4__600,
  .heirs-flex-grow-4__600 > * {
    flex-grow: 4;
  }
  .flex-grow-5__600,
  .heirs-flex-grow-5__600 > * {
    flex-grow: 5;
  }
  .flex-grow-6__600,
  .heirs-flex-grow-6__600 > * {
    flex-grow: 6;
  }
  .flex-grow-7__600,
  .heirs-flex-grow-7__600 > * {
    flex-grow: 7;
  }
  .flex-grow-8__600,
  .heirs-flex-grow-8__600 > * {
    flex-grow: 8;
  }
  .flex-grow-9__600,
  .heirs-flex-grow-9__600 > * {
    flex-grow: 9;
  }
  .flex-grow-10__600,
  .heirs-flex-grow-10__600 > * {
    flex-grow: 10;
  }
  .flex-grow-11__600,
  .heirs-flex-grow-11__600 > * {
    flex-grow: 11;
  }
  .flex-grow-12__600,
  .heirs-flex-grow-12__600 > * {
    flex-grow: 12;
  }

  .flex-shrink-1__600,
  .heirs-flex-shrink-1__600 > * {
    flex-shrink: 1;
  }
  .flex-shrink-2__600,
  .heirs-flex-shrink-2__600 > * {
    flex-shrink: 2;
  }
  .flex-shrink-3__600,
  .heirs-flex-shrink-3__600 > * {
    flex-shrink: 3;
  }
  .flex-shrink-4__600,
  .heirs-flex-shrink-4__600 > * {
    flex-shrink: 4;
  }
  .flex-shrink-5__600,
  .heirs-flex-shrink-5__600 > * {
    flex-shrink: 5;
  }
  .flex-shrink-6__600,
  .heirs-flex-shrink-6__600 > * {
    flex-shrink: 6;
  }
  .flex-shrink-7__600,
  .heirs-flex-shrink-7__600 > * {
    flex-shrink: 7;
  }
  .flex-shrink-8__600,
  .heirs-flex-shrink-8__600 > * {
    flex-shrink: 8;
  }
  .flex-shrink-9__600,
  .heirs-flex-shrink-9__600 > * {
    flex-shrink: 9;
  }
  .flex-shrink-10__600,
  .heirs-flex-shrink-10__600 > * {
    flex-shrink: 10;
  }
  .flex-shrink-11__600,
  .heirs-flex-shrink-11__600 > * {
    flex-shrink: 11;
  }
  .flex-shrink-12__600,
  .heirs-flex-shrink-12__600 > * {
    flex-shrink: 12;
  }

  .order-0__600,
  .heirs-order-0__600 > * {
    order: 0;
  }
  .order-1__600,
  .heirs-order-1__600 > * {
    order: 1;
  }
  .order-2__600,
  .heirs-order-2__600 > * {
    order: 2;
  }
  .order-3__600,
  .heirs-order-3__600 > * {
    order: 3;
  }
  .order-4__600,
  .heirs-order-4__600 > * {
    order: 4;
  }
  .order-5__600,
  .heirs-order-5__600 > * {
    order: 5;
  }
  .order-6__600,
  .heirs-order-6__600 > * {
    order: 6;
  }
  .order-7__600,
  .heirs-order-7__600 > * {
    order: 7;
  }
  .order-8__600,
  .heirs-order-8__600 > * {
    order: 8;
  }
  .order-9__600,
  .heirs-order-9__600 > * {
    order: 9;
  }
  .order-10__600,
  .heirs-order-10__600 > * {
    order: 10;
  }
  .order-11__600,
  .heirs-order-11__600 > * {
    order: 11;
  }
  .order-12__600,
  .heirs-order-12__600 > * {
    order: 12;
  }

  .columns-a__600,
  .heirs-columns-a__600 > * {
    columns: auto;
  }
  .columns-i__600,
  .heirs-columns-i__600 > * {
    columns: inherit;
  }

  .columns-0__600,
  .heirs-columns-0__600 > * {
    columns: 0;
  }
  .columns-1__600,
  .heirs-columns-1__600 > * {
    columns: 1;
  }
  .columns-2__600,
  .heirs-columns-2__600 > * {
    columns: 2;
  }
  .columns-3__600,
  .heirs-columns-3__600 > * {
    columns: 3;
  }
  .columns-4__600,
  .heirs-columns-4__600 > * {
    columns: 4;
  }
  .columns-5__600,
  .heirs-columns-5__600 > * {
    columns: 5;
  }
  .columns-6__600,
  .heirs-columns-6__600 > * {
    columns: 6;
  }
  .columns-7__600,
  .heirs-columns-7__600 > * {
    columns: 7;
  }
  .columns-8__600,
  .heirs-columns-8__600 > * {
    columns: 8;
  }
  .columns-9__600,
  .heirs-columns-9__600 > * {
    columns: 9;
  }
  .columns-10__600,
  .heirs-columns-10__600 > * {
    columns: 10;
  }
  .columns-11__600,
  .heirs-columns-11__600 > * {
    columns: 11;
  }
  .columns-12__600,
  .heirs-columns-12__600 > * {
    columns: 12;
  }

  .column-count-a__600,
  .heirs-column-count-a__600 > * {
    column-count: auto;
  }
  .column-count-i__600,
  .heirs-column-count-i__600 > * {
    column-count: inherit;
  }

  .column-count-0__600,
  .heirs-column-count-0__600 > * {
    column-count: 0;
  }
  .column-count-1__600,
  .heirs-column-count-1__600 > * {
    column-count: 1;
  }
  .column-count-2__600,
  .heirs-column-count-2__600 > * {
    column-count: 2;
  }
  .column-count-3__600,
  .heirs-column-count-3__600 > * {
    column-count: 3;
  }
  .column-count-4__600,
  .heirs-column-count-4__600 > * {
    column-count: 4;
  }
  .column-count-5__600,
  .heirs-column-count-5__600 > * {
    column-count: 5;
  }
  .column-count-6__600,
  .heirs-column-count-6__600 > * {
    column-count: 6;
  }
  .column-count-7__600,
  .heirs-column-count-7__600 > * {
    column-count: 7;
  }
  .column-count-8__600,
  .heirs-column-count-8__600 > * {
    column-count: 8;
  }
  .column-count-9__600,
  .heirs-column-count-9__600 > * {
    column-count: 9;
  }
  .column-count-10__600,
  .heirs-column-count-10__600 > * {
    column-count: 10;
  }
  .column-count-11__600,
  .heirs-column-count-11__600 > * {
    column-count: 11;
  }
  .column-count-12__600,
  .heirs-column-count-12__600 > * {
    column-count: 12;
  }

  .column-width-a__600,
  .heirs-column-width-a__600 > * {
    column-width: auto;
  }
  .column-width-full__600,
  .heirs-column-width-full__600 > * {
    column-width: 100%;
  }
  .column-width-i__600,
  .heirs-column-width-i__600 > * {
    column-width: inherit;
  }
  .column-width-0__600,
  .heirs-column-width-0__600 > * {
    column-width: 0;
  }

  .column-width-1-2__600,
  .heirs-column-width-1-2__600 > * {
    column-width: 50%;
  }
  .column-width-1-3__600,
  .heirs-column-width-1-3__600 > * {
    column-width: 33.33333333333333%;
  }
  .column-width-1-4__600,
  .heirs-column-width-1-4__600 > * {
    column-width: 25%;
  }
  .column-width-1-5__600,
  .heirs-column-width-1-5__600 > * {
    column-width: 20%;
  }
  .column-width-1-6__600,
  .heirs-column-width-1-6__600 > * {
    column-width: 16.66666666666667%;
  }
  .column-width-1-7__600,
  .heirs-column-width-1-7__600 > * {
    column-width: 14.28571428571429%;
  }
  .column-width-1-8__600,
  .heirs-column-width-1-8__600 > * {
    column-width: 12.5%;
  }
  .column-width-1-9__600,
  .heirs-column-width-1-9__600 > * {
    column-width: 11.11111111111111%;
  }
  .column-width-1-10__600,
  .heirs-column-width-1-10__600 > * {
    column-width: 10%;
  }
  .column-width-1-11__600,
  .heirs-column-width-1-11__600 > * {
    column-width: 9.090909090909091%;
  }
  .column-width-1-12__600,
  .heirs-column-width-1-12__600 > * {
    column-width: 8.333333333333333%;
  }
  .column-width-1-20__600,
  .heirs-column-width-1-20__600 > * {
    column-width: 5%;
  }
  .column-width-2-3__600,
  .heirs-column-width-2-3__600 > * {
    column-width: 66.66666666666667%;
  }
  .column-width-2-5__600,
  .heirs-column-width-2-5__600 > * {
    column-width: 40%;
  }
  .column-width-2-7__600,
  .heirs-column-width-2-7__600 > * {
    column-width: 28.57142857142858%;
  }
  .column-width-2-9__600,
  .heirs-column-width-2-9__600 > * {
    column-width: 22.22222222222222%;
  }
  .column-width-2-11__600,
  .heirs-column-width-2-11__600 > * {
    column-width: 18.18181818181818%;
  }
  .column-width-3-4__600,
  .heirs-column-width-3-4__600 > * {
    column-width: 75%;
  }
  .column-width-3-5__600,
  .heirs-column-width-3-5__600 > * {
    column-width: 60%;
  }
  .column-width-3-7__600,
  .heirs-column-width-3-7__600 > * {
    column-width: 42.85714285714287%;
  }
  .column-width-3-8__600,
  .heirs-column-width-3-8__600 > * {
    column-width: 37.5%;
  }
  .column-width-3-10__600,
  .heirs-column-width-3-10__600 > * {
    column-width: 30%;
  }
  .column-width-3-11__600,
  .heirs-column-width-3-11__600 > * {
    column-width: 27.27272727272727%;
  }
  .column-width-4-5__600,
  .heirs-column-width-4-5__600 > * {
    column-width: 80%;
  }
  .column-width-4-7__600,
  .heirs-column-width-4-7__600 > * {
    column-width: 57.14285714285716%;
  }
  .column-width-4-9__600,
  .heirs-column-width-4-9__600 > * {
    column-width: 44.44444444444444%;
  }
  .column-width-4-11__600,
  .heirs-column-width-4-11__600 > * {
    column-width: 36.36363636363636%;
  }
  .column-width-5-6__600,
  .heirs-column-width-5-6__600 > * {
    column-width: 83.33333333333333%;
  }
  .column-width-5-7__600,
  .heirs-column-width-5-7__600 > * {
    column-width: 71.42857142857145%;
  }
  .column-width-5-8__600,
  .heirs-column-width-5-8__600 > * {
    column-width: 62.5%;
  }
  .column-width-5-9__600,
  .heirs-column-width-5-9__600 > * {
    column-width: 55.55555555555555%;
  }
  .column-width-5-11__600,
  .heirs-column-width-5-11__600 > * {
    column-width: 45.45454545454546%;
  }
  .column-width-5-12__600,
  .heirs-column-width-5-12__600 > * {
    column-width: 41.66666666666667%;
  }
  .column-width-6-7__600,
  .heirs-column-width-6-7__600 > * {
    column-width: 85.71428571428574%;
  }
  .column-width-6-11__600,
  .heirs-column-width-6-11__600 > * {
    column-width: 54.54545454545455%;
  }
  .column-width-7-8__600,
  .heirs-column-width-7-8__600 > * {
    column-width: 87.5%;
  }
  .column-width-7-9__600,
  .heirs-column-width-7-9__600 > * {
    column-width: 77.77777777777777%;
  }
  .column-width-7-10__600,
  .heirs-column-width-7-10__600 > * {
    column-width: 70%;
  }
  .column-width-7-11__600,
  .heirs-column-width-7-11__600 > * {
    column-width: 63.63636363636364%;
  }
  .column-width-7-12__600,
  .heirs-column-width-7-12__600 > * {
    column-width: 58.33333333333333%;
  }
  .column-width-8-9__600,
  .heirs-column-width-8-9__600 > * {
    column-width: 88.88888888888888%;
  }
  .column-width-8-11__600,
  .heirs-column-width-8-11__600 > * {
    column-width: 72.72727272727273%;
  }
  .column-width-9-10__600,
  .heirs-column-width-9-10__600 > * {
    column-width: 90%;
  }
  .column-width-9-11__600,
  .heirs-column-width-9-11__600 > * {
    column-width: 81.81818181818182%;
  }
  .column-width-10-11__600,
  .heirs-column-width-10-11__600 > * {
    column-width: 90.90909090909091%;
  }
  .column-width-11-12__600,
  .heirs-column-width-11-12__600 > * {
    column-width: 91.66666666666666%;
  }

  .arrow-after__600:after,
  .heirs-arrow-after__600 > *:after,
  .heirs-arrow-between__600 > *:not(:first-child):before,
  .arrow-l-after__600:after,
  .heirs-arrow-l-after__600 > *:after,
  .heirs-arrow-l-between__600 > *:not(:first-child):before,
  .bar-after__600:after,
  .heirs-bar-after__600 > *:after,
  .heirs-bar-between__600 > *:not(:first-child):before,
  .bullet-after__600:after,
  .heirs-bullet-after__600 > *:after,
  .heirs-bullet-between__600 > *:not(:first-child):before,
  .chevron-after__600:after,
  .heirs-chevron-after__600 > *:after,
  .heirs-chevron-between__600 > *:not(:first-child):before,
  .chevron-l-after__600:after,
  .heirs-chevron-l-after__600 > *:after,
  .heirs-chevron-l-between__600 > *:not(:first-child):before,
  .square-after__600:after,
  .heirs-square-after__600 > *:after,
  .heirs-square-between__600 > *:not(:first-child):before {
    display: inline;
    margin-left: 0.25rem;
  }

  .arrow-before__600:before,
  .heirs-arrow-before__600 > *:before,
  .heirs-arrow-between__600 > *:not(:first-child):before,
  .arrow-l-before__600:before,
  .heirs-arrow-l-before__600 > *:before,
  .heirs-arrow-l-between__600 > *:not(:first-child):before,
  .bar-before__600:before,
  .heirs-bar-before__600 > *:before,
  .heirs-bar-between__600 > *:not(:first-child):before,
  .bullet-before__600:before,
  .heirs-bullet-before__600 > *:before,
  .heirs-bullet-between__600 > *:not(:first-child):before,
  .chevron-before__600:before,
  .heirs-chevron-before__600 > *:before,
  .heirs-chevron-between__600 > *:not(:first-child):before,
  .chevron-l-before__600:before,
  .heirs-chevron-l-before__600 > *:before,
  .heirs-chevron-l-between__600 > *:not(:first-child):before,
  .square-before__600:before,
  .heirs-square-before__600 > *:before,
  .heirs-square-between__600 > *:not(:first-child):before {
    display: inline;
    margin-right: 0.25rem;
  }

  .arrow-after__600:after,
  .heirs-arrow-after__600 > *:after,
  .arrow-before__600:before,
  .heirs-arrow-before__600 > *:before,
  .heirs-arrow-between__600 > *:not(:first-child):before {
    content: "›";
  }

  .arrow-l-after__600:after,
  .heirs-arrow-l-after__600 > *:after,
  .arrow-l-before__600:before,
  .heirs-arrow-l-before__600 > *:before,
  .heirs-arrow-l-between__600 > *:not(:first-child):before {
    content: "‹";
  }

  .bar-after__600:after,
  .heirs-bar-after__600 > *:after,
  .bar-before__600:before,
  .heirs-bar-before__600 > *:before,
  .heirs-bar-between__600 > *:not(:first-child):before {
    content: "|";
  }

  .bullet-after__600:after,
  .heirs-bullet-after__600 > *:after,
  .bullet-before__600:before,
  .heirs-bullet-before__600 > *:before,
  .heirs-bullet-between__600 > *:not(:first-child):before {
    content: "•";
    font-family: Arial;
  }

  .chevron-after__600:after,
  .heirs-chevron-after__600 > *:after,
  .chevron-before__600:before,
  .heirs-chevron-before__600 > *:before,
  .heirs-chevron-between__600 > *:not(:first-child):before {
    content: "»";
  }

  .chevron-l-after__600:after,
  .heirs-chevron-l-after__600 > *:after,
  .chevron-l-before__600:before,
  .heirs-chevron-l-before__600 > *:before,
  .heirs-chevron-l-between__600 > *:not(:first-child):before {
    content: "«";
  }

  .square-after__600:after,
  .heirs-square-after__600 > *:after,
  .square-before__600:before,
  .heirs-square-before__600 > *:before,
  .heirs-square-between__600 > *:not(:first-child):before {
    content: "■";
    font-family: Arial;
  }

  .nothing-after__600:after,
  .heirs-nothing-after__600 > *:after,
  .nothing-before__600:before,
  .heirs-nothing-before__600 > *:before,
  .heirs-nothing-between__600 > *:not(:first-child):before {
    content: "";
    margin-left: 0;
    margin-right: 0;
  }

  .font-size-i__600,
  .heirs-font-size-i__600 > * {
    font-size: inherit !important;
  }

  .font-size-0__600,
  .heirs-font-size-0__600 > * {
    font-size: 0 !important;
  }
  .font-size-1-8__600,
  .heirs-font-size-1-8__600 > * {
    font-size: 0.125rem !important;
  }
  .font-size-1-4__600,
  .heirs-font-size-1-4__600 > * {
    font-size: 0.25rem !important;
  }
  .font-size-3-8__600,
  .heirs-font-size-3-8__600 > * {
    font-size: 0.375rem !important;
  }
  .font-size-1-2__600,
  .heirs-font-size-1-2__600 > * {
    font-size: 0.5rem !important;
  }
  .font-size-5-8__600,
  .heirs-font-size-5-8__600 > * {
    font-size: 0.625rem !important;
  }
  .font-size-3-4__600,
  .heirs-font-size-3-4__600 > * {
    font-size: 0.75rem !important;
  }
  .font-size-7-8__600,
  .heirs-font-size-7-8__600 > * {
    font-size: 0.875rem !important;
  }

  .font-size-1__600,
  .heirs-font-size-1__600 > * {
    font-size: 1rem !important;
  }
  .font-size-1-1-8__600,
  .heirs-font-size-1-1-8__600 > * {
    font-size: 1.125rem !important;
  }
  .font-size-1-1-4__600,
  .heirs-font-size-1-1-4__600 > * {
    font-size: 1.25rem !important;
  }
  .font-size-1-3-8__600,
  .heirs-font-size-1-3-8__600 > * {
    font-size: 1.375rem !important;
  }
  .font-size-1-1-2__600,
  .heirs-font-size-1-1-2__600 > * {
    font-size: 1.5rem !important;
  }
  .font-size-1-5-8__600,
  .heirs-font-size-1-5-8__600 > * {
    font-size: 1.625rem !important;
  }
  .font-size-1-3-4__600,
  .heirs-font-size-1-3-4__600 > * {
    font-size: 1.75rem !important;
  }
  .font-size-1-7-8__600,
  .heirs-font-size-1-7-8__600 > * {
    font-size: 1.875rem !important;
  }

  .font-size-2__600,
  .heirs-font-size-2__600 > * {
    font-size: 2rem !important;
  }
  .font-size-2-1-8__600,
  .heirs-font-size-2-1-8__600 > * {
    font-size: 2.125rem !important;
  }
  .font-size-2-1-4__600,
  .heirs-font-size-2-1-4__600 > * {
    font-size: 2.25rem !important;
  }
  .font-size-2-3-8__600,
  .heirs-font-size-2-3-8__600 > * {
    font-size: 2.375rem !important;
  }
  .font-size-2-1-2__600,
  .heirs-font-size-2-1-2__600 > * {
    font-size: 2.5rem !important;
  }
  .font-size-2-5-8__600,
  .heirs-font-size-2-5-8__600 > * {
    font-size: 2.625rem !important;
  }
  .font-size-2-3-4__600,
  .heirs-font-size-2-3-4__600 > * {
    font-size: 2.75rem !important;
  }
  .font-size-2-7-8__600,
  .heirs-font-size-2-7-8__600 > * {
    font-size: 2.875rem !important;
  }

  .font-size-3__600,
  .heirs-font-size-3__600 > * {
    font-size: 3rem !important;
  }
  .font-size-3-1-8__600,
  .heirs-font-size-3-1-8__600 > * {
    font-size: 3.125rem !important;
  }
  .font-size-3-1-4__600,
  .heirs-font-size-3-1-4__600 > * {
    font-size: 3.25rem !important;
  }
  .font-size-3-3-8__600,
  .heirs-font-size-3-3-8__600 > * {
    font-size: 3.375rem !important;
  }
  .font-size-3-1-2__600,
  .heirs-font-size-3-1-2__600 > * {
    font-size: 3.5rem !important;
  }
  .font-size-3-5-8__600,
  .heirs-font-size-3-5-8__600 > * {
    font-size: 3.625rem !important;
  }
  .font-size-3-3-4__600,
  .heirs-font-size-3-3-4__600 > * {
    font-size: 3.75rem !important;
  }
  .font-size-3-7-8__600,
  .heirs-font-size-3-7-8__600 > * {
    font-size: 3.875rem !important;
  }

  .font-size-4__600,
  .heirs-font-size-4__600 > * {
    font-size: 4rem !important;
  }
  .font-size-4-1-8__600,
  .heirs-font-size-4-1-8__600 > * {
    font-size: 4.125rem !important;
  }
  .font-size-4-1-4__600,
  .heirs-font-size-4-1-4__600 > * {
    font-size: 4.25rem !important;
  }
  .font-size-4-3-8__600,
  .heirs-font-size-4-3-8__600 > * {
    font-size: 4.375rem !important;
  }
  .font-size-4-1-2__600,
  .heirs-font-size-4-1-2__600 > * {
    font-size: 4.5rem !important;
  }
  .font-size-4-5-8__600,
  .heirs-font-size-4-5-8__600 > * {
    font-size: 4.625rem !important;
  }
  .font-size-4-3-4__600,
  .heirs-font-size-4-3-4__600 > * {
    font-size: 4.75rem !important;
  }
  .font-size-4-7-8__600,
  .heirs-font-size-4-7-8__600 > * {
    font-size: 4.875rem !important;
  }

  .font-style-i__600,
  .heirs-font-style-i__600 > * {
    font-style: italic !important;
  }
  .font-style-n__600,
  .heirs-font-style-n__600 > * {
    font-style: normal !important;
  }

  .font-weight-200__600,
  .heirs-font-weight-200__600 > * {
    font-weight: 200 !important;
  }
  .font-weight-300__600,
  .heirs-font-weight-300__600 > * {
    font-weight: 300 !important;
  }
  .font-weight-400__600,
  .heirs-font-weight-400__600 > * {
    font-weight: 400 !important;
  }
  .font-weight-500__600,
  .heirs-font-weight-500__600 > * {
    font-weight: 500 !important;
  }
  .font-weight-600__600,
  .heirs-font-weight-600__600 > * {
    font-weight: 600 !important;
  }

  .font-family-monospace__600,
  .heirs-font-family-monospace__600 > * {
    font-family: Consolas, Monaco, monospace !important;
  }
  .font-family-sans-serif__600,
  .heirs-font-family-sans-serif__600 > * {
    font-family: Arial, sans-serif !important;
  }
  .font-family-serif__600,
  .heirs-font-family-serif__600 > * {
    font-family: Times, serif !important;
  }

  .hyphens-a__600,
  .heirs-hyphens-a__600 > * {
    -ms-hyphens: auto !important;
    -webkit-hyphens: auto !important;
    hyphens: auto !important;
  }
  .hyphens-m__600,
  .heirs-hyphens-m__600 > * {
    -ms-hyphens: manual !important;
    -webkit-hyphens: manual !important;
    hyphens: manual !important;
  }
  .hyphens-n__600,
  .heirs-hyphens-n__600 > * {
    -ms-hyphens: none !important;
    -webkit-hyphens: none !important;
    hyphens: none !important;
  }

  .line-height-i__600,
  .heirs-line-height-i__600 > * {
    line-height: inherit !important;
  }
  .line-height-1w__600,
  .heirs-line-height-1w__600 > * {
    line-height: 1 !important;
  }

  .line-height-0__600,
  .heirs-line-height-0__600 > * {
    line-height: 0 !important;
  }
  .line-height-1-8__600,
  .heirs-line-height-1-8__600 > * {
    line-height: 0.125rem !important;
  }
  .line-height-1-4__600,
  .heirs-line-height-1-4__600 > * {
    line-height: 0.25rem !important;
  }
  .line-height-3-8__600,
  .heirs-line-height-3-8__600 > * {
    line-height: 0.375rem !important;
  }
  .line-height-1-2__600,
  .heirs-line-height-1-2__600 > * {
    line-height: 0.5rem !important;
  }
  .line-height-5-8__600,
  .heirs-line-height-5-8__600 > * {
    line-height: 0.625rem !important;
  }
  .line-height-3-4__600,
  .heirs-line-height-3-4__600 > * {
    line-height: 0.75rem !important;
  }
  .line-height-7-8__600,
  .heirs-line-height-7-8__600 > * {
    line-height: 0.875rem !important;
  }

  .line-height-1__600,
  .heirs-line-height-1__600 > * {
    line-height: 1rem !important;
  }
  .line-height-1-1-8__600,
  .heirs-line-height-1-1-8__600 > * {
    line-height: 1.125rem !important;
  }
  .line-height-1-1-4__600,
  .heirs-line-height-1-1-4__600 > * {
    line-height: 1.25rem !important;
  }
  .line-height-1-3-8__600,
  .heirs-line-height-1-3-8__600 > * {
    line-height: 1.375rem !important;
  }
  .line-height-1-1-2__600,
  .heirs-line-height-1-1-2__600 > * {
    line-height: 1.5rem !important;
  }
  .line-height-1-5-8__600,
  .heirs-line-height-1-5-8__600 > * {
    line-height: 1.625rem !important;
  }
  .line-height-1-3-4__600,
  .heirs-line-height-1-3-4__600 > * {
    line-height: 1.75rem !important;
  }
  .line-height-1-7-8__600,
  .heirs-line-height-1-7-8__600 > * {
    line-height: 1.875rem !important;
  }

  .line-height-2__600,
  .heirs-line-height-2__600 > * {
    line-height: 2rem !important;
  }
  .line-height-2-1-8__600,
  .heirs-line-height-2-1-8__600 > * {
    line-height: 2.125rem !important;
  }
  .line-height-2-1-4__600,
  .heirs-line-height-2-1-4__600 > * {
    line-height: 2.25rem !important;
  }
  .line-height-2-3-8__600,
  .heirs-line-height-2-3-8__600 > * {
    line-height: 2.375rem !important;
  }
  .line-height-2-1-2__600,
  .heirs-line-height-2-1-2__600 > * {
    line-height: 2.5rem !important;
  }
  .line-height-2-5-8__600,
  .heirs-line-height-2-5-8__600 > * {
    line-height: 2.625rem !important;
  }
  .line-height-2-3-4__600,
  .heirs-line-height-2-3-4__600 > * {
    line-height: 2.75rem !important;
  }
  .line-height-2-7-8__600,
  .heirs-line-height-2-7-8__600 > * {
    line-height: 2.875rem !important;
  }

  .line-height-3__600,
  .heirs-line-height-3__600 > * {
    line-height: 3rem !important;
  }
  .line-height-3-1-8__600,
  .heirs-line-height-3-1-8__600 > * {
    line-height: 3.125rem !important;
  }
  .line-height-3-1-4__600,
  .heirs-line-height-3-1-4__600 > * {
    line-height: 3.25rem !important;
  }
  .line-height-3-3-8__600,
  .heirs-line-height-3-3-8__600 > * {
    line-height: 3.375rem !important;
  }
  .line-height-3-1-2__600,
  .heirs-line-height-3-1-2__600 > * {
    line-height: 3.5rem !important;
  }
  .line-height-3-5-8__600,
  .heirs-line-height-3-5-8__600 > * {
    line-height: 3.625rem !important;
  }
  .line-height-3-3-4__600,
  .heirs-line-height-3-3-4__600 > * {
    line-height: 3.75rem !important;
  }
  .line-height-3-7-8__600,
  .heirs-line-height-3-7-8__600 > * {
    line-height: 3.875rem !important;
  }

  .line-height-4__600,
  .heirs-line-height-4__600 > * {
    line-height: 4rem !important;
  }
  .line-height-4-1-8__600,
  .heirs-line-height-4-1-8__600 > * {
    line-height: 4.125rem !important;
  }
  .line-height-4-1-4__600,
  .heirs-line-height-4-1-4__600 > * {
    line-height: 4.25rem !important;
  }
  .line-height-4-3-8__600,
  .heirs-line-height-4-3-8__600 > * {
    line-height: 4.375rem !important;
  }
  .line-height-4-1-2__600,
  .heirs-line-height-4-1-2__600 > * {
    line-height: 4.5rem !important;
  }
  .line-height-4-5-8__600,
  .heirs-line-height-4-5-8__600 > * {
    line-height: 4.625rem !important;
  }
  .line-height-4-3-4__600,
  .heirs-line-height-4-3-4__600 > * {
    line-height: 4.75rem !important;
  }
  .line-height-4-7-8__600,
  .heirs-line-height-4-7-8__600 > * {
    line-height: 4.875rem !important;
  }

  .text-align-c__600,
  .heirs-text-align-c__600 > * {
    text-align: center !important;
  }
  .text-align-j__600,
  .heirs-text-align-j__600 > * {
    text-align: justify !important;
  }
  .text-align-l__600,
  .heirs-text-align-l__600 > * {
    text-align: left !important;
  }
  .text-align-r__600,
  .heirs-text-align-r__600 > * {
    text-align: right !important;
  }

  .text-decoration-lt__600,
  .heirs-text-decoration-lt__600 > * {
    text-decoration: line-through !important;
  }
  .text-decoration-o__600,
  .heirs-text-decoration-o__600 > * {
    text-decoration: overline !important;
  }
  .text-decoration-u__600,
  .heirs-text-decoration-u__600 > * {
    text-decoration: underline !important;
  }
  .text-decoration-uo__600,
  .heirs-text-decoration-uo__600 > * {
    text-decoration: underline overline !important;
  }
  .text-decoration-n__600,
  .heirs-text-decoration-n__600 > * {
    text-decoration: none !important;
  }

  .text-transform-l__600,
  .heirs-text-transform-l__600 > * {
    text-transform: lowercase !important;
  }
  .text-transform-u__600,
  .heirs-text-transform-u__600 > * {
    text-transform: uppercase !important;
  }
  .text-transform-n__600,
  .heirs-text-transform-n__600 > * {
    text-transform: none !important;
  }

  .white-space-n__600,
  .heirs-white-space-n__600 > * {
    white-space: normal !important;
  }
  .white-space-nw__600,
  .heirs-white-space-nw__600 > * {
    white-space: nowrap !important;
  }
  .white-space-p__600,
  .heirs-white-space-p__600 > * {
    white-space: pre !important;
  }
  .white-space-pl__600,
  .heirs-white-space-pl__600 > * {
    white-space: pre-line !important;
  }
  .white-space-pw__600,
  .heirs-white-space-pw__600 > * {
    white-space: pre-wrap !important;
  }

  .word-break-ba__600,
  .heirs-word-break-ba__600 > * {
    word-break: break-all !important;
  }
  .word-break-ka__600,
  .heirs-word-break-ka__600 > * {
    word-break: keep-all !important;
  }
  .word-break-n__600,
  .heirs-word-break-n__600 > * {
    word-break: normal !important;
  }

  .word-wrap-bw__600,
  .heirs-word-wrap-bw__600 > * {
    word-wrap: break-word !important;
  }
  .word-wrap-n__600,
  .heirs-word-wrap-n__600 > * {
    word-wrap: normal !important;
  }

  .display-b__600,
  .heirs-display-b__600 > *,
  .show__600,
  .heirs-show__600 > * {
    display: block !important;
  }
  .display-i__600,
  .heirs-display-i__600 > * {
    display: inline !important;
  }
  .display-ib__600,
  .heirs-display-ib__600 > * {
    display: inline-block !important;
  }
  .display-it__600,
  .heirs-display-it__600 > * {
    display: inline-table !important;
  }
  .display-li__600,
  .heirs-display-li__600 > * {
    display: list-item !important;
  }
  .display-tcap__600,
  .heirs-display-tcap__600 > * {
    display: table-caption !important;
  }
  .display-td__600,
  .heirs-display-td__600 > * {
    display: table-cell !important;
  }
  .display-tcg__600,
  .heirs-display-tcg__600 > * {
    display: table-column-group !important;
  }
  .display-tc__600,
  .heirs-display-tc__600 > * {
    display: table-column !important;
  }
  .display-tfg__600,
  .heirs-display-tfg__600 > * {
    display: table-footer-group !important;
  }
  .display-thg__600,
  .heirs-display-thg__600 > * {
    display: table-header-group !important;
  }
  .display-trg__600,
  .heirs-display-trg__600 > * {
    display: table-row-group !important;
  }
  .display-tr__600,
  .heirs-display-tr__600 > * {
    display: table-row !important;
  }
  .display-t__600,
  .heirs-display-t__600 > * {
    display: table !important;
  }
  .display-n__600,
  .heirs-display-n__600 > *,
  .hide__600,
  .heirs-hide__600 > * {
    display: none !important;
  }

  .opacity-0__600,
  .heirs-opacity-0__600 > * {
    opacity: 0 !important;
  }
  .opacity-1__600,
  .heirs-opacity-1__600 > * {
    opacity: 1 !important;
  }

  .visibility-h__600,
  .heirs-visibility-h__600 > *,
  .hidden__600,
  .heirs-hidden__600 > * {
    visibility: hidden !important;
  }
  .visibility-v__600,
  .heirs-visibility-v__600 > *,
  .visible__600,
  .heirs-visible__600 > * {
    visibility: visible !important;
  }

  .clear-l__600,
  .heirs-clear-l__600 > * {
    clear: left !important;
  }
  .clear-r__600,
  .heirs-clear-r__600 > * {
    clear: right !important;
  }
  .clear-b__600,
  .heirs-clear-b__600 > * {
    clear: both !important;
  }
  .clear-n__600,
  .heirs-clear-n__600 > * {
    clear: none !important;
  }

  .float-l__600,
  .heirs-float-l__600 > * {
    float: left !important;
  }
  .float-r__600,
  .heirs-float-r__600 > * {
    float: right !important;
  }
  .float-n__600,
  .heirs-float-n__600 > * {
    float: none !important;
  }

  .position-a__600,
  .heirs-position-a__600 > * {
    position: absolute !important;
  }
  .position-f__600,
  .heirs-position-f__600 > * {
    position: fixed !important;
  }
  .position-r__600,
  .heirs-position-r__600 > * {
    position: relative !important;
  }
  .position-s__600,
  .heirs-position-s__600 > * {
    position: static !important;
  }

  .vertical-align-bl__600,
  .heirs-vertical-align-bl__600 > * {
    vertical-align: baseline !important;
  }
  .vertical-align-b__600,
  .heirs-vertical-align-b__600 > * {
    vertical-align: bottom !important;
  }
  .vertical-align-m__600,
  .heirs-vertical-align-m__600 > * {
    vertical-align: middle !important;
  }
  .vertical-align-sub__600,
  .heirs-vertical-align-sub__600 > * {
    vertical-align: sub !important;
  }
  .vertical-align-sup__600,
  .heirs-vertical-align-sup__600 > * {
    vertical-align: super !important;
  }
  .vertical-align-tb__600,
  .heirs-vertical-align-tb__600 > * {
    vertical-align: text-bottom !important;
  }
  .vertical-align-tt__600,
  .heirs-vertical-align-tt__600 > * {
    vertical-align: text-top !important;
  }
  .vertical-align-t__600,
  .heirs-vertical-align-t__600 > * {
    vertical-align: top !important;
  }

  .height-a__600,
  .heirs-height-a__600 > * {
    height: auto !important;
  }
  .height-full__600,
  .heirs-height-full__600 > * {
    height: 100% !important;
  }
  .height-i__600,
  .heirs-height-i__600 > * {
    height: inherit !important;
  }
  .height-0__600,
  .heirs-height-0__600 > * {
    height: 0 !important;
  }
  .height-1__600,
  .heirs-height-1__600 > * {
    height: 1px !important;
  }

  .max-height-full__600,
  .heirs-max-height-full__600 > * {
    max-height: 100% !important;
  }
  .max-height-i__600,
  .heirs-max-height-i__600 > * {
    max-height: inherit !important;
  }
  .max-height-0__600,
  .heirs-max-height-0__600 > * {
    max-height: 0 !important;
  }
  .max-height-1__600,
  .heirs-max-height-1__600 > * {
    max-height: 1px !important;
  }

  .min-height-a__600,
  .heirs-min-height-a__600 > * {
    min-height: auto !important;
  }
  .min-height-full__600,
  .heirs-min-height-full__600 > * {
    min-height: 100% !important;
  }
  .min-height-i__600,
  .heirs-min-height-i__600 > * {
    min-height: inherit !important;
  }
  .min-height-0__600,
  .heirs-min-height-0__600 > * {
    min-height: 0 !important;
  }
  .min-height-1__600,
  .heirs-min-height-1__600 > * {
    min-height: 1px !important;
  }

  .width-a__600,
  .heirs-width-a__600 > * {
    width: auto !important;
  }
  .width-full__600,
  .heirs-width-full__600 > * {
    width: 100% !important;
  }
  .width-i__600,
  .heirs-width-i__600 > * {
    width: inherit !important;
  }
  .width-0__600,
  .heirs-width-0__600 > * {
    width: 0 !important;
  }

  .width-1200__600,
  .heirs-width-1200__600 > * {
    width: 1200px !important;
  }
  .width-900__600,
  .heirs-width-900__600 > * {
    width: 900px !important;
  }
  .width-600__600,
  .heirs-width-600__600 > * {
    width: 600px !important;
  }
  .width-320__600,
  .heirs-width-320__600 > * {
    width: 320px !important;
  }

  .width-1-2__600,
  .heirs-width-1-2__600 > * {
    width: 50% !important;
  }
  .width-1-3__600,
  .heirs-width-1-3__600 > * {
    width: 33.33333333333333% !important;
  }
  .width-1-4__600,
  .heirs-width-1-4__600 > * {
    width: 25% !important;
  }
  .width-1-5__600,
  .heirs-width-1-5__600 > * {
    width: 20% !important;
  }
  .width-1-6__600,
  .heirs-width-1-6__600 > * {
    width: 16.66666666666667% !important;
  }
  .width-1-7__600,
  .heirs-width-1-7__600 > * {
    width: 14.28571428571429% !important;
  }
  .width-1-8__600,
  .heirs-width-1-8__600 > * {
    width: 12.5% !important;
  }
  .width-1-9__600,
  .heirs-width-1-9__600 > * {
    width: 11.11111111111111% !important;
  }
  .width-1-10__600,
  .heirs-width-1-10__600 > * {
    width: 10% !important;
  }
  .width-1-11__600,
  .heirs-width-1-11__600 > * {
    width: 9.090909090909091% !important;
  }
  .width-1-12__600,
  .heirs-width-1-12__600 > * {
    width: 8.333333333333333% !important;
  }
  .width-1-20__600,
  .heirs-width-1-20__600 > * {
    width: 5% !important;
  }
  .width-2-3__600,
  .heirs-width-2-3__600 > * {
    width: 66.66666666666667% !important;
  }
  .width-2-5__600,
  .heirs-width-2-5__600 > * {
    width: 40% !important;
  }
  .width-2-7__600,
  .heirs-width-2-7__600 > * {
    width: 28.57142857142858% !important;
  }
  .width-2-9__600,
  .heirs-width-2-9__600 > * {
    width: 22.22222222222222% !important;
  }
  .width-2-11__600,
  .heirs-width-2-11__600 > * {
    width: 18.18181818181818% !important;
  }
  .width-3-4__600,
  .heirs-width-3-4__600 > * {
    width: 75% !important;
  }
  .width-3-5__600,
  .heirs-width-3-5__600 > * {
    width: 60% !important;
  }
  .width-3-7__600,
  .heirs-width-3-7__600 > * {
    width: 42.85714285714287% !important;
  }
  .width-3-8__600,
  .heirs-width-3-8__600 > * {
    width: 37.5% !important;
  }
  .width-3-10__600,
  .heirs-width-3-10__600 > * {
    width: 30% !important;
  }
  .width-3-11__600,
  .heirs-width-3-11__600 > * {
    width: 27.27272727272727% !important;
  }
  .width-4-5__600,
  .heirs-width-4-5__600 > * {
    width: 80% !important;
  }
  .width-4-7__600,
  .heirs-width-4-7__600 > * {
    width: 57.14285714285716% !important;
  }
  .width-4-9__600,
  .heirs-width-4-9__600 > * {
    width: 44.44444444444444% !important;
  }
  .width-4-11__600,
  .heirs-width-4-11__600 > * {
    width: 36.36363636363636% !important;
  }
  .width-5-6__600,
  .heirs-width-5-6__600 > * {
    width: 83.33333333333333% !important;
  }
  .width-5-7__600,
  .heirs-width-5-7__600 > * {
    width: 71.42857142857145% !important;
  }
  .width-5-8__600,
  .heirs-width-5-8__600 > * {
    width: 62.5% !important;
  }
  .width-5-9__600,
  .heirs-width-5-9__600 > * {
    width: 55.55555555555555% !important;
  }
  .width-5-11__600,
  .heirs-width-5-11__600 > * {
    width: 45.45454545454546% !important;
  }
  .width-5-12__600,
  .heirs-width-5-12__600 > * {
    width: 41.66666666666667% !important;
  }
  .width-6-7__600,
  .heirs-width-6-7__600 > * {
    width: 85.71428571428574% !important;
  }
  .width-6-11__600,
  .heirs-width-6-11__600 > * {
    width: 54.54545454545455% !important;
  }
  .width-7-8__600,
  .heirs-width-7-8__600 > * {
    width: 87.5% !important;
  }
  .width-7-9__600,
  .heirs-width-7-9__600 > * {
    width: 77.77777777777777% !important;
  }
  .width-7-10__600,
  .heirs-width-7-10__600 > * {
    width: 70% !important;
  }
  .width-7-11__600,
  .heirs-width-7-11__600 > * {
    width: 63.63636363636364% !important;
  }
  .width-7-12__600,
  .heirs-width-7-12__600 > * {
    width: 58.33333333333333% !important;
  }
  .width-8-9__600,
  .heirs-width-8-9__600 > * {
    width: 88.88888888888888% !important;
  }
  .width-8-11__600,
  .heirs-width-8-11__600 > * {
    width: 72.72727272727273% !important;
  }
  .width-9-10__600,
  .heirs-width-9-10__600 > * {
    width: 90% !important;
  }
  .width-9-11__600,
  .heirs-width-9-11__600 > * {
    width: 81.81818181818182% !important;
  }
  .width-10-11__600,
  .heirs-width-10-11__600 > * {
    width: 90.90909090909091% !important;
  }
  .width-11-12__600,
  .heirs-width-11-12__600 > * {
    width: 91.66666666666666% !important;
  }

  .max-width-full__600,
  .heirs-max-width-full__600 > * {
    max-width: 100% !important;
  }
  .max-width-i__600,
  .heirs-max-width-i__600 > * {
    max-width: inherit !important;
  }
  .max-width-n__600,
  .heirs-max-width-n__600 > * {
    max-width: none !important;
  }
  .max-width-0__600,
  .heirs-max-width-0__600 > * {
    max-width: 0 !important;
  }

  .max-width-1200__600,
  .heirs-max-width-1200__600 > * {
    max-width: 1200px !important;
  }
  .max-width-900__600,
  .heirs-max-width-900__600 > * {
    max-width: 900px !important;
  }
  .max-width-600__600,
  .heirs-max-width-600__600 > * {
    max-width: 600px !important;
  }
  .max-width-320__600,
  .heirs-max-width-320__600 > * {
    max-width: 320px !important;
  }

  .max-width-1-2__600,
  .heirs-max-width-1-2__600 > * {
    max-width: 50% !important;
  }
  .max-width-1-3__600,
  .heirs-max-width-1-3__600 > * {
    max-width: 33.33333333333333% !important;
  }
  .max-width-1-4__600,
  .heirs-max-width-1-4__600 > * {
    max-width: 25% !important;
  }
  .max-width-1-5__600,
  .heirs-max-width-1-5__600 > * {
    max-width: 20% !important;
  }
  .max-width-1-6__600,
  .heirs-max-width-1-6__600 > * {
    max-width: 16.66666666666667% !important;
  }
  .max-width-1-7__600,
  .heirs-max-width-1-7__600 > * {
    max-width: 14.28571428571429% !important;
  }
  .max-width-1-8__600,
  .heirs-max-width-1-8__600 > * {
    max-width: 12.5% !important;
  }
  .max-width-1-9__600,
  .heirs-max-width-1-9__600 > * {
    max-width: 11.11111111111111% !important;
  }
  .max-width-1-10__600,
  .heirs-max-width-1-10__600 > * {
    max-width: 10% !important;
  }
  .max-width-1-11__600,
  .heirs-max-width-1-11__600 > * {
    max-width: 9.090909090909091% !important;
  }
  .max-width-1-12__600,
  .heirs-max-width-1-12__600 > * {
    max-width: 8.333333333333333% !important;
  }
  .max-width-1-20__600,
  .heirs-max-width-1-20__600 > * {
    max-width: 5% !important;
  }
  .max-width-2-3__600,
  .heirs-max-width-2-3__600 > * {
    max-width: 66.66666666666667% !important;
  }
  .max-width-2-5__600,
  .heirs-max-width-2-5__600 > * {
    max-width: 40% !important;
  }
  .max-width-2-7__600,
  .heirs-max-width-2-7__600 > * {
    max-width: 28.57142857142858% !important;
  }
  .max-width-2-9__600,
  .heirs-max-width-2-9__600 > * {
    max-width: 22.22222222222222% !important;
  }
  .max-width-2-11__600,
  .heirs-max-width-2-11__600 > * {
    max-width: 18.18181818181818% !important;
  }
  .max-width-3-4__600,
  .heirs-max-width-3-4__600 > * {
    max-width: 75% !important;
  }
  .max-width-3-5__600,
  .heirs-max-width-3-5__600 > * {
    max-width: 60% !important;
  }
  .max-width-3-7__600,
  .heirs-max-width-3-7__600 > * {
    max-width: 42.85714285714287% !important;
  }
  .max-width-3-8__600,
  .heirs-max-width-3-8__600 > * {
    max-width: 37.5% !important;
  }
  .max-width-3-10__600,
  .heirs-max-width-3-10__600 > * {
    max-width: 30% !important;
  }
  .max-width-3-11__600,
  .heirs-max-width-3-11__600 > * {
    max-width: 27.27272727272727% !important;
  }
  .max-width-4-5__600,
  .heirs-max-width-4-5__600 > * {
    max-width: 80% !important;
  }
  .max-width-4-7__600,
  .heirs-max-width-4-7__600 > * {
    max-width: 57.14285714285716% !important;
  }
  .max-width-4-9__600,
  .heirs-max-width-4-9__600 > * {
    max-width: 44.44444444444444% !important;
  }
  .max-width-4-11__600,
  .heirs-max-width-4-11__600 > * {
    max-width: 36.36363636363636% !important;
  }
  .max-width-5-6__600,
  .heirs-max-width-5-6__600 > * {
    max-width: 83.33333333333333% !important;
  }
  .max-width-5-7__600,
  .heirs-max-width-5-7__600 > * {
    max-width: 71.42857142857145% !important;
  }
  .max-width-5-8__600,
  .heirs-max-width-5-8__600 > * {
    max-width: 62.5% !important;
  }
  .max-width-5-9__600,
  .heirs-max-width-5-9__600 > * {
    max-width: 55.55555555555555% !important;
  }
  .max-width-5-11__600,
  .heirs-max-width-5-11__600 > * {
    max-width: 45.45454545454546% !important;
  }
  .max-width-5-12__600,
  .heirs-max-width-5-12__600 > * {
    max-width: 41.66666666666667% !important;
  }
  .max-width-6-7__600,
  .heirs-max-width-6-7__600 > * {
    max-width: 85.71428571428574% !important;
  }
  .max-width-6-11__600,
  .heirs-max-width-6-11__600 > * {
    max-width: 54.54545454545455% !important;
  }
  .max-width-7-8__600,
  .heirs-max-width-7-8__600 > * {
    max-width: 87.5% !important;
  }
  .max-width-7-9__600,
  .heirs-max-width-7-9__600 > * {
    max-width: 77.77777777777777% !important;
  }
  .max-width-7-10__600,
  .heirs-max-width-7-10__600 > * {
    max-width: 70% !important;
  }
  .max-width-7-11__600,
  .heirs-max-width-7-11__600 > * {
    max-width: 63.63636363636364% !important;
  }
  .max-width-7-12__600,
  .heirs-max-width-7-12__600 > * {
    max-width: 58.33333333333333% !important;
  }
  .max-width-8-9__600,
  .heirs-max-width-8-9__600 > * {
    max-width: 88.88888888888888% !important;
  }
  .max-width-8-11__600,
  .heirs-max-width-8-11__600 > * {
    max-width: 72.72727272727273% !important;
  }
  .max-width-9-10__600,
  .heirs-max-width-9-10__600 > * {
    max-width: 90% !important;
  }
  .max-width-9-11__600,
  .heirs-max-width-9-11__600 > * {
    max-width: 81.81818181818182% !important;
  }
  .max-width-10-11__600,
  .heirs-max-width-10-11__600 > * {
    max-width: 90.90909090909091% !important;
  }
  .max-width-11-12__600,
  .heirs-max-width-11-12__600 > * {
    max-width: 91.66666666666666% !important;
  }

  .min-width-a__600,
  .heirs-min-width-a__600 > * {
    min-width: auto !important;
  }
  .min-width-full__600,
  .heirs-min-width-full__600 > * {
    min-width: 100% !important;
  }
  .min-width-i__600,
  .heirs-min-width-i__600 > * {
    min-width: inherit !important;
  }
  .min-width-0__600,
  .heirs-min-width-0__600 > * {
    min-width: 0 !important;
  }

  .min-width-1200__600,
  .heirs-min-width-1200__600 > * {
    min-width: 1200px !important;
  }
  .min-width-900__600,
  .heirs-min-width-900__600 > * {
    min-width: 900px !important;
  }
  .min-width-600__600,
  .heirs-min-width-600__600 > * {
    min-width: 600px !important;
  }
  .min-width-320__600,
  .heirs-min-width-320__600 > * {
    min-width: 320px !important;
  }

  .min-width-1-2__600,
  .heirs-min-width-1-2__600 > * {
    min-width: 50% !important;
  }
  .min-width-1-3__600,
  .heirs-min-width-1-3__600 > * {
    min-width: 33.33333333333333% !important;
  }
  .min-width-1-4__600,
  .heirs-min-width-1-4__600 > * {
    min-width: 25% !important;
  }
  .min-width-1-5__600,
  .heirs-min-width-1-5__600 > * {
    min-width: 20% !important;
  }
  .min-width-1-6__600,
  .heirs-min-width-1-6__600 > * {
    min-width: 16.66666666666667% !important;
  }
  .min-width-1-7__600,
  .heirs-min-width-1-7__600 > * {
    min-width: 14.28571428571429% !important;
  }
  .min-width-1-8__600,
  .heirs-min-width-1-8__600 > * {
    min-width: 12.5% !important;
  }
  .min-width-1-9__600,
  .heirs-min-width-1-9__600 > * {
    min-width: 11.11111111111111% !important;
  }
  .min-width-1-10__600,
  .heirs-min-width-1-10__600 > * {
    min-width: 10% !important;
  }
  .min-width-1-11__600,
  .heirs-min-width-1-11__600 > * {
    min-width: 9.090909090909091% !important;
  }
  .min-width-1-12__600,
  .heirs-min-width-1-12__600 > * {
    min-width: 8.333333333333333% !important;
  }
  .min-width-1-20__600,
  .heirs-min-width-1-20__600 > * {
    min-width: 5% !important;
  }
  .min-width-2-3__600,
  .heirs-min-width-2-3__600 > * {
    min-width: 66.66666666666667% !important;
  }
  .min-width-2-5__600,
  .heirs-min-width-2-5__600 > * {
    min-width: 40% !important;
  }
  .min-width-2-7__600,
  .heirs-min-width-2-7__600 > * {
    min-width: 28.57142857142858% !important;
  }
  .min-width-2-9__600,
  .heirs-min-width-2-9__600 > * {
    min-width: 22.22222222222222% !important;
  }
  .min-width-2-11__600,
  .heirs-min-width-2-11__600 > * {
    min-width: 18.18181818181818% !important;
  }
  .min-width-3-4__600,
  .heirs-min-width-3-4__600 > * {
    min-width: 75% !important;
  }
  .min-width-3-5__600,
  .heirs-min-width-3-5__600 > * {
    min-width: 60% !important;
  }
  .min-width-3-7__600,
  .heirs-min-width-3-7__600 > * {
    min-width: 42.85714285714287% !important;
  }
  .min-width-3-8__600,
  .heirs-min-width-3-8__600 > * {
    min-width: 37.5% !important;
  }
  .min-width-3-10__600,
  .heirs-min-width-3-10__600 > * {
    min-width: 30% !important;
  }
  .min-width-3-11__600,
  .heirs-min-width-3-11__600 > * {
    min-width: 27.27272727272727% !important;
  }
  .min-width-4-5__600,
  .heirs-min-width-4-5__600 > * {
    min-width: 80% !important;
  }
  .min-width-4-7__600,
  .heirs-min-width-4-7__600 > * {
    min-width: 57.14285714285716% !important;
  }
  .min-width-4-9__600,
  .heirs-min-width-4-9__600 > * {
    min-width: 44.44444444444444% !important;
  }
  .min-width-4-11__600,
  .heirs-min-width-4-11__600 > * {
    min-width: 36.36363636363636% !important;
  }
  .min-width-5-6__600,
  .heirs-min-width-5-6__600 > * {
    min-width: 83.33333333333333% !important;
  }
  .min-width-5-7__600,
  .heirs-min-width-5-7__600 > * {
    min-width: 71.42857142857145% !important;
  }
  .min-width-5-8__600,
  .heirs-min-width-5-8__600 > * {
    min-width: 62.5% !important;
  }
  .min-width-5-9__600,
  .heirs-min-width-5-9__600 > * {
    min-width: 55.55555555555555% !important;
  }
  .min-width-5-11__600,
  .heirs-min-width-5-11__600 > * {
    min-width: 45.45454545454546% !important;
  }
  .min-width-5-12__600,
  .heirs-min-width-5-12__600 > * {
    min-width: 41.66666666666667% !important;
  }
  .min-width-6-7__600,
  .heirs-min-width-6-7__600 > * {
    min-width: 85.71428571428574% !important;
  }
  .min-width-6-11__600,
  .heirs-min-width-6-11__600 > * {
    min-width: 54.54545454545455% !important;
  }
  .min-width-7-8__600,
  .heirs-min-width-7-8__600 > * {
    min-width: 87.5% !important;
  }
  .min-width-7-9__600,
  .heirs-min-width-7-9__600 > * {
    min-width: 77.77777777777777% !important;
  }
  .min-width-7-10__600,
  .heirs-min-width-7-10__600 > * {
    min-width: 70% !important;
  }
  .min-width-7-11__600,
  .heirs-min-width-7-11__600 > * {
    min-width: 63.63636363636364% !important;
  }
  .min-width-7-12__600,
  .heirs-min-width-7-12__600 > * {
    min-width: 58.33333333333333% !important;
  }
  .min-width-8-9__600,
  .heirs-min-width-8-9__600 > * {
    min-width: 88.88888888888888% !important;
  }
  .min-width-8-11__600,
  .heirs-min-width-8-11__600 > * {
    min-width: 72.72727272727273% !important;
  }
  .min-width-9-10__600,
  .heirs-min-width-9-10__600 > * {
    min-width: 90% !important;
  }
  .min-width-9-11__600,
  .heirs-min-width-9-11__600 > * {
    min-width: 81.81818181818182% !important;
  }
  .min-width-10-11__600,
  .heirs-min-width-10-11__600 > * {
    min-width: 90.90909090909091% !important;
  }
  .min-width-11-12__600,
  .heirs-min-width-11-12__600 > * {
    min-width: 91.66666666666666% !important;
  }

  .background-n__600,
  .heirs-background-n__600 > * {
    background: none !important;
  }

  .border-n__600,
  .heirs-border-n__600 > * {
    border: none !important;
  }
  .border-b-n__600,
  .heirs-border-b-n__600 > *,
  .border-bt-n__600,
  .heirs-border-bt-n__600 > * {
    border-bottom: none !important;
  }
  .border-l-n__600,
  .heirs-border-l-n__600 > *,
  .border-lr-n__600,
  .heirs-border-lr-n__600 > * {
    border-left: none !important;
  }
  .border-r-n__600,
  .heirs-border-r-n__600 > *,
  .border-lr-n__600,
  .heirs-border-lr-n__600 > * {
    border-right: none !important;
  }
  .border-t-n__600,
  .heirs-border-t-n__600 > *,
  .border-bt-n__600,
  .heirs-border-bt-n__600 > * {
    border-top: none !important;
  }

  .box-sizing-bb__600,
  .heirs-box-sizing-bb__600 > * {
    box-sizing: border-box !important;
  }
  .box-sizing-cb__600,
  .heirs-box-sizing-cb__600 > * {
    box-sizing: content-box !important;
  }

  .center__600,
  .heirs-center__600 > * {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .margin-0__600,
  .heirs-margin-0__600 > * {
    margin: 0 !important;
  }
  .margin-b-0__600,
  .heirs-margin-b-0__600 > *,
  .margin-bt-0__600,
  .heirs-margin-bt-0__600 > * {
    margin-bottom: 0 !important;
  }
  .margin-l-0__600,
  .heirs-margin-l-0__600 > *,
  .margin-lr-0__600,
  .heirs-margin-lr-0__600 > * {
    margin-left: 0 !important;
  }
  .margin-r-0__600,
  .heirs-margin-r-0__600 > *,
  .margin-lr-0__600,
  .heirs-margin-lr-0__600 > * {
    margin-right: 0 !important;
  }
  .margin-t-0__600,
  .heirs-margin-t-0__600 > *,
  .margin-bt-0__600,
  .heirs-margin-bt-0__600 > * {
    margin-top: 0 !important;
  }

  .margin-1-8__600,
  .heirs-margin-1-8__600 > * {
    margin: 0.125rem !important;
  }
  .margin-b-1-8__600,
  .heirs-margin-b-1-8__600 > *,
  .margin-bt-1-8__600,
  .heirs-margin-bt-1-8__600 > * {
    margin-bottom: 0.125rem !important;
  }
  .margin-l-1-8__600,
  .heirs-margin-l-1-8__600 > *,
  .margin-lr-1-8__600,
  .heirs-margin-lr-1-8__600 > * {
    margin-left: 0.125rem !important;
  }
  .margin-r-1-8__600,
  .heirs-margin-r-1-8__600 > *,
  .margin-lr-1-8__600,
  .heirs-margin-lr-1-8__600 > * {
    margin-right: 0.125rem !important;
  }
  .margin-t-1-8__600,
  .heirs-margin-t-1-8__600 > *,
  .margin-bt-1-8__600,
  .heirs-margin-bt-1-8__600 > * {
    margin-top: 0.125rem !important;
  }

  .margin-1-4__600,
  .heirs-margin-1-4__600 > * {
    margin: 0.25rem !important;
  }
  .margin-b-1-4__600,
  .heirs-margin-b-1-4__600 > *,
  .margin-bt-1-4__600,
  .heirs-margin-bt-1-4__600 > * {
    margin-bottom: 0.25rem !important;
  }
  .margin-l-1-4__600,
  .heirs-margin-l-1-4__600 > *,
  .margin-lr-1-4__600,
  .heirs-margin-lr-1-4__600 > * {
    margin-left: 0.25rem !important;
  }
  .margin-r-1-4__600,
  .heirs-margin-r-1-4__600 > *,
  .margin-lr-1-4__600,
  .heirs-margin-lr-1-4__600 > * {
    margin-right: 0.25rem !important;
  }
  .margin-t-1-4__600,
  .heirs-margin-t-1-4__600 > *,
  .margin-bt-1-4__600,
  .heirs-margin-bt-1-4__600 > * {
    margin-top: 0.25rem !important;
  }

  .margin-1-2__600,
  .heirs-margin-1-2__600 > * {
    margin: 0.5rem !important;
  }
  .margin-b-1-2__600,
  .heirs-margin-b-1-2__600 > *,
  .margin-bt-1-2__600,
  .heirs-margin-bt-1-2__600 > * {
    margin-bottom: 0.5rem !important;
  }
  .margin-l-1-2__600,
  .heirs-margin-l-1-2__600 > *,
  .margin-lr-1-2__600,
  .heirs-margin-lr-1-2__600 > * {
    margin-left: 0.5rem !important;
  }
  .margin-r-1-2__600,
  .heirs-margin-r-1-2__600 > *,
  .margin-lr-1-2__600,
  .heirs-margin-lr-1-2__600 > * {
    margin-right: 0.5rem !important;
  }
  .margin-t-1-2__600,
  .heirs-margin-t-1-2__600 > *,
  .margin-bt-1-2__600,
  .heirs-margin-bt-1-2__600 > * {
    margin-top: 0.5rem !important;
  }

  .margin-3-4__600,
  .heirs-margin-3-4__600 > * {
    margin: 0.75rem !important;
  }
  .margin-b-3-4__600,
  .heirs-margin-b-3-4__600 > *,
  .margin-bt-3-4__600,
  .heirs-margin-bt-3-4__600 > * {
    margin-bottom: 0.75rem !important;
  }
  .margin-l-3-4__600,
  .heirs-margin-l-3-4__600 > *,
  .margin-lr-3-4__600,
  .heirs-margin-lr-3-4__600 > * {
    margin-left: 0.75rem !important;
  }
  .margin-r-3-4__600,
  .heirs-margin-r-3-4__600 > *,
  .margin-lr-3-4__600,
  .heirs-margin-lr-3-4__600 > * {
    margin-right: 0.75rem !important;
  }
  .margin-t-3-4__600,
  .heirs-margin-t-3-4__600 > *,
  .margin-bt-3-4__600,
  .heirs-margin-bt-3-4__600 > * {
    margin-top: 0.75rem !important;
  }

  .margin-1__600,
  .heirs-margin-1__600 > * {
    margin: 1rem !important;
  }
  .margin-b-1__600,
  .heirs-margin-b-1__600 > *,
  .margin-bt-1__600,
  .heirs-margin-bt-1__600 > * {
    margin-bottom: 1rem !important;
  }
  .margin-l-1__600,
  .heirs-margin-l-1__600 > *,
  .margin-lr-1__600,
  .heirs-margin-lr-1__600 > * {
    margin-left: 1rem !important;
  }
  .margin-r-1__600,
  .heirs-margin-r-1__600 > *,
  .margin-lr-1__600,
  .heirs-margin-lr-1__600 > * {
    margin-right: 1rem !important;
  }
  .margin-t-1__600,
  .heirs-margin-t-1__600 > *,
  .margin-bt-1__600,
  .heirs-margin-bt-1__600 > * {
    margin-top: 1rem !important;
  }

  .margin-2__600,
  .heirs-margin-2__600 > * {
    margin: 2rem !important;
  }
  .margin-b-2__600,
  .heirs-margin-b-2__600 > *,
  .margin-bt-2__600,
  .heirs-margin-bt-2__600 > * {
    margin-bottom: 2rem !important;
  }
  .margin-l-2__600,
  .heirs-margin-l-2__600 > *,
  .margin-lr-2__600,
  .heirs-margin-lr-2__600 > * {
    margin-left: 2rem !important;
  }
  .margin-r-2__600,
  .heirs-margin-r-2__600 > *,
  .margin-lr-2__600,
  .heirs-margin-lr-2__600 > * {
    margin-right: 2rem !important;
  }
  .margin-t-2__600,
  .heirs-margin-t-2__600 > *,
  .margin-bt-2__600,
  .heirs-margin-bt-2__600 > * {
    margin-top: 2rem !important;
  }

  .margin-3__600,
  .heirs-margin-3__600 > * {
    margin: 3rem !important;
  }
  .margin-b-3__600,
  .heirs-margin-b-3__600 > *,
  .margin-bt-3__600,
  .heirs-margin-bt-3__600 > * {
    margin-bottom: 3rem !important;
  }
  .margin-l-3__600,
  .heirs-margin-l-3__600 > *,
  .margin-lr-3__600,
  .heirs-margin-lr-3__600 > * {
    margin-left: 3rem !important;
  }
  .margin-r-3__600,
  .heirs-margin-r-3__600 > *,
  .margin-lr-3__600,
  .heirs-margin-lr-3__600 > * {
    margin-right: 3rem !important;
  }
  .margin-t-3__600,
  .heirs-margin-t-3__600 > *,
  .margin-bt-3__600,
  .heirs-margin-bt-3__600 > * {
    margin-top: 3rem !important;
  }

  .margin-4__600,
  .heirs-margin-4__600 > * {
    margin: 4rem !important;
  }
  .margin-b-4__600,
  .heirs-margin-b-4__600 > *,
  .margin-bt-4__600,
  .heirs-margin-bt-4__600 > * {
    margin-bottom: 4rem !important;
  }
  .margin-l-4__600,
  .heirs-margin-l-4__600 > *,
  .margin-lr-4__600,
  .heirs-margin-lr-4__600 > * {
    margin-left: 4rem !important;
  }
  .margin-r-4__600,
  .heirs-margin-r-4__600 > *,
  .margin-lr-4__600,
  .heirs-margin-lr-4__600 > * {
    margin-right: 4rem !important;
  }
  .margin-t-4__600,
  .heirs-margin-t-4__600 > *,
  .margin-bt-4__600,
  .heirs-margin-bt-4__600 > * {
    margin-top: 4rem !important;
  }

  .padding-0__600,
  .heirs-padding-0__600 > * {
    padding: 0 !important;
  }
  .padding-b-0__600,
  .heirs-padding-b-0__600 > *,
  .padding-bt-0__600,
  .heirs-padding-bt-0__600 > * {
    padding-bottom: 0 !important;
  }
  .padding-l-0__600,
  .heirs-padding-l-0__600 > *,
  .padding-lr-0__600,
  .heirs-padding-lr-0__600 > * {
    padding-left: 0 !important;
  }
  .padding-r-0__600,
  .heirs-padding-r-0__600 > *,
  .padding-lr-0__600,
  .heirs-padding-lr-0__600 > * {
    padding-right: 0 !important;
  }
  .padding-t-0__600,
  .heirs-padding-t-0__600 > *,
  .padding-bt-0__600,
  .heirs-padding-bt-0__600 > * {
    padding-top: 0 !important;
  }

  .padding-1-8__600,
  .heirs-padding-1-8__600 > * {
    padding: 0.125rem !important;
  }
  .padding-b-1-8__600,
  .heirs-padding-b-1-8__600 > *,
  .padding-bt-1-8__600,
  .heirs-padding-bt-1-8__600 > * {
    padding-bottom: 0.125rem !important;
  }
  .padding-l-1-8__600,
  .heirs-padding-l-1-8__600 > *,
  .padding-lr-1-8__600,
  .heirs-padding-lr-1-8__600 > * {
    padding-left: 0.125rem !important;
  }
  .padding-r-1-8__600,
  .heirs-padding-r-1-8__600 > *,
  .padding-lr-1-8__600,
  .heirs-padding-lr-1-8__600 > * {
    padding-right: 0.125rem !important;
  }
  .padding-t-1-8__600,
  .heirs-padding-t-1-8__600 > *,
  .padding-bt-1-8__600,
  .heirs-padding-bt-1-8__600 > * {
    padding-top: 0.125rem !important;
  }

  .padding-1-4__600,
  .heirs-padding-1-4__600 > * {
    padding: 0.25rem !important;
  }
  .padding-b-1-4__600,
  .heirs-padding-b-1-4__600 > *,
  .padding-bt-1-4__600,
  .heirs-padding-bt-1-4__600 > * {
    padding-bottom: 0.25rem !important;
  }
  .padding-l-1-4__600,
  .heirs-padding-l-1-4__600 > *,
  .padding-lr-1-4__600,
  .heirs-padding-lr-1-4__600 > * {
    padding-left: 0.25rem !important;
  }
  .padding-r-1-4__600,
  .heirs-padding-r-1-4__600 > *,
  .padding-lr-1-4__600,
  .heirs-padding-lr-1-4__600 > * {
    padding-right: 0.25rem !important;
  }
  .padding-t-1-4__600,
  .heirs-padding-t-1-4__600 > *,
  .padding-bt-1-4__600,
  .heirs-padding-bt-1-4__600 > * {
    padding-top: 0.25rem !important;
  }

  .padding-1-2__600,
  .heirs-padding-1-2__600 > * {
    padding: 0.5rem !important;
  }
  .padding-b-1-2__600,
  .heirs-padding-b-1-2__600 > *,
  .padding-bt-1-2__600,
  .heirs-padding-bt-1-2__600 > * {
    padding-bottom: 0.5rem !important;
  }
  .padding-l-1-2__600,
  .heirs-padding-l-1-2__600 > *,
  .padding-lr-1-2__600,
  .heirs-padding-lr-1-2__600 > * {
    padding-left: 0.5rem !important;
  }
  .padding-r-1-2__600,
  .heirs-padding-r-1-2__600 > *,
  .padding-lr-1-2__600,
  .heirs-padding-lr-1-2__600 > * {
    padding-right: 0.5rem !important;
  }
  .padding-t-1-2__600,
  .heirs-padding-t-1-2__600 > *,
  .padding-bt-1-2__600,
  .heirs-padding-bt-1-2__600 > * {
    padding-top: 0.5rem !important;
  }

  .padding-3-4__600,
  .heirs-padding-3-4__600 > * {
    padding: 0.75rem !important;
  }
  .padding-b-3-4__600,
  .heirs-padding-b-3-4__600 > *,
  .padding-bt-3-4__600,
  .heirs-padding-bt-3-4__600 > * {
    padding-bottom: 0.75rem !important;
  }
  .padding-l-3-4__600,
  .heirs-padding-l-3-4__600 > *,
  .padding-lr-3-4__600,
  .heirs-padding-lr-3-4__600 > * {
    padding-left: 0.75rem !important;
  }
  .padding-r-3-4__600,
  .heirs-padding-r-3-4__600 > *,
  .padding-lr-3-4__600,
  .heirs-padding-lr-3-4__600 > * {
    padding-right: 0.75rem !important;
  }
  .padding-t-3-4__600,
  .heirs-padding-t-3-4__600 > *,
  .padding-bt-3-4__600,
  .heirs-padding-bt-3-4__600 > * {
    padding-top: 0.75rem !important;
  }

  .padding-1__600,
  .heirs-padding-1__600 > * {
    padding: 1rem !important;
  }
  .padding-b-1__600,
  .heirs-padding-b-1__600 > *,
  .padding-bt-1__600,
  .heirs-padding-bt-1__600 > * {
    padding-bottom: 1rem !important;
  }
  .padding-l-1__600,
  .heirs-padding-l-1__600 > *,
  .padding-lr-1__600,
  .heirs-padding-lr-1__600 > * {
    padding-left: 1rem !important;
  }
  .padding-r-1__600,
  .heirs-padding-r-1__600 > *,
  .padding-lr-1__600,
  .heirs-padding-lr-1__600 > * {
    padding-right: 1rem !important;
  }
  .padding-t-1__600,
  .heirs-padding-t-1__600 > *,
  .padding-bt-1__600,
  .heirs-padding-bt-1__600 > * {
    padding-top: 1rem !important;
  }

  .padding-2__600,
  .heirs-padding-2__600 > * {
    padding: 2rem !important;
  }
  .padding-b-2__600,
  .heirs-padding-b-2__600 > *,
  .padding-bt-2__600,
  .heirs-padding-bt-2__600 > * {
    padding-bottom: 2rem !important;
  }
  .padding-l-2__600,
  .heirs-padding-l-2__600 > *,
  .padding-lr-2__600,
  .heirs-padding-lr-2__600 > * {
    padding-left: 2rem !important;
  }
  .padding-r-2__600,
  .heirs-padding-r-2__600 > *,
  .padding-lr-2__600,
  .heirs-padding-lr-2__600 > * {
    padding-right: 2rem !important;
  }
  .padding-t-2__600,
  .heirs-padding-t-2__600 > *,
  .padding-bt-2__600,
  .heirs-padding-bt-2__600 > * {
    padding-top: 2rem !important;
  }

  .padding-3__600,
  .heirs-padding-3__600 > * {
    padding: 3rem !important;
  }
  .padding-b-3__600,
  .heirs-padding-b-3__600 > *,
  .padding-bt-3__600,
  .heirs-padding-bt-3__600 > * {
    padding-bottom: 3rem !important;
  }
  .padding-l-3__600,
  .heirs-padding-l-3__600 > *,
  .padding-lr-3__600,
  .heirs-padding-lr-3__600 > * {
    padding-left: 3rem !important;
  }
  .padding-r-3__600,
  .heirs-padding-r-3__600 > *,
  .padding-lr-3__600,
  .heirs-padding-lr-3__600 > * {
    padding-right: 3rem !important;
  }
  .padding-t-3__600,
  .heirs-padding-t-3__600 > *,
  .padding-bt-3__600,
  .heirs-padding-bt-3__600 > * {
    padding-top: 3rem !important;
  }

  .padding-4__600,
  .heirs-padding-4__600 > * {
    padding: 4rem !important;
  }
  .padding-b-4__600,
  .heirs-padding-b-4__600 > *,
  .padding-bt-4__600,
  .heirs-padding-bt-4__600 > * {
    padding-bottom: 4rem !important;
  }
  .padding-l-4__600,
  .heirs-padding-l-4__600 > *,
  .padding-lr-4__600,
  .heirs-padding-lr-4__600 > * {
    padding-left: 4rem !important;
  }
  .padding-r-4__600,
  .heirs-padding-r-4__600 > *,
  .padding-lr-4__600,
  .heirs-padding-lr-4__600 > * {
    padding-right: 4rem !important;
  }
  .padding-t-4__600,
  .heirs-padding-t-4__600 > *,
  .padding-bt-4__600,
  .heirs-padding-bt-4__600 > * {
    padding-top: 4rem !important;
  }
}

/* styles for minimum supported screen width (320px)
   ========================================================================== */

@media all and (max-width: 320px) {
  .cf__320:after,
  .heirs-cf__320 > *:after,
  .cf__320:before,
  .heirs-cf__320 > *:before,
  .break-after__320:after,
  .heirs-break-after__320 > *:after,
  .break-before__320:before,
  .heirs-break-before__320 > *:before {
    clear: both;
    content: "";
    display: table;
  }

  .show__320,
  .heirs-show__320 > * {
    display: block !important;
  }
  .hide__320,
  .heirs-hide__320 > * {
    display: none !important;
  }
}

/* printer styles
   ========================================================================== */

@media print {
  .cf__print:after,
  .heirs-cf__print > *:after,
  .cf__print:before,
  .heirs-cf__print > *:before,
  .break-after__print:after,
  .heirs-break-after__print > *:after,
  .break-before__print:before,
  .heirs-break-before__print > *:before {
    clear: both;
    content: "";
    display: table;
  }

  .show__print,
  .heirs-show__print > * {
    display: block !important;
  }
  .hide__print,
  .heirs-hide__print > * {
    display: none !important;
  }
}

* {
  box-sizing: border-box;
}

html {
  font-size: 1rem;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro";
  -webkit-font-smoothing: unset;
}

.inner {
  margin: 0 auto;
  max-width: 1004px;
  position: relative;
}

nav,
main,
footer {
  padding: 0 2%;
}

.main {
  display: block;
  overflow: hidden;
  margin: 0 0 4.3rem;
  /*padding: 0 0 7rem;*/
}

.main.modalOpen {
  height: 0;
}

.main a {
  text-decoration: none;
  color: #ef6a05;
}

.main a:hover {
  color: #f77700;
}

/*  header
    --------------------------------*/
header.site-head {
  background: #333;
  overflow: hidden;
  padding: 0.625em 2%;
  font-size: clamp(10px, 3.1vw, 1rem);
}
header.site-head .right {
  position: absolute;
  right: 0;
  text-align: right;
}
header.site-head a {
  color: inherit;
}
header.site-head a:hover {
  color: #fff;
}
header.site-head p {
  font-size: 0.875em;
  color: #949494;
  margin: 0;
}
header.site-head p.makers {
  font-size: 0.9375em;
  font-style: italic;
  margin: 0.33333333em 0 -0.33333333em 3.86666667em;
  text-transform: uppercase;
}
header.site-head .right p {
  margin: 0.28571429em 0;
}
header.site-head p a {
  font-weight: 600;
}
header.site-head h1 {
  font-size: 1.875em;
  color: #fff;
  font-weight: 300;
}
header.site-head h1 a > span {
  display: inline-block;
  vertical-align: middle;
}
header.site-head svg {
  display: block;
  width: 1.6em;
}
header.site-head h1 .wolfram {
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 0.333333em;
}

/*  top nav
    --------------------------------*/
nav {
  background: #535353;
  padding: 6px 2%;
}

nav ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

nav ul li {
  list-style-type: none;
  display: inline-block;
}

nav .right {
  position: absolute;
  right: 0;
}

nav a {
  color: #fff;
  display: block;
  text-transform: uppercase;
  margin: 0 0 0 min(2vw, 1.5rem);
  font-size: 0.938rem;
  line-height: 1.86666667em;
}

nav a:hover {
  opacity: 0.5;
}

nav .open-menu {
  background: #757575;
  margin: 6px -2% -6px;
  padding: 6px 2%;
  position: absolute;
  width: 104%;
  z-index: 1000;
  box-shadow: 10px 0px 0px #757575, -10px 0px 0px #757575;
}
nav .open-menu ul li {
  display: block;
  margin: 6px 0;
}
nav .open-menu a {
  margin: 0;
  text-transform: none;
  font-weight: 600;
  padding: 6px;
}

nav .search-form {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: min(36vw, 16.5em);
  margin-right: min(1vw, 1.5em);
}
nav .search-form.full {
  width: 100%;
  margin-right: 0;
}

nav .search {
  background: #757575;
  border: 0;
  border-radius: 3px;
  color: #fff;
  padding: 4px 32px;
  font-size: 0.875rem;
  width: 100%;
  height: 28px;
}

nav button {
  background: none;
  border: 0;
  margin: auto;
}
nav button svg {
  display: block;
}
nav .search-form .search-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
}
nav .search-form .close-button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
}

nav .mobile-buttons {
  display: flex;
  justify-content: flex-end;
}
nav .mobile-buttons button {
  padding: 6px;
}
nav .mobile-buttons button.search-button {
  position: absolute;
  left: 2px;
}
nav .mobile-buttons button.menu-button {
  border-left: 1px solid #888;
  margin-left: 12px;
  padding-left: 12px;
}
nav .mobile-buttons button:hover svg {
  opacity: 0.5;
}

::-webkit-input-placeholder {
  color: #a6a6a6;
}

::-moz-placeholder {
  color: #a6a6a6;
}

:-ms-input-placeholder {
  color: #a6a6a6;
}

.show {
  display: block;
}

/*  buttons
    --------------------------------*/
.buttons {
  position: relative;
  display: inline-block;
}

a.button,
button.button {
  padding: 0 0.8rem;
  border-radius: 3px;
  border: 1px solid #dd1100;
  color: #dd1100 !important;
  display: inline-block;
  position: relative;
  font-size: 1rem;
  height: 35px;
  line-height: 33px;
}

a.button.red,
button.button.red,
a.button.orange:hover,
button.button.orange:hover {
  color: #fff !important;
  background: #d10;
  background-color: #d10;
}
a.button.orange,
button.button.orange,
a.button.red:hover,
button.button.red:hover {
  color: #fff !important;
  background: #f77700;
  border-color: #f77700;
}

.source-menu button.white {
  background-color: white;
}

a.button.arrow,
button.button.arrow {
  padding: 0 1.5rem 0 0;
  width: 152px;
}

a.button.arrow:after,
button.button.arrow:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 9px solid #dd1100;
  border-bottom: 9px solid transparent;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  display: inline-block;
  position: absolute;
  top: 0.6rem;
  bottom: 0;
  right: 2.7rem;
  margin: auto;
}

/*  popup
    --------------------------------*/
.white-popup-block {
  max-width: 456px;
  padding: 15px 20px 15px 20px;
}

.white-popup-block:after {
  content: "";
  display: block;
  clear: both;
}

.white-popup-block p.heading {
  color: #dd1100;
  font-size: 1.5rem;
  margin: 0 0 1rem;
  line-height: 1;
}

.white-popup-block p {
  color: #777;
  line-height: 1.26;
  margin: 1.5rem 0;
  font-size: 0.975rem;
}

.white-popup-block a {
  color: #dd1100;
}

.white-popup-block a:hover {
  color: #f77700;
}

.white-popup-block a.button {
  font-size: 0.975rem;
  margin: 0 0 1rem 0;
}

.white-popup-block a.button:first-of-type {
  margin: 0 0.3rem 1rem 0;
}

.white-popup-block a.button.right {
  float: right;
}

/*  footer
    --------------------------------*/
.wrapper {
  position: relative;
  height: auto;
  min-height: 100%;
}

footer.site-footer {
  background: #535353;
  padding: 2em;
  color: #949494;
  font-size: clamp(9px, 3vw, 12px);
  text-align: center;
}
@media all and (max-width: 580px) {
  footer.site-footer .inner {
    width: 30em;
  }
}
footer.site-footer p {
  margin: 1em 0;
}
footer.site-footer a {
  color: inherit;
}
footer.site-footer a:hover {
  color: #fff;
}
footer.site-footer .footer-top {
  color: #e0e0e0;
  font-size: clamp(1.16666667em, 1.6vw, 1.33333333em);
  font-weight: 600;
}
@media all and (min-width: 1000px) {
  footer.site-footer .footer-top {
    display: flex;
    justify-content: space-around;
  }
  footer.site-footer p {
    margin: 1.5em 0;
  }
  footer.site-footer .footer-top svg,
  footer.site-footer .footer-top span {
    display: inline-block;
    vertical-align: middle;
  }
  footer.site-footer .footer-top svg {
    fill: #ddd;
    height: 1.875em;
    margin-right: 0.75em;
  }
  footer.site-footer .footer-top span {
    line-height: 1.875em;
  }
  footer.site-footer .footer-top a:hover svg {
    fill: #fff;
  }
}

/********************************

feedback form 

**********************************/
/**************
grey-out background while form open
***************/
.form-open-background {
  /*opacity: 0.6;*/
  filter: brightness(0.5);
  background-color: #808080ad;
}

div#dialog-form label,
div#dialog-form input {
  display: block;
}

div#dialog-form input.text {
  margin-bottom: 12px;
  width: 95%;
  padding: 0.4em;
}

div#dialog-form fieldset {
  padding: 0;
  border: 0;
  margin-top: 0px;
}

/*
    h1 {
     font-size: 1.2em; margin: .6em 0; 
 }*/
div#users-contain {
  width: 350px;
  margin: 20px 0;
}

div#users-contain table {
  margin: 1em 0;
  border-collapse: collapse;
  width: 100%;
}

div#users-contain table td,
div#users-contain table th {
  border: 1px solid #eee;
  padding: 0.6em 10px;
  text-align: left;
}

.ui-dialog .ui-state-error {
  padding: 0.3em;
}

.validateTips {
  border: 1px solid transparent;
  padding: 0.3em;
}

div.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-front.ui-resizable
  > div.ui-dialog-titlebar.ui-widget-header.ui-corner-all.ui-helper-clearfix
  > button {
  margin-right: 6px;
  background: white;
  border: none;
}

body
  > div.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-front.ui-resizable
  > div.ui-dialog-titlebar.ui-widget-header.ui-corner-all.ui-helper-clearfix
  > button
  > span.ui-button-text {
  background: white;
  /* border: none; */
  color: gray;
}

div.ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-front.ui-resizable {
  height: 306px;
  width: 450px;
  border-radius: 3px;
  border-width: 1px;
  border-color: #d1d1d1;
  background-color: white;
  position: relative;
  top: -208.188px;
  left: 1054.5px;
  display: block;
}

.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-resizable {
  color: white;
  background-color: white;
  border: 1px solid #e8e8e8;
  padding: 1em;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.ui-resizable {
  color: #bbb;
  background-color: #fcfcfc;
  border: 1px solid #e8e8e8;
  padding: 1em;
}

.ui-dialog-titlebar.ui-corner-all.ui-widget-header.ui-helper-clearfix {
  height: 0px;
}

.ui-state-error {
  border: 1px solid red;
}

.ui-dialog-titlebar-close {
  position: absolute;
  top: 0px;
  right: 0px;
}

#sendfeedbackbutton:hover {
  background-color: #f77700;
  cursor: pointer;
}

#sendfeedbackbutton {
  background-color: #dd1100;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: white;
  border-radius: 5px;
  height: 35px;
  width: 54px;
  line-height: 33px;
}

div#dialog-form span#btnLbl {
  font-size: 15px;
  font-weight: 400;
}

div#dialog-form span.reqLbl {
  visibility: hidden;
  color: red;
}

div#dialog-form
  button.ui-button.ui-corner-all.ui-widget.ui-button-icon-only.ui-dialog-titlebar-close {
  background: none;
  border: none;
}

div#dialog-form input {
  height: 30px;
  margin-right: 18px;
}

/*    div#dialog-form input::placeholder {
        font-style: italic !important;
    }*/
div#dialog-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: italic !important;
}

div#dialog-form input::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic !important;
}

div#dialog-form input::-ms-input-placeholder {
  /* IE 10+ */
  font-style: italic !important;
}

div#dialog-form input::-moz-placeholder {
  /* Firefox 18- */
  font-style: italic !important;
}

div#dialog-form input,
div#dialog-form textarea {
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 14px;
  display: block;
  padding: 6px 9px;
  font-size: 15px;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.field-lbl {
  font-weight: 400;
  font-size: 14px;
  color: #494949;
}

div#disclaimer {
  width: 332px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #777777;
}

#feedback-content {
  width: 420px;
  height: 63px;
}

div#sendfeedbackbutton {
  float: right;
  margin-top: -33px;
  margin-right: 19px;
}

code,
kbd,
pre,
samp,
tt {
  font-family: "Source Sans Pro" !important;
  font-weight: 600 !important;
}

.site-root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  min-width: 320px;
}
.site-root main {
  flex: 1;
}
