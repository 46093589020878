main.resource-table-page .inner {
  max-width: 1480px;
}

main.resource-table-page h1 {
  color: #444;
  font-size: 2.25rem;
  font-weight: 500;
  margin: 3.75rem 0 2.25rem;
  letter-spacing: -0.02rem;
}

main.resource-table-page .page-controls {
  font-size: 0.875em;
  color: #555;
  margin: -2.25em 0 0;
  display: flex;
  justify-content: space-between;
}

main.resource-table-page .page-controls p.show-titles {
  font-size: 1.14285714em;
  margin-top: -0.125em;
}
