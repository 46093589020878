#loading-page {
  position: absolute;
  width: 60vw;
  height: 60vh;
  margin: 20vh 20vw;
  font-size: clamp(1.2rem, 3vw, 1.8rem);
  line-height: 1.5;
  text-align: center;
  overflow: hidden;
}
#loading-page .demonstrations-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: 5s ease-in loading-fadeIn,
    10s infinite 4s ease-in-out loading-spin;
}

@keyframes loading-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
